import { alpha } from '@mui/material/styles';
import { ENVIRONMENT } from 'src/utils/env';
import { hasPermission } from 'src/utils/accessControl';
import { Staff } from 'src/App';

function palette() {
  // TODO:jestでのテスト実行の時/になるみたい、mockで解決したい
  const pathname = window.location.pathname !== '/' ? window.location.pathname : '/adminStaff';

  const staff: Staff = {
    loggedName: '',
    loggedRole: 'admin',
    userType: pathname.split('/')[1] as 'adminStaff' | 'marriageAgencyStaff',
    marriageAgencyStaffId: '',
  };

  return {
    common: { black: '#000', white: '#fff' },
    primary: PRIMARY(staff),
    secondary: { ...SECONDARY },
    cancel: { ...CANSELL },
    info: { ...INFO },
    success: { ...SUCCESS },
    warning: { ...WARNING },
    error: { ...ERROR },
    grey: GREY,
    gradients: GRADIENTS(staff),
    chart: CHART_COLORS,
    divider: GREY[500_24],
    text: { primary: GREY[800], secondary: GREY[600], disabled: GREY[500] },
    background: { paper: '#fff', default: GREY[100], neutral: GREY[200] },
    action: {
      active: GREY[600],
      hover: GREY[500_8],
      selected: GREY[500_16],
      disabled: GREY[500_80],
      disabledBackground: GREY[500_24],
      focus: GREY[500_24],
      hoverOpacity: 0.08,
      disabledOpacity: 0.48,
    },
  };
}

// ----------------------------------------------------------------------

function createGradient(color1: any, color2: any) {
  return `linear-gradient(to bottom, ${color1}, ${color2})`;
}

// SETUP COLORS
const GREY = {
  0: '#FFFFFF',
  100: '#F9FAFB',
  200: '#F4F6F8',
  300: '#DFE3E8',
  400: '#C4CDD5',
  500: '#919EAB',
  600: '#637381',
  700: '#454F5B',
  800: '#212B36',
  900: '#161C24',
  500_8: alpha('#919EAB', 0.08),
  500_12: alpha('#919EAB', 0.12),
  500_16: alpha('#919EAB', 0.16),
  500_24: alpha('#919EAB', 0.24),
  500_32: alpha('#919EAB', 0.32),
  500_48: alpha('#919EAB', 0.48),
  500_56: alpha('#919EAB', 0.56),
  500_80: alpha('#919EAB', 0.8),
};

const PRIMARY = (staff: Staff) => {
  if (!staff.userType.match(/(adminStaff|marriageAgencyStaff|photoStudioStaff|photoStudioCustomer)/))
    return {
      lighter: '#808080',
      light: '#808080',
      main: '#808080',
      dark: '#808080',
      darker: '#808080',
      contrastText: '#fff',
    };
  return ENVIRONMENT === 'pro' ? hasPermission(staff, 'color', 'pro') : hasPermission(staff, 'color', 'dev');
};
const SECONDARY = {
  lighter: '#D6E4FF',
  light: '#84A9FF',
  main: '#3366FF',
  dark: '#1939B7',
  darker: '#091A7A',
  contrastText: '#fff',
};

const INFO = {
  lighter: '#D0F2FF',
  light: '#74CAFF',
  main: '#1890FF',
  dark: '#0C53B7',
  darker: '#04297A',
  contrastText: '#fff',
};

const SUCCESS = {
  lighter: '#E9FCD4',
  light: '#AAF27F',
  main: '#54D62C',
  dark: '#229A16',
  darker: '#08660D',
  contrastText: GREY[800],
};

const WARNING = {
  lighter: '#FFF7CD',
  light: '#FFE16A',
  main: '#FFC107',
  dark: '#B78103',
  darker: '#7A4F01',
  contrastText: GREY[800],
};

const ERROR = {
  lighter: '#ff9e9c',
  light: '#ff7e7b',
  main: '#FF5E5B',
  dark: '#cc4b48',
  darker: '#993836',
  contrastText: '#fff',
};

const CANSELL = {
  lighter: '#808080',
  light: '#808080',
  main: '#808080',
  dark: '#808080',
  darker: '#808080',
  contrastText: '#fff',
};

const GRADIENTS = (staff: Staff) => ({
  primary: createGradient(PRIMARY(staff).light, PRIMARY(staff).main),
  info: createGradient(INFO.light, INFO.main),
  success: createGradient(SUCCESS.light, SUCCESS.main),
  warning: createGradient(WARNING.light, WARNING.main),
  error: createGradient(ERROR.light, ERROR.main),
});

const CHART_COLORS = {
  violet: ['#826AF9', '#9E86FF', '#D0AEFF', '#F7D2FF'],
  blue: ['#2D99FF', '#83CFFF', '#A5F3FF', '#CCFAFF'],
  green: ['#2CD9C5', '#60F1C8', '#A4F7CC', '#C0F2DC'],
  yellow: ['#FFE700', '#FFEF5A', '#FFF7AE', '#FFF3D6'],
  red: ['#FF6C40', '#FF8F6D', '#FFBD98', '#FFF2D4'],
};

export default palette();
