// icons
import { Icon } from '@iconify/react';
// @mui
import { Box } from '@mui/material';
import { FC } from 'react';

export default function Iconify({ icon, sx, ...other }: any) {
  return <Box component={Icon} icon={icon} sx={{ ...sx }} {...other} />;
}

export const getIcon: FC<string> = (name: string) => <Iconify icon={name} width={22} height={22} />;
