import { FC, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Stack, Grid, Card, CardHeader } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { FormProvider, RHFTextField, RHFTextArea } from 'src/components/atoms/hook-form';
import { photoStudioIntroductionValidationSchema } from 'src/functions/validation-schema';
import usePush from 'src/hooks/use-push';
import { useParams } from 'react-router-dom';
import { PhotoStudioIntroduction } from 'codegen/axios/photo/admin';
import useFetch from 'src/hooks/use-fetch';
import { introductionFormFetchOrganize } from 'src/functions/fetch-value-organize';

export type IntroductionFormType = Omit<PhotoStudioIntroduction, 'photoStudioIntroductionId'>;

export const defaultValues: IntroductionFormType = {
  photoStudioId: '',
  commentTitle: '',
  commentBody: '',
};

export const IntroductionForm: FC = () => {
  /* hook宣言 */
  const push = usePush();
  const { id: photoStudioId } = useParams();
  const { introductionId: photoStudioIntroductionId } = useParams();

  /* 定数宣言 */
  const editMode = !photoStudioIntroductionId;
  const validationSchema = photoStudioIntroductionValidationSchema();

  /* データ取得 */
  const { fetchedData: responseData } = useFetch<IntroductionFormType>({
    iniState: defaultValues,
    reqProp: {
      pathKey: 'introduction',
      pathParam: photoStudioIntroductionId,
    },
    disable: editMode,
    organize: introductionFormFetchOrganize,
  });

  const methods = useForm<IntroductionFormType>({
    resolver: yupResolver(validationSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  useEffect(() => {
    methods.reset(responseData);
  }, [responseData, methods]);

  const onSubmit = (formValue: IntroductionFormType) =>
    new Promise((resolve) => {
      push(resolve, {
        reqProp: {
          pathKey: 'introduction',
          pathParam: photoStudioIntroductionId,
          method: editMode ? 'post' : 'put',
          data: { ...formValue, photoStudioId },
        },
        aftProp: {
          resolve,
          nextPath: `/setting/photoStudio/${photoStudioId}`,
          showMessage: {
            state: {
              snackBar: { open: true, viewType: 'success', message: editMode ? '登録しました' : '保存しました' },
            },
          },
        },
      });
    });
  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Card>
        <CardHeader title="店舗紹介情報" />

        <Stack my={7} mx={4}>
          <Grid container rowSpacing={3} columnSpacing={3}>
            <Grid item xs={12} sm={6} md={6}>
              <RHFTextField
                name="commentTitle"
                label="タイトル"
                placeholder="例：主要駅から徒歩10分以内のフォトスタジオ"
              />
            </Grid>

            <Grid item pt={4} xs={12} sm={12} md={9}>
              <RHFTextArea
                name="commentBody"
                label="内容"
                placeholder={`例：当フォトスタジオでは、プロのカメラマンによる高品質な写真撮影を提供しています。ポートレート、ウェディング、家族写真、商品撮影など、お客様のニーズに合わせた様々なジャンルの撮影に対応しております。また、最新の機材を取り揃え、快適な空間で撮影を行うことができます。お客様の大切な思い出を、美しく切り取るお手伝いをさせていただきます。`}
              />
            </Grid>
          </Grid>
        </Stack>
      </Card>

      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }} />

      <Stack direction="row" alignItems="center" justifyContent="flex-end" sx={{ m: 1 }} spacing={8}>
        <LoadingButton sx={{ width: 200 }} size="large" type="submit" variant="contained" loading={isSubmitting}>
          {editMode ? '登録' : '保存'}
        </LoadingButton>
      </Stack>
    </FormProvider>
  );
};
