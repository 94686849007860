import { FC } from 'react';
import { Container, Stack, Typography } from '@mui/material';
import Page from 'src/components/molecules/page';
import { TeamForm } from 'src/components/organisms/team/form';

export const TeamEditPage: FC = () => {
  const title = '所属チーム編集';

  return (
    <Page title={title}>
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            {title}
          </Typography>
        </Stack>
        <TeamForm />
      </Container>
    </Page>
  );
};
