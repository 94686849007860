import { FC, useState, useRef } from 'react';
import FullCalendar, { EventClickArg, DatesSetArg } from '@fullcalendar/react';
import timeGridPlugin from '@fullcalendar/timegrid';
import scrollGridPlugin from '@fullcalendar/scrollgrid';
import { useParams, useSearchParams } from 'react-router-dom';
import {
  photoStudioCalendarFetchOrganize,
  selectedOptionFetchOrganize,
  selectedOptionValueOrganize,
} from 'src/functions/fetch-value-organize';
import useFetch from 'src/hooks/use-fetch';
import { Box, Card, Container, Dialog, Grid, LinearProgress, Typography, useMediaQuery, useTheme } from '@mui/material';
import { MarriageAgencyStaffPhotoOption, MarriageAgencyStaffPhotoPlan } from 'codegen/axios/photo/adca/api';
import { ReservationFormModal } from 'src/components/organisms/reservation-for-adca/form/schedule/form-modal';
import { CalendarHeader } from 'src/components/organisms/reservation-for-adca/form/schedule/header';
import { toApiDateTimeFormat } from 'src/functions/date-time-organize';

export const reservationFormDefaultValue = {
  reservationStartDateTime: '',
  reservationEndDateTime: '',
  photoStudioId: '',
  photoStudioPlanId: '',
  photoStudioOptions: [],
  customerFirstName: '',
  customerLastName: '',
  gender: 'man',
  email: '',
  telno: '',
  remark: '',
};

type Props = {
  plan: MarriageAgencyStaffPhotoPlan;
  selectedOptions: Array<MarriageAgencyStaffPhotoOption>;
};

export const ReservableSchedule: FC<Props> = ({ plan, selectedOptions }) => {
  /* searchParams */
  const [searchParams] = useSearchParams();
  const { id: photoStudioId } = useParams();
  const { planId: photoStudioPlanId } = useParams();
  const date: string = searchParams.get('date') || new Date().toString();

  /* hooks宣言 */
  const theme = useTheme();
  const [openReservationFormModal, setOpenReservationFormModal] = useState<boolean>(false);
  const [defaultValues, setDefaultValues] = useState<any>(reservationFormDefaultValue);
  const [startDate, setStartDate] = useState<Date | undefined>(undefined);
  const [endDate, setEndDate] = useState<Date | undefined>(undefined);
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'));

  const buttonRef = useRef(null);
  /* データ取得 */
  const { fetchedData: event, loading } = useFetch<any>({
    reqProp: {
      method: 'post',
      pathKey: 'calendar',
      queryParams: {
        startDateTime: startDate && toApiDateTimeFormat(startDate),
        endDateTime: endDate && toApiDateTimeFormat(endDate),
      },
      data: {
        photoStudioId,
        photoStudioPlanId,
        photoStudioOptionIds: selectedOptionFetchOrganize(selectedOptions),
      },
    },
    organize: photoStudioCalendarFetchOrganize,
    disable: !startDate || !endDate || !selectedOptions || !photoStudioPlanId,
    observable: [startDate, endDate, selectedOptions, photoStudioPlanId],
  });

  // 予約イベント取得用の日付セット
  const datesSet = (arg: DatesSetArg) => {
    setStartDate(arg.start);
    setEndDate(arg.end);
  };

  // 予約枠クリックで予約登録
  const eventClick = (info: EventClickArg) => {
    const { start, end } = info.event;
    if (info.event.extendedProps.stock === 0) return;

    if (start && end && photoStudioPlanId) {
      setDefaultValues({
        ...defaultValues,
        photoStudioPlanId,
        photoStudioOptions: selectedOptionValueOrganize(selectedOptions),
        reservationStartDateTime: start.toString(),
        reservationEndDateTime: end.toString(),
      });
      setOpenReservationFormModal(true);
    }
  };

  const onClose = () => {
    ((buttonRef.current || document.body) as HTMLElement).focus();
    setOpenReservationFormModal(false);
    setDefaultValues(reservationFormDefaultValue);
  };

  const calendarRef = useRef<FullCalendar>(null!);
  const renderEventContent = (eventInfo: any) => (
    <Grid container direction="column" justifyContent="center" alignItems="center">
      <Grid item>{eventInfo.event.title} 〜</Grid>
      <Grid item>
        {eventInfo.event.extendedProps.content} {eventInfo.event.extendedProps.rest}
      </Grid>
    </Grid>
  );

  return (
    <>
      <Typography variant="h5">日時を選択して予約する</Typography>
      <Container>
        <Card sx={{ mt: 5 }}>
          <Box sx={{ width: '95%', height: '50%', m: 4 }}>
            <CalendarHeader filterUseProperty={false} calendarRef={calendarRef} />
            {loading && <LinearProgress />}
            <FullCalendar
              scrollTimeReset={false}
              contentHeight={window.innerHeight * 0.75}
              datesSet={datesSet}
              ref={calendarRef}
              schedulerLicenseKey="0439324989-fcs-1673827335"
              slotDuration={'0:15'}
              slotLabelFormat={{
                hour: 'numeric',
                minute: '2-digit',
                omitZeroMinute: false,
                meridiem: 'short',
              }}
              dayHeaderFormat={
                isMdUp
                  ? undefined
                  : {
                      day: 'numeric',
                      weekday: 'short',
                    }
              }
              slotMinTime="00:00:00"
              slotMaxTime="24:00:00"
              scrollTime="09:00:00"
              eventContent={renderEventContent}
              initialView={'timeGridWeek'}
              initialDate={new Date(date)}
              dayCellContent={(e) => e.dayNumberText.replace('日', '')}
              slotLabelInterval="01:00"
              eventClick={eventClick}
              nowIndicator
              plugins={[scrollGridPlugin, timeGridPlugin]}
              headerToolbar={false}
              events={event}
              locale="ja"
              dayMinWidth={isMdUp ? 180 : 46}
              slotEventOverlap={false}
              allDaySlot={false}
            />
          </Box>
        </Card>
        <Dialog maxWidth="sm" open={openReservationFormModal}>
          <ReservationFormModal
            {...{
              plan,
              selectedOptions,
              defaultValues,
              setDefaultValues,
              onClose,
            }}
          />
        </Dialog>
      </Container>
    </>
  );
};
