import { FC } from 'react';
import { StaffDetail } from 'src/components/organisms/staff/detail';
import Page from 'src/components/molecules/page';

export const StaffDetailPage: FC = () => {
  const title = 'スタッフ詳細';

  return (
    <Page title={title}>
      <StaffDetail />
    </Page>
  );
};
