import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

export default function MultipleSelect({
  onChange,
  textFieldOnChange,
  option,
  defaultValue,
  multiple,
  noOptionsText,
  filterOptions,
  isOptionEqualToValue,
  ...other
}: any) {
  return (
    <Autocomplete
      {...(() => {
        if (filterOptions) return { filterOptions };
        return false;
      })()}
      {...(() => ({
        multiple,
        noOptionsText,
      }))()}
      id="combo-box-demo"
      isOptionEqualToValue={isOptionEqualToValue}
      renderOption={(props, option) => (
        <li {...props} key={option.id}>
          {option.label}
        </li>
      )}
      value={defaultValue}
      options={option}
      onChange={(event, item) => {
        onChange(event, item);
      }}
      sx={{ width: 300, mr: 3 }}
      renderInput={(params) => (
        <TextField
          onChange={(event: any, item: any) => {
            textFieldOnChange(event, item);
          }}
          {...other}
          {...params}
        />
      )}
    />
  );
}
