import { FC, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Stack, Grid, Card, CardHeader, Box, Typography, Button } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { FormProvider, RHFDatePicker, RHFTimePicker, RHFRadioButtonGroup } from 'src/components/atoms/hook-form';
import { photoStudioIrregularSettingValidationSchema } from 'src/functions/validation-schema';
import usePush from 'src/hooks/use-push';
import { useLocation, useParams, useSearchParams } from 'react-router-dom';
import useFetch from 'src/hooks/use-fetch';
import { PhotoReservationFrameIrregularSetting } from 'codegen/axios/photo/photo_studio';
import { irregularSettingFormFetchOrganize } from 'src/functions/fetch-value-organize';
import { dayOfWeekOptions } from 'src/utils/option';
import { irregularSettingValueOrganize } from 'src/functions/push-value-organize';
import DeleteModal from 'src/components/molecules/delete-modal';
import { getFromLocalStorage, saveToLocalStorage } from 'src/utils/storage';
import { MdAssignment, MdFileCopy } from 'react-icons/md';

export type IrregularSettingFormType = Omit<
  PhotoReservationFrameIrregularSetting,
  | 'photoStudioReservationFrameIrregularSettingId'
  | 'photoStudioReservationFrameId'
  | 'reservationStartTime'
  | 'reservationEndTime'
> & {
  reservationStartTime: Date;
  reservationEndTime: Date;
};

export const defaultValues: IrregularSettingFormType = {
  photoStudioId: '',
  validStartDate: '',
  validEndDate: '',
  reservationStartTime: new Date(2023, 4, 30, 10, 0),
  reservationEndTime: new Date(2023, 4, 30, 19, 0),
  mondayAvailableFlg: true,
  tuesdayAvailableFlg: true,
  wednesdayAvailableFlg: true,
  thursdayAvailableFlg: true,
  fridayAvailableFlg: true,
  saturdayAvailableFlg: true,
  sundayAvailableFlg: true,
  openOrCloseTag: 'close',
};

export const IrregularSettingForm: FC = () => {
  /* hook宣言 */
  const title = 'イレギュラー設定編集';
  const push = usePush();
  const { id: photoStudioReservationFrameId } = useParams();
  const { photoStudioId } = useParams();
  const { irregularSettingId: photoStudioIrregularSettingId } = useParams();
  const [searchParams] = useSearchParams();
  const startTime = searchParams.get('start');
  const endTime = searchParams.get('end');

  /* 定数宣言 */
  const editMode = !photoStudioIrregularSettingId;
  const validationSchema = photoStudioIrregularSettingValidationSchema();

  /* データ取得 */
  const { fetchedData: responseData } = useFetch<IrregularSettingFormType>({
    iniState: defaultValues,
    reqProp: {
      pathKey: 'irregular',
      pathParam: photoStudioIrregularSettingId,
    },
    disable: editMode,
    organize: irregularSettingFormFetchOrganize,
  });

  const methods = useForm<IrregularSettingFormType>({
    defaultValues: {
      ...defaultValues,
      reservationStartTime: setTime(defaultValues.reservationStartTime, startTime),
      reservationEndTime: setTime(defaultValues.reservationEndTime, endTime),
    },
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  useEffect(() => {
    methods.reset(responseData);
  }, [responseData, methods]);

  const copyFormDataToLocalStorage = () => {
    saveToLocalStorage(`frame/irregular-formData`, methods.getValues());
  };

  const pasteFormDataFromLocalStorage = () => {
    const data = getFromLocalStorage(`frame/irregular-formData`);
    if (data !== null) {
      methods.reset(data);
    }
  };

  const onSubmit = (formValue: IrregularSettingFormType) =>
    photoStudioReservationFrameId &&
    new Promise((resolve) => {
      push(resolve, {
        reqProp: {
          pathKey: 'frame',
          pathParam: editMode
            ? `${photoStudioReservationFrameId}/irregulars`
            : `irregulars/${photoStudioIrregularSettingId}`,
          method: editMode ? 'post' : 'put',
          data: editMode
            ? { ...irregularSettingValueOrganize(formValue), photoStudioId }
            : { ...irregularSettingValueOrganize(formValue), photoStudioReservationFrameId },
        },
        aftProp: {
          resolve,
          nextPath: `/setting/frame/${photoStudioReservationFrameId}`,
          showMessage: {
            state: {
              snackBar: { open: true, viewType: 'success', message: editMode ? '登録しました' : '保存しました' },
            },
          },
        },
      });
    });

  const onDelete = () => {
    new Promise((resolve) =>
      push(resolve, {
        reqProp: {
          pathKey: 'frame',
          method: 'delete',
          pathParam: `irregulars/${photoStudioIrregularSettingId}`,
        },
        aftProp: {
          resolve,
          nextPath: `/setting/frame/${photoStudioReservationFrameId}`,
          showMessage: {
            state: { snackBar: { open: true, viewType: 'info', message: '削除しました' } },
          },
        },
      })
    );
  };

  return (
    <>
      {!editMode && (
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            {title}
          </Typography>
          <Box sx={{ ml: 'auto' }}>
            <DeleteModal
              sx={{
                bgcolor: 'background.paper',
                minWidth: 50,
                minHeight: 47,
                boxShadow: 1,
                borderRadius: 2,
                ml: 2,
              }}
              OnDelete={onDelete}
            />
          </Box>
        </Stack>
      )}
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Card>
          <CardHeader title="基本情報" />
          <Button variant="outlined" onClick={copyFormDataToLocalStorage} sx={{ mr: 2, ml: 3, mt: 2 }}>
            <MdFileCopy />
            コピー
          </Button>

          <Button variant="outlined" onClick={pasteFormDataFromLocalStorage} sx={{ mt: 2 }}>
            <MdAssignment />
            ペースト
          </Button>
          <Stack my={7} mx={4}>
            <Grid container rowSpacing={3} columnSpacing={3}>
              <Grid item xs={12} sm={6} md={3}>
                <RHFDatePicker name="validStartDate" label="有効期間(開始)" />
              </Grid>

              <Grid item xs={12} sm={6} md={3}>
                <RHFDatePicker name="validEndDate" label="有効期間(終了)" />
              </Grid>

              {dayOfWeekOptions.map((dayOfWeek) => (
                <Grid key={dayOfWeek.name} item pt={4} xs={6} sm={3} md={12}>
                  <RHFRadioButtonGroup
                    label={dayOfWeek.label}
                    name={dayOfWeek.name}
                    disabled={!editMode}
                    options={[
                      {
                        value: true,
                        label: '有効',
                      },
                      {
                        value: false,
                        label: '無効',
                      },
                    ]}
                  />
                </Grid>
              ))}

              <Grid item pt={4} xs={6} sm={3} md={3}>
                <RHFTimePicker
                  name="reservationStartTime"
                  label="有効時間（開始）"
                  custom={{ minutesStep: 15, inputFormat: 'HH:mm' }}
                  disabled={!editMode}
                />
              </Grid>

              <Grid item pt={4} xs={6} sm={3} md={3}>
                <RHFTimePicker
                  name="reservationEndTime"
                  label="有効時間（終了）"
                  custom={{ minutesStep: 15, inputFormat: 'HH:mm' }}
                  disabled={!editMode}
                />
              </Grid>

              <Grid item pt={4} xs={6} sm={3} md={12}>
                <RHFRadioButtonGroup
                  label="OPEN/CLOSE"
                  name="openOrCloseTag"
                  options={[
                    {
                      value: 'open',
                      label: 'OPEN',
                    },
                    {
                      value: 'close',
                      label: 'CLOSE',
                    },
                  ]}
                />
              </Grid>
            </Grid>
          </Stack>
        </Card>

        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }} />

        <Stack direction="row" alignItems="center" justifyContent="flex-end" sx={{ m: 1 }} spacing={8}>
          <LoadingButton sx={{ width: 200 }} size="large" type="submit" variant="contained" loading={isSubmitting}>
            {editMode ? '登録' : '保存'}
          </LoadingButton>
        </Stack>
      </FormProvider>
    </>
  );
};
function setTime(date: Date, timeString: string | null) {
  if (!timeString) return date;
  const [hour, minute] = timeString.split(':').map((str) => parseInt(str, 10));
  const newDate = new Date(date.setHours(hour, minute));
  return newDate;
}
