import * as React from 'react';
import { Typography, Box, Button, Container, useTheme, useMediaQuery } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import { MdOutlineCheckBoxOutlineBlank } from 'react-icons/md';
import useFetch from 'src/hooks/use-fetch';
import {
  PhotoStudioCustomerPhotoStudio,
  PhotoStudioCustomerPhotoStudioOption,
  PhotoStudioCustomerPhotoStudioPlan,
} from 'codegen/axios/photo/photo_studio_customer';
import useStaffInformation from 'src/hooks/use-staff-information';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { EventClickArg } from '@fullcalendar/react';
import { toApiDateTimeFormat } from 'src/functions/date-time-organize';
import { getPlanViewTitle } from 'src/utils/customerIndexView';
import useFetchQuery from 'src/hooks/use-custom-fetch';
import { ReservationScheduleForCustomer } from './schedule';
import { CustomStepper } from '../components/CustomStepper';
import { useHooks } from './useHooks';

export const CustomerReservationSelect: React.FC = () => {
  const staff = useStaffInformation();
  const navigate = useNavigate();
  const { pathname, search, hash } = useLocation();
  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'));
  const isSmUp = useMediaQuery(theme.breakpoints.up('sm'));
  const savedData = sessionStorage.getItem(`cmr-rsv-selected-data-${search}`);
  const oldData = savedData ? JSON.parse(savedData) : null;
  const [searchParams] = useSearchParams();
  const paramsId = searchParams.get('id') ?? 1;
  const { photoPlanIdsString, viewTitle, photoCompanyId, photoStudioIds } = getPlanViewTitle(Number(paramsId));

  const photoPlanIds = photoPlanIdsString ? photoPlanIdsString.split(',') : null;
  const [selectedStudioId, setSelectedStudioId] = React.useState<string | null>(
    (oldData && oldData.selectedStudioId) || null
  );
  const [selectedPlanId, setSelectedPlanId] = React.useState<string | null>(
    (oldData && oldData.selectedPlanId) || null
  );
  const [selectedOptionIds, setSelectedOptionIds] = React.useState<string[]>(
    (oldData && oldData.selectedOptionIds) || []
  );
  const [total, setTotal] = React.useState<{ price: number; time: number }>(
    (oldData && oldData.total) || {
      price: 0,
      time: 0,
    }
  );

  /* データ取得 */
  sessionStorage.removeItem('cmr-rsv-form-data'); // 予約入力画面から戻ってきたときに、前回の入力値を消す

  const { data: storeRaw } = useFetchQuery<PhotoStudioCustomerPhotoStudio[]>({
    enabled: !!photoCompanyId,
    pathKey: 'studio',
    queryParams: {
      offset: 0,
      limit: 100,
      photoCompanyId,
    },
  });

  const { data: plansRaw } = useFetchQuery<PhotoStudioCustomerPhotoStudioPlan[]>({
    enabled: !!selectedStudioId,
    pathKey: 'plan',
    queryParams: {
      offset: 0,
      limit: 100,
      photoStudioId: selectedStudioId,
    },
  });
  const plansOrganize = (data: PhotoStudioCustomerPhotoStudioPlan[]) => {
    if (!photoPlanIds) return data;
    const filteredData = data.filter((obj) => photoPlanIds.includes(obj.photoStudioPlanId));
    const sortedData = photoPlanIds
      .map((id) => filteredData.find((obj) => obj.photoStudioPlanId === id)!)
      .filter(Boolean);
    return sortedData.filter((obj) => obj !== undefined);
  };
  const plans = plansRaw ? plansOrganize(plansRaw) : null;
  const { sortedDisplayStudios: displayStores } = useHooks(storeRaw, photoStudioIds);
  const { fetchedData: options } = useFetch<PhotoStudioCustomerPhotoStudioOption[]>({
    iniState: null,
    reqProp: {
      pathKey: 'option',
      queryParams: {
        offset: 0,
        limit: 100,
        photoStudioPlanId: selectedPlanId,
      },
    },
    disable: !selectedPlanId,
    observable: [selectedPlanId],
  });

  React.useEffect(() => {
    if (!plans) return undefined;
    const newTotal = calculateTotal(selectedPlanId || '', selectedOptionIds, plans, options);
    return setTotal(newTotal);
  }, [selectedPlanId, selectedOptionIds]);

  React.useEffect(() => {
    // selectedOptionIdsの中でoptionsのidにないものを削除
    const newSelectedOptionIds = selectedOptionIds.filter((id) => options?.find((o) => o.photoStudioOptionId === id));
    setSelectedOptionIds(newSelectedOptionIds);
  }, [options]);

  const calendarOnClick = (info: EventClickArg, date: Date) => {
    // deleteとかで使うために検索条件を保存
    sessionStorage.setItem(`top-return-url`, `${pathname}${search}${hash}`);
    sessionStorage.setItem(
      `cmr-rsv-selected-data-${search}`,
      JSON.stringify({ selectedStudioId, selectedPlanId, selectedOptionIds, total, date: date.toString() })
    );
    navigate(`/${staff.userType}/reservation/input${search}${hash}`, {
      state: {
        reservationStartDateTime: toApiDateTimeFormat(info.event.start!),
        photoStudio: displayStores.find((obj) => obj.photoStudioId === selectedStudioId),
        photoStudioPlan: plans?.find((obj) => obj.photoStudioPlanId === selectedPlanId),
        photoStudioOptions: selectedOptionIds.map((id) => options.find((o) => o.photoStudioOptionId === id)),
      },
    });
  };

  return (
    <Container
      maxWidth={!isMdUp ? 'lg' : undefined}
      sx={
        !isMdUp
          ? {
              paddingLeft: 0,
              paddingRight: 0,
            }
          : undefined
      }
    >
      <Box sx={{ pb: isMdUp ? 1 : 2, pt: isMdUp ? 3 : 0, pl: isMdUp ? 3 : 0 }}>
        <Typography variant="h4" color="text.secondary" sx={{ mb: 1.5 }}>
          撮影予約
        </Typography>
        <Typography color="primary" variant={isMdUp ? 'h6' : 'subtitle2'} sx={{ display: 'inline' }}>
          {viewTitle}
        </Typography>
      </Box>

      <CustomStepper activeStep={0} />
      <Typography variant={isMdUp ? 'h6' : 'subtitle2'} color="text.secondary" sx={{ pb: 1, pl: isSmUp ? 3 : 0 }}>
        1 店舗選択 <span style={{ fontSize: isMdUp ? '0.8em' : '0.6em' }}>*必須</span>
      </Typography>
      <Box sx={{ pl: isSmUp ? 6 : 1, pb: isSmUp ? 4 : 2 }}>
        {displayStores.map((store) => (
          <Button
            key={store.photoStudioId}
            variant={selectedStudioId === store.photoStudioId ? 'contained' : 'outlined'}
            color="primary"
            onClick={() => setSelectedStudioId(store.photoStudioId)}
            startIcon={selectedStudioId === store.photoStudioId ? <CheckIcon /> : null}
            sx={buttonSx(isSmUp, isMdUp)}
          >
            {store.name}
          </Button>
        ))}
      </Box>
      <Typography variant={isMdUp ? 'h6' : 'subtitle2'} color="text.secondary" sx={{ pb: 1, pl: isSmUp ? 3 : 0 }}>
        2 プラン選択 <span style={{ fontSize: isMdUp ? '0.8em' : '0.6em' }}>*必須</span>
        {selectedPlanId && plans && (
          <>
            <br />
            <span style={{ fontSize: isMdUp ? '0.8em' : '0.6em' }}>
              {plans.find((obj) => obj.photoStudioPlanId === selectedPlanId)?.content}
            </span>
          </>
        )}
      </Typography>
      <Box sx={{ pl: isSmUp ? 6 : 1, pb: isSmUp ? 4 : 2 }}>
        {plans &&
          plans.map((plan) => (
            <Button
              key={plan.photoStudioPlanId}
              variant={selectedPlanId === plan.photoStudioPlanId ? 'contained' : 'outlined'}
              color="primary"
              onClick={() => setSelectedPlanId(plan.photoStudioPlanId)}
              startIcon={selectedPlanId === plan.photoStudioPlanId ? <CheckIcon /> : null}
              sx={buttonSx(isSmUp, isMdUp)}
            >
              {plan.name}
            </Button>
          ))}
      </Box>
      <Typography variant={isMdUp ? 'h6' : 'subtitle2'} color="text.secondary" sx={{ pb: 1, pl: isSmUp ? 3 : 0 }}>
        3 オプション選択 <span style={{ fontSize: isMdUp ? '0.8em' : '0.6em' }}>*任意</span>
      </Typography>
      <Box sx={{ pl: isSmUp ? 6 : 1, pb: isSmUp ? 4 : 2 }}>
        {options &&
          options.map((option) => (
            <Button
              key={option.photoStudioOptionId}
              variant={selectedOptionIds.includes(option.photoStudioOptionId) ? 'contained' : 'outlined'}
              color="primary"
              onClick={() => {
                const isSelected = selectedOptionIds.includes(option.photoStudioOptionId);
                if (isSelected) {
                  setSelectedOptionIds(selectedOptionIds.filter((id) => id !== option.photoStudioOptionId));
                } else {
                  setSelectedOptionIds([...selectedOptionIds, option.photoStudioOptionId]);
                }
              }}
              startIcon={
                selectedOptionIds.includes(option.photoStudioOptionId) ? (
                  <CheckIcon />
                ) : (
                  <MdOutlineCheckBoxOutlineBlank />
                )
              }
              sx={buttonSx(isSmUp, isMdUp)}
            >
              {option.name}
            </Button>
          ))}
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Typography variant="h6" color="text.secondary" sx={{ pr: 4, pt: isMdUp ? 4 : 2 }}>
          所要時間：約 {total.time}分
        </Typography>
      </Box>
      {selectedStudioId && selectedPlanId && (
        <Box>
          <ReservationScheduleForCustomer
            selectedStudioId={selectedStudioId}
            selectedPlanId={selectedPlanId}
            selectedOptionIds={selectedOptionIds}
            calendarOnClick={calendarOnClick}
          />
        </Box>
      )}
    </Container>
  );
};

const calculateTotal = (
  selectedPlanId: string,
  selectedOptionIds: string[],
  plans: PhotoStudioCustomerPhotoStudioPlan[] | undefined | null,
  options: PhotoStudioCustomerPhotoStudioOption[]
): { price: number; time: number } => {
  const plan = plans?.find((p) => p.photoStudioPlanId === selectedPlanId);
  if (!plan) {
    return { price: 0, time: 0 };
  }
  const optionPrices = selectedOptionIds.map((optionId) => {
    const option = options.find((o) => o.photoStudioOptionId === optionId);
    return option ? option.price : 0;
  });
  const optionTimes = selectedOptionIds.map((optionId) => {
    const option = options.find((o) => o.photoStudioOptionId === optionId);
    return option ? option.reserveMinutesForCustomer : 0;
  });
  const totalPrice = optionPrices.reduce((sum, price) => sum + price, plan.price);
  const totalTime = optionTimes.reduce((sum, time) => sum + time, plan.reserveMinutesForCustomer);
  return { price: totalPrice, time: totalTime };
};

function buttonSx(isSmUp: any, isMdUp: any) {
  return {
    mr: isSmUp ? 3 : 2,
    mb: isSmUp ? 1 : 0.5,
    minWidth: isSmUp ? '200px' : '100px',
    flexGrow: 1,
    height: isSmUp ? 45 : 36,
    whiteSpace: 'pre-wrap',
    fontSize: isMdUp ? '' : '0.7rem',
  };
}
