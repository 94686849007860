import { useParams } from 'react-router-dom';
import usePush from 'src/hooks/use-push';
import { Card, CardHeader, Grid, Stack } from '@mui/material';
import { photoStudioValidationSchema } from 'src/functions/validation-schema';
import { useForm } from 'react-hook-form';
import { FC, useEffect } from 'react';
import { FormProvider, RHFPhoneNumber, RHFTextField } from 'src/components/atoms/hook-form';
import { LoadingButton } from '@mui/lab';
import { yupResolver } from '@hookform/resolvers/yup';
import useFetch from 'src/hooks/use-fetch';
import { PhotoStudioStaffPhotoStudio } from 'codegen/axios/photo/photo_studio';
import AddressInputs from 'src/components/molecules/address-inputs';
import { photoStudioValueOrganize } from 'src/functions/push-value-organize';
import { photoStudioFormFetchOrganize } from 'src/functions/fetch-value-organize';

export type PhotoStudioFormType = Omit<PhotoStudioStaffPhotoStudio, 'companyId' | 'companyName' | 'photoStudioId'>;

export const defaultValues: PhotoStudioFormType = {
  photoStudioName: '',
  photoStudioNameKana: '',
  email: '',
  telno: '',
  zip: '',
  prefecture: '',
  city: '',
  street: '',
  building: '',
};

export const PhotoStudioInputForm: FC = () => {
  /* hook宣言 */
  const push = usePush();
  const { id: photoStudioId } = useParams();

  /* 定数宣言 */
  const editMode = !photoStudioId;
  const validationSchema = photoStudioValidationSchema();

  /* データ取得 */
  const { fetchedData: responseData } = useFetch<PhotoStudioFormType>({
    iniState: defaultValues,
    reqProp: {
      pathKey: 'studio',
      pathParam: photoStudioId,
    },
    disable: editMode,
    organize: photoStudioFormFetchOrganize,
  });

  const methods = useForm<PhotoStudioFormType>({
    resolver: yupResolver(validationSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  useEffect(() => {
    methods.reset(responseData);
  }, [responseData, methods]);

  const onSubmit = (formValue: PhotoStudioFormType) =>
    new Promise((resolve) => {
      push(resolve, {
        reqProp: {
          pathKey: 'studio',
          pathParam: photoStudioId,
          method: editMode ? 'post' : 'put',
          data: photoStudioValueOrganize(formValue),
        },
        aftProp: {
          resolve,
          nextPath: editMode ? `/setting/photoStudio` : `/setting/photoStudio/${photoStudioId}`,
          showMessage: {
            state: {
              snackBar: { open: true, viewType: 'success', message: editMode ? '登録しました' : '保存しました' },
            },
          },
        },
      });
    });
  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Card>
        <CardHeader title={'基本情報'} />

        <Stack my={7} mx={4}>
          <Grid container rowSpacing={3} columnSpacing={3}>
            <Grid item xs={12} sm={6} md={6}>
              <RHFTextField name="photoStudioName" label="店舗名" placeholder="例：PHOTOスタジオ" />
            </Grid>

            <Grid item xs={12} sm={6} md={6}>
              <RHFTextField name="photoStudioNameKana" label="店舗名(かな)" placeholder="例：ふぉとい すたじお" />
            </Grid>

            <AddressInputs methods={methods} />

            <Grid item xs={12} sm={6} md={3}>
              <RHFTextField name="email" label="メールアドレス" placeholder="例：xxx@photoapan.jp" />
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              <RHFPhoneNumber methods={methods} name="telno" label="電話番号" placeholder="例：09012345678" />
            </Grid>
          </Grid>
        </Stack>
      </Card>

      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }} />

      <Stack direction="row" alignItems="center" justifyContent="flex-end" sx={{ m: 1 }} spacing={8}>
        <LoadingButton sx={{ width: 200 }} size="large" type="submit" variant="contained" loading={isSubmitting}>
          {editMode ? '登録' : '保存'}
        </LoadingButton>
      </Stack>
    </FormProvider>
  );
};
