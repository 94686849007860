import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { Stack, Grid, Card, CardHeader } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import { FormProvider, RHFTextField, RHFPhoneNumber } from 'src/components/atoms/hook-form';
import HumanNameInput from 'src/components/molecules/human-name-inputs';
// function
import { customerValidationSchema } from 'src/functions/validation-schema';
import { CustomerForm } from 'src/types/customer-form';

export default function InputForm({ OnSubmit, defaultValues }: any) {
  const { customerId } = useParams();

  const validationSchema = customerValidationSchema();

  const methods = useForm<CustomerForm>({ resolver: yupResolver(validationSchema), defaultValues });
  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;
  useEffect(() => {
    methods.reset(defaultValues);
  }, [defaultValues, methods]);

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(OnSubmit)}>
      <Card>
        <CardHeader title={'基本情報'} subheader={''} />

        <Stack my={7} mx={4}>
          <Grid container rowSpacing={3} columnSpacing={3}>
            <HumanNameInput methods={methods} identifier={'customer'} />

            <Grid item xs={12} sm={6} md={4}>
              <RHFTextField name="email" label="メールアドレス" placeholder="例：xxx@photoapan.jp" />
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
              <RHFPhoneNumber methods={methods} name="telno" label="電話番号" placeholder="例：09000001234" />
            </Grid>
          </Grid>
        </Stack>
      </Card>

      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }} />

      <Stack direction="row" alignItems="center" justifyContent="flex-end" sx={{ m: 1 }} spacing={8}>
        <LoadingButton sx={{ width: 200 }} size="large" type="submit" variant="contained" loading={isSubmitting}>
          {customerId ? '保存' : '登録'}
        </LoadingButton>
      </Stack>
    </FormProvider>
  );
}
