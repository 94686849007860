import React, { useState, useEffect } from 'react';
import MuiAlert from '@mui/material/Alert';
import { Stack, Snackbar, Slide } from '@mui/material';

const Alert = React.forwardRef((props: any, ref: any) => (
  <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
));
function TransitionLeft(props: any) {
  return <Slide {...props} direction="up" />;
}
export default function SnackBar() {
  const [open, setOpen] = useState(false);
  const snackBarOpen = localStorage.getItem('snackBarOpen');
  const snackBarMessage = localStorage.getItem('snackBarMessage');
  const snackBarViewType = localStorage.getItem('snackBarViewType');

  useEffect(() => {
    if (snackBarOpen === 'true') {
      setOpen(true);
      localStorage.setItem('snackBarOpen', 'false');
    }
  }, [snackBarOpen]);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Stack spacing={2} sx={{ width: '100%' }}>
      <Snackbar open={open} TransitionComponent={TransitionLeft} autoHideDuration={2000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={snackBarViewType} sx={{ width: '100%' }}>
          {snackBarMessage ?? 'メッセージがないよ'}
        </Alert>
      </Snackbar>
    </Stack>
  );
}
