export const addDays = (date: Date, daysNum: number) => {

  if(!Number.isInteger(daysNum) || daysNum < 1) throw new Error('"days" argument must be a positive integer.');

  const dateCopy = new Date(date);

  return new Date((dateCopy).setDate((dateCopy).getDate() + daysNum));
}

export const subtractDays = (date: Date, daysNum: number) => {
  if(!Number.isInteger(daysNum) || daysNum < 1) throw new Error('"days" argument must be a positive integer.');

  const dateCopy = new Date(date);

  return new Date((dateCopy).setDate((dateCopy).getDate() - daysNum));
}

export const calcNextDay = (date: Date) => addDays(date, 1);

export const calcPrevDay = (date: Date) => subtractDays(date, 1);

export const calcOneWeekLater = (date: Date) => addDays(date, 7);

export const calcOneWeekAgo = (date: Date) => subtractDays(date, 7);