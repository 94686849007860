import { useEffect, RefObject, useState, FC } from 'react';
import { useCookies } from 'react-cookie';
import FullCalendar from '@fullcalendar/react';
import { useSearchParams } from 'react-router-dom';
import useFetch from 'src/hooks/use-fetch';
import { createParams } from 'src/functions/params';
import { Button, Box, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';

export const useHooks = ({ calendarRef }: UseHooksArg) => {
  /* hook宣言 */
  const [timer, setTimer] = useState<NodeJS.Timeout | null>(null);
  const [cookies, setCookie, removeCookie] = useCookies();
  const theme = useTheme();
  const [textFieldRestaurantOption, setTextFieldRestaurantOption] = useState('');
  const [searchParams, setSearchParams] = useSearchParams();
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'));
  const isSmUp = useMediaQuery(theme.breakpoints.up('sm'));

  /* 定数宣言 */
  const restaurantId = searchParams.get('restaurantId') ?? null;

  const date: string = searchParams.get('date') || new Date().toString();
  const filterView = cookies.reserveScheduleFilterView ?? null;

  useEffect(() => {
    const calApi = calendarRef.current?.getApi();
    if (calApi) {
      setDate(calApi.getDate());
      setSearchParams(createParams({ keyValues: { viewType: calApi.view.type }, searchParams }));
    }
  }, [calendarRef]);

  const { fetchedData: restaurantOption } = useFetch<any>({
    iniState: [{ label: '' }],
    reqProp: {
      pathKey: 'studio',
      queryParams: {
        offset: 0,
        limit: 100,
        ...(() => textFieldRestaurantOption && { query: textFieldRestaurantOption.replace(/\s+/g, '') })(),
      },
    },
    disable: true,
    observable: [textFieldRestaurantOption],
  });
  /* handle */
  const handleFilterView = () => {
    !filterView ? setCookie('reserveScheduleFilterView', true) : removeCookie('reserveScheduleFilterView');
  };
  const textFieldOnChangeRestaurantOption = (event: any) => {
    // @ts-expect-error TS(2345): Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
    clearTimeout(timer);
    const newTimer = setTimeout(() => {
      setTextFieldRestaurantOption(event.target.value);
    }, 400);
    setTimer(newTimer);
  };
  const handleDateChange = (direction: 'prev' | 'today' | 'next'): void => {
    const calApi = calendarRef.current?.getApi();

    if (calApi) {
      if (direction === 'prev') {
        calApi.prev();
      } else if (direction === 'next') {
        calApi.next();
      } else {
        calApi.today();
      }

      setDate(calApi.getDate());
    }
  };
  const handleViewChange = (direction: 'dayGridMonth' | 'timeGridWeek' | 'resourceTimeline'): void => {
    const calApi = calendarRef.current?.getApi();
    if (calApi) {
      if (direction === 'dayGridMonth') {
        calApi.changeView('dayGridMonth');
      } else if (direction === 'timeGridWeek') {
        calApi.changeView('timeGridWeek');
      } else if (direction === 'resourceTimeline') {
        calApi.changeView('resourceTimeline');
      }
      calApi && setSearchParams(createParams({ keyValues: { viewType: calApi.view.type }, searchParams }));
      setDate(calApi.getDate());
    }
  };
  const setDate = (date: any) => setSearchParams(createParams({ keyValues: { date }, searchParams }));

  const changeFilterByRestaurant = (event: any, item: any) => {
    handleFilterByRestaurant(event, item);
  };
  const handleFilterByRestaurant = (_: any, item: any) => {
    setSearchParams(createParams({ keyValues: { restaurantId: item ? item.id : null }, searchParams }));
  };

  return {
    filterView,
    handleFilterView,
    textFieldOnChangeRestaurantOption,
    restaurantOption,
    changeFilterByRestaurant,
    restaurantId,
    handleViewChange,
    setDate,
    date: new Date(date),
    handleDateChange,
    isMdUp,
    isSmUp,
  };
};

export const getLabel = (ary: any[], id: string): string => {
  for (let i = 0; i < ary.length; i += 1) {
    if (ary[i].id === id) {
      return ary[i].label;
    }
  }
  return '';
};

export type UseHooksArg = {
  filterUseProperty: boolean;
  calendarRef: RefObject<FullCalendar>;
};

export const CalendarHeader: FC<UseHooksArg> = ({ filterUseProperty, calendarRef }) => {
  const { date, handleDateChange, isMdUp } = useHooks({
    filterUseProperty,
    calendarRef,
  });

  return (
    <Box sx={{ mb: 2, mt: isMdUp ? 2 : 1, mx: 2 }}>
      <Grid container rowSpacing={0} columnSpacing={0}>
        <Grid item xs={0} sm={0} md={4} />
        <Grid style={{ textAlign: 'center' }} item xs={3} sm={3} md={3} mt={1}>
          <Typography variant={isMdUp ? 'body1' : 'body2'} sx={{ fontWeight: 'bold', mt: 1 }}>
            {date ? new Date(date.toString()).toLocaleDateString('ja-JP', { year: 'numeric', month: 'long' }) : ''}
          </Typography>
        </Grid>
        <Grid style={{ textAlign: 'right' }} item xs={9} sm={9} md={5} mt={1}>
          <Button
            sx={{ mr: isMdUp ? 4 : 1 }}
            size={!isMdUp ? 'small' : undefined}
            variant="outlined"
            onClick={(): void => handleDateChange('today')}
            disabled={((): boolean => {
              if (!date) return false;
              return new Date(date.toString()).getDate() === new Date().getDate();
            })()}
          >
            {isMdUp ? '今日' : '今週'}
          </Button>
          <Button
            variant="contained"
            sx={{ mr: isMdUp ? 1 : 0.5 }}
            size={!isMdUp ? 'small' : undefined}
            onClick={(): void => handleDateChange('prev')}
          >
            <IoIosArrowBack size={22} /> {isMdUp ? '前の週' : ''}
          </Button>
          <Button
            variant="contained"
            size={!isMdUp ? 'small' : undefined}
            onClick={(): void => handleDateChange('next')}
          >
            {isMdUp ? '次の週' : ''} <IoIosArrowForward size={22} />
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};
