export const numberOption: number[] = Array.from({ length: 500 }, (_, i) => i + 1);

export const minutesOption: number[] = [15, 30, 45, 60, 90, 120, 150, 180, 240];

export const reservationSlotMinutesOption: number[] = [15, 30, 40, 45, 60, 90, 120, 150, 180, 240];

export const colorOption: string[] = [
  '#607d8b',
  '#f44336',
  '#e91e63',
  '#9c27b0',
  '#673ab7',
  '#3f51b5',
  '#2196f3',
  '#03a9f4',
  '#00bcd4',
  '#009688',
  '#4caf50',
  '#8bc34a',
  '#cddc39',
  '#ffeb3b',
  '#ffc107',
  '#ff9800',
  '#ff5722',
  '#795548',
];

export const validOrInvalidOption = [
  {
    value: true,
    label: '有効',
  },
  {
    value: false,
    label: '無効',
  },
];
export const viewOption = [
  {
    value: true,
    label: '表示',
  },
  {
    value: false,
    label: '非表示',
  },
];

export const doOrNotOption = [
  {
    value: true,
    label: 'する',
  },
  {
    value: false,
    label: 'しない',
  },
];

export const inputTypeOption = [
  'テキストボックス',
  'テキストエリア',
  'チェックボックス',
  'ラジオボタン',
  'セレクトボックス',
];

export const dayOfWeekOptions = [
  {
    label: '日曜日',
    name: 'sundayAvailableFlg',
  },
  {
    label: '月曜日',
    name: 'mondayAvailableFlg',
  },
  {
    label: '火曜日',
    name: 'tuesdayAvailableFlg',
  },
  {
    label: '水曜日',
    name: 'wednesdayAvailableFlg',
  },
  {
    label: '木曜日',
    name: 'thursdayAvailableFlg',
  },
  {
    label: '金曜日',
    name: 'fridayAvailableFlg',
  },
  {
    label: '土曜日',
    name: 'saturdayAvailableFlg',
  },
];
