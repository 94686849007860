import { FC } from 'react';
import { Container, Stack, Typography } from '@mui/material';
import Page from 'src/components/molecules/page';
import { IrregularSettingForm } from 'src/components/organisms/reservation-frame/detail/irregular-setting/form';

export const IrregularSettingFrameEditPage: FC = () => {
  /* 定数宣言 */
  const title = 'イレギェラー設定編集';

  return (
    <Page title={title}>
      <Container>
        <IrregularSettingForm />
      </Container>
    </Page>
  );
};
