import { FC, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Stack, Card, CardHeader, Grid } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { FormProvider, RHFTextField, RHFSelectBox, RHFRadioButtonGroup } from 'src/components/atoms/hook-form';
import { planValidationSchema } from 'src/functions/validation-schema';
import useFetch from 'src/hooks/use-fetch';
import { PhotoPlan } from 'codegen/axios/photo/photo_studio';
import { useParams } from 'react-router-dom';
import usePush from 'src/hooks/use-push';
import { doOrNotOption, minutesOption } from 'src/utils/option';
import { planFormFetchOrganize } from 'src/functions/fetch-value-organize';

export type PlanFormType = Omit<PhotoPlan, 'photoStudioPlanId' | 'availableOptions' | 'imagesPlan'>;

export const defaultValues: PlanFormType = {
  name: '',
  price: 0,
  reserveMinutes: 0,
  reserveMinutesForCustomer: 0,
  invalidFlg: true,
  content: '',
};

export const PlanForm: FC = () => {
  /* hook宣言 */
  const { id: planId } = useParams();
  const push = usePush();

  /* 定数宣言 */
  const editMode = !planId;
  const validationSchema = planValidationSchema();

  /* データ取得 */
  const { fetchedData: responseData } = useFetch<PlanFormType>({
    iniState: defaultValues,
    reqProp: {
      pathKey: 'plan',
      pathParam: planId,
    },
    disable: editMode,
    organize: planFormFetchOrganize,
  });

  const methods = useForm<PlanFormType>({
    resolver: yupResolver(validationSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  useEffect(() => {
    methods.reset(responseData);
  }, [responseData, methods]);

  const onSubmit = (formValue: PlanFormType) =>
    new Promise((resolve) => {
      push(resolve, {
        reqProp: {
          pathKey: 'plan',
          pathParam: planId,
          method: editMode ? 'post' : 'put',
          data: formValue,
        },
        aftProp: {
          resolve,
          nextPath: editMode ? `/setting/plan` : `/setting/plan/${planId}`,
          showMessage: {
            state: {
              snackBar: { open: true, viewType: 'success', message: editMode ? '登録しました' : '保存しました' },
            },
          },
        },
      });
    });

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Card>
        <CardHeader title="プラン" />

        <Stack my={7} mx={4}>
          <Grid container rowSpacing={3} columnSpacing={3}>
            <Grid item pt={4} xs={6} sm={6} md={8}>
              <RHFTextField name="name" label="プラン名" placeholder="例：婚活プロフィールプラン" />
            </Grid>
            <Grid item pt={4} xs={3} sm={3} md={3}>
              <RHFTextField name="price" label="料金" placeholder="例：3000" />
            </Grid>

            <Grid item pt={4} xs={3} sm={3} md={3}>
              <RHFSelectBox name="reserveMinutes" label="所要時間（分）" option={minutesOption} />
            </Grid>

            <Grid item pt={4} xs={3} sm={3} md={3}>
              <RHFSelectBox name="reserveMinutesForCustomer" label="お客様への表示時間（分）" option={minutesOption} />
            </Grid>
            <Grid item pl={8} xs={3} sm={12} md={6}>
              <RHFRadioButtonGroup name="invalidFlg" label="お客様へ表示" options={doOrNotOption} />
            </Grid>
            <Grid item pt={4} xs={6} sm={3} md={12}>
              <RHFTextField
                multiline
                name="content"
                label="内容"
                placeholder={`例：\n・撮影場所はスタジオまたは屋外で選択可能\n・写真5枚の補正を行い、SNSや婚活アプリで使用できる形式で提供\n・追加料金により、衣装のレンタル、ヘアメイクサービスの提供が可能（オプション）`}
              />
            </Grid>
          </Grid>
        </Stack>
      </Card>

      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }} />

      <Stack direction="row" alignItems="center" justifyContent="flex-end" sx={{ m: 1 }} spacing={8}>
        <LoadingButton sx={{ width: 200 }} size="large" type="submit" variant="contained" loading={isSubmitting}>
          {editMode ? '登録' : '保存'}
        </LoadingButton>
      </Stack>
    </FormProvider>
  );
};
