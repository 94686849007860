import { Helmet } from 'react-helmet-async';
import { forwardRef } from 'react';
// @mui
import { Box } from '@mui/material';

type Props = {
  children: React.ReactNode;
  title?: string;
  meta?: React.ReactNode;
};

// ----------------------------------------------------------------------

const Page = forwardRef<any, Props>(({ children, title = '', meta, ...other }, ref) => (
  <>
    <Helmet>
      <title>{`${title} | PhotoReserve`}</title>
      {meta}
    </Helmet>

    <Box ref={ref} {...other}>
      {children}
    </Box>
  </>
));

export default Page;
