import { useParams, useNavigate, useSearchParams } from 'react-router-dom';
// @mui
// components
import Page from 'src/components/molecules/page';
import DetailTemplate from 'src/components/templates/company/company-detail-template';
// hooks
import usePush from 'src/hooks/use-push';
import useFetch from 'src/hooks/use-fetch';
// f
import { createQueryParameter } from 'src/functions/api-parameter';
import { createParams } from 'src/functions/params';
import {
  staffListFetchOrganize,
  companyDetailFetchOrganize,
  nextPageCheckOrganize,
} from 'src/functions/fetch-value-organize';
// staff
import useStaffInformation from 'src/hooks/use-staff-information';
import { Company, Staff } from 'codegen/axios/photo/admin/api';

export default function Detail() {
  /* hook宣言 */
  const staff = useStaffInformation();
  const [searchParams, setSearchParams] = useSearchParams();
  const { id: companyId } = useParams();
  const navigate = useNavigate();
  const push = usePush();

  /* 定数宣言 */
  const title = '会社詳細';
  const page = Number(searchParams.get('page')) ?? 0;
  const searchName = searchParams.get('searchName') ?? null;
  const rowsPerPage = 5;
  const query = createQueryParameter({
    searchName: { key: 'searchName', value: searchName },
  });

  /* データ取得 */
  const { fetchedData: defaultValues } = useFetch<Company>({
    iniState: {},
    reqProp: {
      pathKey: 'company',
      pathParam: companyId,
    },
    organize: companyDetailFetchOrganize,
  });
  const { fetchedData: staffResponseData } = useFetch<Staff[]>({
    reqProp: {
      pathKey: 'marriageAgencyStaff',
      queryParams: {
        companyId,
        offset: page * rowsPerPage,
        limit: rowsPerPage,
        ...query,
      },
    },
    organize: staffListFetchOrganize,
    observable: [searchParams],
  });
  // 次のページがあるかどうか
  const { fetchedData: nextPageCheck } = useFetch<Staff>({
    iniState: false,
    reqProp: {
      pathKey: 'marriageAgencyStaff',
      queryParams: {
        companyId,
        offset: (page + 1) * rowsPerPage,
        limit: 2,
        ...query,
      },
    },
    organize: nextPageCheckOrganize,
    observable: [searchParams],
  });

  /* handle */
  const handleChangePage = (event: any, newPage: any) => {
    setSearchParams(createParams({ keyValues: { page: newPage }, searchParams, setSearchParams }));
  };

  const handleRowClick = (id: any) => {
    navigate(`/${staff.userType}/companies/${companyId}/staffs/${id}`, {
      state: { beforeParams: { page } },
    });
  };
  const handleEditButtonOnClick = () => {
    navigate(`/${staff.userType}/companies/${companyId}/edit`, { state: { beforeParams: { page } } });
  };
  const handleSearchByName = (value: any) => {
    setSearchParams(createParams({ keyValues: { searchName: value }, searchParams, page: 0 }));
  };

  const OnDelete = () => {
    new Promise((resolve) => {
      push(resolve, {
        reqProp: {
          pathKey: 'company',
          method: 'delete',
          pathParam: companyId,
        },
        aftProp: {
          nextPath: `/companies`,
          showMessage: {
            state: { snackBar: { open: true, viewType: 'info', message: '削除しました' } },
          },
        },
      });
    });
  };

  return (
    <Page title={title}>
      <DetailTemplate
        {...(() => ({
          title,
          defaultValues,
          companyId,
          nextPageCheck,
          staffResponseData,
          handleRowClick,
          isUserNotFound: staffResponseData.length === 0,
          rowsPerPage,
          page,
          handleChangePage,
          handleEditButtonOnClick,
          handleSearchByName,
          searchName,
          OnDelete,
        }))()}
      />
    </Page>
  );
}
