import { FC, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Stack, Card, Grid, Box } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import {
  FormProvider,
  RHFTextField,
  RHFSelectBox,
  RHFRadioButtonGroup,
  RHFCheckboxGroup,
  RHFMultipleTextField,
} from 'src/components/atoms/hook-form';
import { formSettingValidationSchema } from 'src/functions/validation-schema';
import useFetch from 'src/hooks/use-fetch';
import { AvailableOption, Option, PhotoFormUiParts, PhotoPlan } from 'codegen/axios/photo/photo_studio';
import { useParams } from 'react-router-dom';
import usePush from 'src/hooks/use-push';
import { doOrNotOption, inputTypeOption, numberOption } from 'src/utils/option';
import {
  formSettingFormFetchOrganize,
  optionChoiceFetchOrganize,
  planForFormSettingFetchOrganize,
} from 'src/functions/fetch-value-organize';
import { formSettingValueOrganize } from 'src/functions/push-value-organize';
import DeleteModal from 'src/components/molecules/delete-modal';

export type PhotoFormUiPartsType = Omit<
  PhotoFormUiParts,
  | 'photoFormUiPartsId'
  | 'availableOptions'
  | 'imagesPlan'
  | 'inputType'
  | 'options'
  | 'availablePlans'
  | 'availableOptionIds'
> & { inputType: string } & { options: Array<string> } & { availablePlans: Array<string> } & {
  availableOptionIds: Array<string>;
};

export const defaultValues: PhotoFormUiPartsType = {
  availableOptionIds: [],
  photoReservationFormGroupId: '',
  inputType: '',
  name: '',
  requiredFlg: true,
  customerViewFlg: true,
  placeholder: '',
  orderNumber: 0,
  availablePlans: [],
  options: [],
};

export const FormSettingForm: FC = () => {
  /* hook宣言 */
  const { id: photoReservationFormGroupId } = useParams();
  const { partsId: photoFormUiPartsId } = useParams();
  const push = usePush();

  /* 定数宣言 */
  const editMode = !photoFormUiPartsId;
  const validationSchema = formSettingValidationSchema();

  const methods = useForm<PhotoFormUiPartsType>({
    resolver: yupResolver(validationSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
    reset,
    watch,
  } = methods;

  /* データ取得 */
  const { fetchedData: responseData } = useFetch<PhotoFormUiPartsType>({
    iniState: defaultValues,
    reqProp: {
      pathKey: 'formInputs',
      pathParam: photoFormUiPartsId,
    },
    disable: editMode,
    organize: formSettingFormFetchOrganize,
  });

  /* データ取得 */
  const { fetchedData: plans } = useFetch<PhotoPlan[]>({
    reqProp: {
      pathKey: 'plan',
      queryParams: { offset: 0, limit: 100 },
    },
  });
  const organizedPlans = planForFormSettingFetchOrganize(plans);

  const { fetchedData: options } = useFetch<Array<{ value: string; label: string }>>({
    reqProp: {
      pathKey: 'option',
      queryParams: { offset: 0, limit: 100 },
    },
    organize: optionChoiceFetchOrganize,
  });

  useEffect(() => {
    reset(responseData);
  }, [responseData, methods]);

  useEffect(() => {
    methods.clearErrors();
  }, [watch().inputType]);

  const onSubmit = (formValue: PhotoFormUiPartsType) =>
    new Promise((resolve) => {
      push(resolve, {
        reqProp: {
          pathKey: 'formInputs',
          pathParam: photoFormUiPartsId,
          method: editMode ? 'post' : 'put',
          data: formSettingValueOrganize({ ...formValue, photoReservationFormGroupId }, organizedPlans),
        },
        aftProp: {
          resolve,
          nextPath: '/setting/reservationFormSetting',
          showMessage: {
            state: {
              snackBar: { open: true, viewType: 'success', message: editMode ? '登録しました' : '保存しました' },
            },
          },
        },
      });
    });

  const onDelete = () => {
    new Promise((resolve) =>
      push(resolve, {
        reqProp: {
          pathKey: 'formInputs',
          method: 'delete',
          pathParam: photoFormUiPartsId,
        },
        aftProp: {
          resolve,
          nextPath: `/setting/reservationFormSetting`,
          showMessage: {
            state: { snackBar: { open: true, viewType: 'info', message: '削除しました' } },
          },
        },
      })
    );
  };

  const isPlanNotFound = organizedPlans.length !== 0;

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      {!editMode && (
        <Stack direction="row" alignItems="center" justifyContent="start" mb={2} ml={1} mt={'3%'} mr={0}>
          <Box sx={{ ml: 'auto' }}>
            <DeleteModal
              sx={{
                bgcolor: 'background.paper',
                minWidth: 50,
                minHeight: 47,
                boxShadow: 1,
                borderRadius: 2,
                ml: 2,
              }}
              OnDelete={onDelete}
            />
          </Box>
        </Stack>
      )}
      <Card>
        <Stack my={7} mx={4}>
          <Grid container alignItems="center" rowSpacing={3} columnSpacing={3}>
            <Grid item pt={4} xs={12} sm={12} md={3}>
              <RHFSelectBox name="inputType" label="入力タイプ" option={inputTypeOption} />
            </Grid>
            <Grid item pt={4} xs={12} sm={12} md={2}>
              <RHFSelectBox name="orderNumber" label="並び順" option={numberOption} />
            </Grid>
            <Grid item pt={4} xs={12} sm={12} md={6} />
            <Grid item pt={4} xs={12} sm={12} md={10}>
              <RHFTextField name="name" label="項目名" placeholder="例：〇〇プランについて" />
            </Grid>

            <Grid item pt={4} xs={12} sm={12} md={2} />
            {
              <>
                <Grid item pt={4} xs={12} sm={12} md={10}>
                  <RHFTextField
                    name="placeholder"
                    label="プレースホルダー"
                    placeholder="例：選択中のプランは〇〇向けのプランです"
                  />
                </Grid>
                <Grid item pt={4} xs={12} sm={12} md={2} />
              </>
            }

            {(watch().inputType === 'セレクトボックス' ||
              watch().inputType === 'ラジオボタン' ||
              watch().inputType === 'チェックボックス') && (
              <>
                <Grid item pl={8} xs={12} sm={12} md={10}>
                  <div style={{ marginTop: '20px', marginBottom: '20px' }}>
                    <RHFMultipleTextField name="options" label="選択肢" placeholder="テキストを入力してください" />
                  </div>
                </Grid>
                <Grid item pl={8} xs={12} sm={12} md={2} />
              </>
            )}

            <Grid item pl={8} xs={12} sm={12} md={2}>
              <RHFRadioButtonGroup
                label="必須/任意入力"
                name="requiredFlg"
                options={[
                  {
                    value: true,
                    label: '必須',
                  },
                  {
                    value: false,
                    label: '任意',
                  },
                ]}
              />
            </Grid>
            <Grid item pl={8} xs={12} sm={12} md={3}>
              <RHFRadioButtonGroup name="customerViewFlg" label="お客様へ表示" options={doOrNotOption} />
            </Grid>
            {isPlanNotFound && (
              <Grid item pl={8} xs={12} sm={12} md={12}>
                <RHFCheckboxGroup
                  name="availablePlans"
                  label="この予約フォームを表示するプランを選択してください"
                  options={organizedPlans}
                />
              </Grid>
            )}
            {isPlanNotFound && (
              <Grid item pl={8} xs={12} sm={12} md={12}>
                <RHFCheckboxGroup
                  name="availableOptionIds"
                  label="予約フォームを表示するオプションを選択(未選択時は全てのオプションで表示)"
                  options={options}
                />
              </Grid>
            )}
          </Grid>
        </Stack>
      </Card>

      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }} />

      <Stack direction="row" alignItems="center" justifyContent="flex-end" sx={{ m: 1 }} spacing={8}>
        <LoadingButton sx={{ width: 200 }} size="large" type="submit" variant="contained" loading={isSubmitting}>
          {editMode ? '登録' : '保存'}
        </LoadingButton>
      </Stack>
    </FormProvider>
  );
};
