import { useState } from 'react';
// form
// @mui
import { IconButton } from '@mui/material';
import { FiTrash } from 'react-icons/fi';
// components
import { DeleteConfirmDialog } from 'src/components/molecules/dialog/DeleteConfirmDialog';
import { MyDialog } from 'src/components/molecules/dialog/MyDialog';
// utils
import log from 'src/utils/log';
// ----------------------------------------------------------------------

export default function DeleteModal({ OnDelete, ...props }: any) {
  // 削除ダイヤログ
  const [myDialogConfig] = useState<any>();
  const [deleteConfirmDialogConfig, setDeleteConfirmDialogConfig] = useState<any>();

  const handleSpDeleteClick = async () => {
    const ret = await new Promise((resolve) => {
      log(resolve);
      setDeleteConfirmDialogConfig({ onClose: resolve });
    });
    setDeleteConfirmDialogConfig(undefined);
    log(ret);
    if (ret === 'ok') {
      OnDelete();
    }
    if (ret === 'cancel') {
      log('削除する:Cancel時の処理を実行する');
    }
  };
  return (
    <>
      <IconButton {...props} aria-label="delete" size="small" onClick={handleSpDeleteClick}>
        <FiTrash size={20} />
      </IconButton>

      {myDialogConfig && <MyDialog {...myDialogConfig} />}

      {deleteConfirmDialogConfig && <DeleteConfirmDialog {...deleteConfirmDialogConfig} />}
    </>
  );
}
