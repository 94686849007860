import usePush from 'src/hooks/use-push';
import { useState } from 'react';
import { availablePlanValueOrganize } from 'src/functions/push-value-organize';

export const useFrameCopy = () => {
  const push = usePush();
  const [loading, setLoading] = useState(false);

  const doCopy = async (frameId: string) => {
    setLoading(true);
    // frameから詳細をとる
    const frame: any = await getFrame(frameId);
    // planの詳細をとる
    const plan = await getPlan(frameId);
    frame.name = `${frame.name} コピー`;
    frame.pageViewFlg = false;
    const createdFrame: any = await postFrame(frameId, frame); // frame増やす

    const pushPlan = availablePlanValueOrganize(plan as any);
    await putPlan(createdFrame.photoStudioReservationFrameId, pushPlan); // plan増やす
    setLoading(false);
  };

  const putPlan = async (id: string, plan: any) =>
    push(() => 1, {
      reqProp: {
        pathKey: 'frame',
        pathParam: `${id}/plans`,
        method: 'put',
        data: plan,
      },
    });

  const postFrame = async (id: string, frame: any) =>
    push(() => 1, {
      reqProp: {
        pathKey: 'frame',
        method: 'post',
        data: frame,
      },
    });

  const getFrame = async (id: string) =>
    push(() => 1, {
      reqProp: {
        pathKey: 'frame',
        pathParam: id,
        method: 'get',
      },
    });
  const getPlan = async (id: string) =>
    push(() => 1, {
      reqProp: {
        pathKey: 'frame',
        pathParam: `${id}/plans`,
        method: 'get',
      },
    });

  return { doCopy, loading };
};
