import Page from 'src/components/molecules/page';
import { FC } from 'react';
import { FrameDetail } from 'src/components/organisms/reservation-frame/detail';

export const PhotoStudioFrameDetailPage: FC = () => {
  /* 定数宣言 */
  const title = '予約枠詳細';

  return (
    <Page title={title}>
      <FrameDetail />
    </Page>
  );
};
