// @mui
import { Card, Stack, Container, TableContainer, TablePagination, Table } from '@mui/material';
// components
import ListHead from 'src/components/molecules/list-head';
import Scrollbar from 'src/components/molecules/scroll-bar';
import NotFoundTableBody from 'src/components/molecules/not-found-table-body';
import ListToolbar from 'src/components/molecules/list-toolbar';
import HeadLineText from 'src/components/atoms/head-line-text';
import CreateButton from 'src/components/atoms/create-button';
import CustomTableBody from 'src/components/molecules/custom-table-body';
// function
import { count, labelDisplayedRows } from 'src/functions/pagination';
// staff情報
import useStaffInformation from 'src/hooks/use-staff-information';

const TABLE_HEAD = [
  { id: 'name', label: '会社名', align: 'left', typographyProperty: { variant: 'subtitle2', noWrap: true } },
  { id: 'prefecture', label: '都道府県', align: 'left' },
  { id: 'city', label: '市区町村', align: 'left' },
  { id: 'telno', label: '電話番号', align: 'left' },
  { id: 'email', label: 'メールアドレス', align: 'left' },
];

export default function CompanyListTemplate({
  page,
  title,
  optionCompany,
  searchName,
  responseData,
  isUserNotFound,
  rowsPerPage,
  nextPageCheck,
  handleChangePage,
  handleSearchByName,
  handleFilterByName,
  handleRowClick,
}: any) {
  const emptyRows = page > 0 ? Math.max(0, rowsPerPage - responseData.length) : 0;
  const staff = useStaffInformation();
  return (
    <Container>
      <Stack direction="row" alignItems="center" justifyContent="space-between" m={5}>
        <HeadLineText text={title} />

        <CreateButton path={`/${staff.userType}/companies/new`} text="会社登録" />
      </Stack>

      <Card>
        <ListToolbar
          optionCompany={optionCompany}
          searchName={searchName}
          onFilterName={handleFilterByName}
          handleSearchByName={handleSearchByName}
          placeholder={'会社名、メールアドレス、電話番号'}
          width={350}
        />

        <Scrollbar>
          <TableContainer sx={{ minWidth: 800 }}>
            <Table>
              <ListHead headLabel={TABLE_HEAD} />

              <CustomTableBody
                {...(() => ({
                  TABLE_HEAD,
                  responseData,
                  handleRowClick,
                  emptyRows,
                }))()}
              />

              {isUserNotFound && <NotFoundTableBody searchName={searchName} />}
            </Table>
          </TableContainer>
        </Scrollbar>

        <TablePagination
          rowsPerPageOptions={[30]}
          component="div"
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          count={count(nextPageCheck, rowsPerPage, page)}
          labelDisplayedRows={({ from, to }) => labelDisplayedRows(responseData, nextPageCheck, from, to)}
        />
      </Card>
    </Container>
  );
}
