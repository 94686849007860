import { FC } from 'react';
import { Box, Card, Typography, Stack, IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Label } from 'src/components/atoms/label';
import Iconify from 'src/components/atoms/Iconify';

const StyledPhotoStudioImg = styled('img')({
  top: 0,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  position: 'absolute',
});

const StyledImageDummy = styled('div')({
  top: 0,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  position: 'absolute',
  backgroundColor: '#DFE3E8',
});

type Props = {
  id: string;
  name: string;
  prefecture: string;
  city: string;
  access: string;
  caption: string;
  bookmarkFlg: boolean;
  imagesTop: Array<any>;
  onClickDetailPage: (id: string) => void;
  onClickBookmark: (event: React.MouseEvent<HTMLButtonElement>, photoStudioId: string, bookmarkFlg?: boolean) => void;
};

export const PhotoStudioCard: FC<Props> = ({
  id,
  name,
  prefecture,
  city,
  access,
  caption,
  bookmarkFlg,
  imagesTop,
  onClickDetailPage,
  onClickBookmark,
}) => {
  /* 定数宣言 */
  const isImage = imagesTop && imagesTop.length !== 0;
  const imageUrl = isImage && imagesTop[0].imageUrl ? imagesTop[0].imageUrl : '';

  return (
    <Card onClick={() => onClickDetailPage(id)} sx={{ width: '100%', height: '100%' }}>
      <Box sx={{ pt: '52.5%', position: 'relative' }}>
        {isImage ? <StyledPhotoStudioImg alt={name} src={imageUrl} /> : <StyledImageDummy />}
      </Box>

      <Stack spacing={1} sx={{ p: 2 }}>
        <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={3}>
          <Typography variant="h5" noWrap>
            {name}
          </Typography>

          <IconButton onClick={(e: React.MouseEvent<HTMLButtonElement>) => onClickBookmark(e, id, bookmarkFlg)}>
            <Iconify icon={!bookmarkFlg ? 'ph:bookmark-simple' : 'ph:bookmark-simple-fill'} />
          </IconButton>
        </Stack>

        <Stack direction="row" spacing={1}>
          <Label variant="filled" color="primary">
            {prefecture}
          </Label>
          <Label variant="filled" color="primary">
            {city}
          </Label>
        </Stack>

        <Typography variant="caption">{access}</Typography>

        <Typography variant="h6" color="primary">
          {caption}
        </Typography>
      </Stack>
    </Card>
  );
};
