// useReservationEdit.ts
import { useState, useEffect } from 'react';
import { toApiDateTimeFormat } from 'src/functions/date-time-organize';
import useFetch from 'src/hooks/use-fetch';

export const useReservationEdit = (
  methods: any,
  plans: any,
  options: any,
  selectPlanId: any,
  setSelectPlanId: any,
  setSelectFrameId: any,
  setOptions: any
) => {
  const [rsvMin, setRsvMin] = useState({ sys: 0, cs: 0 });
  const { watch } = methods;

  const [plan, optionIds, photoStudioFrameId, reservationStartDateTime, photoStudioReserveId] = watch([
    'plan',
    'option',
    'photoStudioFrameId',
    'reservationStartDateTime',
    'photoStudioReserveId',
  ]);

  useFetch<any>({
    // useFetch を追加
    setStateFunc: (v) => {
      methods.setValue('needTimeChange', !v);
      setTimeout(() => methods.trigger('needTimeChange'), 0); // プロミスダメだった
    },
    reqProp: {
      pathKey: 'needTimeAdjustment',
      method: 'post',
      data: {
        photoStudioFrameId,
        photoStudioPlanId: plan && plan.id,
        photoStudioOptionId: optionIds ?? [],
        reservationStartDateTime: toApiDateTimeFormat(reservationStartDateTime),
        excludePhotoStudioReserveId: photoStudioReserveId,
      },
    },
    disable: !plan || !photoStudioFrameId,
    observable: [plan, optionIds],
  });

  const updateRsvMin = (planId: string | null, optionIds: string[] | null) => {
    const planObj = plans.find((obj: any) => obj.photoStudioPlanId === planId) ?? {};

    const { reserveMinutes: planRsvMin = 0, reserveMinutesForCustomer: planRsvMinCs = 0 } = planObj;

    const { optionRsvMin, optionRsvMinCs } = (optionIds ?? []).reduce(
      (acc, optionId) => {
        const choice = options.find((obj: any) => obj.photoStudioOptionId === optionId);

        if (choice) {
          acc.optionRsvMin += choice.reserveMinutes;
          acc.optionRsvMinCs += choice.reserveMinutesForCustomer;
        }

        return acc;
      },
      { optionRsvMin: 0, optionRsvMinCs: 0 }
    );

    setRsvMin({ sys: planRsvMin + optionRsvMin, cs: planRsvMinCs + optionRsvMinCs });
  };

  useEffect(() => {
    setSelectFrameId(photoStudioFrameId);
  }, [photoStudioFrameId]);

  useEffect(() => {
    if (plan) {
      setSelectPlanId(plan?.id ?? null);
      updateRsvMin(plan?.id, null);
    } else {
      setOptions(null);
      setSelectPlanId(null);
      methods.setValue('option', []);
      updateRsvMin(null, null);
    }
  }, [plan]);

  useEffect(() => {
    if (!selectPlanId) return;
    updateRsvMin(selectPlanId, optionIds);
  }, [optionIds]);

  return rsvMin;
};
