import Page from 'src/components/molecules/page';
import useStaffInformation from 'src/hooks/use-staff-information';
import { Container, Stack } from '@mui/material';
import HeadLineText from 'src/components/atoms/head-line-text';
import CreateButton from 'src/components/atoms/create-button';
import { PhotoStudioList } from 'src/components/organisms/photo-studio/list';
import { FC } from 'react';

export const PhotoStudioListPage: FC = () => {
  /* hook宣言 */
  const staff = useStaffInformation();

  /* 定数宣言 */
  const title = '店舗一覧';

  return (
    <Page title={title}>
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" m={5}>
          <HeadLineText text={title} />
          <CreateButton text="店舗登録" path={`/${staff.userType}/setting/photoStudio/new`} />
        </Stack>
        <PhotoStudioList />
      </Container>
    </Page>
  );
};
