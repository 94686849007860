import { ReactElement, useEffect, RefObject, memo } from 'react';
import FullCalendar from '@fullcalendar/react';
import { Button, IconButton, Box, Grid, CircularProgress } from '@mui/material';
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';
import CustomDatePicker from 'src/components/atoms/date-picker';
import BasicSelect from 'src/components/atoms/basic-select';
import { SelectChangeEvent } from '@mui/material/Select';
import { toStringYmdD } from 'src/functions/date-time-organize';
import { IoReload } from 'react-icons/io5';
import { useSearchParams } from 'react-router-dom';
import { createParams } from 'src/functions/params';
import { calcPrevDay, calcNextDay, calcOneWeekAgo, calcOneWeekLater } from 'src/functions/date';

import { StudioSelect } from './studio-select';

export type TCalendarHeader = {
  filterUseProperty: boolean;
  calendarRef: RefObject<FullCalendar>;
  selectSlotDuration: { label: string; value: string };
  setSelectSlotDuration: any;
  refresh?: {
    loading: boolean;
    trigger: () => void;
    frameTrigger?: () => void;
  };
  weekButtonView?: boolean;
  studioSelectState?: any | undefined;
  initialDate?: Date;
};

const items = [
  { label: '3分', value: '0:03' },
  { label: '5分', value: '0:05' },
  { label: '10分', value: '0:10' },
  { label: '15分', value: '0:15' },
  { label: '30分', value: '0:30' },
  { label: '1時間', value: '1:00' },
];

export const CalendarHeader = memo(
  ({
    calendarRef,
    selectSlotDuration,
    setSelectSlotDuration,
    refresh,
    weekButtonView,
    studioSelectState,
    initialDate,
  }: TCalendarHeader): ReactElement => {
    /* hook宣言 */
    const [searchParams, setSearchParams] = useSearchParams();
    const date: Date =
      initialDate || (searchParams.get('date') ? new Date(searchParams.get('date') || '') : new Date());
    /* 定数宣言 */
    const viewType = searchParams.get('viewType') ?? 'resourceTimeGridFourDay';

    // useEffect(() => {
    //   if (!searchParams.get('date')) {
    //     setDate(new Date());
    //   }
    // }, [searchParams]);
    useEffect(() => {
      const calApi = calendarRef.current?.getApi();
      if (calApi) {
        calApi.changeView(viewType);
        setDate(calApi.getDate());
        setSearchParams(createParams({ keyValues: { viewType }, searchParams }));
      }
    }, [calendarRef, viewType]);

    /* handle */
    const handleDateChange = (direction: 'prev' | 'today' | 'next'): void => {
      const calApi = calendarRef.current?.getApi();

      if (calApi) {
        if (direction === 'prev') {
          calApi.prev();
        } else if (direction === 'next') {
          calApi.next();
        } else {
          calApi.today();
        }

        setDate(calApi.getDate());
      } else if (typeof refresh?.frameTrigger === 'function') {
        const currentDate = new Date(searchParams.get('date') || '');
        const viewTypeSearchParam = searchParams.get('viewType');
        if (direction === 'prev') {
          setDate(viewTypeSearchParam === 'resourceTimeGridFourDay' || viewTypeSearchParam === null ? calcPrevDay(currentDate) : calcOneWeekAgo(currentDate));
        } else if (direction === 'next') {
          setDate(viewTypeSearchParam === 'resourceTimeGridFourDay' || viewTypeSearchParam === null ? calcNextDay(currentDate) : calcOneWeekLater(currentDate));
        } else {
          setDate(new Date());
        }
        refresh.frameTrigger();

      } else {
        alert('計算に失敗しました。日付をクリックし、開いたカレンダーから日付を選択してください。')
      }
    };
    const handleViewChange = (
      direction:
        | 'dayGridMonth'
        | 'timeGridWeek'
        | 'resourceTimeline'
        | 'resourceTimeGridWeek'
        | 'resourceTimeGridFourDay'
    ): void => {
      const calApi = calendarRef.current?.getApi();
      if (calApi) {
        if (direction === 'dayGridMonth') {
          calApi.changeView('dayGridMonth');
        } else if (direction === 'timeGridWeek') {
          calApi.changeView('timeGridWeek');
        } else if (direction === 'resourceTimeline') {
          calApi.changeView('resourceTimeline');
        } else if (direction === 'resourceTimeGridWeek') {
          calApi.changeView('resourceTimeGridWeek');
        } else if (direction === 'resourceTimeGridFourDay') {
          calApi.changeView('resourceTimeGridFourDay');
        }

        calApi && setSearchParams(createParams({ keyValues: { viewType: calApi.view.type }, searchParams }));
        setDate(calApi.getDate());

      // カレンダーが無い場合（予約枠データが無い場合）、カレンダーでの処理ができないので、ここで親コンポーネントに通知する
      } else {
        setSearchParams(createParams({ keyValues: { viewType: direction }, searchParams }));
        if(refresh && typeof refresh.frameTrigger === 'function') refresh.frameTrigger();
      }
    };
    const setDate = (date: any) => setSearchParams(createParams({ keyValues: { date }, searchParams }));

    return (
      <Box sx={{ mb: refresh?.loading ? 2.5 : 3, mt: 0 }}>
        <Grid container rowSpacing={0} columnSpacing={0}>
          <Grid item xs={12} sm={5} md={4} sx={{ display: 'flex' }}>
            {viewType !== 'dayGridMonth' && (
              <BasicSelect
                sx={{ ml: 1, borderRadius: 1.0, width: 100 }}
                items={items}
                value={selectSlotDuration.value}
                label={'間隔'}
                handleChange={(e: SelectChangeEvent<string | number>) => setSelectSlotDuration(e.target)}
              />
            )}
          </Grid>
          <Grid style={{ textAlign: 'center' }} item xs={12} sm={5} md={4}>
            <IconButton data-trackid="prev-button" sx={{ mt: 1, mr: 1 }} onClick={(): void => handleDateChange('prev')}>
              <IoIosArrowBack size={25} />
            </IconButton>

            <CustomDatePicker
              onChange={(newValue: Date | null): void => {
                setDate(newValue);
                calendarRef.current?.getApi().gotoDate(newValue && new Date(newValue.toString()));
                // カレンダーが無い場合（予約枠データが無い場合）、カレンダーでの処理ができないので、ここで親コンポーネントに通知する
                if(!calendarRef.current?.getApi().getDate().toString() && refresh && typeof refresh.frameTrigger === 'function') {
                  refresh.frameTrigger();
                }
              }}
              value={date}
              {...{ textValue: date && toStringYmdD(date), inputFormat: 'yyyy 年 MM 月 dd 日 (E)', width: 0.7 }}
            />

            <IconButton data-trackid="next-button" sx={{ mt: 1, ml: 1 }} onClick={(): void => handleDateChange('next')}>
              <IoIosArrowForward size={25} />
            </IconButton>
          </Grid>
          <Grid style={{ display: 'flex', justifyContent: 'flex-end' }} item xs={12} sm={5} md={4}>
            {studioSelectState === undefined && (
              <>
                <Button
                  disabled={!weekButtonView}
                  data-trackid="next-button"
                  sx={{ ml: 1 }}
                  onClick={(): void => handleViewChange('resourceTimeGridWeek')}
                >
                  Week
                </Button>
                <Button
                  data-trackid="next-button"
                  sx={{ ml: 1 }}
                  onClick={(): void => handleViewChange('resourceTimeGridFourDay')}
                >
                  Day
                </Button>
              </>
            )}

            <IconButton
              data-trackid="update-button"
              sx={{ mt: 1, mr: 3, height: 35 }}
              onClick={(): void => {
                refresh && refresh.trigger();
              }}
              disabled={refresh?.loading}
            >
              {refresh?.loading ? <CircularProgress color="inherit" size={20} /> : <IoReload size={20} />}
            </IconButton>

            <Button
              data-trackid="today-button"
              size="large"
              sx={{ mr: 5 }}
              variant="outlined"
              onClick={(): void => handleDateChange('today')}
              disabled={((): boolean => {
                if (!date) return false;
                const inputDate = new Date(date.toString());
                const currentDate = new Date();
                return (
                  inputDate.getDate() === currentDate.getDate() &&
                  inputDate.getMonth() === currentDate.getMonth() &&
                  inputDate.getFullYear() === currentDate.getFullYear()
                );
              })()}
            >
              今日
            </Button>
          </Grid>
          {studioSelectState !== undefined && <StudioSelect studioSelectState={studioSelectState} />}
        </Grid>
      </Box>
    );
  }
);

export const getLabel = (ary: any[], id: string): string => {
  for (let i = 0; i < ary.length; i += 1) {
    if (ary[i].id === id) {
      return ary[i].label;
    }
  }
  return '';
};
