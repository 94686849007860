import { useParams, useNavigate, useSearchParams, useLocation } from 'react-router-dom';
// @mui
// components
import Page from 'src/components/molecules/page';
import CustomerDetailTemplate from 'src/components/templates/customer/customer-detail-template';
import {
  reserveListFetchOrganize,
  customerDetailFetchOrganize,
  nextPageCheckOrganize,
} from 'src/functions/fetch-value-organize';
// hooks
import useFetch from 'src/hooks/use-fetch';
import usePush from 'src/hooks/use-push';
import { createParams } from 'src/functions/params';
// staff情報
import useStaffInformation from 'src/hooks/use-staff-information';

export default function Detail() {
  /* hook宣言 */
  const staff = useStaffInformation();
  const navigate = useNavigate();
  const { customerId } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const push = usePush();
  const location = useLocation();

  /* 定数宣言 */
  const page = Number(searchParams.get('page')) ?? 0;
  const order = searchParams.get('order') ?? 'asc';
  const orderBy = searchParams.get('orderBy') ?? 'reservationStartDatetime';
  const title = '顧客詳細';
  const rowsPerPage = 30;

  /* データ取得 */
  const { fetchedData: defaultValues } = useFetch<any>({
    iniState: {},
    reqProp: {
      pathKey: 'customer',
      pathParam: customerId,
    },
    organize: customerDetailFetchOrganize,
  });
  const { fetchedData: reservesResponseData } = useFetch<any>({
    reqProp: {
      pathKey: 'reservation',
      queryParams: {
        query: customerId,
        offset: page * rowsPerPage,
        limit: rowsPerPage,
        ...(() => (orderBy === '' || !orderBy ? {} : { orderBy }))(),
        ...(() => (order === '' || !order ? {} : { order }))(),
      },
    },
    organize: reserveListFetchOrganize,
    observable: [page, orderBy, order],
  });
  // 次のページがあるかどうか
  const { fetchedData: nextPageCheck } = useFetch<any>({
    iniState: false,
    reqProp: {
      pathKey: 'reservation',
      queryParams: {
        query: customerId,
        offset: (page + 1) * rowsPerPage,
        limit: 2,
        ...(() => (orderBy === '' || !orderBy ? {} : { orderBy }))(),
        ...(() => (order === '' || !order ? {} : { order }))(),
      },
    },
    organize: nextPageCheckOrganize,
    observable: [page, orderBy, order],
  });

  /* handle */
  const handleChangePage = (event: any, newPage: any) => {
    setSearchParams(createParams({ keyValues: { page: newPage }, searchParams }));
  };
  const handleRequestSort = (event: any, property: any) => {
    const isAsc = orderBy === property && order === 'asc';
    setSearchParams(createParams({ keyValues: { order: isAsc ? 'desc' : 'asc', orderBy: property }, searchParams }));
  };
  const handleEditButtonOnClick = () => {
    navigate(`/${staff.userType}/customers/${customerId}/edit`, { state: { beforeParams: { page } } });
  };
  const handleRowClick = (id: any) => {
    navigate(`/${staff.userType}/customers/${customerId}/reserves/${id}/edit`, {
      state: { beforeParams: location },
    });
  };

  const OnDelete = () => {
    new Promise((resolve) => {
      push(resolve, {
        reqProp: {
          pathKey: 'customer',
          method: 'delete',
          data: { customerId },
        },
        aftProp: {
          nextPath: `/customers`,
          showMessage: {
            state: { snackBar: { open: true, viewType: 'info', message: '削除しました' } },
          },
        },
      });
    });
  };

  return (
    <Page title={title}>
      <CustomerDetailTemplate
        {...(() => ({
          title,
          handleRequestSort,
          defaultValues,
          nextPageCheck,
          customerId,
          rowsPerPage,
          order,
          orderBy,
          reservesResponseData,
          handleRowClick,
          isUserNotFound: reservesResponseData.length === 0,
          page,
          handleChangePage,
          OnDelete,
          handleEditButtonOnClick,
        }))()}
      />
    </Page>
  );
}
