import { LoadingButton } from '@mui/lab';
import useFetch from 'src/hooks/use-fetch';
import { Fragment, useEffect, useRef } from 'react';
import {
  Typography,
  Grid,
  Stack,
  Button,
  Box,
  Container,
  AccordionSummary,
  AccordionDetails,
  Accordion,
  CircularProgress,
} from '@mui/material';
import { styled } from '@mui/system';
import {
  PhotoFormUiPartsList,
  PhotoFormUiPartsListInputsInnerOptionsInner,
  PhotoFormUiPartsListInputsInner,
  ReserveDetailFormInnerInputsInnerOptionsInner,
  PhotoStudioReservationFrame,
  PhotoStudioStaffPhotoStudio,
} from 'codegen/axios/photo/photo_studio';
import { RHFCheckboxGroup, FormProvider, RHFTextField, RHFDatePicker } from 'src/components/atoms/hook-form';
import * as React from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useForm } from 'react-hook-form';
import { useLocation, useSearchParams } from 'react-router-dom';
import CustomDatePicker from 'src/components/atoms/date-picker';
import { toStringYmdD } from 'src/functions/date-time-organize';
import { createParams } from 'src/functions/params';
import { FRAME_INDEX_PARAMS } from 'src/utils/requestParams';
import { filterBarValidationSchema } from 'src/functions/validation-schema';
import { yupResolver } from '@hookform/resolvers/yup';
import { reservationListDynamicExcludedKeys } from '..';

const CustomAccordionSummary = styled(AccordionSummary)(() => ({
  minHeight: '30px', // ここで閉じた状態の高さを変更できます
  '&.Mui-expanded': {
    minHeight: '48px',
  },
  '& .MuiAccordionSummary-content': {
    margin: '8px 0',
    '&.Mui-expanded': {
      margin: '12px 0',
    },
  },
}));

interface ListToolbarProps {
  searchFilterParamsState: {
    searchFilterParams: { [key: string]: any } | null;
    setSearchFilterParams: React.Dispatch<React.SetStateAction<any>>;
  };
  loading: boolean;
  resources: PhotoStudioReservationFrame[];
  period?: boolean;
  viewItem?: boolean;
  calendarRef?: any;
  refresh?: any;
}

type ReturnDefaultValue = [] | '' | false | null;

export const DynamicFilterBar = React.memo(
  ({ searchFilterParamsState, loading, resources, period, viewItem, calendarRef, refresh }: ListToolbarProps) => {
    /* データ取得 */
    const { fetchedData: formInputs } = useFetch<PhotoFormUiPartsList[]>({
      iniState: null,
      reqProp: {
        pathKey: 'formInputs',
        queryParams: { limit: 100, offset: 0 },
      },
    });

    const { fetchedData: plans } = useFetch<PhotoFormUiPartsList[]>({
      iniState: null,
      reqProp: {
        pathKey: 'plan',
        queryParams: { limit: 100, offset: 0 },
      },
    });

    const { fetchedData: restaurantOption } = useFetch<PhotoStudioStaffPhotoStudio[]>({
      reqProp: {
        pathKey: 'studio',
        queryParams: { limit: 100, offset: 0 },
      },
    });

    return (
      <>
        {formInputs && resources && restaurantOption && plans ? (
          <Stack direction="row" alignItems="center" justifyContent="flax" m={1}>
            <DynamicToolbarForm
              formInputs={formInputs}
              searchFilterParamsState={searchFilterParamsState}
              options={{ photoStudio: restaurantOption, resources, plans }}
              loading={loading}
              period={period}
              viewItem={viewItem}
              calendarRef={calendarRef}
              refresh={refresh}
            />
          </Stack>
        ) : (
          <Box sx={{ px: 10, display: 'flex', justifyContent: 'center', alignItems: 'center', height: '60px' }}>
            <CircularProgress size={30} />
          </Box>
        )}
      </>
    );
  }
);

const DynamicToolbarForm = ({
  formInputs,
  options,
  searchFilterParamsState,
  loading,
  period,
  viewItem,
  calendarRef,
  refresh,
}: {
  formInputs: PhotoFormUiPartsList[];
  options: { photoStudio: any; resources: PhotoStudioReservationFrame[]; plans: PhotoFormUiPartsList[] };
  searchFilterParamsState: {
    searchFilterParams: { [key: string]: any } | null;
    setSearchFilterParams: React.Dispatch<React.SetStateAction<any>>;
  };
  loading: boolean;
  period?: boolean;
  viewItem?: boolean;
  calendarRef?: any;
  refresh?: any;
}) => {
  const { pathname } = useLocation();
  const { setSearchFilterParams } = searchFilterParamsState;
  const [searchParams, setSearchParams] = useSearchParams();
  const prevAllFormValues = useRef<{ [key: string]: any }>({});
  const date: Date = searchParams.get('date') ? new Date(searchParams.get('date') || '') : new Date();
  const [localDate, setLocalDate] = React.useState<Date>(date);

  useEffect(() => {
    setLocalDate(date);
  }, [date.toString()]);

  useEffect(() => {
    if (!calendarRef) return;
    if (!refresh) return;
    refresh.trigger();
  }, []);

  const setDate = (date: any) => setSearchParams(createParams({ keyValues: { date }, searchParams }));
  /* handle */
  const onSubmit = () => {
    const data = methods.getValues();
    searchParams.delete('page');
    if (data.photoStudioId.length !== 1) searchParams.delete('viewType');
    setSearchParams(searchParams);
    localStorage.setItem(pathname, JSON.stringify(data));
    setSearchFilterParams(data);

    if (!calendarRef) return;
    const setDate = (date: any) => setSearchParams(createParams({ keyValues: { date }, searchParams }));
    setDate(localDate);
    if(!calendarRef.current?.getApi().getDate().toString()) { // カレンダーが無い場合（予約枠データが無い場合)
      refresh.frameTrigger(); // カレンダーでの処理ができないので、ここで親コンポーネントに通知する
    } else if (calendarRef.current.getApi().getDate().toString() !== localDate.toString()) {
      calendarRef.current.getApi().gotoDate(localDate && new Date(localDate.toString()));
    } else {
      refresh.trigger();
    }
  };
  const validationSchema = filterBarValidationSchema();
  const isReserveListPage = period;
  const resolver = isReserveListPage ? yupResolver(validationSchema) : undefined;

  const methods = useForm({
    resolver,
    defaultValues: organizeFlattenedData(
      formInputs,
      localStorage.getItem(pathname) && JSON.parse(localStorage.getItem(pathname)!),
      options
    ),
  });

  const { handleSubmit, watch } = methods;
  const allFormValues = watch();

  useEffect(() => {
    if (JSON.stringify(prevAllFormValues.current) === JSON.stringify(allFormValues)) return;

    const data = {
      viewFilterItem: allFormValues.viewFilterItem,
      viewItem: allFormValues.viewItem,
      photoStudioPlanId: allFormValues.photoStudioPlanId,
      photoStudioFrameId: allFormValues.photoStudioFrameId,
      photoStudioId: allFormValues.photoStudioId,
      searchName: allFormValues.searchName,
      periodStart: allFormValues.periodStart,
      periodEnd: allFormValues.periodEnd,
      reserveIndexFilterView: allFormValues.reserveIndexFilterView,
      // viewFilterItemに含まれる項目のみを抽出
      ...(allFormValues.viewFilterItem &&
        allFormValues.viewFilterItem.reduce((acc: any, cur: any) => {
          if (!reservationListDynamicExcludedKeys.includes(cur)) {
            return { ...acc, [cur]: allFormValues[cur] };
          }
          return acc;
        }, {})),
    };

    // onSubmitしなくても保存するもの
    if (prevAllFormValues.current.reserveIndexFilterView !== allFormValues.reserveIndexFilterView) {
      localStorage.setItem(pathname, JSON.stringify(data));
    }
    if (prevAllFormValues.current.viewFilterItem !== allFormValues.viewFilterItem) {
      localStorage.setItem(pathname, JSON.stringify(data));
    }
    if (prevAllFormValues.current.viewItem !== allFormValues.viewItem) {
      localStorage.setItem(pathname, JSON.stringify(data));
    }
    prevAllFormValues.current = allFormValues;
    // methods.reset(data);テキストがおかしくなる
  }, [allFormValues]);

  useEffect(() => {
    const data = methods.getValues();
    localStorage.setItem(pathname, JSON.stringify(data));
    setSearchFilterParams(data);
    // 初回のフェッチ処理
  }, []);

  // todo:ハードコーディングで店舗の並び替えを行なっているので、display_orderのカラム追加など使用して要修正
  const reorderedPhotoStudio = React.useMemo(
    () =>
      reorderStudios(
        options.photoStudio,
        'a3b2c544-0df7-4434-a6fd-1cd458cc0c41',
        '09df9741-6dbd-4959-9d9a-c6fb84753dbd'
      ),
    [options.photoStudio]
  );

  const formValues = methods.getValues();

  return (
    <>
      <Container maxWidth={false}>
        <Accordion
          expanded={formValues.reserveIndexFilterView}
          onChange={() => methods.setValue('reserveIndexFilterView', !formValues.reserveIndexFilterView)}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon color={checkEmptyValues(allFormValues) ? 'disabled' : 'primary'} />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <Typography sx={{ width: '16%', flexShrink: 0 }}>絞り込みメニュー</Typography>
            <Typography sx={{ color: 'text.secondary', whiteSpace: 'pre-line' }}>
              {checkEmptyValues(allFormValues)
                ? ''
                : (() => {
                    const maxLength = 75;
                    const filters = generateOutput(allFormValues, options);
                    if (filters.length > maxLength) {
                      return `${filters.slice(0, maxLength)}......etc`;
                    }
                    return `${filters}`;
                  })()}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            {formInputs && formValues.reserveIndexFilterView && (
              <Box sx={{ mt: 0 }}>
                <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} md={6}>
                      <Accordion style={{ backgroundColor: '#f5f5f5' }}>
                        <CustomAccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Typography>フィルター設定</Typography>
                        </CustomAccordionSummary>
                        <AccordionDetails>
                          <RHFCheckboxGroup name={'viewFilterItem'} options={extractSelectedInputs(formInputs)} />
                        </AccordionDetails>
                      </Accordion>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                      {viewItem && (
                        <Accordion style={{ backgroundColor: '#f5f5f5' }}>
                          <CustomAccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                          >
                            <Typography>項目-表示・非表示</Typography>
                          </CustomAccordionSummary>
                          <AccordionDetails>
                            <RHFCheckboxGroup
                              name={'viewItem'}
                              options={extractSelectedInputs(formInputs, 'viewItem')}
                            />
                          </AccordionDetails>
                        </Accordion>
                      )}
                    </Grid>
                    {period ? (
                      <Grid item xs={12} sm={12} md={12}>
                        <RHFTextField sx={{ pb: 2 }} size="small" name={'searchName'} label={'検索'} />
                      </Grid>
                    ) : (
                      <>
                        <Grid item xs={12} sm={6} md={6}>
                          <CustomDatePicker
                            onChange={async (newValue: Date) => {
                              // dateないときに変更できないから苦肉の策(ブックマークとか考えるとデフォルトでdate付けとくのは微妙)
                              if (!searchParams.get('date')) {
                                await new Promise((resolve) => {
                                  setDate(new Date());
                                  resolve(newValue);
                                });
                              }
                              setLocalDate(newValue);
                            }}
                            value={localDate}
                            size="small"
                            inputPropsStyle={{ fontWeight: 'regular' }}
                            {...{
                              textValue: date && toStringYmdD(localDate),
                              inputFormat: 'yyyy 年 MM 月 dd 日 (E)',
                              width: '300px',
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                          <RHFTextField sx={{ pb: 2 }} size="small" name={'searchName'} label={'検索'} />
                        </Grid>
                      </>
                    )}

                    {period && (
                      <>
                        <Grid item xs={12} sm={6} md={5} sx={{ mb: 2 }}>
                          <RHFDatePicker name="periodStart" clearButton label="有効期間(開始)" size="small" />
                        </Grid>
                        <Grid item xs={12} sm={6} md={2} sx={{ mb: 2 }}>
                          〜
                        </Grid>
                        <Grid item xs={12} sm={6} md={5} sx={{ mb: 2 }}>
                          <RHFDatePicker name="periodEnd" clearButton label="有効期間(終了)" size="small" />
                        </Grid>
                      </>
                    )}
                  </Grid>
                  {formValues.viewFilterItem.includes('photoStudio') && (
                    <Grid item xs={12} sm={12} md={12}>
                      <RHFCheckboxGroup
                        label={'店舗'}
                        name={'photoStudioId'}
                        options={reorderedPhotoStudio.map((obj: any) => ({
                          value: obj.photoStudioId,
                          label: obj.photoStudioName,
                        }))}
                      />
                    </Grid>
                  )}

                  {formValues.viewFilterItem.includes('photoStudioFlame') && (
                    <Grid item xs={12} sm={12} md={12}>
                      <FrameFilter frames={options.resources} />
                    </Grid>
                    )
                  }
                  {formValues.viewFilterItem.includes('photoStudioPlan') && (
                    <Grid item xs={12} sm={12} md={12}>
                      <RHFCheckboxGroup
                        label={'プラン'}
                        name={'photoStudioPlanId'}
                        options={options.plans.map((obj: any) => ({
                          value: obj.photoStudioPlanId,
                          label: obj.name,
                        }))}
                      />
                    </Grid>
                  )}

                  {formInputs.map((group: PhotoFormUiPartsList, index: number) => (
                    <Fragment key={index}>
                      <>
                        {group.inputs?.map((input: PhotoFormUiPartsListInputsInner, idx: number) => {
                          if (
                            allFormValues.viewFilterItem === null ||
                            !allFormValues.viewFilterItem.includes(input.name)
                          )
                            return null;
                          switch (input.inputType) {
                            case 'checkbox':
                              return (
                                <Grid key={idx} item xs={12} sm={12} md={12}>
                                  <RHFCheckboxGroup
                                    label={`${`${input.name}${input.requiredFlg ? '*' : ''}`}` || ''}
                                    name={input.name || ''}
                                    options={input.options ? convertOptions(input.options) : [{ label: '', value: '' }]}
                                  />
                                </Grid>
                              );
                            case 'selectbox':
                              return (
                                <Grid key={idx} item xs={12} sm={12} md={12}>
                                  <RHFCheckboxGroup
                                    name={input.name || ''}
                                    label={`${input.name}${input.requiredFlg ? '*' : ''}`}
                                    options={input.options ? convertOptions(input.options) : [{ label: '', value: '' }]}
                                  />
                                </Grid>
                              );
                            case 'radiobutton':
                              return (
                                <Grid key={idx} item xs={12} sm={12} md={12}>
                                  <RHFCheckboxGroup
                                    name={input.name || ''}
                                    label={`${input.name}${input.requiredFlg ? '*' : ''}`}
                                    options={input.options ? convertOptions(input.options) : [{ label: '', value: '' }]}
                                  />
                                </Grid>
                              );
                            case 'textbox':
                              return (
                                <Grid key={idx} item xs={12} sm={12} md={12}>
                                  <RHFTextField
                                    sx={{ mt: 2 }}
                                    size="small"
                                    name={input.name || ''}
                                    label={`${input.name}${input.requiredFlg ? '*' : ''}`}
                                  />
                                </Grid>
                              );
                            case 'textarea':
                              return (
                                <Grid key={idx} item xs={12} sm={12} md={12}>
                                  <RHFTextField
                                    sx={{ mt: 2 }}
                                    size="small"
                                    name={input.name || ''}
                                    label={`${input.name}${input.requiredFlg ? '*' : ''}`}
                                  />
                                </Grid>
                              );
                            default:
                              return null;
                          }
                        })}
                      </>
                    </Fragment>
                  ))}
                  <Stack direction="row" alignItems="center" justifyContent="center" sx={{ m: 1, pt: 3 }} spacing={5}>
                    <Button
                      variant="outlined"
                      sx={{ width: 120, height: 47 }}
                      size="large"
                      onClick={() => {
                        const values = methods.getValues();
                        methods.reset({
                          ...getInitialValues(values),
                          viewItem: values.viewItem,
                          viewFilterItem: values.viewFilterItem,
                          reserveIndexFilterView: true,
                        });
                      }}
                    >
                      クリア
                    </Button>
                    <LoadingButton
                      variant="contained"
                      sx={{ width: 120, height: 47 }}
                      size="large"
                      type="submit"
                      loading={loading}
                    >
                      絞り込み
                    </LoadingButton>
                  </Stack>
                </FormProvider>
              </Box>
            )}
          </AccordionDetails>
        </Accordion>
      </Container>
    </>
  );
};

const FrameFilter = ({
  frames
}: {
  frames: PhotoStudioReservationFrame[];
}) => {
  // 予約枠のデータ数がframe取得APIのlimitと一致している場合は取得漏れしているので、予約枠は表示しない
  if(frames.length >= FRAME_INDEX_PARAMS.limit){
    return (
      <>
        <Typography color="text.secondary">
          予約枠
        </Typography>
        <Typography py={1}>
          表示数上限（{ FRAME_INDEX_PARAMS.limit }件）を超えました。有効期間を指定してください。
        </Typography>
      </>
    );
  }

  return (
    <RHFCheckboxGroup
      label={'予約枠'}
      name={'photoStudioFrameId'}
      options={frames.map((obj: any) => ({
        value: obj.photoStudioReservationFrameId,
        label: obj.name,
      }))}
    />
  );
}

function generateOutput(data: Record<string, unknown>, options: { plans: any; resources: any; photoStudio: any }) {
  const excludedKeys = ['viewFilterItem', 'viewItem', 'reserveIndexFilterView', 'periodStart', 'periodEnd'];

  const getName = (value: any, collection: any[], idKey: string, nameKey: string) => {
    const item = collection.find((obj) => obj[idKey] === value);
    return item ? item[nameKey] : '';
  };

  const getNames = (values: unknown, collection: any[], idKey: string, nameKey: string) =>
    Array.isArray(values) ? values.map((value) => getName(value, collection, idKey, nameKey)).join(', ') : values;

  const filters = Object.keys(data)
    .filter((key) => !excludedKeys.includes(key))
    .map((key) => {
      const values = data[key];

      if (key === 'photoStudioPlanId') {
        return getNames(values, options.plans, 'photoStudioPlanId', 'name');
      }
      if (key === 'photoStudioFrameId') {
        return getNames(values, options.resources, 'photoStudioReservationFrameId', 'name');
      }
      if (key === 'photoStudioId') {
        return getNames(values, options.photoStudio, 'photoStudioId', 'photoStudioName');
      }
      return values;
    })
    .filter((item: any) => item !== '' && item !== undefined && item?.length !== 0)
    .join(', ');

  return `チェックされてる項目: ${filters}`;
}

function getInitialValues(initialValues: Record<string, any>): Record<string, any> {
  const initialFormValues: Record<string, any> = {};

  Object.entries(initialValues).forEach(([propName, value]) => {
    const isPeriodEnd = propName === 'periodEnd';
    const isPeriodStart = propName === 'periodStart';
    const isPeriod = isPeriodEnd || isPeriodStart;

    if (isPeriod) {
      initialFormValues[propName] = value;
    } else {
      initialFormValues[propName] = getDefaultValue(value);
    }
  });

  return initialFormValues;
}

function getDefaultValue(value: any): ReturnDefaultValue {
  if (Array.isArray(value)) return [];
  if (typeof value === 'string') return '';
  if (typeof value === 'boolean') return false;
  return null;
}

function convertOptions(
  options: ReserveDetailFormInnerInputsInnerOptionsInner[] | PhotoFormUiPartsListInputsInnerOptionsInner[]
): { value: string; label: string }[] {
  return options.map((option) => ({
    value: option.label || '',
    label: option.label || '',
  }));
}

export const organizeFlattenedData = (
  data: PhotoFormUiPartsList[],
  allFormValues: any,
  options: {
    photoStudio: PhotoStudioStaffPhotoStudio[];
    resources: PhotoStudioReservationFrame[];
    plans: PhotoFormUiPartsList[];
  }
) => {
  const availablePlanIds = options.plans.map((obj: any) => obj.photoStudioPlanId);
  const availableResourceIds = options.resources.map((obj: any) => obj.photoStudioReservationFrameId);
  const availablePhotoStudioIds = options.photoStudio.map((obj: any) => obj.photoStudioId);
  // 選択肢にないものはvalueに含めない
  const photoStudioPlanId =
    (allFormValues &&
      allFormValues.photoStudioPlanId &&
      allFormValues.photoStudioPlanId.filter((value: any) => availablePlanIds.includes(value))) ||
    [];
  const photoStudioFrameId =
    (allFormValues &&
      allFormValues.photoStudioFrameId &&
      allFormValues.photoStudioFrameId.filter((value: any) => availableResourceIds.includes(value))) ||
    [];
  const photoStudioId =
    (allFormValues &&
      allFormValues.photoStudioId &&
      allFormValues.photoStudioId.filter((value: any) => availablePhotoStudioIds.includes(value))) ||
    [];

  const flattenedData: { [key: string]: any } = {
    periodStart: allFormValues && allFormValues.periodStart,
    periodEnd: allFormValues && allFormValues.periodEnd,
    searchName: (allFormValues && allFormValues.searchName) || '',
    photoStudioId,
    viewFilterItem: (allFormValues && allFormValues.viewFilterItem) || [],
    viewItem: (allFormValues && allFormValues.viewItem) || [],
    photoStudioPlanId,
    photoStudioFrameId,
    reserveIndexFilterView: (allFormValues && allFormValues.reserveIndexFilterView) || false,
  };
  data.forEach((group: any) => {
    group.inputs.forEach((input: any) => {
      const availableOptions = input.options.map((obj: any) => obj.label);
      const valueGet = () =>
        allFormValues &&
        allFormValues[input.name] &&
        typeof allFormValues[input.name] !== 'string' &&
        allFormValues[input.name].filter((value: any) => availableOptions.includes(value));
      switch (input.inputType) {
        case 'textbox':
          flattenedData[input.name] = (allFormValues && allFormValues[input.name]) || '';
          break;
        case 'textarea':
          flattenedData[input.name] = (allFormValues && allFormValues[input.name]) || '';
          break;
        default:
          flattenedData[input.name] = valueGet() || [];
      }
    });
  });
  return flattenedData;
};

// 絞り込みフィルターの表示非表示を切り替えるチェックボックス用のデータ生成
function extractSelectedInputs(groups: PhotoFormUiPartsList[], name?: string): { value: string; label: string }[] {
  const selectedInputs: { value: string; label: string }[] =
    name === 'viewItem'
      ? []
      : [
          { value: 'photoStudio', label: '店舗' },
          { value: 'photoStudioFlame', label: '予約枠' },
          { value: 'photoStudioPlan', label: 'プラン' },
        ];

  groups.forEach((group) => {
    group.inputs
      ? group.inputs.forEach((input) => {
          selectedInputs.push({ value: input.name, label: input.name });
        })
      : [];
  });
  return selectedInputs;
}
// 絞り込みの値が空かどうかを判定する
function checkEmptyValues(obj: any) {
  const nonEmptyEntries = Object.entries(obj).filter(([key, value]) => {
    if (key === 'reserveIndexFilterView') return false;
    if (key === 'viewFilterItem') return false;
    if (key === 'viewItem') return false;
    return value !== null && value !== undefined && value !== '' && !(Array.isArray(value) && value.length === 0);
  });
  return nonEmptyEntries.length === 0;
}

function reorderStudios(
  photoStudio: PhotoStudioStaffPhotoStudio[],
  firstStudioId: string,
  secondStudioId: string
): PhotoStudioStaffPhotoStudio[] {
  const firstIndex = photoStudio.findIndex((studio: any) => studio.photoStudioId === firstStudioId);
  const secondIndex = photoStudio.findIndex((studio: any) => studio.photoStudioId === secondStudioId);

  if (firstIndex !== -1 && secondIndex !== -1) {
    const adjustedIndex = firstIndex < secondIndex ? secondIndex - 1 : secondIndex;
    const [movedStudio] = photoStudio.splice(firstIndex, 1);
    photoStudio.splice(adjustedIndex + 1, 0, movedStudio);
  }
  return photoStudio;
}
