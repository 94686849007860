import { Dialog, Button, Stack, Typography } from '@mui/material';
import log from 'src/utils/log';
import usePush from 'src/hooks/use-push';
import { PhotoFormUiPartsList, PostReserveRequestBody, Rule } from 'codegen/axios/photo/photo_studio';
import useFetch from 'src/hooks/use-fetch';
import { useState } from 'react';
import { LoadingButton } from '@mui/lab';
import { convertInputFormToPostReserveRequestBody } from '../../create-edit/functions/push-value-organize';

export function EmptySetModal({
  setOpenMdl,
  event,
  fetchTriggerTimestampState,
  emptyDetailState,
  setSemiDetailRsvId,
}: any) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const push = usePush();
  const [, setFetchTriggerTimestamp] = fetchTriggerTimestampState;
  const handleClick = (ret: string) => {
    if (ret === 'ok') {
      onSubmit();
    }
    if (ret === 'cancel') {
      setOpenMdl(false);
      log('削除する:Cancel時の処理を実行する');
    }
  };

  const { fetchedData: formInputs } = useFetch<PhotoFormUiPartsList[]>({
    iniState: null,
    reqProp: {
      pathKey: 'formInputs',
      queryParams: { limit: 100, offset: 0 },
    },
  });
  const { fetchedData: rule } = useFetch<Rule>({
    reqProp: {
      pathKey: 'rule',
      queryParams: { offset: 0, limit: 500 },
    },
    organize: (obj: Rule[]) => {
      // 与えられたrule配列から"ruleType": "status_color_change"の要素だけを抽出
      const statusColorChangeRules = obj.filter((r) => r.ruleType === 'status_plan_id');
      const statusRule = statusColorChangeRules.find(
        (r) => r.conditionKey === 'ステータス' && r.conditionValue === '仮押え'
      );
      return statusRule;
    },
  });

  /* handle */
  const onSubmit = () => {
    // todo:apiが受けれる形にtimeとか直す
    const data: PostReserveRequestBody = convertInputFormToPostReserveRequestBody(
      {
        customerName: ' ',
        customerNameKana: '',
        customerEmail: '',
        customerTelno: '',
        customerGender: '',
        zip: '',
        prefecture: '',
        city: '',
        street: '',
        plan: { id: rule.actionValue }, // ここruleから取得する
        option: [],
        reservationStartDateTime: event.start!.toISOString(),
        reservationEndDateTime: event.start!.toISOString(),
        photoStudioFrameId: event.extendedProps.photoStudioFrameId,
        photoStudioId: event.extendedProps.photoStudioId,
        sendAlertFlg: false,
        ステータス: '仮押え',
      },
      formInputs
    );
    new Promise((resolve) => {
      setIsSubmitting(true);
      push(resolve, {
        reqProp: {
          pathKey: 'reservation',
          method: 'post',
          data,
        },
        aftProp: {
          calledFunc: () => {
            setFetchTriggerTimestamp(Date.now());
            setOpenMdl(false);
          },
          errorFunc: () => {
            setIsSubmitting(false);
          },
          nextPath: '',
          resolve: () => {
            setIsSubmitting(false);
            resolve;
          },
          showMessage: {
            state: {
              snackBar: {
                open: true,
                viewType: 'success',
                message: '変更を保存しました',
              },
            },
          },
        },
      }).then((response) => {
        emptyDetailState[1]({ ...emptyDetailState[0], open: false });
        setSemiDetailRsvId((response as any).reserve.photoStudioReserveId);
      });
    });
  };
  return (
    <>
      <Dialog open onClose={() => handleClick('cancel')} sx={{ top: 0, left: 0 }}>
        <Stack justifyContent="center" alignItems="center" sx={styles.container}>
          <Typography color="text.secondary" variant="body1" sx={{ pt: 1 }}>
            仮押えしますか？
          </Typography>
          <Stack direction="row" spacing={2} sx={{ mb: 2, mt: 2 }}>
            <Button variant="outlined" color="primary" onClick={() => handleClick('cancel')}>
              キャンセル
            </Button>
            <LoadingButton
              disabled={formInputs === null || rule.actionValue === undefined}
              variant="contained"
              sx={{ width: 100 }}
              color="primary"
              onClick={() => handleClick('ok')}
              loading={isSubmitting}
            >
              {formInputs === null || rule.actionValue === undefined ? '準備中..' : 'OK'}
            </LoadingButton>
          </Stack>
        </Stack>
      </Dialog>
    </>
  );
}
const styles = {
  container: {
    p: 2,
  },
  iconConstainer: {
    fontSize: 100,
    color: '#BF6761',
  },
  title: {
    fontWeight: 'bold',
  },
};
