import axios from 'axios';
// @mui
import { Grid } from '@mui/material';
// components
import { RHFTextField, RHFTextInputPrefecture, RHFZipCode } from 'src/components/atoms/hook-form';
// utils
import log from 'src/utils/log';
import { prefecture } from 'src/utils/prefecture';

// ----------------------------------------------------------------------

export default function AddressInputs({ methods }: any) {
  const handleZipCode = (value: any) => {
    addressGet(value);
  };

  const addressGet = async (e: any) => {
    if (e.replace(/-/, '').match(/\d{7}/)) {
      try {
        const zipCode = `${e.replace(/-/, '').slice(0, 3)}-${e.replace(/-/, '').slice(3, e.replace(/-/, '').length)}`;
        log(zipCode);
        const res = await axios.get(
          `https://madefor.github.io/postal-code-api/api/v1/${zipCode.replace(/-/, '/')}.json`
        );
        const address = res.data.data[0].ja;

        methods.setValue('prefecture', address.prefecture);
        methods.setValue('city', `${address.address1}${address.address2}`);
      } catch {
        log('住所の取得に失敗しました。');
      }
    }
  };

  return (
    <>
      <Grid item xs={12} sm={6} md={2}>
        <RHFZipCode
          size="small"
          name="zip"
          methods={methods}
          onChange={handleZipCode}
          label="郵便番号"
          placeholder="例：1234567"
        />
      </Grid>

      <Grid item xs={12} sm={6} md={2}>
        <RHFTextInputPrefecture
          size="small"
          isOptionEqualToValue={(option: any, value: any) => option === value}
          option={prefecture}
          name="prefecture"
          label="都道府県"
        />
      </Grid>

      <Grid item xs={12} sm={6} md={3}>
        <RHFTextField size="small" name="city" label="市区町村" placeholder="例：新宿区西新宿" />
      </Grid>

      <Grid item xs={12} sm={6} md={5}>
        <RHFTextField size="small" name="street" label="それ以降の住所" placeholder="例：1-23-7 〇〇ビル 5F" />
      </Grid>
    </>
  );
}
