import { Navigate, useRoutes } from 'react-router-dom';
import useStaffInformation from 'src/hooks/use-staff-information';
import { hasPermission } from 'src/utils/accessControl';
import { LogoOnlyLayout } from 'src/layouts/LogoOnlyLayout';
import { DashboardLayout } from 'src/layouts/dashboard';
import CustomerList from 'src/pages/customer';
import CustomerEdit from 'src/pages/customer/Edit';
import CustomerDetail from 'src/pages/customer/Detail';
import { ReservationEditPage } from 'src/pages/reservation/Edit';
import CompanyList from 'src/pages/company';
import CompanyDetail from 'src/pages/company/Detail';
import CompanyCreate from 'src/pages/company/Create';
import CompanyEdit from 'src/pages/company/Edit';
import { StaffListPage } from 'src/pages/staff';
import { StaffDetailPage } from 'src/pages/staff/Detail';
import { StaffEditPage } from 'src/pages/staff/Edit';
import { StaffCreatePage } from 'src/pages/staff/Create';
import { AccountListPage } from 'src/pages/account';
import { ReservationForAdcaPage } from 'src/pages/reservation-for-adca';
import { ReservationForAdcaDetailPage } from 'src/pages/reservation-for-adca/Detail';
import RsLogin from 'src/pages/login/marriageAgencyStaff';
import TwoFactorAuth from 'src/pages/login/TwoFactorAuth';
import Login from 'src/pages/login/admin';
import NotFound from 'src/pages/exception/Page404';
import NotPermission from 'src/pages/exception/NotPermission';
import { ReservationSchedulePage } from 'src/pages/schedule';
import { PhotoStudioDetailPage } from 'src/pages/photo-studio/Detail';
import { PhotoStudioCreatePage } from 'src/pages/photo-studio/Create';
import { PhotoStudioEditPage } from 'src/pages/photo-studio/Edit';
import { PhotoStudioListPage } from 'src/pages/photo-studio';
import { BusinessHourCreatePage } from 'src/pages/photo-studio/businessHour/Create';
import { BusinessHourEditPage } from 'src/pages/photo-studio/businessHour/Edit';
import { IntroductionCreatePage } from 'src/pages/photo-studio/introduction/Create';
import { IntroductionEditPage } from 'src/pages/photo-studio/introduction/Edit';
import { InformationEditPage } from 'src/pages/photo-studio/information/Edit';
import { InformationCreatePage } from 'src/pages/photo-studio/information/Create';
import { RegularHolidayCreatePage } from 'src/pages/photo-studio/regularHoliday/Create';
import { RegularHolidayEditPage } from 'src/pages/photo-studio/regularHoliday/Edit';
import { PhotoStudioPlanListPage } from 'src/pages/photo-studio/plan';
import { PhotoStudioPlanCreatePage } from 'src/pages/photo-studio/plan/Create';
import { PhotoStudioPlanDetailPage } from 'src/pages/photo-studio/plan/Detail';
import { PhotoStudioPlanEditPage } from 'src/pages/photo-studio/plan/Edit';
import { PhotoStudioOptionListPage } from 'src/pages/photo-studio/option';
import { PhotoStudioOptionCreatePage } from 'src/pages/photo-studio/option/Create';
import { PhotoStudioOptionEditPage } from 'src/pages/photo-studio/option/Edit';
import { ReservationListPage } from 'src/pages/reservation';
import { ReservationCreatePage } from 'src/pages/reservation/Create';
import { ReservationDetailPage } from 'src/pages/reservation/Detail';
import { PhotoStudioAvailableOptionEditPage } from 'src/pages/photo-studio/plan/available-option';
import { PhotoStudioFrameListPage } from 'src/pages/reservation-frame';
import { PhotoStudioFrameDetailPage } from 'src/pages/reservation-frame/Detail';
import { PhotoStudioFrameEditPage } from 'src/pages/reservation-frame/Edit';
import { PhotoStudioFrameCreatePage } from 'src/pages/reservation-frame/Create';
import { PhotoStudioAvailablePlanEditPage } from 'src/pages/photo-studio/option/available-plan';
import { IrregularSettingFrameCreatePage } from 'src/pages/reservation-frame/irregularSetting/Create';
import { IrregularSettingFrameEditPage } from 'src/pages/reservation-frame/irregularSetting/Edit';
import { CustomerReservationIndex } from 'src/pages/customer-reservation/studio-date-select';
import { BookmarkListPage } from 'src/pages/reservation-for-adca/Bookmark';
import { ReservationForAdcaCreatePage } from 'src/pages/reservation-for-adca/Create';
import { AccountCreatePage } from 'src/pages/account/Create';
import { AccountEditPage } from 'src/pages/account/Edit';
import { AccountDetailPage } from 'src/pages/account/Detail';
import { AutoMailSettingListPage } from 'src/pages/auto-mail-setting';
import { AutoMailSettingEditPage } from 'src/pages/auto-mail-setting/Edit';
import { AutoMailSettingCreatePage } from 'src/pages/auto-mail-setting/Create';
import { ReservationFormSettingListPage } from 'src/pages/reservation-form-setting';
import { ReservationFormSettingCreatePage } from 'src/pages/reservation-form-setting/Create';
import { ReservationFormSettingEditPage } from 'src/pages/reservation-form-setting/Edit';
import { TeamEditPage } from 'src/pages/team/Edit';
import { CustomerNotesPage } from './pages/customer-reservation/notes';
import ExternalSitePage from './pages/customer-reservation/top';
import { CustomerReservationInputPage } from './pages/customer-reservation/input';
import { CustomerReservationCheckPage } from './pages/customer-reservation/check';
import { CustomerReservationDonePage } from './pages/customer-reservation/done';

// ----------------------------------------------------------------------

export default function Router() {
  const staff = useStaffInformation();

  const photoStudioChildren = [
    { path: '', element: <PhotoStudioListPage /> },
    { path: 'new', element: <PhotoStudioCreatePage /> },
    { path: ':id', element: <PhotoStudioDetailPage /> },
    { path: ':id/edit', element: <PhotoStudioEditPage /> },
    { path: ':id/information/new', element: <InformationCreatePage /> },
    {
      path: ':id/information/:informationId/edit',
      element: <InformationEditPage />,
    },
    { path: ':id/introduction/new', element: <IntroductionCreatePage /> },
    {
      path: ':id/introduction/:introductionId/edit',
      element: <IntroductionEditPage />,
    },
    { path: ':id/businessHour/new', element: <BusinessHourCreatePage /> },
    {
      path: ':id/businessHour/:businessHourId/edit',
      element: <BusinessHourEditPage />,
    },
    { path: ':id/regularHoliday/new', element: <RegularHolidayCreatePage /> },
    {
      path: ':id/regularHoliday/:regularHolidayId/edit',
      element: <RegularHolidayEditPage />,
    },
  ];

  const customerChildren = [
    { path: '', element: <CustomerList /> },
    { path: ':id', element: <CustomerDetail /> },
    { path: ':id/edit', element: <CustomerEdit /> },
  ];

  const reservationChildren = [
    { path: '', element: <ReservationListPage /> },
    { path: 'new', element: <ReservationCreatePage /> },
    { path: ':id', element: <ReservationDetailPage /> },
    { path: ':id/edit', element: <ReservationEditPage /> },
  ];

  const reservationFormSettingChildren = [
    { path: '', element: <ReservationFormSettingListPage /> },
    { path: ':id/new', element: <ReservationFormSettingCreatePage /> },
    { path: ':id/:partsId/edit', element: <ReservationFormSettingEditPage /> },
  ];

  const companyChildren = [
    { path: '', element: <CompanyList /> },
    { path: 'new', element: <CompanyCreate /> },
    { path: ':id', element: <CompanyDetail /> },
    { path: ':id/edit', element: <CompanyEdit /> },
  ];

  const staffChildren = [
    { path: '', element: hasPermission(staff, 'staff', 'index') ? <StaffListPage /> : <NotPermission /> },
    { path: 'new', element: hasPermission(staff, 'staff', 'create') ? <StaffCreatePage /> : <NotPermission /> },
    {
      path: ':id',
      element: hasPermission(staff, 'staff', 'detail') ? <StaffDetailPage /> : <NotPermission />,
    },
    {
      path: ':id/edit',
      element: hasPermission(staff, 'staff', 'edit') ? <StaffEditPage /> : <NotPermission />,
    },
  ];

  const accountChildren = [
    { path: '', element: <AccountListPage /> },
    { path: ':id', element: <AccountDetailPage /> },
    { path: 'new', element: <AccountCreatePage /> },
    { path: ':id/edit', element: <AccountEditPage /> },
  ];

  const autoMailSetting = [
    { path: '', element: <AutoMailSettingListPage /> },
    { path: 'new', element: <AutoMailSettingCreatePage /> },
    { path: ':id/edit', element: <AutoMailSettingEditPage /> },
  ];

  const reservationForAdcaChildren = [
    { path: '', element: <ReservationForAdcaPage /> },
    { path: ':id', element: <ReservationForAdcaDetailPage /> },
    { path: ':id/:planId/new', element: <ReservationForAdcaCreatePage /> },
  ];

  // ----------------------------------------------------------------------

  const rootRoutes = [
    {
      path: '',
      element: <LogoOnlyLayout />,
      children: [
        { path: 'login', element: <Login /> },
        { path: '404', element: <NotFound /> },
        { path: '', element: <Navigate to="setting/photoStudio" /> },
        { path: '*', element: <Navigate to="404" /> },
      ],
    },
  ];

  const marriageAgencyStaffRoutes = [
    {
      path: 'list',
      element: <DashboardLayout />,
      children: reservationForAdcaChildren,
    },
    {
      path: 'bookmark',
      element: <DashboardLayout />,
      children: [{ path: '', element: <BookmarkListPage /> }],
    },
    {
      path: 'reserve',
      element: <DashboardLayout />,
      children: [{ path: ':id/:planId/new', element: <ReservationForAdcaCreatePage /> }],
    },
    {
      path: 'reservation',
      element: <DashboardLayout />,
      children: reservationChildren,
    },
    {
      path: 'staff',
      element: <DashboardLayout />,
      children: staffChildren,
    },
    {
      path: 'team',
      element: <DashboardLayout />,
      children: [{ path: 'edit', element: <TeamEditPage /> }],
    },
    {
      path: '',
      element: <LogoOnlyLayout />,
      children: [
        { path: 'login', element: <RsLogin /> },
        { path: 'login/twoFactorAuth', element: <TwoFactorAuth /> },
        { path: '404', element: <NotFound /> },
        { path: '', element: <Navigate to="reserves" /> },
        { path: '*', element: <Navigate to="404" /> },
      ],
    },
  ];

  const photoStudioStaffRoutes = [
    {
      path: '',
      element: <LogoOnlyLayout />,
      children: [
        { path: 'login', element: <RsLogin /> },
        { path: 'login/twoFactorAuth', element: <TwoFactorAuth /> },
        { path: '404', element: <NotFound /> },
        { path: '', element: <Navigate to="schedule" /> },
        { path: '*', element: <Navigate to="404" /> },
      ],
    },
    {
      path: 'schedule',
      element: <DashboardLayout />,
      children: [{ path: '', element: <ReservationSchedulePage /> }],
    },
    {
      path: 'reservation',
      element: <DashboardLayout />,
      children: reservationChildren,
    },

    {
      path: 'customer',
      element: <DashboardLayout />,
      children: customerChildren,
    },
    {
      path: 'company',
      element: <DashboardLayout />,
      children: companyChildren,
    },
    {
      path: 'account',
      element: <DashboardLayout />,
      children: accountChildren,
    },
    hasPermission(staff, 'setting', 'allRoute') && {
      path: 'setting',
      element: <DashboardLayout />,
      children: [
        {
          path: 'plan',
          children: [
            { path: '', element: <PhotoStudioPlanListPage /> },
            { path: 'new', element: <PhotoStudioPlanCreatePage /> },
            { path: ':id', element: <PhotoStudioPlanDetailPage /> },
            { path: ':id/edit', element: <PhotoStudioPlanEditPage /> },
            { path: ':id/available/option', element: <PhotoStudioAvailableOptionEditPage /> },
          ],
        },
        {
          path: 'option',
          children: [
            { path: '', element: <PhotoStudioOptionListPage /> },
            { path: 'new', element: <PhotoStudioOptionCreatePage /> },
            { path: ':id/edit', element: <PhotoStudioOptionEditPage /> },
          ],
        },
        {
          path: 'frame',
          children: [
            { path: '', element: <PhotoStudioFrameListPage /> },
            { path: 'new', element: <PhotoStudioFrameCreatePage /> },
            { path: ':id', element: <PhotoStudioFrameDetailPage /> },
            { path: ':id/edit', element: <PhotoStudioFrameEditPage /> },
            { path: ':id/available/plan', element: <PhotoStudioAvailablePlanEditPage /> },
            { path: ':id/irregular/:photoStudioId/new', element: <IrregularSettingFrameCreatePage /> },
            { path: ':id/irregular/:irregularSettingId/edit', element: <IrregularSettingFrameEditPage /> },
          ],
        },
        {
          path: 'photoStudio',
          children: photoStudioChildren,
        },
        {
          path: 'account',
          children: accountChildren,
        },
        {
          path: 'autoMailSetting',
          children: autoMailSetting,
        },
        {
          path: 'reservationFormSetting',
          children: reservationFormSettingChildren,
        },
      ],
    },
  ];

  const adminStaffRoutes = [
    {
      path: 'reservation',
      element: <DashboardLayout />,
      children: reservationChildren,
    },
    {
      path: 'restaurant',
      element: <DashboardLayout />,
      children: photoStudioChildren,
    },
    {
      path: 'customer',
      element: <DashboardLayout />,
      children: customerChildren,
    },
    hasPermission(staff, 'company', 'allRoute') && {
      path: 'company',
      element: <DashboardLayout />,
      children: companyChildren,
    },
    hasPermission(staff, 'staff', 'allRoute') && {
      path: 'staff',
      element: <DashboardLayout />,
      children: staffChildren,
    },
    {
      path: 'account',
      element: <DashboardLayout />,
      children: accountChildren,
    },
    {
      path: 'schedule',
      element: <DashboardLayout />,
      children: [{ path: '', element: <ReservationSchedulePage /> }],
    },
  ];

  let routes;
  if (staff.userType === 'adminStaff') {
    routes = [
      {
        path: 'adminStaff',
        children: [...adminStaffRoutes, ...rootRoutes],
      },
    ];
  } else if (staff.userType === 'marriageAgencyStaff') {
    routes = [
      {
        path: 'marriageAgencyStaff',
        children: [...marriageAgencyStaffRoutes, ...rootRoutes],
      },
    ];
  } else if (staff.userType === 'photoStudioStaff') {
    routes = [
      {
        path: 'photoStudioStaff',
        children: [...photoStudioStaffRoutes, ...rootRoutes],
      },
    ];
  } else if (staff.userType === 'photoStudioCustomer') {
    routes = [
      {
        path: 'photoStudioCustomer',
        children: [
          {
            path: 'reservation',
            element: <DashboardLayout />,
            children: [
              { path: '', element: <CustomerReservationIndex /> },
              { path: 'input', element: <CustomerReservationInputPage /> },
              { path: 'check', element: <CustomerReservationCheckPage /> },
              { path: 'done', element: <CustomerReservationDonePage /> },
              { path: 'notes', element: <CustomerNotesPage /> },
            ],
          },
          {
            path: 'notes',
            element: <DashboardLayout />,
            children: [{ path: '', element: <CustomerNotesPage /> }],
          },
          {
            path: 'customerTop',
            element: <DashboardLayout />,
            children: [{ path: '', element: <ExternalSitePage /> }],
          },
        ],
      },
    ];
  } else {
    routes = [
      {
        path: '',
        element: <LogoOnlyLayout />,
        children: [
          { path: '404', element: <NotFound /> },
          { path: '*', element: <Navigate to="404" /> },
        ],
      },
    ];
  }
  return useRoutes(routes);
}
