import React from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import { FormControlLabel, Radio, RadioGroup, InputLabel, Typography, FormHelperText } from '@mui/material';

export default function RHFRadioButtonGroup({ name, label, options, onChange, optionTextSx, ...other }: any) {
  const { control } = useFormContext();
  const disabledStyle = {
    pointerEvents: 'none',
    opacity: 0.5,
  };
  return (
    <Controller
      control={control}
      name={name}
      render={({ field, fieldState: { error } }) => (
        <>
          <InputLabel shrink>
            <Typography variant="h6">{label}</Typography>
          </InputLabel>

          <RadioGroup
            {...field}
            row
            {...(onChange && { onChange })}
            {...other}
            sx={other.disabled ? disabledStyle : {}}
          >
            {options.map((option: any, idx: number) => (
              <FormControlLabel
                value={option.value}
                control={<Radio />}
                label={
                  <Typography variant="subtitle2" color="text.secondary" {...optionTextSx}>
                    {option.label}
                  </Typography>
                }
                key={idx}
              />
            ))}
          </RadioGroup>
          {error && <FormHelperText error>{error.message}</FormHelperText>}
        </>
      )}
    />
  );
}
