import { Staff } from 'src/App';

interface Acl {
  reservationForAdca: {
    allRoute: (staff: Staff) => boolean;
    navMenuView: (staff: Staff) => boolean;
  };
  photoStudio: {
    allRoute: (staff: Staff) => boolean;
    navMenuView: (staff: Staff) => boolean;
    create: (staff: Staff) => boolean;
    edit: (staff: Staff) => boolean;
    delete: (staff: Staff) => boolean;
    companyFilter: (staff: Staff) => boolean;
  };
  bookmark: {
    allRoute: (staff: Staff) => boolean;
    navMenuView: (staff: Staff) => boolean;
  };
  schedule: {
    allRoute: (staff: Staff) => boolean;
    navMenuView: (staff: Staff) => boolean;
  };
  reservationForCustomer: { allRoute: (staff: Staff) => boolean };
  notes: { allRoute: (staff: Staff) => boolean; navMenuView: (staff: Staff) => boolean };
  customerTop: { allRoute: (staff: Staff) => boolean; navMenuView: (staff: Staff) => boolean };
  reservation: {
    allRoute: (staff: Staff) => boolean;
    navMenuView: (staff: Staff) => boolean;
    index: (staff: Staff) => boolean;
    create: (staff: Staff) => boolean;
  };
  listToolBar: {
    company: (staff: Staff) => boolean;
  };
  customer: {
    navMenuView: (staff: Staff) => boolean;
    index: (staff: Staff) => boolean;
    create: (staff: Staff) => boolean;
    edit: (staff: Staff) => boolean;
    detail: (staff: Staff) => boolean;
  };
  company: {
    allRoute: (staff: Staff) => boolean;
    navMenuView: (staff: Staff) => boolean;
    index: (staff: Staff) => boolean;
    create: (staff: Staff) => boolean;
    edit: (staff: Staff) => boolean;
    detail: (staff: Staff) => boolean;
  };
  account: {
    allRoute: (staff: Staff) => boolean;
    navMenuView: (staff: Staff) => boolean;
    index: (staff: Staff) => boolean;
    create: (staff: Staff) => boolean;
    edit: (staff: Staff) => boolean;
    detail: (staff: Staff) => boolean;
  };
  setting: {
    allRoute: (staff: Staff) => boolean;
    navMenuView: (staff: Staff) => boolean;
    index: (staff: Staff) => boolean;
    create: (staff: Staff) => boolean;
    edit: (staff: Staff) => boolean;
    detail: (staff: Staff) => boolean;
  };
  staff: {
    allRoute: (staff: Staff) => boolean;
    navMenuView: (staff: Staff) => boolean;
    index: (staff: Staff) => boolean;
    create: (staff: Staff) => boolean;
    edit: (staff: Staff) => boolean;
    detail: (staff: Staff) => boolean;
  };
  apiPath: { admin: (staff: Staff) => string };
  color: { pro: (staff: Staff) => {}; dev: (staff: Staff) => {} };
  calendar: { color: (staff: Staff) => string };
}
const acl: Acl = {
  photoStudio: {
    allRoute: (staff: Staff) => {
      if (staff.userType === 'adminStaff') return true;
      if (staff.userType === 'photoStudioStaff') return true;
      if (staff.userType === 'marriageAgencyStaff') return false;
      return false;
    },
    navMenuView: (staff: Staff) => {
      if (staff.userType === 'adminStaff') return true;
      if (staff.userType === 'photoStudioStaff') return true;
      if (staff.userType === 'marriageAgencyStaff') return false;
      return false;
    },
    create: (staff: Staff) => {
      if (staff.userType === 'adminStaff') return true;
      if (staff.userType === 'photoStudioStaff') return true;
      if (staff.userType === 'marriageAgencyStaff') return false;
      return false;
    },
    edit: (staff: Staff) => {
      if (staff.userType === 'adminStaff') return true;
      if (staff.userType === 'photoStudioStaff') return true;
      if (staff.userType === 'marriageAgencyStaff') return false;
      return false;
    },
    delete: (staff: Staff) => {
      if (staff.userType === 'adminStaff') return true;
      if (staff.userType === 'photoStudioStaff') return true;
      if (staff.userType === 'marriageAgencyStaff') return false;
      return false;
    },
    companyFilter: (staff: Staff) => {
      if (staff.userType === 'adminStaff') return true;
      if (staff.userType === 'photoStudioStaff') return true;
      if (staff.userType === 'marriageAgencyStaff') return false;
      return false;
    },
  },
  reservationForAdca: {
    allRoute: (staff: Staff) => {
      if (staff.userType === 'adminStaff') return false;
      if (staff.userType === 'photoStudioStaff') return false;
      if (staff.userType === 'marriageAgencyStaff') return true;
      return false;
    },
    navMenuView: (staff: Staff) => {
      if (staff.userType === 'adminStaff') return false;
      if (staff.userType === 'photoStudioStaff') return false;
      if (staff.userType === 'marriageAgencyStaff') return true;
      return false;
    },
  },
  bookmark: {
    allRoute: (staff: Staff) => {
      if (staff.userType === 'adminStaff') return false;
      if (staff.userType === 'photoStudioStaff') return false;
      if (staff.userType === 'marriageAgencyStaff') return true;
      return false;
    },
    navMenuView: (staff: Staff) => {
      if (staff.userType === 'adminStaff') return false;
      if (staff.userType === 'photoStudioStaff') return false;
      if (staff.userType === 'marriageAgencyStaff') return true;
      return false;
    },
  },
  schedule: {
    allRoute: (staff: Staff) => {
      if (staff.userType === 'adminStaff') return true;
      if (staff.userType === 'photoStudioStaff') return true;
      if (staff.userType === 'marriageAgencyStaff') return false;
      return false;
    },
    navMenuView: (staff: Staff) => {
      if (staff.userType === 'adminStaff') return true;
      if (staff.userType === 'photoStudioStaff') return true;
      if (staff.userType === 'marriageAgencyStaff') return false;
      return false;
    },
  },
  reservationForCustomer: {
    allRoute: (staff: Staff) => {
      if (staff.userType === 'photoStudioCustomer') return true;

      return false;
    },
  },
  notes: {
    allRoute: (staff: Staff) => {
      if (staff.userType === 'photoStudioCustomer') return true;
      return false;
    },
    navMenuView: (staff: Staff) => {
      if (staff.userType === 'photoStudioCustomer') return true;
      return false;
    },
  },
  customerTop: {
    allRoute: (staff: Staff) => {
      if (staff.userType === 'photoStudioCustomer') return true;
      return false;
    },
    navMenuView: (staff: Staff) => {
      if (staff.userType === 'photoStudioCustomer') return true;
      return false;
    },
  },
  reservation: {
    allRoute: (staff: Staff) => {
      if (staff.userType === 'adminStaff') return true;
      if (staff.userType === 'photoStudioStaff') return true;
      if (staff.userType === 'marriageAgencyStaff') return false;
      return false;
    },
    navMenuView: (staff: Staff) => {
      if (staff.userType === 'adminStaff') return true;
      if (staff.userType === 'photoStudioStaff') return true;
      if (staff.userType === 'marriageAgencyStaff') return false;
      return false;
    },
    index: (staff: Staff) => {
      if (staff.userType === 'adminStaff') return true;
      if (staff.userType === 'photoStudioStaff') return true;
      if (staff.userType === 'marriageAgencyStaff') return false;
      return false;
    },
    create: (staff: Staff) => {
      if (staff.userType === 'adminStaff') return true;
      if (staff.userType === 'photoStudioStaff') return true;
      if (staff.userType === 'marriageAgencyStaff') return false;
      return false;
    },
  },
  setting: {
    allRoute: (staff: Staff) => {
      if (staff.userType === 'adminStaff') return true;
      if (staff.userType === 'photoStudioStaff') return true;
      if (staff.userType === 'marriageAgencyStaff') return false;
      return false;
    },
    navMenuView: (staff: Staff) => {
      if (staff.userType === 'adminStaff') return true;
      if (staff.userType === 'photoStudioStaff') return true;
      if (staff.userType === 'marriageAgencyStaff') return false;
      return false;
    },
    index: (staff: Staff) => {
      if (staff.loggedRole === 'admin') return true;
      if (staff.loggedRole === 'normal') return false;
      return false;
    },
    create: (staff: Staff) => {
      if (staff.loggedRole === 'admin') return true;
      if (staff.loggedRole === 'normal') return false;
      return false;
    },
    edit: (staff: Staff) => {
      if (staff.loggedRole === 'admin') return true;
      if (staff.loggedRole === 'normal') return false;
      return false;
    },
    detail: (staff: Staff) => {
      if (staff.loggedRole === 'admin') return true;
      if (staff.loggedRole === 'normal') return false;
      return false;
    },
  },
  listToolBar: {
    company: (staff: Staff) => {
      if (staff.userType === 'adminStaff') return true;
      if (staff.userType === 'marriageAgencyStaff') return false;
      return false;
    },
  },
  customer: {
    navMenuView: (staff: Staff) => {
      if (staff.userType === 'adminStaff') return true;
      if (staff.userType === 'marriageAgencyStaff') return false;
      return false;
    },
    index: (staff: Staff) => {
      if (staff.userType === 'adminStaff') return true;
      if (staff.loggedRole === 'normal') return false;
      return false;
    },
    create: (staff: Staff) => {
      if (staff.userType === 'adminStaff') return true;
      if (staff.loggedRole === 'normal') return false;
      return false;
    },
    edit: (staff: Staff) => {
      if (staff.userType === 'adminStaff') return true;
      if (staff.loggedRole === 'normal') return false;
      return false;
    },
    detail: (staff: Staff) => {
      if (staff.userType === 'adminStaff') return true;
      if (staff.loggedRole === 'normal') return false;
      return false;
    },
  },
  company: {
    allRoute: (staff: Staff) => {
      if (staff.userType === 'adminStaff') return true;
      if (staff.userType === 'marriageAgencyStaff') return false;
      return false;
    },
    navMenuView: (staff: Staff) => {
      if (staff.userType === 'adminStaff') return true;
      if (staff.userType === 'marriageAgencyStaff') return false;
      return false;
    },
    index: (staff: Staff) => {
      if (staff.userType === 'adminStaff') return true;
      if (staff.userType === 'marriageAgencyStaff') return false;
      return false;
    },
    create: (staff: Staff) => {
      if (staff.userType === 'adminStaff') return true;
      if (staff.userType === 'marriageAgencyStaff') return false;
      return false;
    },
    edit: (staff: Staff) => {
      if (staff.userType === 'adminStaff') return true;
      if (staff.userType === 'marriageAgencyStaff') return false;
      return false;
    },
    detail: (staff: Staff) => {
      if (staff.userType === 'adminStaff') return true;
      if (staff.userType === 'marriageAgencyStaff') return false;
      return false;
    },
  },
  staff: {
    allRoute: (staff: Staff) => {
      if (staff.userType === 'adminStaff') return false;
      if (staff.userType === 'photoStudioStaff') return false;
      if (staff.userType === 'marriageAgencyStaff') return true;
      return false;
    },
    navMenuView: (staff: Staff) => {
      if (staff.userType === 'adminStaff') return false;
      if (staff.userType === 'photoStudioStaff') return false;
      if (staff.userType === 'marriageAgencyStaff') return true;
      return false;
    },
    index: (staff: Staff) => {
      if (staff.userType === 'adminStaff') return false;
      if (staff.userType === 'photoStudioStaff') return false;
      if (staff.userType === 'marriageAgencyStaff') return true;
      return false;
    },
    create: (staff: Staff) => {
      if (staff.userType === 'adminStaff') return false;
      if (staff.userType === 'photoStudioStaff') return false;
      if (staff.userType === 'marriageAgencyStaff') return true;
      return false;
    },
    edit: (staff: Staff) => {
      if (staff.userType === 'adminStaff') return false;
      if (staff.userType === 'photoStudioStaff') return false;
      if (staff.userType === 'marriageAgencyStaff') return true;
      return false;
    },
    detail: (staff: Staff) => {
      if (staff.userType === 'adminStaff') return false;
      if (staff.userType === 'photoStudioStaff') return false;
      if (staff.userType === 'marriageAgencyStaff') return true;
      return false;
    },
  },
  account: {
    navMenuView: (staff: Staff) => {
      if (staff.userType === 'adminStaff') return true;
      if (staff.userType === 'photoStudioStaff') return true;
      if (staff.userType === 'marriageAgencyStaff') return false;
      return false;
    },
    allRoute: (staff: Staff) => {
      if (staff.userType === 'adminStaff') return true;
      if (staff.userType === 'marriageAgencyStaff') return false;
      return false;
    },
    index: (staff: Staff) => {
      if (staff.loggedRole === 'admin') return true;
      if (staff.loggedRole === 'normal') return false;
      return false;
    },
    create: (staff: Staff) => {
      if (staff.loggedRole === 'admin') return true;
      if (staff.loggedRole === 'normal') return false;
      return false;
    },
    edit: (staff: Staff) => {
      if (staff.loggedRole === 'admin') return true;
      if (staff.loggedRole === 'normal') return false;
      return false;
    },
    detail: (staff: Staff) => {
      if (staff.loggedRole === 'admin') return true;
      if (staff.loggedRole === 'normal') return false;
      return false;
    },
  },
  apiPath: {
    admin: (staff: Staff) => {
      if (staff.userType === 'adminStaff') return 'adminStaff';
      if (staff.userType === 'marriageAgencyStaff') return 'marriageAgencyStaff';
      if (staff.userType === 'photoStudioStaff') return 'photoStudioStaff';
      if (staff.userType === 'photoStudioCustomer') return 'photoStudioCustomer';
      return 'false';
    },
  },
  color: {
    pro: (staff: Staff) => {
      if (staff.userType === 'adminStaff')
        return {
          lighter: '#93dddf',
          light: '#6ed2d5',
          main: '#4BC7CB',
          dark: '#3c9fa2',
          darker: '#2d7779',
          contrastText: '#fff',
        };
      if (staff.userType === 'marriageAgencyStaff')
        return {
          lighter: '#ffd69a',
          light: '#ffd69a',
          main: '#ffc774',
          dark: '#c67d11',
          darker: '#c67d11',
          contrastText: '#fff',
        };
      if (staff.userType === 'photoStudioStaff')
        return {
          lighter: '#e7e1de',
          light: '#d6cdc7',
          main: '#c5b9b0',
          dark: '#b5a59a',
          darker: '##93746c',
          contrastText: '#fff',
        };
      if (staff.userType === 'photoStudioCustomer')
        return {
          lighter: '#e7e1de',
          light: '#d6cdc7',
          main: '#c5b9b0',
          dark: '#b5a59a',
          darker: '##93746c',
          contrastText: '#fff',
        };
      return 'false';
    },
    dev: (staff: Staff) => {
      if (staff.userType === 'adminStaff')
        return {
          lighter: '#bfd5e1',
          light: '#8fb5ca',
          main: '#6096B4',
          dark: '#43697d',
          darker: '#263c48',
          contrastText: '#fff',
        };
      if (staff.userType === 'marriageAgencyStaff')
        return {
          lighter: '#bfd5e1',
          light: '#8fb5ca',
          main: '#6096B4',
          dark: '#43697d',
          darker: '#263c48',
          contrastText: '#fff',
        };
      if (staff.userType === 'photoStudioStaff')
        return {
          lighter: '#ded6d2',
          light: '#cec0bc',
          main: '#bdaba5',
          dark: '#ad968e',
          darker: '#9c8178',
          contrastText: '#fff',
        };
      if (staff.userType === 'photoStudioCustomer')
        return {
          lighter: '#e7e1de',
          light: '#d6cdc7',
          main: '#c5b9b0',
          dark: '#b5a59a',
          darker: '##93746c',
          contrastText: '#fff',
        };
      return 'false';
    },
  },
  calendar: {
    color: (staff: Staff) => {
      if (staff.userType === 'adminStaff') return '#008080';
      if (staff.userType === 'marriageAgencyStaff') return '#cd853f';
      return 'false';
    },
  },
};

export const hasPermission = <T extends keyof Acl, U extends keyof Acl[T]>(
  staff: Staff,
  subject: T,
  verb: U // verbの型を定義
) => {
  const func = <Function>acl[subject][verb];
  return func(staff);
};
