import { useState } from 'react';
// form
// @mui
import { Button, Box } from '@mui/material';
import { IoLogOut } from 'react-icons/io5';
// components

import { DeleteConfirmDialog } from 'src/components/molecules/dialog/DeleteConfirmDialog';

import { MyDialog } from 'src/components/molecules/dialog/MyDialog';
// utils

import log from 'src/utils/log';
// ----------------------------------------------------------------------

export default function LogoutModal({ OnDelete, ...props }: any) {
  // 削除ダイヤログ
  const [myDialogConfig] = useState<any>();
  const [deleteConfirmDialogConfig, setDeleteConfirmDialogConfig] = useState<any>();

  const handleSpDeleteClick = async () => {
    const ret = await new Promise((resolve) => {
      setDeleteConfirmDialogConfig({ onClose: resolve });
    });
    setDeleteConfirmDialogConfig(undefined);
    if (ret === 'ok') {
      OnDelete();
    }
    if (ret === 'cancel') {
      log('削除する:Cancel時の処理を実行する');
    }
  };
  return (
    <>
      <Button
        style={{ color: '#637381', fontWeight: 400, fontSize: '0.875rem' }}
        {...props}
        startIcon={<IoLogOut size={23} />}
        onClick={handleSpDeleteClick}
      >
        {/* <Box style={{ marginLeft: '7px' }}> ログアウト</Box> */}
      </Button>

      {myDialogConfig && <MyDialog {...myDialogConfig} />}
      {deleteConfirmDialogConfig && (
        <DeleteConfirmDialog title={'ログアウトしますか？'} message={''} okText={'OK'} {...deleteConfirmDialogConfig} />
      )}
    </>
  );
}
