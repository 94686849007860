import { useParams } from 'react-router-dom';
// components
import CompanyEditTemplate from 'src/components/templates/company/company-edit-template';
import Page from 'src/components/molecules/page';
import usePush from 'src/hooks/use-push';
import { companyValueOrganize as valueOrganize } from 'src/functions/push-value-organize';
// hooks
import useFetch from 'src/hooks/use-fetch';
import { CompanyForm } from 'src/types/company-form';
import { Company } from 'codegen/axios/photo/admin/api';

export default function Edit() {
  /* hook宣言 */
  const { companyId } = useParams();
  const push = usePush();

  /* 定数宣言 */
  const title = '会社編集';
  const initValue: CompanyForm = {
    companyName: '',
    companyNameKana: '',
    zip: '',
    prefecture: '',
    city: '',
    street: '',
    email: '',
    telno: '',
  };

  /* データ取得 */
  const { fetchedData: responseData } = useFetch<Company & CompanyForm>({
    iniState: initValue,
    reqProp: {
      pathKey: 'company',
      pathParam: companyId,
    },
  });

  const OnSubmit = (formValue: CompanyForm) =>
    new Promise((resolve) => {
      push(resolve, {
        reqProp: { pathKey: 'company', method: 'put', pathParam: companyId, data: valueOrganize(formValue) },
        aftProp: {
          resolve,
          nextPath: `/companies/${companyId}`,
          showMessage: {
            state: { snackBar: { open: true, viewType: 'success', message: '登録しました。' } },
          },
        },
      });
    });

  return (
    <Page title={title}>
      <CompanyEditTemplate
        {...(() => ({
          OnSubmit,
          title,
          responseData,
        }))()}
      />
    </Page>
  );
}
