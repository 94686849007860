import { UseQueryOptions, useQuery, useQueryClient } from '@tanstack/react-query';
import axios, { AxiosError } from 'axios';
import { createApiUrl } from 'src/functions/api/call';
import { ApiEndpoints } from 'src/utils/api';
import useStaffInformation from '../use-staff-information';

type Method = 'get' | 'post' | 'put' | 'delete';

interface FetcherOptions {
  method: Method;
  url: string;
  data?: any;
}

interface useCustomQueryProps {
  method?: Method;
  pathKey: keyof ApiEndpoints;
  pathParam?: string;
  queryParams?: any;
  observable?: any[];
  data?: any;
}
async function fetcher<T>({ method = 'get', url, data }: FetcherOptions): Promise<T> {
  switch (method) {
    case 'get':
      return (await axios.get<T>(url, { withCredentials: true })).data;
    case 'post':
      return (await axios.post<T>(url, data, { withCredentials: true })).data;
    case 'put':
      return (await axios.put<T>(url, data, { withCredentials: true })).data;
    case 'delete':
      return (await axios.delete<T>(url, { withCredentials: true })).data;
    default:
      throw new Error('Invalid method');
  }
}

export default function useCustomQuery<T>({
  method = 'get',
  pathKey,
  pathParam,
  queryParams,
  data,
  onSuccess: userOnSuccess,
  onError: userOnError,
  observable,
  ...queryOptions
}: useCustomQueryProps & UseQueryOptions<T, AxiosError>) {
  const staff = useStaffInformation();
  const url = createApiUrl({
    staff,
    pathKey,
    pathParam,
    queryParams,
  });

  const queryClient = useQueryClient();

  const onSuccess = (data: T) => {
    if (userOnSuccess) userOnSuccess(data);
  };

  const onError = (error: AxiosError) => {
    if (userOnError) userOnError(error);
    console.log(error);
  };

  // これ使うとリトライ時にキャンセルされてしまう
  const onFetch = () => {
    queryClient.cancelQueries([method, pathKey], { exact: false });
  };

  const query = useQuery<T, AxiosError>(
    [method, pathKey, pathParam, queryParams, data],
    () => fetcher<T>({ method, url, data }),
    {
      onSuccess,
      onError,
      ...queryOptions,
    }
  );

  return query;
}
