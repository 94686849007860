import { FC } from 'react';
import { Grid } from '@mui/material';
import { MarriageAgencyStaffPhotoStudio } from 'codegen/axios/photo/adca';
import { PhotoStudioCard } from 'src/components/molecules/card';

export type PhotoStudioCardType = Required<
  Omit<
    MarriageAgencyStaffPhotoStudio,
    'telno' | 'zip' | 'street' | 'building' | 'marriageAgencyStaffBookmarkPhotoStudioId'
  >
>;

type Props = {
  photoStudios: Array<PhotoStudioCardType>;
  onClickDetailPage: (id: string) => void;
  onClickBookmark: (event: React.MouseEvent<HTMLButtonElement>, photoStudioId: string, bookmarkFlg?: boolean) => void;
};

export const CardList: FC<Props> = ({ photoStudios, onClickDetailPage, onClickBookmark }) => (
  <Grid container spacing={2}>
    {photoStudios &&
      photoStudios.map((photoStudio: PhotoStudioCardType) => (
        <Grid key={photoStudio.photoStudioId} item xs={12} sm={6} md={4}>
          <PhotoStudioCard
            id={photoStudio.photoStudioId}
            name={photoStudio.name}
            prefecture={photoStudio.prefecture}
            city={photoStudio.city}
            access={photoStudio.access}
            caption={photoStudio.caption}
            bookmarkFlg={photoStudio.bookmarkFlg}
            imagesTop={photoStudio.imagesTop}
            onClickDetailPage={onClickDetailPage}
            onClickBookmark={onClickBookmark}
          />
        </Grid>
      ))}
  </Grid>
);
