import { useNavigate, useParams } from 'react-router-dom';
import usePush from 'src/hooks/use-push';
import { Card, CardHeader, Grid, Stack, Typography } from '@mui/material';
import { reservationFrameValidationSchema } from 'src/functions/validation-schema';
import { useForm } from 'react-hook-form';
import { FC, useEffect, useState } from 'react';
import {
  FormProvider,
  RHFDatePicker,
  RHFRadioButtonGroup,
  RHFSelectBox,
  RHFTextField,
  RHFTextInputSelectBox,
  RHFTimePicker,
} from 'src/components/atoms/hook-form';
import { LoadingButton } from '@mui/lab';
import { yupResolver } from '@hookform/resolvers/yup';
import useFetch from 'src/hooks/use-fetch';
import { PhotoStudioReservationFrame } from 'codegen/axios/photo/photo_studio';
import { frameForFormFetchOrganize, photoStudioOptionFetchOrganize } from 'src/functions/fetch-value-organize';
import { dayOfWeekOptions, viewOption, numberOption, reservationSlotMinutesOption } from 'src/utils/option';
import { reservationFrameValueOrganize, searchNameValueOrganize } from 'src/functions/push-value-organize';
import { OptionType } from 'src/types/common';
import { RHFColorPicker } from 'src/components/atoms/hook-form/RHFColorPicker';
import useStaffInformation from 'src/hooks/use-staff-information';

export type ReservationFrameFormType = Omit<
  PhotoStudioReservationFrame,
  'photoStudioReservationFrameId' | 'availablePlans' | 'photoStudioId' | 'photoStudioName'
> & { photoStudio: OptionType };

export const defaultValues: ReservationFrameFormType = {
  name: '',
  color: '#607d8b',
  validStartDate: '',
  validEndDate: '',
  unitTimeMin: 0,
  priorityOrderNumber: 10,
  reservationStartTime: '',
  reservationEndTime: '',
  sundayAvailableFlg: false,
  mondayAvailableFlg: false,
  tuesdayAvailableFlg: false,
  wednesdayAvailableFlg: false,
  thursdayAvailableFlg: false,
  fridayAvailableFlg: false,
  saturdayAvailableFlg: false,
  pageViewFlg: true,
  photoStudio: { id: '', label: '' },
};

export const FrameForm: FC = () => {
  /* hook宣言 */
  const staff = useStaffInformation();
  const navigate = useNavigate();
  const push = usePush();
  const { id: photoStudioReservationFrameId } = useParams();
  const [timer, setTimer] = useState<NodeJS.Timeout>();
  const [textFieldPhotoStudioOption, setTextFieldPhotoStudioOption] = useState('');

  /* 定数宣言 */
  const editMode = !photoStudioReservationFrameId;
  const validationSchema = reservationFrameValidationSchema();

  /* データ取得 */
  const { fetchedData: responseData } = useFetch<ReservationFrameFormType>({
    iniState: defaultValues,
    reqProp: {
      pathKey: 'frame',
      pathParam: photoStudioReservationFrameId,
    },
    disable: editMode,
    organize: frameForFormFetchOrganize,
  });

  const { fetchedData: photoStudioOptions } = useFetch<Array<OptionType>>({
    iniState: [{ id: '', label: '' }],
    reqProp: {
      pathKey: 'studio',
      queryParams: {
        offset: 0,
        limit: 100,
        ...(() => textFieldPhotoStudioOption && { query: searchNameValueOrganize(textFieldPhotoStudioOption) })(),
      },
    },
    organize: photoStudioOptionFetchOrganize,
    observable: [textFieldPhotoStudioOption],
  });

  const methods = useForm<ReservationFrameFormType>({
    resolver: yupResolver(validationSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  useEffect(() => {
    methods.reset(responseData);
  }, [responseData, methods]);

  const textFieldOnChangePhotoStudioOption = (value: any) => {
    clearTimeout(timer);
    const newTimer = setTimeout(() => {
      setTextFieldPhotoStudioOption(value);
    }, 400);
    setTimer(newTimer);
  };

  const onSubmit = (formValue: ReservationFrameFormType) =>
    new Promise((resolve) => {
      push(resolve, {
        reqProp: {
          pathKey: 'frame',
          pathParam: photoStudioReservationFrameId,
          method: editMode ? 'post' : 'put',
          data: reservationFrameValueOrganize(formValue),
        },
        aftProp: {
          resolve,
          nextPath: ``,
          showMessage: {
            state: {
              snackBar: { open: true, viewType: 'success', message: editMode ? '登録しました' : '保存しました' },
            },
          },
        },
      }).then((response) => {
        navigate(`/${staff.userType}/setting/frame/${(response as any).photoStudioReservationFrameId}`);
      });
    });

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Card>
        <CardHeader title="基本情報" />

        {!editMode && (
          <Typography variant={'subtitle2'} color="text.secondary" align="left" sx={{ pl: 2, pt: 1 }}>
            *予約が入っている予約枠は[曜日, 時間, 最小単位時間, 店舗名]が変更できません
          </Typography>
        )}
        <Stack my={7} mx={4}>
          <Grid container rowSpacing={3} columnSpacing={3}>
            <Grid item xs={12} sm={6} md={6}>
              <RHFTextField name="name" label="予約枠名" placeholder="例：【梅田店】店舗管理No.1" />
            </Grid>

            <Grid item xs={12} sm={6} md={6}>
              <RHFTextInputSelectBox
                filterOptions={(x: any) => x}
                option={photoStudioOptions}
                isOptionEqualToValue={(option: any, value: any) => option.id === value.id}
                getOptionLabel={(option: any) => option.label || ''}
                name="photoStudio"
                label="店舗名"
                placeholder="入力して検索"
                textFieldOnChange={textFieldOnChangePhotoStudioOption}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              <RHFDatePicker name="validStartDate" label="有効期間（開始）" />
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              <RHFDatePicker name="validEndDate" label="有効期間（終了）" />
            </Grid>

            {dayOfWeekOptions.map((dayOfWeek) => (
              <Grid key={dayOfWeek.name} item pt={4} xs={12} sm={3} md={12}>
                <RHFRadioButtonGroup
                  label={dayOfWeek.label}
                  name={dayOfWeek.name}
                  options={[
                    {
                      value: true,
                      label: '有効',
                    },
                    {
                      value: false,
                      label: '無効',
                    },
                  ]}
                />
              </Grid>
            ))}

            <Grid item pt={4} xs={12} sm={6} md={3}>
              <RHFTimePicker
                name="reservationStartTime"
                label="有効時間(開始)"
                custom={{ minutesStep: 15, inputFormat: 'HH:mm' }}
              />
            </Grid>

            <Grid item pt={4} xs={12} sm={6} md={3}>
              <RHFTimePicker
                name="reservationEndTime"
                label="有効時間(終了)"
                custom={{ minutesStep: 15, inputFormat: 'HH:mm' }}
              />
            </Grid>

            <Grid item pt={4} xs={6} sm={3} md={3}>
              <RHFSelectBox label="最小単位時間" name="unitTimeMin" option={reservationSlotMinutesOption} />
            </Grid>
            <Grid item pt={4} xs={6} sm={3} md={3}>
              <RHFSelectBox label="予約優先順(小さい順から埋まる)" name="priorityOrderNumber" option={numberOption} />
            </Grid>
            <Grid item pt={4} xs={12} sm={3} md={12}>
              <RHFRadioButtonGroup label="お客様への表示" name="pageViewFlg" options={viewOption} />
            </Grid>
            <Grid item xs={12} sm={6} md={12}>
              <RHFColorPicker name="color" label="枠の色" />
            </Grid>
          </Grid>
        </Stack>
      </Card>

      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }} />

      <Stack direction="row" alignItems="center" justifyContent="flex-end" sx={{ m: 1 }} spacing={8}>
        <LoadingButton sx={{ width: 200 }} size="large" type="submit" variant="contained" loading={isSubmitting}>
          {editMode ? '登録' : '保存'}
        </LoadingButton>
      </Stack>
    </FormProvider>
  );
};
