import { Button, Grid } from '@mui/material';
import { FC, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { createParams } from 'src/functions/params';

export const regionOptions = [
  {
    name: '全て',
    path: '',
    isClicked: false,
  },
  {
    name: '北海道・東北',
    path: '北海道&東北',
    isClicked: false,
  },
  {
    name: '関東',
    path: '関東',
    isClicked: false,
  },
  {
    name: '北陸・信越',
    path: '北陸&信越',
    isClicked: false,
  },
  {
    name: '東海',
    path: '東海',
    isClicked: false,
  },
  {
    name: '関西',
    path: '関西',
    isClicked: false,
  },
  {
    name: '中国・四国',
    path: '中国&四国',
    isClicked: false,
  },
  {
    name: '九州・沖縄',
    path: '九州&沖縄',
    isClicked: false,
  },
];

export const RegionSelect: FC = () => {
  /* hooks */
  const [searchParams, setSearchParams] = useSearchParams();
  const regionFilterValue = searchParams.get('regionFilterValue') ?? '';
  const [regions, setRegion] = useState(regionOptions);

  const handleClick = (name: string, path: string) => {
    const updatedRegions = regions.map((region) => {
      if (region.name === name) {
        return {
          ...region,
          isClicked: true,
        };
      }
      return {
        ...region,
        isClicked: false,
      };
    });
    setRegion(updatedRegions);
    setSearchParams(createParams({ keyValues: { regionFilterValue: path }, searchParams, page: 1 }));
  };

  return (
    <Grid container direction="row" justifyContent="center" alignItems="center" spacing={2}>
      {regions &&
        regions.map((region) => (
          <Grid key={region.name} item xs={6} sm={3} md={1.5}>
            <Button
              variant={region.path !== regionFilterValue ? 'contained' : 'outlined'}
              size="large"
              style={{ width: 140 }}
              onClick={() => handleClick(region.name, region.path)}
            >
              {region.name}
            </Button>
          </Grid>
        ))}
    </Grid>
  );
};
