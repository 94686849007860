import Page from 'src/components/molecules/page';
import { FC } from 'react';
import { PlanDetail } from 'src/components/organisms/plan/detail';

export const PhotoStudioPlanDetailPage: FC = () => {
  /* 定数宣言 */
  const title = 'プラン詳細';

  return (
    <Page title={title}>
      <PlanDetail />
    </Page>
  );
};
