import { FC, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Stack, Grid, Card, CardHeader, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { FormProvider, RHFTextArea, RHFTimePicker } from 'src/components/atoms/hook-form';
import { photoStudioBusinessHourValidationSchema } from 'src/functions/validation-schema';
import usePush from 'src/hooks/use-push';
import { useParams } from 'react-router-dom';
import useFetch from 'src/hooks/use-fetch';
import { businessHourFormFetchOrganize } from 'src/functions/fetch-value-organize';
import { PhotoStudioBusinessHour } from 'codegen/axios/photo/photo_studio';
import { businessHourValueOrganize } from 'src/functions/push-value-organize';

export type BusinessHourFormType = Omit<PhotoStudioBusinessHour, 'photoStudioBusinessHourId'>;

export const defaultValues: BusinessHourFormType = {
  photoStudioId: '',
  salesStartTime: '',
  salesEndTime: '',
  mondaySalesStartTime: '',
  mondaySalesEndTime: '',
  tuesdaySalesStartTime: '',
  tuesdaySalesEndTime: '',
  wednesdaySalesStartTime: '',
  wednesdaySalesEndTime: '',
  thursdaySalesStartTime: '',
  thursdaySalesEndTime: '',
  fridaySalesStartTime: '',
  fridaySalesEndTime: '',
  saturdaySalesStartTime: '',
  saturdaySalesEndTime: '',
  sundaySalesStartTime: '',
  sundaySalesEndTime: '',
  remark: '',
};

export const BusinessHourForm: FC = () => {
  /* hook宣言 */
  const push = usePush();
  const { id: photoStudioId } = useParams();
  const { businessHourId: photoStudioBusinessHourId } = useParams();

  /* 定数宣言 */
  const editMode = !photoStudioBusinessHourId;
  const validationSchema = photoStudioBusinessHourValidationSchema();

  /* データ取得 */
  const { fetchedData: responseData } = useFetch<PhotoStudioBusinessHour>({
    iniState: defaultValues,
    reqProp: {
      pathKey: 'businessHour',
      pathParam: photoStudioBusinessHourId,
    },
    disable: editMode,
    organize: businessHourFormFetchOrganize,
  });

  const methods = useForm<BusinessHourFormType>({
    resolver: yupResolver(validationSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  useEffect(() => {
    methods.reset(responseData);
  }, [responseData, methods]);

  const onSubmit = (formValue: BusinessHourFormType) =>
    photoStudioId &&
    new Promise((resolve) => {
      push(resolve, {
        reqProp: {
          pathKey: 'businessHour',
          pathParam: photoStudioBusinessHourId,
          method: editMode ? 'post' : 'put',
          data: businessHourValueOrganize({ ...formValue, photoStudioId }),
        },
        aftProp: {
          resolve,
          nextPath: `/setting/photoStudio/${photoStudioId}`,
          showMessage: {
            state: {
              snackBar: { open: true, viewType: 'success', message: editMode ? '登録しました' : '保存しました' },
            },
          },
        },
      });
    });

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Card>
        <CardHeader title="営業時間" />

        <Grid container direction="row" justifyContent="center" alignItems="center">
          <Grid item pt={4} xs={3} sm={1} md={1}>
            <Typography variant="h6">通常</Typography>
          </Grid>

          <Grid item pt={4} xs={3} sm={3} md={3}>
            <RHFTimePicker
              name="salesStartTime"
              label="営業開始時間"
              custom={{ minutesStep: 15, inputFormat: 'HH:mm' }}
            />
          </Grid>

          <Grid item pt={4} xs={3} sm={3} md={3}>
            <RHFTimePicker
              name="salesEndTime"
              label="営業終了時間"
              custom={{ minutesStep: 15, inputFormat: 'HH:mm' }}
            />
          </Grid>
        </Grid>

        <Grid container direction="row" justifyContent="center" alignItems="center">
          <Grid item pt={4} xs={3} sm={1} md={1}>
            <Typography variant="h6">月曜日</Typography>
          </Grid>

          <Grid item pt={4} xs={3} sm={3} md={3}>
            <RHFTimePicker
              name="mondaySalesStartTime"
              label="営業開始時間"
              custom={{ minutesStep: 15, inputFormat: 'HH:mm' }}
            />
          </Grid>

          <Grid item pt={4} xs={3} sm={3} md={3}>
            <RHFTimePicker
              name="mondaySalesEndTime"
              label="営業終了時間"
              custom={{ minutesStep: 15, inputFormat: 'HH:mm' }}
            />
          </Grid>
        </Grid>

        <Grid container direction="row" justifyContent="center" alignItems="center">
          <Grid item pt={4} xs={3} sm={1} md={1}>
            <Typography variant="h6">火曜日</Typography>
          </Grid>

          <Grid item pt={4} xs={3} sm={3} md={3}>
            <RHFTimePicker
              name="tuesdaySalesStartTime"
              label="営業開始時間"
              custom={{ minutesStep: 15, inputFormat: 'HH:mm' }}
            />
          </Grid>

          <Grid item pt={4} xs={3} sm={3} md={3}>
            <RHFTimePicker
              name="tuesdaySalesEndTime"
              label="営業終了時間"
              custom={{ minutesStep: 15, inputFormat: 'HH:mm' }}
            />
          </Grid>
        </Grid>

        <Grid container direction="row" justifyContent="center" alignItems="center">
          <Grid item pt={4} xs={3} sm={1} md={1}>
            <Typography variant="h6">水曜日</Typography>
          </Grid>

          <Grid item pt={4} xs={3} sm={3} md={3}>
            <RHFTimePicker
              name="wednesdaySalesStartTime"
              label="営業開始時間"
              custom={{ minutesStep: 15, inputFormat: 'HH:mm' }}
            />
          </Grid>

          <Grid item pt={4} xs={3} sm={3} md={3}>
            <RHFTimePicker
              name="wednesdaySalesEndTime"
              label="営業終了時間"
              custom={{ minutesStep: 15, inputFormat: 'HH:mm' }}
            />
          </Grid>
        </Grid>

        <Grid container direction="row" justifyContent="center" alignItems="center">
          <Grid item pt={4} xs={3} sm={1} md={1}>
            <Typography variant="h6">木曜日</Typography>
          </Grid>

          <Grid item pt={4} xs={3} sm={3} md={3}>
            <RHFTimePicker
              name="thursdaySalesStartTime"
              label="営業開始時間"
              custom={{ minutesStep: 15, inputFormat: 'HH:mm' }}
            />
          </Grid>

          <Grid item pt={4} xs={3} sm={3} md={3}>
            <RHFTimePicker
              name="thursdaySalesEndTime"
              label="営業終了時間"
              custom={{ minutesStep: 15, inputFormat: 'HH:mm' }}
            />
          </Grid>
        </Grid>

        <Grid container direction="row" justifyContent="center" alignItems="center">
          <Grid item pt={4} xs={3} sm={1} md={1}>
            <Typography variant="h6">金曜日</Typography>
          </Grid>

          <Grid item pt={4} xs={3} sm={3} md={3}>
            <RHFTimePicker
              name="fridaySalesStartTime"
              label="営業開始時間"
              custom={{ minutesStep: 15, inputFormat: 'HH:mm' }}
            />
          </Grid>

          <Grid item pt={4} xs={3} sm={3} md={3}>
            <RHFTimePicker
              name="fridaySalesEndTime"
              label="営業終了時間"
              custom={{ minutesStep: 15, inputFormat: 'HH:mm' }}
            />
          </Grid>
        </Grid>

        <Grid container direction="row" justifyContent="center" alignItems="center">
          <Grid item pt={4} xs={3} sm={1} md={1}>
            <Typography variant="h6">土曜日</Typography>
          </Grid>

          <Grid item pt={4} xs={3} sm={3} md={3}>
            <RHFTimePicker
              name="saturdaySalesStartTime"
              label="営業開始時間"
              custom={{ minutesStep: 15, inputFormat: 'HH:mm' }}
            />
          </Grid>

          <Grid item pt={4} xs={3} sm={3} md={3}>
            <RHFTimePicker
              name="saturdaySalesEndTime"
              label="営業終了時間"
              custom={{ minutesStep: 15, inputFormat: 'HH:mm' }}
            />
          </Grid>
        </Grid>
        <Grid container direction="row" justifyContent="center" alignItems="center">
          <Grid item pt={4} xs={3} sm={1} md={1}>
            <Typography variant="h6">日曜日</Typography>
          </Grid>

          <Grid item pt={4} xs={3} sm={3} md={3}>
            <RHFTimePicker
              name="sundaySalesStartTime"
              label="営業開始時間"
              custom={{ minutesStep: 15, inputFormat: 'HH:mm' }}
            />
          </Grid>

          <Grid item pt={4} xs={3} sm={3} md={3}>
            <RHFTimePicker
              name="sundaySalesEndTime"
              label="営業終了時間"
              custom={{ minutesStep: 15, inputFormat: 'HH:mm' }}
            />
          </Grid>
        </Grid>
        <Grid container direction="row" justifyContent="center" alignItems="center">
          <Grid item py={4} xs={12} sm={6} md={7}>
            <RHFTextArea name="remark" label="営業時間に関する備考" placeholder="例：ラストオーダー22:00" />
          </Grid>
        </Grid>
      </Card>

      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }} />

      <Stack direction="row" alignItems="center" justifyContent="flex-end" sx={{ m: 1 }} spacing={8}>
        <LoadingButton sx={{ width: 200 }} size="large" type="submit" variant="contained" loading={isSubmitting}>
          {editMode ? '登録' : '保存'}
        </LoadingButton>
      </Stack>
    </FormProvider>
  );
};
