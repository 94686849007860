import { useState, useRef, useEffect } from 'react';
import FullCalendar, { EventClickArg, DatesSetArg } from '@fullcalendar/react';
import resourceTimeGridPlugin from '@fullcalendar/resource-timegrid';
import { useParams, useSearchParams } from 'react-router-dom';
import scrollGridPlugin from '@fullcalendar/scrollgrid';
import { resourceFetchOrganize, scheduleForCalendarFetchOrganize } from 'src/functions/fetch-value-organize';
import useFetch from 'src/hooks/use-fetch';
import { Card, LinearProgress } from '@mui/material';
import { PhotoStudioReservationFrame, Reserve, ReserveRequestBody, Rule } from 'codegen/axios/photo/photo_studio';
import { toApiDateTimeFormat } from 'src/functions/date-time-organize';
import {
  emptyResourceDelete,
  mergeEvents,
  renderEventContent,
} from 'src/components/organisms/schedule/reservation-schedule';
import { CalendarHeader } from 'src/components/organisms/schedule/reservation-schedule/header';
import { FRAME_INDEX_PARAMS } from "src/utils/requestParams";
import { calcNextDay } from 'src/functions/date';

export const SelectModalReservationSchedule = ({ methods, onClose }: any) => {
  /* searchParams */
  const [searchParams] = useSearchParams();
  const [fetchTriggerTimestamp, setFetchTriggerTimestamp] = useState<number | null>(null);
  // <ScheduleCalendar/> が動いていない場合に<DynamicFilterBar/>や<CalendarHeader/>から'予約枠取得してください'の通知を直接もらう用
  const [frameFetchTriggerTimestamp, setFrameFetchTriggerTimestamp] = useState<number | null>(null);
  const viewType = searchParams.get('viewType') ?? 'resourceTimeGridFourDay';
  const formValues = methods.getValues();
  const date: Date = new Date(searchParams.get('date') || formValues.reservationStartDateTime);
  const { id: rsvId } = useParams();
  /* hook宣言 */
  const [event, setEvent] = useState([]);
  const [rule, setRule] = useState([]);
  const initialStartDate = new Date((new Date(date)).toDateString());
  const [startDate, setStartDate] = useState<Date>(initialStartDate);

  const [checkedPsIds, setCheckedPsIds] = useState([formValues.photoStudioId]);
  const [endDate, setEndDate] = useState<Date>(calcNextDay(initialStartDate));
  const [selectSlotDuration, setSelectSlotDuration] = useState<{ value: string; label: string }>({
    label: '5分',
    value: '0:05',
  });
  const endDateObj = new Date(endDate || '');
  const oneSecondBeforeEndDate = new Date(endDateObj.setSeconds(endDateObj.getSeconds() - 1)); // 1を引く

  const bodyData: ReserveRequestBody = {
    reserve: {
      photoStudioId: [],
      photoStudioPlanId: [],
      photoStudioFrameId: [],
    },
    formInput: {},
  };

  const { loading: emptyOnlyLoad, fetchedData: eventEmptyOnly } = useFetch<Array<Reserve>>({
    reqProp: {
      method: 'post',
      pathKey: 'schedule',
      queryParams: {
        startDateTime: startDate && toApiDateTimeFormat(startDate),
        endDateTime: oneSecondBeforeEndDate && toApiDateTimeFormat(oneSecondBeforeEndDate),
      },
      data: {
        photoStudioPlanId: formValues.plan.id,
        photoStudioOptionIds: formValues.option,
        ...(rsvId ? { excludePhotoStudioReserveId: rsvId } : {}),
      },
    },
    disable: startDate === undefined || endDate === undefined,
    observable: [startDate?.toString(), endDate?.toString(), fetchTriggerTimestamp],
  });
  const { loading } = useFetch<Array<Reserve>>({
    reqProp: {
      method: 'post',
      pathKey: 'reservationSearch',
      queryParams: {
        startDatetime: startDate && toApiDateTimeFormat(startDate),
        endDatetime: oneSecondBeforeEndDate && toApiDateTimeFormat(oneSecondBeforeEndDate),
        offset: 0,
        limit: 2000,
      },
      data: bodyData,
    },
    setStateFunc: setEvent,
    disable: startDate === undefined || endDate === undefined,
    observable: [startDate?.toString(), endDate?.toString(), fetchTriggerTimestamp],
  });

  useEffect(() => {
    const date = new Date(searchParams.get('date') || formValues.reservationStartDateTime);
    const start = new Date((new Date(date)).toDateString());
    const end = calcNextDay(start);
    setStartDate(start);
    setEndDate(end);
  }, [frameFetchTriggerTimestamp]);

  /* データ取得 */
  const { fetchedData: resources, loading: frameLoad } = useFetch<Array<PhotoStudioReservationFrame>>({
    reqProp: {
      pathKey: 'frame',
      queryParams: {
        offset: FRAME_INDEX_PARAMS.offset,
        limit: FRAME_INDEX_PARAMS.limit,
        ...(startDate && { startDatetime: toApiDateTimeFormat(startDate) }),
        ...(endDate && { endDatetime: toApiDateTimeFormat(oneSecondBeforeEndDate) }),
      },
    },
    observable: [startDate?.toString(), endDate?.toString()],
  });
  useFetch<Array<Rule>>({
    reqProp: {
      pathKey: 'rule',
      queryParams: { offset: 0, limit: 500 },
    },
    setStateFunc: setRule,
    disable: rule.length > 0,
  });

  // 予約イベント取得用の日付セット
  const datesSet = (arg: DatesSetArg) => {
    setStartDate(arg.start);
    setEndDate(arg.end);
  };

  // クリックで予約フォームに値をセットして閉じる
  const eventClick = (info: EventClickArg) => {
    if (info.event.extendedProps.photoStudioReserveId !== '') return;
    const [frameObj] = resources.filter(
      (obj: PhotoStudioReservationFrame) =>
        obj.photoStudioReservationFrameId === info.event.extendedProps.photoStudioFrameId
    );
    methods.setValue('photoStudioName', frameObj.photoStudioName);
    methods.setValue('photoStudioId', frameObj.photoStudioId);
    methods.setValue('photoStudioFrameName', frameObj.name);
    methods.setValue('photoStudioFrameId', frameObj.photoStudioReservationFrameId);
    methods.setValue('reservationStartDateTime', info.event.start);
    methods.setValue('needTimeChange', false);
    methods.trigger('needTimeChange');
    onClose();
  };

  const calendarRef = useRef<FullCalendar>(null!);
  const cResource = resourceFetchOrganize(resources, null, formValues.plan.id, checkedPsIds); // リソースをサーチ条件によって絞り込む、
  const cEvent =
    startDate && endDate && rule.length > 0 && resources.length > 0
      ? mergeEvents(eventEmptyOnly, scheduleForCalendarFetchOrganize(event, rule), resources)
      : [];

  return (
    <Card sx={{ pt: 3 }}>
      <CalendarHeader
        filterUseProperty={false}
        calendarRef={calendarRef}
        selectSlotDuration={selectSlotDuration}
        setSelectSlotDuration={setSelectSlotDuration}
        refresh={{
          loading: loading || emptyOnlyLoad || frameLoad,
          trigger: () => setFetchTriggerTimestamp(Date.now()),
          // <ScheduleCalendar/> が動いていない場合に<DynamicFilterBar/>や<CalendarHeader/>から'予約枠取得してください'の通知を直接もらう用
          frameTrigger: () => setFrameFetchTriggerTimestamp(Date.now()),
        }}
        studioSelectState={{ checkedPsIds, setCheckedPsIds }}
        initialDate={date}
      />
      {(loading || emptyOnlyLoad || frameLoad) && <LinearProgress />}
      {resources.length > 0 &&
        (() => {
          console.log('2id');
          return (
            <FullCalendar
              eventTextColor="#18191a"
              scrollTimeReset={false}
              contentHeight={window.innerHeight * 0.75}
              datesSet={datesSet}
              ref={calendarRef}
              schedulerLicenseKey="0439324989-fcs-1673827335"
              slotDuration={selectSlotDuration.value}
              views={{
                resourceTimeGridFourDay: {
                  startParam: 'Monday',
                  type: 'resourceTimeGrid',
                  duration: { days: 1 },
                  buttonText: '1 days',
                },
              }}
              slotLabelFormat={{
                hour: 'numeric',
                minute: '2-digit',
                omitZeroMinute: false,
                meridiem: 'short',
              }}
              {...(() => {
                const { reservationStartTime } = resources.reduce((a, b) =>
                  a.reservationStartTime < b.reservationStartTime ? a : b
                );
                const { reservationEndTime } = resources.reduce((a, b) =>
                  a.reservationEndTime > b.reservationEndTime ? a : b
                );

                // reservationStartTimeを":"で分割して時間部分だけを取得
                const startHours = reservationStartTime.split(':')[0];
                const endHours = reservationEndTime.split(':')[0];

                return {
                  slotMinTime: `${startHours}:00`, // 分を"00"にして時間を返す
                  slotMaxTime: `${Number(endHours) + 1}:00`, // 分を"00"にして時間を返す
                };
              })()}
              eventContent={renderEventContent}
              initialView={viewType}
              initialDate={new Date(date)}
              dayCellContent={(e) => e.dayNumberText.replace('日', '')}
              slotLabelInterval="00:30"
              eventClick={eventClick}
              nowIndicator
              plugins={[scrollGridPlugin, resourceTimeGridPlugin]}
              headerToolbar={false}
              resourceOrder="extendedProps.index"
              resources={emptyResourceDelete(cEvent, cResource)}
              events={cEvent}
              locale="ja"
              datesAboveResources
              dayMinWidth={100}
              slotEventOverlap={false}
              allDaySlot={false}
            />
          );
        })()}
    </Card>
  );
};
