import {
  PhotoFormUiPartsList,
  PhotoFormUiPartsListInputsInnerOptionsInner,
  PostReserve,
  PostReserveRequestBody,
  PostReserveRequestBodyFormInner,
  PostReserveRequestBodyFormInnerInputsInner,
  PostReserveRequestBodyFormInnerInputsInnerAvailablePlansInner,
  PostReserveRequestBodyFormInnerInputsInnerInputTypeEnum,
  ReserveDetailFormInner,
  ReserveDetailFormInnerInputsInnerOptionsInner,
} from 'codegen/axios/photo/photo_studio';
import { toApiDateTimeFormat } from 'src/functions/date-time-organize';
import { rsvInputDefaultValues } from '../../types/index.d';

const filterEmptyKeys = (obj: PostReserve): Partial<PostReserve> =>
  Object.fromEntries(Object.entries(obj).filter(([, value]) => value !== ''));

export const convertInputFormToPostReserveRequestBody = (
  inputFormData: rsvInputDefaultValues,
  fetchedData: PhotoFormUiPartsList[] | ReserveDetailFormInner[] | null,
  edit?: boolean
): PostReserveRequestBody => {
  const [customerFirstName, customerLastName] = inputFormData.customerName.trim().split(/[\s\u3000]+/);
  const [customerFirstNameKana, customerLastNameKana] = inputFormData.customerNameKana.trim().split(/[\s\u3000]+/);
  const form = convertGroups(inputFormData, fetchedData);

  const getSendAlertType = (inputFormData: rsvInputDefaultValues, edit: boolean) => {
    if (inputFormData.ステータス === 'キャンセル') return 'reserve_cancel';
    if (edit) return 'reserve_update';
    return 'reserve_confirm';
  };

  const reserve: PostReserve = {
    reservationStartDateTime: toApiDateTimeFormat(inputFormData.reservationStartDateTime),
    photoStudioId: inputFormData.photoStudioId,
    photoStudioPlanId: inputFormData.plan.id,
    photoStudioOptions: inputFormData.option.map((v: any) => ({ photoStudioOptionId: v })),
    photoStudioFrameId: inputFormData.photoStudioFrameId,
    customerEmail: inputFormData.customerEmail,
    customerTelno: inputFormData.customerTelno.replace(/-/g, ''),
    customerFirstName,
    customerFirstNameKana,
    customerLastName,
    customerLastNameKana,
    customerGender: inputFormData.customerGender,
    zip: inputFormData.zip,
    prefecture: inputFormData.prefecture,
    city: inputFormData.city,
    street: inputFormData.street,
    sendAlertFlg: inputFormData.sendAlertFlg === 'true',
    // メール送信タイプ
    ...(inputFormData.sendAlertFlg === 'true' ? { sendAlertType: getSendAlertType(inputFormData, !!edit) } : {}),
    ...(inputFormData.ステータス === 'キャンセル' ? { cancelFlg: true } : {}),
    remark: inputFormData.remark,
  };
  return {
    reserve: filterEmptyKeys(reserve) as PostReserve, // これできたらキャスト辞めたい
    form,
  };
};

function convertGroups(
  inputFormData: rsvInputDefaultValues,
  fetchedData: PhotoFormUiPartsList[] | ReserveDetailFormInner[] | null
): PostReserveRequestBodyFormInner[] | undefined {
  return fetchedData?.map((group, idx) => {
    const inputs = convertInputs(inputFormData, group);
    return {
      groupName: group.groupName,
      orderNumber: idx + 1,
      inputs,
    };
  });
}

function convertInputs(
  inputFormData: rsvInputDefaultValues,
  group: PhotoFormUiPartsList | ReserveDetailFormInner
): PostReserveRequestBodyFormInnerInputsInner[] {
  return group.inputs
    ? group.inputs.map((input, idx) => {
        let value: Array<string> = [];

        switch (input.inputType) {
          // select消した

          case 'checkbox':
            if (input.name) value = inputFormData[input.name] || [];
            break;

          default:
            if (input.name) {
              value = [inputFormData[input.name] || ''];
            }
            break;
        }

        const options: ReserveDetailFormInnerInputsInnerOptionsInner[] = input.options
          ? input.options.map((option: PhotoFormUiPartsListInputsInnerOptionsInner) => ({
              label: option.label || '',
            }))
          : [];

        const availablePlans: PostReserveRequestBodyFormInnerInputsInnerAvailablePlansInner[] = input.availablePlans
          ? input.availablePlans.map((v) => ({
              photoStudioPlanId: v.photoStudioPlanId || '',
            }))
          : [];

        const formInnerInputsInner: PostReserveRequestBodyFormInnerInputsInner = {
          inputType: input.inputType as PostReserveRequestBodyFormInnerInputsInnerInputTypeEnum,
          name: input.name || '',
          placeholder: input.placeholder ? input.placeholder : '',
          value,
          options,
          requiredFlg: input.requiredFlg || false,
          orderNumber: input.orderNumber || idx,
          customerViewFlg: input.customerViewFlg || false,
          availablePlans,
          availableOptionIds: input.availableOptionIds ?? [],
        };
        return formInnerInputsInner;
      })
    : [];
}
