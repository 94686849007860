export const labelDisplayedRows = (responseData: any, nextPageCheck: any, from: any, to: any) => {
  if (responseData.length === 0) return '';
  if (nextPageCheck.length !== 0) {
    return `${from} 〜 ${to}　　計 ${to} 件以上`;
  }
  return `${from} 〜 ${responseData.length + from - 1}
              　計 ${responseData.length + from - 1} 件`;
};

export const totalCountLabelDisplayedRows = (
  responseData: any,
  nextPageCheck: any,
  from: any,
  to: any,
  totalCount: any
) => {
  const morePageCount = `${from} 〜 ${to}　　計 ${to} 件以上`;
  const justPageCount = ` ${from} 〜 ${responseData.length + from - 1} 　計 ${responseData.length + from - 1} 件`;
  const totalReserves = `合計予約数　計 ${totalCount.total_reserves} 件`;
  const totalParticipants = `合計予約者数　計 ${totalCount.total_participants} 名`;
  if (responseData.length === 0) return '';
  if (nextPageCheck) {
    return `${totalReserves}　/　 ${totalParticipants} 　/　${morePageCount}`;
  }

  return `${totalReserves}　/　 ${totalParticipants}　/　${justPageCount}`;
};

export const count = (nextPageCheck: any, rowsPerPage: any, pageValue: any) => {
  if (nextPageCheck.length !== 0) return -1;
  return pageValue === 0 ? rowsPerPage : rowsPerPage + 1;
};
