import React from 'react';
import ja from 'date-fns/locale/ja';
// form
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import { TextField, IconButton } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

// ----------------------------------------------------------------------

export default function RHFDatePicker({ name, clearButton, ...other }: any) {
  const { control } = useFormContext();
  const [open, setOpen] = React.useState(false);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ja}>
      <Controller
        name={name}
        control={control}
        render={({ field, fieldState: { error } }) => (
          <DatePicker
            open={open}
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
            showToolbar
            inputFormat="yyyy / MM / dd  (E)"
            toolbarFormat="yyyy / MM / dd  (E)"
            disableMaskedInput
            {...field}
            value={field.value}
            onChange={(date) => {
              field.onChange(date);
            }}
            renderInput={(params) => (
              <TextField
                onClick={() => setOpen(true)}
                {...(() => {
                  if (!clearButton || !params.InputProps) return params;
                  params.InputProps.endAdornment = (
                    <IconButton
                      onClick={(e) => {
                        e.stopPropagation();
                        field.onChange(null);
                      }}
                      disabled={!field.value}
                      style={{ order: 1 }}
                    >
                      <ClearIcon color="disabled" fontSize="small" />
                    </IconButton>
                  );
                  return params;
                })()}
                label={name}
                error={!!error}
                helperText={error?.message}
                {...other}
              />
            )}
          />
        )}
      />
    </LocalizationProvider>
  );
}
