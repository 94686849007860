import { Box, Card, CardHeader, Container, Stack } from '@mui/material';
import { PhotoStudioStaffPhotoStudio } from 'codegen/axios/photo/photo_studio';
import { FC, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import EditButton from 'src/components/atoms/edit-button';
import HeadLineText from 'src/components/atoms/head-line-text';
import DeleteModal from 'src/components/molecules/delete-modal';
import { photoStudioDetailFetchOrganize } from 'src/functions/fetch-value-organize';
import useFetch from 'src/hooks/use-fetch';
import usePush from 'src/hooks/use-push';
import useStaffInformation from 'src/hooks/use-staff-information';
import DetailCard from 'src/components/molecules/detail-card';
import { defaultValues } from 'src/components/organisms/photo-studio/form';
import { PhotoStudioBusinessHourCard } from 'src/components/organisms/photo-studio/detail/business-hour';
import { PhotoStudioImageField } from 'src/components/organisms/photo-studio/detail/photo-studio-image';
import { PhotoStudioInformationCard } from 'src/components/organisms/photo-studio/detail/information';
import { PhotoStudioIntroductionCard } from 'src/components/organisms/photo-studio/detail/introduction';
import { PhotoStudioRegularHolidayCard } from 'src/components/organisms/photo-studio/detail/regular-holiday';

export const PhotoStudioDetail: FC = () => {
  /* 定数宣言 */
  const title = '店舗詳細';
  const staff = useStaffInformation();
  const { id: photoStudioId } = useParams();
  const navigate = useNavigate();
  const push = usePush();
  const [listUpdate, setListUpdate] = useState(0);

  /* データ取得 */
  const { fetchedData: responseData } = useFetch<PhotoStudioStaffPhotoStudio>({
    iniState: defaultValues,
    reqProp: {
      pathKey: 'studio',
      pathParam: photoStudioId,
    },
    organize: photoStudioDetailFetchOrganize,
  });

  const handleEditButtonOnClick = () => {
    navigate(`/${staff.userType}/setting/photoStudio/${photoStudioId}/edit`);
  };

  const onDelete = () => {
    new Promise((resolve) =>
      push(resolve, {
        reqProp: {
          pathKey: 'studio',
          method: 'delete',
          pathParam: photoStudioId,
        },
        aftProp: {
          resolve,
          nextPath: '/setting/photoStudio',
          showMessage: {
            state: { snackBar: { open: true, viewType: 'info', message: '削除しました' } },
          },
        },
      })
    ).then(() => {
      setListUpdate(listUpdate + 1);
    });
  };

  return (
    <Container>
      <Stack direction="row" alignItems="center" justifyContent="start" mb={2} ml={1} mt={'3%'} mr={0}>
        <HeadLineText text={title} />

        <Box sx={{ ml: 'auto' }}>
          <DeleteModal
            sx={{
              bgcolor: 'background.paper',
              minWidth: 50,
              minHeight: 47,
              boxShadow: 1,
              borderRadius: 2,
              ml: 2,
            }}
            OnDelete={onDelete}
          />

          <EditButton handleEditOnClick={handleEditButtonOnClick} />
        </Box>
      </Stack>
      <DetailCard defaultValues={responseData} />

      <Card sx={{ mt: 5 }}>
        <CardHeader title="店舗画像" sx={{ mb: 3, mt: 1 }} />

        <PhotoStudioImageField label="photoStudioTop" width={480} height={260} displayNumber={3} />
      </Card>
      <PhotoStudioInformationCard />
      <PhotoStudioIntroductionCard />
      <PhotoStudioBusinessHourCard />
      <PhotoStudioRegularHolidayCard />
    </Container>
  );
};
