import { useState } from 'react';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { Stack, IconButton, InputAdornment } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import Iconify from 'src/components/atoms/Iconify';
import { FormProvider, RHFTextField, RHFCheckbox } from 'src/components/atoms/hook-form';
// function
import { loginValidationSchema } from 'src/functions/validation-schema';

// ----------------------------------------------------------------------

export default function LoginForm({ LoginOnSubmit, defaultValues }: any) {
  const [showPassword, setShowPassword] = useState(false);

  const validationSchema = loginValidationSchema();

  const methods = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(LoginOnSubmit())}>
      <Stack spacing={3}>
        <RHFTextField name="email" label="メールアドレス" />

        <RHFTextField
          name="password"
          label="パスワード"
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Stack>

      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
        <RHFCheckbox name="remember" label="ログイン状態を保存する" />
      </Stack>

      <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting}>
        Login
      </LoadingButton>
    </FormProvider>
  );
}
