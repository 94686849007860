import Iconify from 'src/components/atoms/Iconify';
import { Link } from 'react-router-dom';
// @mui
import { Button } from '@mui/material';

export default function CreateButton({ path, text }: any) {
  return (
    <Button variant="contained" component={Link} to={path} startIcon={<Iconify icon="eva:plus-fill" />}>
      {text}
    </Button>
  );
}
