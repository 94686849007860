import { Fragment } from 'react';
// @mui
import { Stack, Grid, Card, Typography, Button, Box, Divider } from '@mui/material';
// components
import {
  FormProvider,
  RHFCheckboxGroup,
  RHFRadioButtonGroup,
  RHFSelectBox,
  RHFTextArea,
  RHFTextField,
  RHFTextInputSelectBox,
} from 'src/components/atoms/hook-form';
// hooks

// staff
import { LoadingButton } from '@mui/lab';
import { ReserveDetailFormInnerInputsInner } from 'codegen/axios/photo/photo_studio';
import AddressInputs from 'src/components/molecules/address-inputs';
import { toDateDefault } from 'src/functions/date-time-organize';

export const ReservationCreateEditFormInputs = ({
  setRsvSelectMdlData,
  onSubmit,
  formInputs,
  plans,
  methods,
  handleSubmit,
  rsvMin,
  options,
  selectPlanId,
  isSubmitting,
}: {
  setRsvSelectMdlData: (v: any) => void;
  onSubmit: (formValue: any) => void;
  formInputs: any;
  plans: any;
  methods: any;
  handleSubmit: any;
  rsvMin: any;
  options: any;
  selectPlanId: any;
  isSubmitting: any;
}) => {
  let customTitleInputDone = false;

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Card>
        <Stack my={7} mx={4}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12}>
              <Typography variant="h4" color="text.secondary" sx={{ pb: 1 }}>
                予約情報
              </Typography>
              <Divider sx={{ mb: 3, mt: 1 }} />
            </Grid>
            {rsvInfoFormInputs(methods.getValues(), rsvMin, plans, options, methods, setRsvSelectMdlData)}

            {basicInfoFormInputs(methods)}

            {formInputs.map((group: any, index: any) => (
              <Fragment key={index}>
                <Grid item xs={12} sm={12} md={12} sx={{ mt: 3 }}>
                  <Typography variant="h4" color="text.secondary" sx={{ mt: 1 }}>
                    {group.groupName}
                  </Typography>
                  <Divider sx={{ mb: 4, mt: 1 }} />
                </Grid>

                {group.inputs?.map((input: ReserveDetailFormInnerInputsInner, idx: number) => (
                  <Fragment key={idx}>
                    {(() => {
                      if (input.name.includes('【スタッフ入力】') && !customTitleInputDone) {
                        customTitleInputDone = true;
                        return (
                          <Grid item xs={12} sm={12} md={12}>
                            <Typography variant="h4" color="text.secondary" sx={{ pt: 5 }}>
                              {'顧客入力'}
                            </Typography>
                            <Divider sx={{ mb: 3, mt: 1 }} />
                          </Grid>
                        );
                      }
                      return undefined;
                    })()}
                    {renderFormControl(input, idx, selectPlanId, methods.getValues('option'))}
                  </Fragment>
                ))}
              </Fragment>
            ))}
          </Grid>
        </Stack>
      </Card>

      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }} />

      <Stack direction="row" alignItems="center" justifyContent="flex-end" sx={{ m: 1 }} spacing={3}>
        <Box sx={{ ml: 2 }}>
          <RHFRadioButtonGroup
            label="お客様へのメール送信"
            name="sendAlertFlg"
            options={[
              {
                value: true,
                label: 'する',
              },
              {
                value: false,
                label: 'しない',
              },
            ]}
          />
        </Box>
        <LoadingButton variant="contained" size="large" type="submit" sx={{ width: 200 }} loading={isSubmitting}>
          保存
        </LoadingButton>
      </Stack>
    </FormProvider>
  );
};

export function convertOptions(options: { label?: string | undefined }[]): { value: string; label: string }[] {
  return options.map((option) => ({
    value: option.label || '',
    label: option.label || '',
  }));
}

export const renderFormControl = (
  input: ReserveDetailFormInnerInputsInner,
  idx: number,
  selectPlanId: string,
  selectedOptionIds: string[]
) => {
  // input.plansの中のphotoStudioPlanIdがselectPlan.valueと一致しなければnullを返す
  if (!input.availablePlans!.some((obj: any) => obj.photoStudioPlanId === selectPlanId)) return null;

  // フォームのavailableOptionIdsが空だったら全部表示、あれば選択されたオプションがふくまれているかどうか
  if (
    input.availableOptionIds.length > 0 &&
    !input.availableOptionIds!.some((id: any) => selectedOptionIds.includes(id))
  )
    return null;
  switch (input.inputType) {
    case 'checkbox':
      return (
        <>
          <Grid item xs={12} sm={5} md={2} sx={{ mt: 1.5 }}>
            <Typography variant={'subtitle2'} color="text.secondary" align="left">
              {input.requiredFlg && '*'} {input.name}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={7} md={10}>
            <RHFCheckboxGroup
              name={input.name || ''}
              componentsProps={{
                typography: { variant: 'body2', color: 'text.secondary' },
              }}
              options={input.options ? convertOptions(input.options) : [{ value: '', label: '' }]}
            />
          </Grid>
        </>
      );
    case 'selectbox':
      return (
        <>
          <Grid item xs={12} sm={5} md={2} sx={{ mt: 1.5 }}>
            <Typography variant={'subtitle2'} color="text.secondary" align="left">
              {input.requiredFlg && '*'} {input.name}
            </Typography>
          </Grid>
          <Grid key={idx} item xs={12} sm={5} md={10}>
            <RHFSelectBox
              size="small"
              name={input.name}
              option={input.options ? input.options.map((option) => option.label) : []}
            />
          </Grid>
        </>
      );
    case 'radiobutton':
      return (
        <>
          <Grid item xs={12} sm={5} md={2} sx={{ mt: 1.5 }}>
            <Typography variant={'subtitle2'} color="text.secondary" align="left">
              {input.requiredFlg && '*'} {input.name}
            </Typography>
          </Grid>
          <Grid key={idx} item xs={12} sm={5} md={10}>
            <RHFRadioButtonGroup
              name={input.name}
              options={input.options!.map((option) => ({
                label: option.label,
                value: option.label,
              }))}
            />
          </Grid>
        </>
      );
    case 'textarea':
      return (
        <>
          <Grid item xs={12} sm={5} md={2} sx={{ mt: 1.5 }}>
            <Typography variant={'subtitle2'} color="text.secondary" align="left">
              {input.requiredFlg && '*'} {input.name}
            </Typography>
          </Grid>
          <Grid key={idx} item xs={12} sm={5} md={10}>
            <RHFTextArea size="small" name={input.name} placeholder={input.placeholder} multiline minRows={2} />
          </Grid>
        </>
      );
    case 'textbox':
      return (
        <>
          <Grid item xs={12} sm={5} md={2} sx={{ mt: 1.5 }}>
            <Typography variant={'subtitle2'} color="text.secondary" align="left">
              {input.requiredFlg && '*'} {input.name}
            </Typography>
          </Grid>
          <Grid key={idx} item xs={12} sm={5} md={10}>
            <RHFTextField size="small" name={input.name} placeholder={input.placeholder} />
          </Grid>
        </>
      );
    default:
      return null;
  }
};

export const basicInfoFormInputs = (methods: any) => (
  <>
    <Grid item xs={12} sm={12} md={12} sx={{ mt: 3 }}>
      <Typography variant="h4" color="text.secondary" sx={{ mt: 2 }}>
        顧客情報
      </Typography>
      <Divider sx={{ mb: 4, mt: 1 }} />
    </Grid>
    <Grid item xs={12} sm={8} md={6}>
      <RHFTextField name="customerName" size="small" label="氏名" placeholder="例：田中 太郎" />
    </Grid>
    <Grid item xs={12} sm={8} md={6}>
      <RHFTextField name="customerNameKana" size="small" label="氏名（ふりがな）" placeholder="例：たなか たろう" />
    </Grid>
    <Grid item xs={12} sm={6} md={6}>
      <RHFTextField
        name="customerEmail"
        size="small"
        label="メールアドレス（任意）"
        placeholder="例：exmple@photoapan.jp"
      />
    </Grid>
    <Grid item xs={12} sm={6} md={6}>
      <RHFTextField name="customerTelno" size="small" label="電話番号（任意）" placeholder="例：09012345678" />
    </Grid>
    <Grid item xs={12} sm={5} md={1} sx={{ mt: 1.5 }}>
      <Typography variant={'subtitle2'} color="text.secondary" align="left">
        性別
      </Typography>
    </Grid>
    <Grid item xs={12} sm={5} md={11}>
      <RHFRadioButtonGroup
        name="customerGender"
        options={[
          {
            value: 'man',
            label: '男性',
          },
          {
            value: 'woman',
            label: '女性',
          },
        ]}
      />
    </Grid>
    <AddressInputs methods={methods} />
    {/* <Grid item pt={4} xs={12} sm={6} md={12}>
      <RHFTextArea
        name="remark"
        placeholder="例)個室の席でお願いします→ 個室でのご予約を承りました"
        label="お客様からのご要望（任意）"
        multiline
        minRows={2}
      />
    </Grid> */}
  </>
);

export const rsvInfoFormInputs = (
  defaultValues: any,
  rsvMin: any,
  plans: any,
  options: any,
  methods: any,
  setRsvSelectMdlData: any
) => (
  <>
    <Grid container item xs={12} sm={12} md={12}>
      <Grid item xs={6} sm={6} md={6} sx={{ mb: 3 }}>
        <Typography color="text.secondary" variant="h5">
          <strong>枠・来店日時:</strong>
        </Typography>
      </Grid>
      <Grid item xs={1} sm={1} md={1} sx={{ mb: 3 }}>
        <input type="checkbox" {...methods.register('needTimeChange', { required: true })} hidden />
        <Button
          variant="contained"
          color="primary"
          onClick={async () => {
            if (!(await methods.trigger('plan'))) return;
            setRsvSelectMdlData({
              photoStudioPlanId: methods.getValues('photoStudioPlanId'),
              photoStudioOptionId: methods.getValues('photoStudioOptionId'),
            });
          }}
        >
          変更する
        </Button>
      </Grid>
      <Grid item xs={5} sm={5} md={5} sx={{ mt: 1 }}>
        {methods.formState.errors && methods.formState.errors.needTimeChange && (
          <Typography color="error" variant="body1" sx={{ ml: 1 }}>
            日時・枠を再選択してください
          </Typography>
        )}
      </Grid>
      <Grid item xs={6} sm={6} md={6}>
        <Typography color="text.secondary" variant="body1">
          <strong>　　店舗:</strong>
        </Typography>
      </Grid>
      <Grid item xs={6} sm={6} md={6}>
        <Typography color="text.secondary" variant="body1">
          {defaultValues.photoStudioName}
        </Typography>
      </Grid>
      <Grid item xs={6} sm={6} md={6}>
        <Typography color="text.secondary" variant="body1">
          <strong>　　予約枠:</strong>
        </Typography>
      </Grid>
      <Grid item xs={6} sm={6} md={6}>
        <Typography color="text.secondary" variant="body1">
          {defaultValues.photoStudioFrameName}
        </Typography>
      </Grid>
      <Grid item xs={6} sm={6} md={6}>
        <Typography color="text.secondary" variant="body1">
          <strong>　　予約日:</strong>
        </Typography>
      </Grid>
      <Grid item xs={6} sm={6} md={6}>
        <Typography color="text.secondary" variant="body1">
          {toDateDefault(defaultValues.reservationStartDateTime)}
        </Typography>
      </Grid>
      <Grid item xs={6} sm={6} md={6}>
        <Typography color="text.secondary" variant="body1">
          <strong>　　予約時間:</strong>
        </Typography>
      </Grid>
      <Grid item xs={6} sm={6} md={6}>
        <Typography color="text.secondary" variant="body1">
          {new Date(defaultValues.reservationStartDateTime).toLocaleTimeString([], {
            hour: '2-digit',
            minute: '2-digit',
            hour12: false,
          })}
          ~
        </Typography>
      </Grid>
      <Grid item xs={6} sm={6} md={6}>
        <Typography color="text.secondary" variant="body1">
          <strong>　　所要時間（お客様向け表示）:</strong>
        </Typography>
      </Grid>
      <Grid item xs={6} sm={6} md={6}>
        <Typography color="text.secondary" variant="body1">
          {rsvMin.sys}分 （{rsvMin.cs}分）
        </Typography>
      </Grid>
    </Grid>
    <Grid item xs={6} sm={6} md={6} sx={{ mt: 2 }}>
      <RHFTextInputSelectBox
        size="small"
        name="plan"
        label={'プラン'}
        option={plans.map((obj: any) => ({ id: obj.photoStudioPlanId, label: obj.name }))}
      />
    </Grid>
    {options && (
      <Grid item xs={6} sm={6} md={6}>
        <RHFCheckboxGroup
          label="オプション"
          name="option"
          options={options.map((obj: any) => ({ value: obj.photoStudioOptionId, label: obj.name }))}
        />
      </Grid>
    )}
  </>
);
