import {
  Stack,
  Typography,
  Container,
  Grid,
  Button,
  useTheme,
  useMediaQuery,
  Box,
  CircularProgress,
} from '@mui/material';
import {
  PostReserveRequestBodyForPhotoStudioCustomer,
  PostReserveRequestBodyForPhotoStudioCustomerFormInner,
} from 'codegen/axios/photo/photo_studio_customer';
import { FC, Fragment, useEffect, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import usePush from 'src/hooks/use-push';
import { formatDate } from 'src/functions/format';
import { phoneNumberAddHyphen } from 'src/functions/phone-number-format';
import useStaffInformation from 'src/hooks/use-staff-information';
import { LoadingButton } from '@mui/lab';
import { kidsPlanId } from 'src/utils/planId';
import { getPlanViewTitle } from 'src/utils/customerIndexView';
import { CustomStepper } from '../components/CustomStepper';

export const CustomerReservationCheck: FC = () => {
  const push = usePush();
  const [searchParams] = useSearchParams();
  const staff = useStaffInformation();
  const location = useLocation();
  const navigate = useNavigate();
  const { state } = location as any;
  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'));

  const [isSubmitting, setIsSubmitting] = useState(false);
  const paramsId = searchParams.get('id') ?? 1;
  const { viewTitle } = getPlanViewTitle(Number(paramsId));

  const reserve = state && (state.postData.reserve as PostReserveRequestBodyForPhotoStudioCustomer);
  const form = state && (state.postData.form as PostReserveRequestBodyForPhotoStudioCustomerFormInner[]);
  const otherData = state && state.otherData;
  const { search, hash } = useLocation();

  useEffect(() => {
    if (state === null) navigate(`/${staff.userType}/reservation`);
  }, []);

  const OnSubmit = () => {
    setIsSubmitting(true);
    new Promise((resolve) => {
      push(resolve, {
        reqProp: {
          pathKey: 'reservation',
          method: 'post',
          data: state.postData,
          notAlert: { status: 400, message: 'mail setting not found.' },
        },
        aftProp: {
          calledFunc: () => {
            sessionStorage.removeItem('cmr-rsv-form-data');
            sessionStorage.removeItem(`cmr-rsv-selected-data-${search}`);
            setIsSubmitting(false);
          },
          errorFunc: () => setIsSubmitting(false),
          resolve,
          nextPath: ``,
          showMessage: {
            state: {
              snackBar: {
                open: true,
                viewType: 'success',
                message: '登録しました',
              },
            },
          },
        },
      }).then((response) => {
        navigate(`/${staff.userType}/reservation/done?photoStudioPlanId=${reserve.photoStudioPlanId}${hash}`, {
          state: {
            resId: response,
          },
        });
      });
    });
  };

  return (
    <Container>
      {reserve && form && otherData ? (
        <>
          <Typography
            variant="h4"
            color="text.secondary"
            sx={{ pb: isMdUp ? 1 : 2, pt: isMdUp ? 3 : 0, pl: isMdUp ? 3 : 0 }}
          >
            撮影予約 <span style={{ fontSize: isMdUp ? '0.8em' : '0.6em' }}>　*まだ予約は完了していません</span>
            <Typography color="primary" variant={isMdUp ? 'h6' : 'subtitle2'} sx={{ display: 'inline' }}>
              <>
                <br />
                {viewTitle}
              </>
            </Typography>
          </Typography>

          <CustomStepper activeStep={2} />

          <Grid container spacing={isMdUp ? 3.5 : 1} sx={{ pb: 4 }}>
            <Grid item xs={4} sm={4} md={6}>
              <Typography
                variant={isMdUp ? 'subtitle1' : 'subtitle2'}
                color="text.secondary"
                align="left"
                sx={{ pl: isMdUp ? 42 : 0 }}
              >
                店舗
              </Typography>
            </Grid>
            <Grid item xs={8} sm={8} md={6}>
              <Typography variant={isMdUp ? 'subtitle1' : 'subtitle2'} color="text.secondary">
                {otherData.photoStudio.name}
              </Typography>
            </Grid>
            <Grid item xs={4} sm={4} md={6}>
              <Typography
                variant={isMdUp ? 'subtitle1' : 'subtitle2'}
                color="text.secondary"
                align="left"
                sx={{ pl: isMdUp ? 42 : 0 }}
              >
                プラン
              </Typography>
            </Grid>
            <Grid item xs={8} sm={8} md={6}>
              <Typography variant={isMdUp ? 'subtitle1' : 'subtitle2'} color="text.secondary">
                {otherData.photoStudioPlan.name}
              </Typography>
            </Grid>
            <Grid item xs={4} sm={4} md={6}>
              <Typography
                variant={isMdUp ? 'subtitle1' : 'subtitle2'}
                color="text.secondary"
                align="left"
                sx={{ pl: isMdUp ? 42 : 0 }}
              >
                オプション
              </Typography>
            </Grid>
            <Grid item xs={8} sm={8} md={6}>
              <Typography variant={isMdUp ? 'subtitle1' : 'subtitle2'} color="text.secondary">
                {otherData.photoStudioOptions.map((option: any) => option.name).join(', ')}
              </Typography>
            </Grid>
            <Grid item xs={4} sm={4} md={6}>
              <Typography
                variant={isMdUp ? 'subtitle1' : 'subtitle2'}
                color="text.secondary"
                align="left"
                sx={{ pl: isMdUp ? 42 : 0 }}
              >
                撮影開始日時
              </Typography>
            </Grid>
            <Grid item xs={8} sm={8} md={6}>
              <Typography variant={isMdUp ? 'subtitle1' : 'subtitle2'} color="text.secondary">
                {formatDate(reserve.reservationStartDateTime)}〜
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <Typography
                variant={isMdUp ? 'subtitle1' : 'h6'}
                color="text.secondary"
                align="left"
                sx={{ pl: isMdUp ? 38 : 0, pt: 4 }}
              >
                基本情報
              </Typography>
            </Grid>
            <Grid item xs={4} sm={4} md={6}>
              <Typography
                variant={isMdUp ? 'subtitle1' : 'subtitle2'}
                color="text.secondary"
                align="left"
                sx={{ pl: isMdUp ? 42 : 0 }}
              >
                * お名前
              </Typography>
            </Grid>
            <Grid item xs={8} sm={8} md={6}>
              <Typography variant={isMdUp ? 'subtitle1' : 'subtitle2'} color="text.secondary">
                {`${reserve.customerFirstName} ${reserve.customerLastName}`}
              </Typography>
            </Grid>
            <Grid item xs={4} sm={4} md={6}>
              <Typography
                variant={isMdUp ? 'subtitle1' : 'subtitle2'}
                color="text.secondary"
                align="left"
                sx={{ pl: isMdUp ? 42 : 0 }}
              >
                * ふりがな
              </Typography>
            </Grid>
            <Grid item xs={8} sm={8} md={6}>
              <Typography variant={isMdUp ? 'subtitle1' : 'subtitle2'} color="text.secondary">
                {`${reserve.customerFirstNameKana} ${reserve.customerLastNameKana}`}
              </Typography>
            </Grid>
            <Grid item xs={4} sm={4} md={6}>
              <Typography
                variant={isMdUp ? 'subtitle1' : 'subtitle2'}
                color="text.secondary"
                align="left"
                sx={{ pl: isMdUp ? 42 : 0 }}
              >
                * メールアドレス
              </Typography>
            </Grid>
            <Grid item xs={8} sm={8} md={6}>
              <Typography variant={isMdUp ? 'subtitle1' : 'subtitle2'} color="text.secondary">
                {reserve.customerEmail}
              </Typography>
            </Grid>
            <Grid item xs={4} sm={4} md={6}>
              <Typography
                variant={isMdUp ? 'subtitle1' : 'subtitle2'}
                color="text.secondary"
                align="left"
                sx={{ pl: isMdUp ? 42 : 0 }}
              >
                * 電話番号
              </Typography>
            </Grid>
            <Grid item xs={8} sm={8} md={6}>
              <Typography variant={isMdUp ? 'subtitle1' : 'subtitle2'} color="text.secondary">
                {phoneNumberAddHyphen(reserve.customerTelno)}
              </Typography>
            </Grid>
            <Grid item xs={4} sm={4} md={6}>
              <Typography
                variant={isMdUp ? 'subtitle1' : 'subtitle2'}
                color="text.secondary"
                align="left"
                sx={{ pl: isMdUp ? 42 : 0 }}
              >
                * 性別
              </Typography>
            </Grid>
            <Grid item xs={8} sm={8} md={6}>
              <Typography variant={isMdUp ? 'subtitle1' : 'subtitle2'} color="text.secondary">
                {(() => {
                  if (reserve.customerGender === 'man') return '男性';
                  return reserve.customerGender === 'woman' ? '女性' : 'その他';
                })()}
              </Typography>
            </Grid>
            {/*   */}
            {kidsPlanId === reserve.photoStudioPlanId && (
              <>
                {' '}
                <Grid item xs={12}>
                  <Typography
                    variant={isMdUp ? 'subtitle1' : 'subtitle2'}
                    color="text.secondary"
                    align="left"
                    sx={{ pl: isMdUp ? 38 : 0, pt: 4 }}
                  >
                    住所
                  </Typography>
                </Grid>
                <Grid item xs={4} sm={4} md={6}>
                  <Typography
                    variant={isMdUp ? 'subtitle1' : 'subtitle2'}
                    color="text.secondary"
                    align="left"
                    sx={{ pl: isMdUp ? 42 : 0 }}
                  >
                    郵便番号
                  </Typography>
                </Grid>
                <Grid item xs={8} sm={8} md={6}>
                  <Typography variant={isMdUp ? 'subtitle1' : 'subtitle2'} color="text.secondary">
                    {reserve.zip && reserve.zip.replace(/(.{3})(.{4})/, '$1-$2')}
                  </Typography>
                </Grid>
                <Grid item xs={4} sm={4} md={6}>
                  <Typography
                    variant={isMdUp ? 'subtitle1' : 'subtitle2'}
                    color="text.secondary"
                    align="left"
                    sx={{ pl: isMdUp ? 42 : 0 }}
                  >
                    都道府県
                  </Typography>
                </Grid>
                <Grid item xs={8} sm={8} md={6}>
                  <Typography variant={isMdUp ? 'subtitle1' : 'subtitle2'} color="text.secondary">
                    {reserve.prefecture}
                  </Typography>
                </Grid>
                <Grid item xs={4} sm={4} md={6}>
                  <Typography
                    variant={isMdUp ? 'subtitle1' : 'subtitle2'}
                    color="text.secondary"
                    align="left"
                    sx={{ pl: isMdUp ? 42 : 0 }}
                  >
                    市町村
                  </Typography>
                </Grid>
                <Grid item xs={8} sm={8} md={6}>
                  <Typography variant={isMdUp ? 'subtitle1' : 'subtitle2'} color="text.secondary">
                    {reserve.city}
                  </Typography>
                </Grid>
                <Grid item xs={4} sm={4} md={6}>
                  <Typography
                    variant={isMdUp ? 'subtitle1' : 'subtitle2'}
                    color="text.secondary"
                    align="left"
                    sx={{ pl: isMdUp ? 42 : 0 }}
                  >
                    それ以降
                  </Typography>
                </Grid>
                <Grid item xs={8} sm={8} md={6}>
                  <Typography variant={isMdUp ? 'subtitle1' : 'subtitle2'} color="text.secondary">
                    {reserve.street}
                  </Typography>
                </Grid>
              </>
            )}

            {form &&
              form.map((group: PostReserveRequestBodyForPhotoStudioCustomerFormInner, index: number) => (
                <Fragment key={index}>
                  {group.inputs?.some((input) => input.customerViewFlg === true) &&
                    group.inputs?.some((input) =>
                      input.availablePlans.some(
                        (obj) => obj.photoStudioPlanId === otherData.photoStudioPlan.photoStudioPlanId
                      )
                    ) && (
                      <Grid item xs={12}>
                        <Typography
                          variant={isMdUp ? 'subtitle1' : 'h6'}
                          color="text.secondary"
                          align="left"
                          sx={{ pl: isMdUp ? 38 : 0, pt: 4 }}
                        >
                          {group.groupName}
                        </Typography>
                      </Grid>
                    )}
                  {group.inputs?.map((input, idx) => {
                    if (input.customerViewFlg === false) return undefined;
                    if (
                      !input.availablePlans.some(
                        (obj) => obj.photoStudioPlanId === otherData.photoStudioPlan.photoStudioPlanId
                      )
                    ) {
                      return undefined;
                    }

                    if (
                      input.availableOptionIds.length > 0 &&
                      !input.availableOptionIds.some((id) =>
                        otherData.photoStudioOptions.map((op: any) => op.photoStudioOptionId).includes(id)
                      )
                    ) {
                      return undefined;
                    }

                    switch (input.inputType) {
                      case 'checkbox':
                        return (
                          <Fragment key={idx}>
                            <Grid item xs={12} sm={12} md={6}>
                              <Typography
                                variant={isMdUp ? 'subtitle1' : 'subtitle2'}
                                color="text.secondary"
                                align="left"
                                sx={{ pl: isMdUp ? 42 : 0 }}
                              >
                                {`${input.requiredFlg ? '*' : ''} ${input.name}`}
                              </Typography>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6}>
                              <Typography variant={isMdUp ? 'subtitle1' : 'body2'} color="text.secondary">
                                {input.value.map((item, index) => (
                                  <Fragment key={index}>
                                    ・{item}
                                    <br />
                                  </Fragment>
                                ))}
                              </Typography>
                            </Grid>
                          </Fragment>
                        );
                      case 'selectbox':
                        return (
                          <Fragment key={idx}>
                            <Grid item xs={4} sm={4} md={6}>
                              <Typography
                                variant={isMdUp ? 'subtitle1' : 'subtitle2'}
                                color="text.secondary"
                                align="left"
                                sx={{ pl: isMdUp ? 42 : 0 }}
                              >
                                {`${input.requiredFlg ? '*' : ''} ${input.name}`}
                              </Typography>
                            </Grid>
                            <Grid item xs={8} sm={8} md={6}>
                              <Typography variant={isMdUp ? 'subtitle1' : 'subtitle2'} color="text.secondary">
                                {input.value}
                              </Typography>
                            </Grid>
                          </Fragment>
                        );
                      case 'radiobutton':
                        return (
                          <Fragment key={idx}>
                            <Grid item xs={4} sm={4} md={6}>
                              <Typography
                                variant={isMdUp ? 'subtitle1' : 'subtitle2'}
                                color="text.secondary"
                                align="left"
                                sx={{ pl: isMdUp ? 42 : 0 }}
                              >
                                {`${input.requiredFlg ? '*' : ''} ${input.name}`}
                              </Typography>
                            </Grid>
                            <Grid item xs={8} sm={8} md={6}>
                              <Typography variant={isMdUp ? 'subtitle1' : 'subtitle2'} color="text.secondary">
                                {input.value}
                              </Typography>
                            </Grid>
                          </Fragment>
                        );
                      case 'textarea':
                        return (
                          <Fragment key={idx}>
                            <Grid item xs={4} sm={4} md={6}>
                              <Typography
                                variant={isMdUp ? 'subtitle1' : 'subtitle2'}
                                color="text.secondary"
                                align="left"
                                sx={{ pl: isMdUp ? 42 : 0 }}
                              >
                                {`${input.requiredFlg ? '*' : ''} ${input.name}`}
                              </Typography>
                            </Grid>
                            <Grid item xs={8} sm={8} md={6}>
                              <Typography variant={isMdUp ? 'subtitle1' : 'subtitle2'} color="text.secondary">
                                {input.value && input.value[0]
                                  ? input.value[0].split('\n').map((item, key) => (
                                      <span key={key}>
                                        {item}
                                        <br />
                                      </span>
                                    ))
                                  : ''}
                              </Typography>
                            </Grid>
                          </Fragment>
                        );
                      case 'textbox':
                        return (
                          <Fragment key={idx}>
                            <Grid item xs={4} sm={4} md={6}>
                              <Typography
                                variant={isMdUp ? 'subtitle1' : 'subtitle2'}
                                color="text.secondary"
                                align="left"
                                sx={{ pl: isMdUp ? 42 : 0 }}
                              >
                                {`${input.requiredFlg ? '*' : ''} ${input.name}`}
                              </Typography>
                            </Grid>
                            <Grid item xs={8} sm={8} md={6}>
                              <Typography variant={isMdUp ? 'subtitle1' : 'subtitle2'} color="text.secondary">
                                {input.value}
                              </Typography>
                            </Grid>
                          </Fragment>
                        );
                      default:
                        return null;
                    }
                  })}
                </Fragment>
              ))}
          </Grid>
          <Stack direction="row" alignItems="center" justifyContent="center" sx={{ m: 1, pt: 3 }} spacing={5}>
            <Button
              variant="outlined"
              sx={{ width: 110, height: 47 }}
              size="large"
              onClick={() => window.history.back()}
            >
              戻る
            </Button>
            <LoadingButton
              variant="contained"
              sx={{ width: 110, height: 47 }}
              size="large"
              onClick={OnSubmit}
              loading={isSubmitting}
            >
              予約確定
            </LoadingButton>
          </Stack>
        </>
      ) : (
        <Box>
          <CircularProgress
            size={60}
            sx={{
              position: 'fixed',
              left: '45%',
              top: '50%',
              transform: 'translate(-50%, -50%)',
              zIndex: 2,
            }}
          />
        </Box>
      )}
    </Container>
  );
};
