export type Exception = {
  get?: {
    400: string | { [key: string]: string };
    500?: string | { [key: string]: string };
  };
  post?:
    | string
    | {
        400: string | { [key: string]: string };
        401?: string | { [key: string]: string };
      };

  put?: {
    400: { [key: string]: string } | string;
    500?: string | { [key: string]: string };
  };
  delete?: {
    400: { [key: string]: string };
  };
};

export type ApiEndpoint = {
  path: string;
  exception: Exception;
};

export type ApiEndpoints = {
  calendarAvailability: ApiEndpoint;
  needTimeAdjustment: ApiEndpoint;
  marriageAgencyStaff: ApiEndpoint;
  team: ApiEndpoint;
  reservation: ApiEndpoint;
  reservationSearch: ApiEndpoint;
  reservationSearchCount: ApiEndpoint;
  studio: ApiEndpoint;
  bookmark: ApiEndpoint;
  calendar: ApiEndpoint;
  introduction: ApiEndpoint;
  information: ApiEndpoint;
  businessHour: ApiEndpoint;
  regularHoliday: ApiEndpoint;
  irregular: ApiEndpoint;
  plan: ApiEndpoint;
  option: ApiEndpoint;
  frame: ApiEndpoint;
  rule: ApiEndpoint;
  schedule: ApiEndpoint;
  account: ApiEndpoint;
  autoMailSetting: ApiEndpoint;
  formInputs: ApiEndpoint;
  pdf: ApiEndpoint;
  reserveCount: ApiEndpoint;
  company: ApiEndpoint;
  customer: ApiEndpoint;
  permission: ApiEndpoint;
  signIn: ApiEndpoint;
  twoFactorAuth: ApiEndpoint;
  signOut: ApiEndpoint;
  signInForMarriageAgencyStaff: ApiEndpoint;
  twoFactorAuthForMarriageAgencyStaff: ApiEndpoint;
  name: ApiEndpoint;
  marriageAgencyStaffName: ApiEndpoint;
};
export function api(): ApiEndpoints {
  return {
    rule: {
      path: '/photo/rules',
      exception: {
        get: { 400: { 'invalid uuid': '無効なURLです。' } },
        post: { 400: { 'restaurant staff already exists': 'メールアドレスが不正なものか、既に使用されています。' } },
        put: {
          400: { 'email is duplicated': 'このメールアドレスは既に使用されています。' },
          500: { undefined: 'このメールアドレスは既に使用されています。' },
        },
      },
    },
    calendarAvailability: {
      path: '/photo/reserves/calendar/availability',
      exception: {
        get: { 400: { 'invalid uuid': '無効なURLです。' } },
        post: { 400: { 'restaurant staff already exists': 'メールアドレスが不正なものか、既に使用されています。' } },
        put: {
          400: { 'email is duplicated': 'このメールアドレスは既に使用されています。' },
          500: { undefined: 'このメールアドレスは既に使用されています。' },
        },
      },
    },
    needTimeAdjustment: {
      path: '/photo/reserves/needTimeAdjustment',
      exception: {
        get: { 400: { 'invalid uuid': '無効なURLです。' } },
        post: { 400: { 'restaurant staff already exists': 'メールアドレスが不正なものか、既に使用されています。' } },
        put: {
          400: { 'email is duplicated': 'このメールアドレスは既に使用されています。' },
          500: { undefined: 'このメールアドレスは既に使用されています。' },
        },
      },
    },
    marriageAgencyStaff: {
      path: '/photo/marriageAgencyStaff',
      exception: {
        get: { 400: { 'invalid uuid': '無効なURLです。' } },
        post: { 400: { 'restaurant staff already exists': 'メールアドレスが不正なものか、既に使用されています。' } },
        put: {
          400: { 'email is duplicated': 'このメールアドレスは既に使用されています。' },
          500: { undefined: 'このメールアドレスは既に使用されています。' },
        },
      },
    },
    team: {
      path: '/photo/me/teams',
      exception: {
        get: { 400: { 'invalid uuid': '無効なURLです。' } },
        post: { 400: { 'restaurant staff already exists': 'メールアドレスが不正なものか、既に使用されています。' } },
        put: {
          400: { 'email is duplicated': 'このメールアドレスは既に使用されています。' },
          500: { undefined: 'このメールアドレスは既に使用されています。' },
        },
      },
    },
    studio: {
      path: '/photo/studios',
      exception: {
        get: {
          400: 'データが取得できませんでした。この状態が長く続く場合は管理者にお問合せください',
        },
        post: 'エラー',
      },
    },
    bookmark: {
      path: '/photo/studios/bookmarks',
      exception: {
        get: {
          400: 'データが取得できませんでした。この状態が長く続く場合は管理者にお問合せください',
        },
        post: 'エラー',
      },
    },
    pdf: {
      path: '/photo/reserves/pdf',
      exception: {
        get: {
          400: 'データが取得できませんでした。この状態が長く続く場合は管理者にお問合せください',
          500: { undefined: 'この予約は存在しないか削除されています。' },
        },
        post: { 400: '値が不正です。値を変更してしてやり直してください' },
        put: { 400: '値が不正です。更新してやり直してください' },
      },
    },
    reservation: {
      path: '/photo/reserves',
      exception: {
        get: {
          400: {
            'get reserve process invalid.':
              'データが取得できませんでした。この状態が長く続く場合は管理者にお問合せください',
          },
          500: { undefined: 'この予約は存在しないか削除されています。' },
        },
        post: {
          400: {
            'reserved.': '予約の在庫数が０になりました。お手数ですが、再度ご予約をお願いします。',
            'invalid mail': 'メールアドレスの形式が不明です。入力し直してください',
          },
        },
        put: {
          400: {
            'reserved.': '予約が埋まっています',
            'invalid mail': 'メールアドレスの形式が不明です。入力し直してください',
          },
        },
      },
    },
    reservationSearch: {
      path: '/photo/reserves/search',
      exception: {
        get: {
          400: 'データが取得できませんでした。この状態が長く続く場合は管理者にお問合せください',
          500: { undefined: 'この予約は存在しないか削除されています。' },
        },
        post: { 400: '値が不正です。値を変更してしてやり直してください' },
        put: { 400: '値が不正です。更新してやり直してください' },
      },
    },
    reservationSearchCount: {
      path: '/photo/reserves/search/count',
      exception: {
        get: {
          400: 'データが取得できませんでした。この状態が長く続く場合は管理者にお問合せください',
          500: { undefined: 'この予約は存在しないか削除されています。' },
        },
        post: { 400: '値が不正です。値を変更してしてやり直してください' },
        put: { 400: '値が不正です。更新してやり直してください' },
      },
    },
    introduction: { path: '/photo/studios/introductions', exception: { post: 'エラー' } },
    information: { path: '/photo/studios/informations', exception: { post: 'エラー' } },
    businessHour: { path: '/photo/studios/businessHours', exception: { post: 'エラー' } },
    regularHoliday: { path: '/photo/studios/regularHolidays', exception: { post: 'エラー' } },
    plan: {
      path: '/photo/plans',
      exception: {
        get: { 400: { 'invalid uuid': '無効なURLです。' } },
        post: 'エラー',
        delete: { 400: { 'delete process invalid': 'お客様に選択されているプランは削除できません。' } },
      },
    },
    option: {
      path: '/photo/options',
      exception: {
        get: { 400: { 'invalid uuid': '無効なURLです。' } },
        post: 'エラー',
        delete: { 400: { 'delete process invalid': 'お客様に選択されているプランは削除できません。' } },
      },
    },
    formInputs: {
      path: '/photo/formInputs',
      exception: {
        get: { 400: { 'invalid uuid': '無効なURLです。' } },
        post: 'エラー',
        delete: { 400: { 'delete process invalid': 'お客様に選択されているプランは削除できません。' } },
      },
    },
    frame: {
      path: '/photo/reserves/frames',
      exception: {
        get: { 400: { 'invalid uuid': '無効なURLです。' } },
        post: 'エラー',
        delete: {
          400: {
            'delete process invalid': 'お客様に選択されているプランは削除できません。',
            'bad request': '以下の日時に予約があるため削除できません',
          },
        },
      },
    },
    irregular: { path: '/photo/reserves/frames/irregulars', exception: { post: 'エラー' } },
    schedule: {
      path: '/photo/reserves/schedule/availability',
      exception: {
        get: {
          400: 'データが取得できませんでした。この状態が長く続く場合は管理者にお問合せください',
          500: { undefined: 'この予約は存在しないか削除されています。' },
        },
        post: { 400: '値が不正です。値を変更してしてやり直してください' },
        put: { 400: '値が不正です。更新してやり直してください' },
      },
    },
    autoMailSetting: {
      path: '/photo/mailSettings',
      exception: {
        get: { 400: { 'invalid uuid': '無効なURLです。' } },
        post: 'エラー',
        delete: { 400: { 'delete process invalid': 'お客様に選択されているプランは削除できません。' } },
      },
    },
    account: {
      path: '/photo',
      exception: {
        post: { 400: { 'admin staff already exists': 'メールアドレスが不正なものか、既に使用されています。' } },
        put: {
          400: {
            'email is duplicated': 'このメールアドレスは既に使用されています。',
            'invalid phone number': '電話番号が重複しているか、不正なものです。',
          },
        },
      },
    },
    calendar: {
      path: '/photo/reserves/calendar',
      exception: {
        get: {
          400: 'データが取得できませんでした。この状態が長く続く場合は管理者にお問合せください',
          500: { undefined: 'この予約は存在しないか削除されています。' },
        },
        post: { 400: '値が不正です。値を変更してしてやり直してください' },
        put: { 400: '値が不正です。更新してやり直してください' },
      },
    },
    reserveCount: {
      path: '/photo/reserves/count',
      exception: { get: { 400: { 'invalid uuid': '無効なURLです。' } }, post: 'エラー' },
    },
    company: {
      path: '/photo/companies',
      exception: { get: { 400: { 'invalid uuid': '無効なURLです。' } }, post: 'エラー' },
    },
    customer: {
      path: '/photo/customers',
      exception: { get: { 400: { 'invalid uuid': '無効なURLです。' } }, post: 'エラー' },
    },
    permission: {
      path: '/photo/StaffViewPermission',
      exception: { get: { 400: { 'invalid uuid': '無効なURLです。' } }, post: 'エラー' },
    },
    signIn: {
      path: '/photo/signin',
      exception: {
        post: { 400: { 'The login information is incorrect': 'パスワードが間違っているか、不正なものです。' } },
      },
    },
    twoFactorAuth: {
      path: '/photo/signin/second',
      exception: {
        post: {
          400: { 'one time pass incollect': 'コードが違います' },
          401: 'コードが無効になりました。ログインからやり直してください',
        },
      },
    },
    signOut: { path: '/photo/signout', exception: { post: 'エラー' } },
    name: { path: '/photo/me', exception: { post: 'エラー' } },
    signInForMarriageAgencyStaff: {
      path: '/photo/marriageAgencyStaff/signin',
      exception: {
        post: { 400: { 'The login information is incorrect': 'パスワードが間違っているか、不正なものです。' } },
      },
    },
    twoFactorAuthForMarriageAgencyStaff: {
      path: '/photo/marriageAgencyStaff/signin/second',
      exception: {
        post: {
          400: { 'one time pass incollect': 'コードが違います' },
          401: 'コードが無効になりました。ログインからやり直してください',
        },
      },
    },
    marriageAgencyStaffName: {
      path: '/photo/marriageAgencyStaff/me/name',
      exception: {
        post: {
          400: { 'one time pass incollect': 'コードが違います' },
          401: 'コードが無効になりました。ログインからやり直してください',
        },
      },
    },
  };
}
