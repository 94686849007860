import FullCalendar, { EventClickArg, DatesSetArg } from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import allLocales from '@fullcalendar/core/locales-all';
import interactionPlugin from '@fullcalendar/interaction';
import timeGridPlugin from '@fullcalendar/timegrid';
import resourceTimeGridPlugin from '@fullcalendar/resource-timegrid';
import resourceTimelinePlugin from '@fullcalendar/resource-timeline';
import scrollGridPlugin from '@fullcalendar/scrollgrid';
import { useSearchParams } from 'react-router-dom';
import { PhotoStudioReservationFrame } from 'codegen/axios/photo/photo_studio';
import { useChangeDebugger } from 'src/hooks/useChangeDebugger';
import { memo } from 'react';
import './index.css';

interface CalendarProps {
  datesSet: (arg: DatesSetArg) => void;
  calendarRef: any;
  selectSlotDuration: { value: string; label: string };
  resources: PhotoStudioReservationFrame[];
  viewType: string;
  eventClick: (info: EventClickArg) => void;
  emptyFilteredResource: any;
  cEvent: any;
}

export const ScheduleCalendar = memo(
  ({
    datesSet,
    calendarRef,
    selectSlotDuration,
    resources,
    viewType,
    eventClick,
    emptyFilteredResource,
    cEvent,
  }: CalendarProps) => {
    const [searchParams] = useSearchParams();
    const date: Date = searchParams.get('date') ? new Date(searchParams.get('date') || '') : new Date();

    return (
      <div className="admin">
        <FullCalendar
          eventTextColor="#18191a"
          scrollTimeReset={false}
          contentHeight={window.innerHeight * 0.7}
          expandRows
          datesSet={datesSet}
          ref={calendarRef}
          schedulerLicenseKey="0439324989-fcs-1673827335"
          slotDuration={selectSlotDuration.value}
          dayMaxEvents
          firstDay={0}
          views={{
            resourceTimeGridWeek: {
              type: 'resourceTimeGrid',
              duration: { days: 7 },
              buttonText: 'week',
            },
            resourceTimeGridFourDay: {
              startParam: 'Monday',
              type: 'resourceTimeGrid',
              duration: { days: 1 },
              buttonText: '1 days',
            },
            resourceTimeline: {
              resourceAreaHeaderContent: '予約枠',
            },
          }}
          slotLabelFormat={{
            hour: 'numeric',
            minute: '2-digit',
            omitZeroMinute: false,
            meridiem: 'short',
          }}
          {...(() => {
            const { reservationStartTime } = resources.reduce((a, b) =>
              a.reservationStartTime < b.reservationStartTime ? a : b
            );
            const { reservationEndTime } = resources.reduce((a, b) =>
              a.reservationEndTime > b.reservationEndTime ? a : b
            );

            // reservationStartTimeを":"で分割して時間部分だけを取得
            const startHours = reservationStartTime.split(':')[0];
            const endHours = reservationEndTime.split(':')[0];

            return {
              slotMinTime: `${startHours}:00`, // 分を"00"にして時間を返す
              slotMaxTime: `${Number(endHours) + 1}:00`, // 分を"00"にして時間を返す
            };
          })()}
          eventContent={renderEventContent}
          initialView={viewType}
          dayCellContent={(e) => e.dayNumberText.replace('日', '')}
          slotLabelInterval="00:30"
          eventClick={eventClick}
          selectable
          nowIndicator
          plugins={[
            scrollGridPlugin,
            dayGridPlugin,
            timeGridPlugin,
            interactionPlugin,
            resourceTimelinePlugin,
            resourceTimeGridPlugin,
          ]}
          initialDate={new Date(date)}
          headerToolbar={false}
          resourceOrder="extendedProps.index"
          resources={emptyFilteredResource}
          events={cEvent}
          locales={allLocales}
          locale="ja"
          datesAboveResources
          dayMinWidth={100}
          slotEventOverlap={false}
          allDaySlot={false}
        />
      </div>
    );
  }
);

const renderEventContent = (eventInfo: any) => (
  <div style={{ textAlign: 'center', lineHeight: '1' }}>
    <span>{eventInfo.event.extendedProps.time}~</span>
    <br />
    {eventInfo.event.extendedProps.status && (
      <>
        <span style={{ fontSize: '12px' }}>
          {'《'}
          {eventInfo.event.extendedProps.status}
          {'》'}
        </span>
        <br />
      </>
    )}
    <b style={{ fontSize: '12px' }}>{eventInfo.event.title}</b>
    <br />
    {eventInfo.event.extendedProps.customerNameKana && eventInfo.event.extendedProps.gender && (
      <>
        <span style={{ fontSize: '10px' }}>
          {'('}
          {eventInfo.event.extendedProps.customerNameKana}
          {')'}
        </span>
        <br />
        <span style={{ fontSize: '12px' }}>{eventInfo.event.extendedProps.gender}</span>
      </>
    )}
    <br />
    {eventInfo.event.extendedProps.plan && typeof eventInfo.event.extendedProps.plan === 'string' && (
      <b style={{ fontSize: '10px' }}>{`・${eventInfo.event.extendedProps.plan.split('※')[0]}`}</b>
    )}

    <br />
    <span style={{ fontSize: '10px' }}>
      {eventInfo.event.extendedProps.staffRemark && typeof eventInfo.event.extendedProps.staffRemark[0] === 'string'
        ? eventInfo.event.extendedProps.staffRemark[0].split('\n').map((item: any, key: any) => (
            <span key={key}>
              {item}
              <br />
            </span>
          ))
        : ''}
    </span>
    <br />
  </div>
);
