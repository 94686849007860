import { FC } from 'react';
import Page from 'src/components/molecules/page';
import { PartnerStudioDetail } from 'src/components/organisms/reservation-for-adca/detail';

export const ReservationForAdcaDetailPage: FC = () => {
  /* 定数宣言 */
  const title = '提携店詳細';

  return (
    <Page title={title}>
      <PartnerStudioDetail />
    </Page>
  );
};
