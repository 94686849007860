import Page from 'src/components/molecules/page';
import { FC } from 'react';
import { ReservationDetail } from 'src/components/organisms/reservation/detail';

export const ReservationDetailPage: FC = () => {
  /* 定数宣言 */
  const title = '予約詳細';

  return (
    <Page title={title}>
      <ReservationDetail title={title} />
    </Page>
  );
};
