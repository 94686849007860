import {
  toDateTimeDefault,
  toTimeDefault,
  toIsoString,
  toApiTimeFormat,
  toStringYmdD,
  formatFlgToDayOfWeek,
} from 'src/functions/date-time-organize';
import { phoneNumberAddHyphen } from 'src/functions/phone-number-format';
import {
  concatenateNames,
  convertTimeFormat,
  formatDateToTime,
  formatDateToTimeSimple,
  formatPrice,
  getInputType,
  lastLetterSplitSemicolon,
  truncateString,
  zipNumberAddHyphen,
} from 'src/functions/format';
import { OptionType } from 'src/types/common';
import { Company, Customer, Plan, ReservationFrame } from 'codegen/axios/photo/admin';
import { CustomerList } from 'src/types/customer-form';
import {
  Image,
  Information,
  Introduction,
  MarriageAgencyStaff,
  MarriageAgencyStaffPhotoOption,
  MarriageAgencyStaffPhotoPlan,
  MarriageAgencyStaffPhotoStudio,
  MarriageAgencyStaffPhotoStudioDetail,
  ReserveForCalendarInner,
  TeamResponse,
} from 'codegen/axios/photo/adca';
import {
  AvailableOption,
  AvailablePlan,
  Option,
  PhotoFormUiParts,
  PhotoFormUiPartsAvailablePlansInner,
  PhotoFormUiPartsList,
  PhotoFormUiPartsListInputsInner,
  PhotoPlan,
  PhotoReservationAutoMailSetting,
  PhotoReservationFrameIrregularSetting,
  PhotoStudioBusinessHour,
  PhotoStudioInformation,
  PhotoStudioIntroduction,
  PhotoStudioPlanImage,
  PhotoStudioRegularHoliday,
  PhotoStudioReservationFrame,
  PhotoStudioStaff,
  PhotoStudioStaffPhotoStudio,
  Reserve,
  ReserveDetail,
  ReserveForCalendarAvailabilityInner,
  Rule,
} from 'codegen/axios/photo/photo_studio';
import { PhotoStudioFormType } from 'src/components/organisms/photo-studio/form';
import { InformationFormType } from 'src/components/organisms/photo-studio/detail/information/form';
import { OFFSET_MIN } from 'src/utils/timeEnv';
import { getDateObj } from 'src/components/organisms/customer-reservation/reservation-select/schedule';

export const partnerStudioListFetchOrganize = (photoStudios: Array<MarriageAgencyStaffPhotoStudio>) =>
  photoStudios.map((photoStudio: MarriageAgencyStaffPhotoStudio) => ({
    photoStudioId: (photoStudio && photoStudio.photoStudioId) ?? '',
    name: (photoStudio && photoStudio.name) ?? '',
    prefecture: (photoStudio && photoStudio.prefecture) ?? '',
    city: (photoStudio && photoStudio.city) ?? '',
    access: (photoStudio && photoStudio.access) ?? '',
    caption: (photoStudio && photoStudio.caption) ?? '',
    bookmarkFlg: (photoStudio && photoStudio.bookmarkFlg) ?? false,
    imagesTop: (photoStudio && photoStudio.imagesTop) ?? [],
  }));

export const partnerStudioDetailFetchOrganize = (photoStudio: MarriageAgencyStaffPhotoStudioDetail) => ({
  photoStudioId: (photoStudio && photoStudio.photoStudioId) ?? '',
  name: (photoStudio && photoStudio.name) ?? '',
  telno: (photoStudio && phoneNumberAddHyphen(photoStudio.telno)) ?? '',
  zip: (photoStudio && photoStudio.zip) ?? '',
  prefecture: (photoStudio && photoStudio.prefecture) ?? '',
  city: (photoStudio && photoStudio.city) ?? '',
  street: (photoStudio && photoStudio.street) ?? '',
  building: (photoStudio && photoStudio.building) ?? '',
  salesTime:
    photoStudio && photoStudio.salesStartTime && photoStudio.salesEndTime
      ? `${convertTimeFormat(photoStudio.salesStartTime)} 〜 ${convertTimeFormat(photoStudio.salesEndTime)}`
      : ``,
  remark: (photoStudio && photoStudio.remark) ?? '',
  introduction: (photoStudio && photoStudio.introduction) ?? { commentTitle: '', commentBody: '' },
  information: (photoStudio && photoStudio.information) ?? {},
  regularHoliday:
    photoStudio && photoStudio.regularHoliday
      ? formatFlgToDayOfWeek(
          !photoStudio.regularHoliday.sunday,
          !photoStudio.regularHoliday.monday,
          !photoStudio.regularHoliday.tuesday,
          !photoStudio.regularHoliday.wednesday,
          !photoStudio.regularHoliday.thursday,
          !photoStudio.regularHoliday.friday,
          !photoStudio.regularHoliday.saturday
        )
      : '',
  plans: (photoStudio && photoStudio.plans) ?? [],
  imagesTop: (photoStudio && photoStudio.imagesTop) ?? [],
});

export const imageGalleryFetchOrganize = (images: Array<Image>) =>
  images.map((image) => ({
    original: (image && image.imageUrl) ?? '',
    thumbnail: (image && image.imageUrl) ?? '',
  }));

export const photoStudioListFetchOrganize = (photoStudios: Array<PhotoStudioStaffPhotoStudio>) =>
  photoStudios.map((photoStudio: PhotoStudioStaffPhotoStudio) => ({
    ...photoStudio,
    id: (photoStudio && photoStudio.photoStudioId) ?? '',
    photoStudioName: (photoStudio && photoStudio.photoStudioName) ?? '',
    prefecture: (photoStudio && photoStudio.prefecture) ?? '',
    city: (photoStudio && photoStudio.city) ?? '',
    email: (photoStudio && photoStudio.email) ?? '',
    telno: (photoStudio && phoneNumberAddHyphen(photoStudio.telno)) ?? '',
  }));

export const photoStudioDetailFetchOrganize = (photoStudio: PhotoStudioStaffPhotoStudio) => ({
  店舗名: (photoStudio && photoStudio.photoStudioName) ?? '',
  '店舗名（かな）': (photoStudio && photoStudio.photoStudioNameKana) ?? '',
  会社: (photoStudio && photoStudio.companyName) ?? '',
  郵便番号: (photoStudio && zipNumberAddHyphen(photoStudio.zip)) ?? '',
  住所: `${(photoStudio && photoStudio.prefecture) ?? ''} ${(photoStudio && photoStudio.city) ?? ''} ${
    (photoStudio && photoStudio.street) ?? ''
  } ${(photoStudio && photoStudio.building) ?? ''}`,
  電話番号: (photoStudio && phoneNumberAddHyphen(photoStudio.telno)) ?? '',
  メールアドレス: (photoStudio && photoStudio.email) ?? '',
});

export const photoStudioFormFetchOrganize = (photoStudio: PhotoStudioStaffPhotoStudio): PhotoStudioFormType => ({
  photoStudioName: (photoStudio && photoStudio.photoStudioName) ?? '',
  photoStudioNameKana: (photoStudio && photoStudio.photoStudioNameKana) ?? '',
  email: (photoStudio && photoStudio.email) ?? '',
  telno: (photoStudio && phoneNumberAddHyphen(photoStudio.telno)) ?? '',
  zip: (photoStudio && photoStudio.zip) ?? '',
  prefecture: (photoStudio && photoStudio.prefecture) ?? '',
  city: (photoStudio && photoStudio.city) ?? '',
  street: (photoStudio && photoStudio.street) ?? '',
  building: (photoStudio && photoStudio.building) ?? '',
});

export const informationFetchOrganize = (info: PhotoStudioInformation) => ({
  概要: (info && info.caption) ?? '',
  アクセス: (info && info.access) ?? '',
  予約方法: (info && info.reservationMethod) ?? '',
  URL: (info && info.url) ?? '',
  お支払い方法: (info && info.paymentMethod) ?? '',
  お支払い方法に関する備考: (info && info.paymentRemark) ?? '',
  更衣室: info && info.changingRoomFlg !== undefined ? flagToLetter(info.changingRoomFlg) : '',
  更衣室に関する備考: (info && info.changingRoomRemark) ?? '',
  撮影データの納品方法: (info && info.deliveryMethod) ?? '',
  注意事項: (info && info.specialNote) ?? '',
  その他備考: (info && info.remark) ?? '',
});

export const informationFormFetchOrganize = (info: PhotoStudioInformation): InformationFormType => ({
  photoStudioId: (info && info.photoStudioId) ?? '',
  caption: (info && info.caption) ?? '',
  url: (info && info.url) ?? '',
  access: (info && info.access) ?? '',
  paymentMethod: (info && info.paymentMethod) ?? '',
  paymentRemark: (info && info.paymentRemark) ?? '',
  changingRoomFlg: (info && info.changingRoomFlg) ?? false,
  changingRoomRemark: (info && info.changingRoomRemark) ?? '',
  deliveryMethod: (info && info.deliveryMethod) ?? '',
  reservationMethod: (info && info.reservationMethod) ?? '',
  specialNote: (info && info.specialNote) ?? '',
  remark: (info && info.remark) ?? '',
});

export const informationForCardOrganize = (info?: Information) => ({
  access: (info && info.access) ?? '',
  paymentMethod: (info && info.paymentMethod) ?? '',
  paymentRemark: (info && info.paymentRemark) ?? '',
  changingRoomFlg: info && info.changingRoomFlg !== undefined ? flagToLetter(info.changingRoomFlg) : '',
  changingRoomRemark: (info && info.changingRoomRemark) ?? '',
  reservationMethod: (info && info.reservationMethod) ?? '',
  deliveryMethod: (info && info.deliveryMethod) ?? '',
  specialNote: (info && info.specialNote) ?? '',
  url: (info && info.url) ?? '',
  remark: (info && info.remark) ?? '',
});

export const introductionCardOrganize = (introduction: Introduction) => ({
  commentTitle: (introduction && introduction.commentTitle) ?? '',
  commentBody: (introduction && introduction.commentBody) ?? '',
});

export const introductionFetchOrganize = (introduction: PhotoStudioIntroduction) => ({
  タイトル: (introduction && introduction.commentTitle) ?? '',
  内容: (introduction && introduction.commentBody) ?? '',
});

export const introductionFormFetchOrganize = (introduction: PhotoStudioIntroduction) => ({
  photoStudioId: (introduction && introduction.photoStudioId) ?? '',
  commentTitle: (introduction && introduction.commentTitle) ?? '',
  commentBody: (introduction && introduction.commentBody) ?? '',
});

// export const businessHourForScheduleFetchOrganize = (businessHour: PhotoStudioBusinessHour) => ({
//   salesStartTime: businessHour && businessHour.salesStartTime ? toTimeDefault(businessHour.salesStartTime) : '00:00',
//   salesEndTime: businessHour && businessHour.salesEndTime ? toTimeDefault(businessHour.salesEndTime) : '24:00',
// });

export const businessHourFetchOrganize = (businessHour: PhotoStudioBusinessHour) => ({
  通常営業日:
    businessHour && businessHour.salesStartTime && businessHour.salesEndTime
      ? `${toTimeDefault(businessHour.salesStartTime)} 〜 ${toTimeDefault(businessHour.salesEndTime)}`
      : '',
  月曜日:
    businessHour && businessHour.mondaySalesStartTime && businessHour.mondaySalesEndTime
      ? `${toTimeDefault(businessHour.mondaySalesStartTime)} 〜 ${toTimeDefault(businessHour.mondaySalesEndTime)}`
      : '',
  火曜日:
    businessHour && businessHour.tuesdaySalesStartTime && businessHour.tuesdaySalesEndTime
      ? `${toTimeDefault(businessHour.tuesdaySalesStartTime)} 〜 ${toTimeDefault(businessHour.tuesdaySalesEndTime)}`
      : '',
  水曜日:
    businessHour && businessHour.wednesdaySalesStartTime && businessHour.wednesdaySalesEndTime
      ? `${toTimeDefault(businessHour.wednesdaySalesStartTime)} 〜 ${toTimeDefault(businessHour.wednesdaySalesEndTime)}`
      : '',
  木曜日:
    businessHour && businessHour.thursdaySalesStartTime && businessHour.thursdaySalesEndTime
      ? `${toTimeDefault(businessHour.thursdaySalesStartTime)} 〜 ${toTimeDefault(businessHour.thursdaySalesEndTime)}`
      : '',
  金曜日:
    businessHour && businessHour.fridaySalesStartTime && businessHour.fridaySalesEndTime
      ? `${toTimeDefault(businessHour.fridaySalesStartTime)} 〜 ${toTimeDefault(businessHour.fridaySalesEndTime)}`
      : '',
  土曜日:
    businessHour && businessHour.saturdaySalesStartTime && businessHour.saturdaySalesEndTime
      ? `${toTimeDefault(businessHour.saturdaySalesStartTime)} 〜 ${toTimeDefault(businessHour.saturdaySalesEndTime)}`
      : '',
  日曜日:
    businessHour && businessHour.sundaySalesStartTime && businessHour.sundaySalesEndTime
      ? `${toTimeDefault(businessHour.sundaySalesStartTime)} 〜 ${toTimeDefault(businessHour.sundaySalesEndTime)}`
      : '',
  営業時間に関する備考: (businessHour && businessHour.remark) ?? '',
});

export const businessHourFormFetchOrganize = (businessHour: PhotoStudioBusinessHour) => ({
  salesStartTime: (businessHour && new Date(`2023/01/01 ${businessHour.salesStartTime}`)) ?? '',
  salesEndTime: (businessHour && new Date(`2023/01/01 ${businessHour.salesEndTime}`)) ?? '',
  mondaySalesStartTime: (businessHour && new Date(`2023/01/01 ${businessHour.mondaySalesStartTime}`)) ?? '',
  mondaySalesEndTime: (businessHour && new Date(`2023/01/01 ${businessHour.mondaySalesEndTime}`)) ?? '',
  tuesdaySalesStartTime: (businessHour && new Date(`2023/01/01 ${businessHour.tuesdaySalesStartTime}`)) ?? '',
  tuesdaySalesEndTime: (businessHour && new Date(`2023/01/01 ${businessHour.thursdaySalesEndTime}`)) ?? '',
  wednesdaySalesStartTime: (businessHour && new Date(`2023/01/01 ${businessHour.wednesdaySalesStartTime}`)) ?? '',
  wednesdaySalesEndTime: (businessHour && new Date(`2023/01/01 ${businessHour.wednesdaySalesEndTime}`)) ?? '',
  thursdaySalesStartTime: (businessHour && new Date(`2023/01/01 ${businessHour.thursdaySalesStartTime}`)) ?? '',
  thursdaySalesEndTime: (businessHour && new Date(`2023/01/01 ${businessHour.thursdaySalesEndTime}`)) ?? '',
  fridaySalesStartTime: (businessHour && new Date(`2023/01/01 ${businessHour.fridaySalesStartTime}`)) ?? '',
  fridaySalesEndTime: (businessHour && new Date(`2023/01/01 ${businessHour.fridaySalesEndTime}`)) ?? '',
  saturdaySalesStartTime: (businessHour && new Date(`2023/01/01 ${businessHour.saturdaySalesStartTime}`)) ?? '',
  saturdaySalesEndTime: (businessHour && new Date(`2023/01/01 ${businessHour.saturdaySalesEndTime}`)) ?? '',
  sundaySalesStartTime: (businessHour && new Date(`2023/01/01 ${businessHour.sundaySalesStartTime}`)) ?? '',
  sundaySalesEndTime: (businessHour && new Date(`2023/01/01 ${businessHour.sundaySalesEndTime}`)) ?? '',
  remark: (businessHour && businessHour.remark) ?? '',
});

export const regularHolidayFetchOrganize = (regularHoliday: PhotoStudioRegularHoliday) => ({
  月曜日: regularHoliday && regularHoliday.monday !== undefined ? HolidayToLetter(regularHoliday.monday) : '',
  火曜日: regularHoliday && regularHoliday.tuesday !== undefined ? HolidayToLetter(regularHoliday.tuesday) : '',
  水曜日: regularHoliday && regularHoliday.wednesday !== undefined ? HolidayToLetter(regularHoliday.wednesday) : '',
  木曜日: regularHoliday && regularHoliday.thursday !== undefined ? HolidayToLetter(regularHoliday.thursday) : '',
  金曜日: regularHoliday && regularHoliday.friday !== undefined ? HolidayToLetter(regularHoliday.friday) : '',
  土曜日: regularHoliday && regularHoliday.saturday !== undefined ? HolidayToLetter(regularHoliday.saturday) : '',
  日曜日: regularHoliday && regularHoliday.sunday !== undefined ? HolidayToLetter(regularHoliday.sunday) : '',
  定休日に関する備考: (regularHoliday && regularHoliday.remark) ?? '',
});

export const regularHolidayFormFetchOrganize = (regularHoliday: PhotoStudioRegularHoliday) => ({
  monday: (regularHoliday && regularHoliday.monday) ?? false,
  tuesday: (regularHoliday && regularHoliday.tuesday) ?? false,
  wednesday: (regularHoliday && regularHoliday.wednesday) ?? false,
  thursday: (regularHoliday && regularHoliday.thursday) ?? false,
  friday: (regularHoliday && regularHoliday.friday) ?? false,
  saturday: (regularHoliday && regularHoliday.saturday) ?? false,
  sunday: (regularHoliday && regularHoliday.sunday) ?? false,
  remark: (regularHoliday && regularHoliday.remark) ?? '',
});

export const formSettingListFetchOrganize = (formSettings: Array<PhotoFormUiPartsList>) =>
  formSettings.map((v: PhotoFormUiPartsList) => ({
    ...v,
    id: (v && `${v.photoReservationFormGroupId}`) ?? '',
    name: (v && v.groupName) ?? '',
    orderNumber: (v && v.orderNumber) ?? '',
    input: (v && v.inputs) ?? [],
  }));

export const formSettingFetchOrganize = (inputs: Array<PhotoFormUiPartsListInputsInner>, groupId: string) =>
  inputs.map((v: PhotoFormUiPartsListInputsInner) => ({
    id: (v && `${groupId}/${v.photoFormUiPartsId}`) ?? '',
    name: (v && v.name) ?? '',
    orderNumber: (v && v.orderNumber) ?? 'なし',
    inputType: v && v.inputType ? getInputType(v.inputType) : '',
    requiredFlg: v && v.requiredFlg ? '必須' : '任意',
    customerViewFlg: v && v.customerViewFlg ? 'する' : 'しない',
    availablePlans: v && v.availablePlans ? `${truncateString(concatenateNames(v.availablePlans), 25)}` : '',
    options: (v && v.options) ?? [],
  }));

export const formSettingFormFetchOrganize = (data: PhotoFormUiParts) => ({
  availableOptionIds: (data && data.availableOptionIds) ?? [],
  photoReservationFormGroupId: (data && data.photoReservationFormGroupId) ?? '',
  inputType: data && data.inputType ? getInputType(data.inputType) : '',
  name: (data && data.name) ?? '',
  requiredFlg: (data && data.requiredFlg) ?? false,
  customerViewFlg: (data && data.customerViewFlg) ?? false,
  placeholder: (data && data.placeholder) ?? '',
  orderNumber: (data && data.orderNumber) ?? '',
  availablePlans:
    (data &&
      data.availablePlans &&
      data.availablePlans.map((plan: PhotoFormUiPartsAvailablePlansInner) => plan.photoStudioPlanId)) ??
    '',
  options: data && data.options.map((option) => option.label ?? []),
});

export const planFetchOrganize = (plans: Array<PhotoPlan>) =>
  plans.map((v: PhotoPlan) => ({
    ...v,
    id: (v && v.photoStudioPlanId) ?? '',
    price: (v && `${formatPrice(v.price)}円`) ?? '',
    reserveMinutes: (v && `${v.reserveMinutes}分`) ?? '',
    reserveMinutesForCustomer: (v && `${v.reserveMinutesForCustomer}分`) ?? '',
    invalidFlg: v && v.invalidFlg ? 'する' : 'しない',
  }));

export const planDetailFetchOrganize = (plan: PhotoPlan) => ({
  名前: (plan && plan.name) ?? '',
  料金: `${plan && formatPrice(plan.price)}円` ?? '',
  所要時間: `${plan && plan.reserveMinutes}分` ?? '',
  お客様への表示時間: `${plan && plan.reserveMinutesForCustomer}分` ?? '',
  お客様へ表示: plan && plan.invalidFlg ? 'する' : 'しない',
  内容: (plan && plan.content) ?? '',
});

export const planFormFetchOrganize = (plan: PhotoPlan) => ({
  name: (plan && plan.name) ?? '',
  price: (plan && plan.price) ?? 0,
  reserveMinutes: (plan && plan.reserveMinutes) ?? 0,
  reserveMinutesForCustomer: (plan && plan.reserveMinutesForCustomer) ?? 0,
  invalidFlg: (plan && plan.invalidFlg) ?? true,
  content: (plan && plan.content) ?? '',
});

export const planForFormSettingFetchOrganize = (plans: Array<PhotoPlan>) =>
  plans.map((v: PhotoPlan) => ({
    value: (v && v.photoStudioPlanId) ?? '',
    label: (v && v.name) ?? '',
  }));

export const optionChoiceFetchOrganize = (plans: Array<Option>) =>
  plans.map((v) => ({
    value: (v && v.photoStudioOptionId) ?? '',
    label: (v && v.name) ?? '',
  }));

export const planCardOrganize = (plan: MarriageAgencyStaffPhotoPlan) => ({
  photoStudioPlanId: (plan && plan.photoStudioPlanId) ?? '',
  imageUrl: plan && plan.imagesPlan && plan.imagesPlan.length !== 0 ? plan.imagesPlan[0].imageUrl ?? '' : '',
  name: (plan && plan.name) ?? '',
  price: `料金：${plan && formatPrice(plan.price ?? 0)}円` ?? '',
  reserveMinutesForCustomer:
    plan && plan.reserveMinutesForCustomer ? `所要時間：${plan.reserveMinutesForCustomer}分` : '',
  content: (plan && plan.content) ?? '',
});

export const optionFetchOrganize = (options: Array<Option>) =>
  options.map((v: Option) => ({
    ...v,
    id: (v && v.photoStudioOptionId) ?? '',
    price: v && v.price ? `${formatPrice(v.price)}円` : '',
    reserveMinutes: v && v.reserveMinutes ? `${v.reserveMinutes}分` : '',
    reserveMinutesForCustomer: v && v.reserveMinutesForCustomer ? `${v.reserveMinutesForCustomer}分` : '',
    invalidFlg: v && v.invalidFlg ? 'する' : 'しない',
  }));

export const optionFormFetchOrganize = (option: Option) => ({
  name: (option && option.name) ?? '',
  price: (option && option.price) ?? 0,
  reserveMinutes: (option && option.reserveMinutes) ?? 0,
  reserveMinutesForCustomer: (option && option.reserveMinutes) ?? 0,
  invalidFlg: (option && option.invalidFlg) ?? false,
  content: (option && option.content) ?? '',
});

export const availablePlanFetchOrganize = (plans: Array<AvailablePlan>) =>
  plans
    ? plans.map((plan) => ({
        photoStudioPlanId: plan.photoStudioPlanId,
        name: `${plan.name}（${formatPrice(plan.price ?? 0)}円）`,
        availableFlg: plan.availableFlg,
      }))
    : [];

export const availableOptionFetchOrganize = (options: Array<AvailableOption>) =>
  options
    ? options.map((option) => ({
        photoStudioOptionId: option.photoStudioOptionId,
        name: `${option.name} （+${formatPrice(option.price ?? 0)}円）`,
        availableFlg: option.availableFlg,
      }))
    : [];

export const resourceFetchOrganize = (
  frame: Array<PhotoStudioReservationFrame>,
  searchFilterParams?: { [key: string]: any } | null,
  planId?: string | null,
  photoStudioIds?: string[] | null
) => {
  let f = frame;
  if (searchFilterParams && searchFilterParams.photoStudioFrameId.length > 0) {
    f = frame.filter((v: PhotoStudioReservationFrame) =>
      searchFilterParams.photoStudioFrameId.includes(v.photoStudioReservationFrameId)
    );
  }
  if (searchFilterParams && searchFilterParams.photoStudioId.length > 0) {
    f = f.filter((v: PhotoStudioReservationFrame) => searchFilterParams.photoStudioId.includes(v.photoStudioId));
  }
  if (planId && planId !== '') {
    f = f.filter((v: PhotoStudioReservationFrame) =>
      v.availablePlans.some((plan) => plan.photoStudioPlanId === planId)
    );
  }
  if (photoStudioIds && photoStudioIds[0] !== '') {
    f = f.filter((v: PhotoStudioReservationFrame) => photoStudioIds.includes(v.photoStudioId));
  }
  return f.map((v: PhotoStudioReservationFrame, index: number) => ({
    id: v.photoStudioReservationFrameId,
    title: v.name,
    eventColor: v.color,
    extendedProps: {
      index,
      photoStudioName: v.photoStudioName,
      start: v.reservationStartTime,
      end: v.reservationEndTime,
    },
  }));
};

export const frameListFetchOrganize = (frames: Array<PhotoStudioReservationFrame>) =>
  frames.map((v: PhotoStudioReservationFrame) => ({
    id: (v && v.photoStudioReservationFrameId) ?? '',
    photoStudioName: (v && v.photoStudioName) ?? '',
    name: (v && v.name) ?? '',
    validPeriod:
      v && v.validStartDate && v.validEndDate
        ? `${toStringYmdD(v.validStartDate)} 〜 ${toStringYmdD(v.validEndDate)}`
        : '',
    validDay: v
      ? formatFlgToDayOfWeek(
          v.sundayAvailableFlg,
          v.mondayAvailableFlg,
          v.tuesdayAvailableFlg,
          v.wednesdayAvailableFlg,
          v.thursdayAvailableFlg,
          v.fridayAvailableFlg,
          v.saturdayAvailableFlg
        )
      : '',
    validTime:
      v && v.reservationStartTime && v.reservationEndTime
        ? `${toTimeDefault(v.reservationStartTime)} 〜 ${toTimeDefault(v && v.reservationEndTime)}`
        : '',
    unitTimeMin: v && v.unitTimeMin ? `${v.unitTimeMin}分` : '',
    availablePlans: v && v.availablePlans ? v.availablePlans : [],
    pageViewFlg: v && v.pageViewFlg ? '表示' : '非表示',
    setStatus: v && v.availablePlans.length > 0 ? '正常' : '未設定',
  }));

export const frameDetailFetchOrganize = (frame: PhotoStudioReservationFrame) => ({
  予約枠名: (frame && frame.name) ?? '',
  店舗名: (frame && frame.photoStudioName) ?? '',
  有効期間:
    frame && frame.validStartDate && frame.validEndDate
      ? `${toStringYmdD(frame.validStartDate)} 〜 ${toStringYmdD(frame.validEndDate)}`
      : '',

  無効曜日: frame
    ? `${formatFlgToDayOfWeek(
        frame.sundayAvailableFlg,
        frame.mondayAvailableFlg,
        frame.tuesdayAvailableFlg,
        frame.wednesdayAvailableFlg,
        frame.thursdayAvailableFlg,
        frame.fridayAvailableFlg,
        frame.saturdayAvailableFlg
      )}`
    : '',
  有効時間:
    frame && frame.reservationStartTime && frame.reservationEndTime
      ? `${toTimeDefault(frame.reservationStartTime)} 〜 ${toTimeDefault(frame.reservationEndTime)}`
      : '',
  最小単位時間: frame && frame.unitTimeMin ? `${frame.unitTimeMin}分` : '',
  お客様への表示: frame && frame.pageViewFlg ? '表示' : '非表示',
  '予約優先順(小さい順から埋まる)': frame && frame.priorityOrderNumber,
});

export const frameForFormFetchOrganize = (frame: PhotoStudioReservationFrame) => {
  const { availablePlans, photoStudioReservationFrameId, photoStudioId, photoStudioName, ...rest } = frame;
  return {
    ...rest,
    photoStudio: { id: photoStudioId ?? '', label: photoStudioName ?? '' },
    reservationStartTime: (frame && new Date(`2023/01/01 ${frame.reservationStartTime}`)) ?? '',
    reservationEndTime: (frame && new Date(`2023/01/01 ${frame.reservationEndTime}`)) ?? '',
  };
};

export const irregularSettingFetchOrganize = (data: Array<PhotoReservationFrameIrregularSetting>) =>
  data.map((irregularSetting: PhotoReservationFrameIrregularSetting) => ({
    id: (irregularSetting && irregularSetting.photoStudioReservationFrameIrregularSettingId) ?? '',
    validPeriod:
      irregularSetting && irregularSetting.validStartDate && irregularSetting.validEndDate
        ? `${toStringYmdD(irregularSetting.validStartDate)} 〜 ${toStringYmdD(irregularSetting.validEndDate)}`
        : '',
    validDay: irregularSetting
      ? formatFlgToDayOfWeek(
          !irregularSetting.sundayAvailableFlg,
          !irregularSetting.mondayAvailableFlg,
          !irregularSetting.tuesdayAvailableFlg,
          !irregularSetting.wednesdayAvailableFlg,
          !irregularSetting.thursdayAvailableFlg,
          !irregularSetting.fridayAvailableFlg,
          !irregularSetting.saturdayAvailableFlg
        )
      : '',
    validTime:
      irregularSetting && irregularSetting.reservationStartTime && irregularSetting.reservationEndTime
        ? `${toTimeDefault(irregularSetting.reservationStartTime)} 〜 ${toTimeDefault(
            irregularSetting.reservationEndTime
          )}`
        : '',
    openOrCloseTag: (irregularSetting && irregularSetting.openOrCloseTag.toUpperCase()) ?? '',
  }));

export const irregularSettingFormFetchOrganize = (irregularSetting: PhotoReservationFrameIrregularSetting) => ({
  photoStudioId: (irregularSetting && irregularSetting.photoStudioId) ?? '',
  validStartDate: (irregularSetting && irregularSetting.validStartDate) ?? new Date(),
  validEndDate: (irregularSetting && irregularSetting.validEndDate) ?? new Date(),
  reservationStartTime:
    (irregularSetting && new Date(`2023/01/01 ${irregularSetting.reservationStartTime}`)) ?? new Date(),
  reservationEndTime: (irregularSetting && new Date(`2023/01/01 ${irregularSetting.reservationEndTime}`)) ?? new Date(),
  mondayAvailableFlg: irregularSetting.mondayAvailableFlg ?? false,
  tuesdayAvailableFlg: irregularSetting.tuesdayAvailableFlg ?? false,
  wednesdayAvailableFlg: irregularSetting.wednesdayAvailableFlg ?? false,
  thursdayAvailableFlg: irregularSetting.thursdayAvailableFlg ?? false,
  fridayAvailableFlg: irregularSetting.fridayAvailableFlg ?? false,
  saturdayAvailableFlg: irregularSetting.saturdayAvailableFlg ?? false,
  sundayAvailableFlg: irregularSetting.sundayAvailableFlg ?? false,
  openOrCloseTag: (irregularSetting && irregularSetting.openOrCloseTag) ?? 'close',
});

export const companyOptionFetchOrganize = (data: any): Array<OptionType> =>
  data.map((v: any) => ({
    id: v.companyId,
    label: v.companyName,
  }));

export const photoStudioOptionFetchOrganize = (data: Array<PhotoStudioStaffPhotoStudio>): Array<OptionType> =>
  data.map((v: PhotoStudioStaffPhotoStudio) => ({
    id: (v && v.photoStudioId) ?? '',
    label: (v && v.photoStudioName) ?? '',
    name: (v && v.photoStudioId) ?? '',
  }));

export const customerOptionFetchOrganize = (data: any): Array<OptionType> =>
  data.map((v: any) => ({
    label: `${v.customerFirstName} ${v.customerLastName}`,
    id: v.customerId,
  }));

export const planOptionFetchOrganize = (data: Plan[] | any) =>
  data.map((v: any) => ({
    label: v.name, // スキーマではphotoStudioPlanName
    id: v.photoStudioPlanId,
    name: v.photoStudioPlanId,
    reserveMinutes: v.reserveMinutes,
  }));

export const frameOptionFetchOrganize = (data: any) => {
  const ary = data.map((v: any) => ({
    label: v.frameName,
    id: v.reservationFrameId,
    ...v,
  }));
  ary.push({ label: 'その他', id: 'other' });
  return ary;
};

export const companyListFetchOrganize = (data: Company[]) =>
  data.map((v: Company) => ({
    ...v,
    id: v.companyId,
    name: v.companyName,
    telno: phoneNumberAddHyphen(v.telno),
  }));

export const customerListFetchOrganize = (data: Customer[]): CustomerList[] =>
  data.map((v: Customer) => ({
    ...v,
    id: v.customerId,
    name: `${v.customerFirstName} ${v.customerLastName}`,
    telno: phoneNumberAddHyphen(v.telno),
  }));

export const accountListFetchOrganize = (data: Array<PhotoStudioStaff>) =>
  data.map((v: PhotoStudioStaff) => ({
    ...v,
    id: (v && v.photoStudioStaffId) ?? '',
    name: `${(v && v.photoStudioStaffFirstName) ?? ''} ${(v && v.photoStudioStaffLastName) ?? ''}`,
    telno: (v && phoneNumberAddHyphen(v.telno)) ?? '',
  }));

export const mailSettingFetchOrganize = (data: Array<PhotoReservationAutoMailSetting>) =>
  data.map((v: PhotoReservationAutoMailSetting) => ({
    ...v,
    id: (v && v.photoReservationAutoMailSettingId) ?? '',
  }));

export const accountDetailFetchOrganize = (data: PhotoStudioStaff) => ({
  氏名: `${(data && data.photoStudioStaffFirstName) ?? ''} ${(data && data.photoStudioStaffLastName) ?? ''}`,
  '氏名(かな)': `${(data && data.photoStudioStaffFirstNameKana) ?? ''} ${
    (data && data.photoStudioStaffLastNameKana) ?? ''
  }`,
  電話番号: (data && phoneNumberAddHyphen(data.telno)) ?? '',
  メールアドレス: (data && data.email) ?? '',
  権限: (data && data.role) === 'admin' ? '管理者' : '一般',
});

export const accountForFormFetchOrganize = (data: PhotoStudioStaff) => ({
  photoStudioStaffFirstName: (data && data.photoStudioStaffFirstName) ?? '',
  photoStudioStaffLastName: (data && data.photoStudioStaffLastName) ?? '',
  photoStudioStaffFirstNameKana: (data && data.photoStudioStaffFirstNameKana) ?? '',
  photoStudioStaffLastNameKana: (data && data.photoStudioStaffLastNameKana) ?? '',
  email: (data && data.email) ?? '',
  telno: (data && data.telno) ?? '',
  role: (data && data.role) ?? '',
});

export const mailSettingFetchForFormOrganize = (data: PhotoReservationAutoMailSetting) => ({
  ...data,
  type: { name: data.type, label: data.type === 'reserve_confirm' ? '予約確定' : data.type },
  plan: { name: data.photoStudioPlanId, label: data.photoStudioPlanName },
  photoStudio: { name: data.photoStudioId, label: data.photoStudioName },
});

export const outputFetchOrganize = (reserveDetail: Array<ReserveDetail>, formInput: PhotoFormUiPartsList[]) => {
  const header = new Map();
  // formInputのnameをkeyにして、headerを作成
  formInput.forEach((v) => {
    v.inputs &&
      v.inputs.forEach((input) => {
        header.set(input.name, null);
      });
  });

  const listValues = reserveDetail.map((v) => {
    const formData: { [key: string]: string } = {};
    v.form.forEach((group) => {
      group.inputs &&
        group.inputs.forEach((input) => {
          header.set(input.name, null);
          formData[input.name] = input.value.toString();
        });
    });
    return {
      createdAt: toDateTimeDefault(v.reserve.createdAt),
      reservationStartDateTime: `${(v.reserve && toDateTimeDefault(v.reserve.reservationStartDateTime)) ?? ''} 〜 ${
        (v.reserve && toTimeDefault(toApiTimeFormat(v.reserve.reservationEndDateTime))) ?? ''
      }`,
      id: (v.reserve && v.reserve.photoStudioReserveId) ?? '',
      reservationFrameName: (v.reserve && v.reserve.photoStudioFrameName) ?? '',
      customerName: `${(v.reserve && v.reserve.customerFirstName) ?? ''} ${
        (v.reserve && v.reserve.customerLastName) ?? ''
      }`,
      customerNameKana: `${(v.reserve && v.reserve.customerFirstNameKana) ?? ''} ${
        (v.reserve && v.reserve.customerLastNameKana) ?? ''
      }`,
      customerGender: (v.reserve && getG(v.reserve.customerGender)) ?? '',
      customerTelno: (v.reserve && v.reserve.customerTelno) ?? '',
      customerEmail: (v.reserve && v.reserve.customerEmail) ?? '',
      zip: (v.reserve && v.reserve.zip) ?? '',
      prefecture: (v.reserve && v.reserve.prefecture) ?? '',
      city: (v.reserve && v.reserve.city) ?? '',
      street: (v.reserve && v.reserve.street) ?? '',
      photoStudioName: (v.reserve && v.reserve.photoStudioName) ?? '',
      planName: (v.reserve && v.reserve.photoStudioPlanName) ?? '',
      optionName:
        (v.reserve && v.reserve.photoStudioOptions && v.reserve.photoStudioOptions.map((o) => o.name).toString()) ?? '',
      ...formData,
    };
  });
  return { header: Object.fromEntries(header.entries()), listValues };
};

const getG = (gender: any) => {
  if (!gender) return '';
  if (gender === 'man') return '男性';
  if (gender === 'woman') return '女性';
  return '';
};

export const reserveListFetchOrganize = (reserveDetail: Array<ReserveDetail>, viewItem: string[] | undefined) => {
  const header: { [key: string]: null } = {};

  const listValues = reserveDetail.map((v) => {
    const formData: { [key: string]: string } = {};
    v.form.forEach((group) => {
      group.inputs &&
        group.inputs.forEach((input) => {
          header[input.name] = null;
          formData[input.name] = input.value.toString();
        });
    });
    return {
      reservationStartDateTime: `${(v.reserve && toDateTimeDefault(v.reserve.reservationStartDateTime)) ?? ''} 〜 ${
        (v.reserve && toTimeDefault(toApiTimeFormat(v.reserve.reservationEndDateTime))) ?? ''
      }`,
      id: (v.reserve && v.reserve.photoStudioReserveId) ?? '',
      reservationFrameName: (v.reserve && v.reserve.photoStudioFrameName) ?? '',
      customerName: `${(v.reserve && v.reserve.customerFirstName) ?? ''} ${
        (v.reserve && v.reserve.customerLastName) ?? ''
      }`,
      photoStudioName: (v.reserve && v.reserve.photoStudioName) ?? '',
      planName: (v.reserve && v.reserve.photoStudioPlanName) ?? '',
      ...formData,
      createdAt: (v.reserve && toDateTimeDefault(v.reserve.createdAt)) ?? '',
    };
  });

  // 項目-表示・非表示の設定、前は[header, listValues]だった
  const filteredHeader: { [key: string]: null } = {};
  Object.keys(header).forEach((key) => {
    if (viewItem && viewItem.includes(key)) {
      filteredHeader[key] = header[key];
    }
  });

  return [filteredHeader, listValues];
};

export const reservationFetchOrganize = (reservation: Array<Reserve>) =>
  reservation.map((v: Reserve) => ({
    reservationDateTime: `${v.reservationStartDateTime && toDateTimeDefault(v.reservationStartDateTime)} 〜 ${
      v.reservationEndDateTime && toTimeDefault(toApiTimeFormat(v.reservationEndDateTime))
    }`,
    reservationFrameName: (v.photoStudioFrameName && v.photoStudioFrameName) ?? '',
    customerName: `${(v.customerFirstName && v.customerFirstName) ?? ''} ${
      (v.customerLastName && v.customerLastName) ?? ''
    }`,
    photoStudioName: (v.photoStudioName && v.photoStudioName) ?? '',
    planName: (v.photoStudioPlanName && v.photoStudioPlanName) ?? '',
  }));

export const scheduleForCalendarFetchOrganize = (values: Array<ReserveDetail>, rule: Array<Rule>) =>
  values.flatMap((v: ReserveDetail) => {
    const gender = (gender: any) => {
      let result;
      switch (gender) {
        case 'man':
          result = '男性';
          break;
        case 'woman':
          result = '女性';
          break;
        default:
          result = 'その他';
          break;
      }
      return result;
    };

    // 与えられたrule配列から"ruleType": "status_color_change"の要素だけを抽出
    const statusColorChangeRules = rule.filter((r) => r.ruleType === 'status_color_change');
    // ステータスに合致するルールを探す
    const statusValue = v.form
      .filter((f) => f.groupName === 'ステータス')[0]
      ?.inputs?.filter((i) => i.name === 'ステータス')[0]?.value[0];
    const statusRule = statusColorChangeRules.filter((r) => r.conditionKey === 'ステータス');
    const matchedRule = statusRule?.find((obj) => obj.conditionValue === statusValue);

    const color = matchedRule ? matchedRule.actionValue : null;
    if (statusValue === 'キャンセル') return []; // キャンセル弾く

    return [
      {
        photoStudioReserveId: v.reserve.photoStudioReserveId ?? '',
        title: `${v.reserve.customerFirstName ?? ''} ${v.reserve.customerLastName ?? ''}`,
        start: toIsoString(v.reserve.reservationStartDateTime ?? ''),
        end: toIsoString(v.reserve.reservationEndDateTime ?? ''),
        resourceIds: [v.reserve.photoStudioFrameId ?? ''],
        ...(color ? { color } : {}),
        extendedProps: {
          id: v.reserve.photoStudioReserveId ?? '',
          customerNameKana: `${v.reserve.customerFirstNameKana ?? ''} ${v.reserve.customerLastNameKana ?? ''}`,
          photoStudioFrameName: v.reserve.photoStudioFrameName,
          photoStudioFrameId: v.reserve.photoStudioFrameId,
          gender: gender(v.reserve.customerGender),
          plan: v.reserve.photoStudioPlanName,
          staffRemark: v.form
            .filter((v) => v.groupName === '注意事項')[0]
            ?.inputs?.filter((v) => v.name === 'スタッフ備考')[0]?.value,
          photoStudioId: v.reserve.photoStudioId,
          photoStudioName: v.reserve.photoStudioName,
          time: `${formatDateToTimeSimple(v.reserve.reservationStartDateTime ?? '')}`,
          status: v.form
            .filter((v) => v.groupName === 'ステータス')[0]
            ?.inputs?.filter((v) => v.name === 'ステータス')[0]?.value,
          // time: `${formatDateToTime(v.reserve.reservationStartDateTime ?? '')} 〜 ${formatDateToTime(
          //   v.reserve.reservationEndDateTime ?? ''
          // )}`,
        },
      },
    ];
  });

export const scheduleFetchOrganize = (reserveDetail: Array<ReserveDetail>) =>
  reserveDetail.map((v: ReserveDetail) => ({
    photoStudioReserveId: (v.reserve && v.reserve.photoStudioReserveId) ?? '',
    title: `${(v.reserve && v.reserve.customerFirstName) ?? ''} ${(v.reserve && v.reserve.customerLastName) ?? ''}`,
    start: toIsoString((v.reserve && v.reserve.reservationStartDateTime) ?? ''),
    end: toIsoString((v.reserve && v.reserve.reservationEndDateTime) ?? ''),
    resourceIds: [(v.reserve && v.reserve.photoStudioFrameId) ?? ''],
    extendedProps: {
      id: (v.reserve && v.reserve.photoStudioReserveId) ?? '',
      time: `${formatDateToTime((v.reserve && v.reserve.reservationStartDateTime) ?? '')} 〜 ${formatDateToTime(
        (v.reserve && v.reserve.reservationEndDateTime) ?? ''
      )}`,
      plan: (v.reserve && v.reserve.photoStudioPlanName) ?? '',
      remark: (v.reserve && v.reserve.remark) ?? '',
    },
    color: '#008080',
  }));

export const selectedOptionFetchOrganize = (data: Array<MarriageAgencyStaffPhotoOption>) =>
  data.map((v: MarriageAgencyStaffPhotoOption) => v.photoStudioOptionId);

export const selectedOptionValueOrganize = (data: Array<MarriageAgencyStaffPhotoOption>) =>
  data.map((v: MarriageAgencyStaffPhotoOption) => ({
    photoStudioOptionId: v.photoStudioOptionId,
  }));

export const photoStudioCalendarFetchOrganize = (data: Array<ReserveForCalendarInner>) =>
  data.map((v: ReserveForCalendarInner) => ({
    title: (v.startDatetime && `${toTimeDefault(toApiTimeFormat(v.startDatetime))}`) ?? '',
    start: v.startDatetime,
    end: v.endDatetime,
    extendedProps: {
      stock: v.stock,
      rest: v.stock !== 0 ? `残り${v.stock}枠` : `空きなし`,
      content: v.stock === 0 ? `×` : `◯`,
    },
    color: v.stock === 0 ? '#CFD6DD' : '#6096B4',
  }));
// この上のやつがcustomer
export const photoStudioCalendarAvailabilityFetchOrganize = (data: ReserveForCalendarAvailabilityInner[]) =>
  data.map((v: ReserveForCalendarAvailabilityInner) => {
    const futureTime = new Date(new Date().getTime() + OFFSET_MIN * 60000); // 60分 = 60 * 60,000ミリ秒
    if (futureTime > getDateObj(v.startDatetime!)) {
      v.stock = 0;
    }
    return {
      title: (v.startDatetime && `${toTimeDefault(toApiTimeFormat(v.startDatetime.replace(/-/g, '/')))}`) ?? '',
      start: v.startDatetime,
      end: v.endDatetime,
      resourceIds: [v.photoStudioFrameId ?? ''],
      extendedProps: {
        stock: v.stock,
        content: v.stock === 0 ? `×` : `残り${v.stock}`,
        photoStudioFrameId: v.photoStudioFrameId,
      },
      color: v.stock === 0 ? '#CFD6DD' : '#6096B4',
    };
  });

export const staffListFetchOrganize = (data: Array<MarriageAgencyStaff>) =>
  data.map((v: MarriageAgencyStaff) => ({
    ...v,
    id: v.marriageAgencyStaffId,
    name: `${v.firstName} ${v.lastName}`,
    telno: phoneNumberAddHyphen(v.telno),
  }));

export const staffDetailFetchOrganize = (data: MarriageAgencyStaff) => ({
  氏名: `${(data && data.firstName) ?? ''} ${(data && data.lastName) ?? ''}`,
  '氏名(かな)': `${(data && data.firstNameKana) ?? ''} ${(data && data.lastNameKana) ?? ''}`,
  電話番号: (data && phoneNumberAddHyphen(data.telno)) ?? '',
  メールアドレス: (data && data.email) ?? '',
  権限: data.role && data.role === 'admin' ? '管理者' : '一般',
});

export const staffForFormFetchOrganize = (data: MarriageAgencyStaff) => ({
  firstName: (data && data.firstName) ?? '',
  lastName: (data && data.lastName) ?? '',
  firstNameKana: (data && data.firstNameKana) ?? '',
  lastNameKana: (data && data.lastNameKana) ?? '',
  email: (data && data.email) ?? '',
  telno: (data && data.telno) ?? '',
  team: (data && data.team) ?? [],
  role: (data && data.role) ?? '',
});

export const teamDetailFetchOrganize = (data: TeamResponse) => ({
  氏名: (data && data.name) ?? '',
  メールアドレス: (data && data.email) ?? '',
});

export const planListFetchOrganize = (data: Plan[]) =>
  data.map((v: Plan) => ({
    ...v,
    id: v.photoStudioPlanId,
    name: v.photoStudioPlanName,
    invalidFlg: v.invalidFlg ? 'する' : 'しない',
  }));

export const reservationFrameFetchOrganize = (data: ReservationFrame[]) =>
  data.map((v: ReservationFrame) => ({
    ...v,
    id: v.reservationFrameId,
  }));

export const resourceListFetchOrganize = (data: any) => {
  const output: {
    id: string;
    title: string;
  }[] = [];
  data.forEach((frame: any) => {
    output.push({
      id: frame.reservationFrameId,
      title: frame.frameName,
    });
  });

  return output;
};

export const companyDetailFetchOrganize = (i: { [key: string]: string | null }) => ({
  会社名: (i && i.companyName) ?? '',
  '会社名(かな)': (i && i.companyNameKana) ?? '',
  郵便番号: (i && zipNumberAddHyphen(i.zip ?? '')) ?? '',
  住所: `${(i && i.prefecture) ?? ''} ${(i && i.city) ?? ''} ${(i && i.street) ?? ''}`,
  メールアドレス: (i && i.email) ?? '',
  電話番号: (i && phoneNumberAddHyphen(i.telno ?? '')) ?? '',
});

export const copyObj = (i: any) => {
  const copiedObj = { ...JSON.parse(JSON.stringify(i)) };
  return copiedObj;
};

export const imageFetchOrganize = (images: Array<PhotoStudioPlanImage>) =>
  images.map((image) => ({
    ...image,
    imageUrl: image.imageUrl,
    newImageUrl: `${image.imageUrl}?${new Date()}`,
  }));

export const customerDetailFetchOrganize = (i: { [key: string]: string }): { [key: string]: string } => ({
  氏名: `${(i && i.customerFirstName) ?? ''} ${(i && i.customerLastName) ?? ''} `,
  '氏名(かな)': `${(i && i.customerFirstNameKana) ?? ''} ${(i && i.customerLastNameKana) ?? ''} `,
  メールアドレス: (i && i.email) ?? '',
  電話番号: (i && phoneNumberAddHyphen(i.telno)) ?? '',
});

export const nextPageCheckOrganize = (data: any) => data.length !== 0;
export const flagToOnOff = (i: boolean): string => (i ? 'ON' : 'OFF');
export const flagToLetter = (i: boolean): string => (i ? 'あり' : 'なし');
export const openOrCloseToLetter = (i: boolean): string => (i ? 'OPEN' : 'CLOSE');
export const HolidayToLetter = (i: boolean): string => (!i ? '営業日' : '定休日');
export const contactEmailSplit = (i: string): { [key: string]: string } => {
  const ce = lastLetterSplitSemicolon(i);
  const test: any = {};
  if (ce && ce !== '') {
    ce.split(';').forEach((v: any, i: any) => {
      test[`連絡用メールアドレス${String.fromCharCode('①'.charCodeAt(0) + i)}`] = v;
    });
  }
  return test;
};

function elapsedTime(start: string, end: string): string {
  const elapsedMinutes = (new Date(end).getTime() - new Date(start).getTime()) / 60000;

  return `${new Date(end).toLocaleTimeString([], {
    hour: '2-digit',
    minute: '2-digit',
    hour12: false,
  })}(${elapsedMinutes}分)`;
}
