import { Container, Grid } from '@mui/material';
import { FC, useState } from 'react';
import { useParams } from 'react-router-dom';
import useFetch from 'src/hooks/use-fetch';
import { MarriageAgencyStaffPhotoOption, MarriageAgencyStaffPhotoPlan } from 'codegen/axios/photo/adca/api';
import { OptionForm } from 'src/components/organisms/reservation-for-adca/form/option';
import { CalTable } from 'src/components/organisms/reservation-for-adca/form/table';
import { ReservableSchedule } from 'src/components/organisms/reservation-for-adca/form/schedule';

export const ReservationForAdcaForm: FC = () => {
  /* hooks */
  const { planId: photoStudioPlanId } = useParams();
  const [selectedOptions, setSelectedOptions] = useState<Array<MarriageAgencyStaffPhotoOption>>([]);

  /* データ取得 */
  const { fetchedData: plan } = useFetch<MarriageAgencyStaffPhotoPlan>({
    iniState: [],
    reqProp: {
      pathKey: 'plan',
      pathParam: photoStudioPlanId,
    },
  });

  return (
    <Container>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12}>
          <CalTable plan={plan} selectedOptions={selectedOptions} />
        </Grid>
        <Grid item xs={12} sm={12}>
          <OptionForm
            options={plan.availableOptions ?? []}
            selectedOptions={selectedOptions}
            setSelectedOptions={setSelectedOptions}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <ReservableSchedule plan={plan} selectedOptions={selectedOptions} />
        </Grid>
      </Grid>
    </Container>
  );
};
