import Page from 'src/components/molecules/page';
import { Container } from '@mui/material';
import { FC } from 'react';

import { CustomerReservationCheck } from 'src/components/organisms/customer-reservation/check';

export const CustomerReservationCheckPage: FC = () => {
  /* 定数宣言 */
  const title = '確認';

  return (
    <Page title={title}>
      <Container maxWidth={false}>
        <CustomerReservationCheck />
      </Container>
    </Page>
  );
};
