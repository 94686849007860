// material
import { Paper, Typography } from '@mui/material';

export default function SearchNotFound({ searchQuery = '', overrideMsg, ...other }: any) {
  return (
    <Paper {...other}>
      <Typography gutterBottom align="center" variant="subtitle1">
        Not found
      </Typography>
      {overrideMsg || (
        <Typography variant="body2" align="center">
          {searchQuery !== '' && <strong>&quot;{searchQuery}&quot;</strong>}
          この検索条件での結果は見つかりませんでした。 条件を設定し直してください
        </Typography>
      )}
    </Paper>
  );
}
