export const truncateString = (str: string, count: number) => {
  if (str.length <= count) {
    return str;
  }
  return `${str.slice(0, count)}...`;
};

export const zipNumberAddHyphen = (zip: string) => {
  if (!zip) return zip;
  const z = zip.toString();
  if (!z.match(/\d{7}/)) return z;
  return `${z.slice(0, 3)}-${z.slice(3, z.length)}`;
};
export const zipDropHyphen = (value: any) =>
  value.replace(/-/g, '').replace(/[０-９]/g, (s: any) => String.fromCharCode(s.charCodeAt(0) - 0xfee0));

export const lastLetterSplitSemicolon = (v: any) => (v && v.slice(-1).match(/;/) !== null ? v.slice(0, -1) : v);

export const emptyStrBlankKey = (formValue: any, key: any) => {
  (formValue[key] === undefined || formValue[key] === '' || formValue[key] === null || formValue[key] === false) &&
    delete formValue[key];
  return formValue;
};

export const formatDate = (date: Date | string): string => {
  if (date === '') return '';
  let newDate = date;
  if (typeof newDate === 'string') {
    newDate = new Date(date);
  }
  const year = newDate.getFullYear();
  const month = newDate.getMonth() + 1;
  const day = newDate.getDate();
  const hours = newDate.getHours();
  const minutes = newDate.getMinutes();
  const weekDay = ['日', '月', '火', '水', '木', '金', '土'];
  const wd = weekDay[newDate.getDay()];

  return `${year}年${month}月${day}日(${wd})${hours}時${zeroPadding(minutes)}分`;
};

export const formatDateToTime = (date: Date | string): string => {
  if (date === '') return '';
  let newDate = date;
  if (typeof newDate === 'string') {
    newDate = new Date(date);
  }
  const hours = newDate.getHours();
  const minutes = newDate.getMinutes();
  return `${hours}時${zeroPadding(minutes)}分`;
};

export const formatDateToTimeSimple = (date: Date | string): string => {
  if (date === '') return '';
  let newDate = date;
  if (typeof newDate === 'string') {
    newDate = new Date(date);
  }
  const hours = newDate.getHours();
  const minutes = newDate.getMinutes();
  return `${hours}:${zeroPadding(minutes)}`;
};

export const zeroPadding = (num: number): string => {
  if (num < 10) {
    return `0${num}`;
  }
  return String(num);
};

export const convertTimeFormat = (timeString?: string) => (timeString ? timeString.substring(0, 5) : '');

export const dateTimeUniter = (date: Date, time: Date): string => {
  const year = `${date.getFullYear()}`;
  const month = `${(date.getMonth() + 1).toString().padStart(2, '0')}`;
  const day = `${date.getDate().toString().padStart(2, '0')}`;
  const hour = `${time.getHours().toString().padStart(2, '0')}`;
  const minutes = `${time.getMinutes().toString().padStart(2, '0')}`;
  return `${year}/${month}/${day} ${hour}:${minutes}:00`;
};

export const calStayTime = (startDateTime: string, endDateTime: string): string =>
  Math.abs(Math.floor((new Date(endDateTime).getTime() - new Date(startDateTime).getTime()) / 1000 / 60)).toString();

export const formatPrice = (num?: number): string => (num ? num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '0');

export const concatenateNames = (arr: Array<any>): string => arr.map((item) => item.name).join(', ');

export const getInputType = (input: string): string => {
  switch (input) {
    case 'textbox':
      return 'テキストボックス';
    case 'textarea':
      return 'テキストエリア';
    case 'selectbox':
      return 'セレクトボックス';
    case 'radiobutton':
      return 'ラジオボタン';
    case 'checkbox':
      return 'チェックボックス';
    default:
      return '対応する入力タイプがありません';
  }
};

export const returnInputType = (input: string): string => {
  switch (input) {
    case 'テキストボックス':
      return 'textbox';
    case 'テキストエリア':
      return 'textarea';
    case 'セレクトボックス':
      return 'selectbox';
    case 'ラジオボタン':
      return 'radiobutton';
    case 'チェックボックス':
      return 'checkbox';
    default:
      return '';
  }
};
