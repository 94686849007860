import { useSearchParams, useNavigate } from 'react-router-dom';
// components
import Page from 'src/components/molecules/page';
import CustomerIndexTemplate from 'src/components/templates/customer/customer-index-template';
import { customerListFetchOrganize, nextPageCheckOrganize } from 'src/functions/fetch-value-organize';
// hooks
import useFetch from 'src/hooks/use-fetch';
// f
import { createQueryParameter } from 'src/functions/api-parameter';
import { createParams } from 'src/functions/params';
// staff
import useStaffInformation from 'src/hooks/use-staff-information';
import { Customer } from 'codegen/axios/photo/admin/api';

export default function List() {
  /* hook宣言 */
  const staff = useStaffInformation();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  /* 定数宣言 */
  const rowsPerPage = 30;
  const page = Number(searchParams.get('page')) ?? 0;
  const searchName = searchParams.get('searchName') ?? null;
  const query = createQueryParameter({
    searchName: { key: 'searchName', value: searchName },
  });

  /* データ取得 */
  const { fetchedData: responseData } = useFetch<Customer[]>({
    reqProp: {
      pathKey: 'customer',
      queryParams: {
        offset: page * rowsPerPage,
        limit: rowsPerPage,
        ...query,
      },
    },
    observable: [searchParams],
  });
  // 次のページがあるかどうか
  const { fetchedData: nextPageCheck } = useFetch<boolean>({
    reqProp: {
      pathKey: 'customer',
      queryParams: {
        offset: (page + 1) * rowsPerPage,
        limit: 2,
        ...query,
      },
    },
    observable: [searchParams],
  });

  /* handle */
  const handleChangePage = (_: any, newPage: any) => {
    setSearchParams(createParams({ keyValues: { page: newPage }, searchParams }));
  };
  const handleSearchByName = (value: any) => {
    setSearchParams(createParams({ keyValues: { searchName: value }, searchParams, page: 0 }));
  };
  const handleRowClick = (id: any) => {
    navigate(`/${staff.userType}/customers/${id}`);
  };

  return (
    <Page>
      <CustomerIndexTemplate
        {...(() => ({
          nextPageCheck: nextPageCheckOrganize(nextPageCheck),
          page,
          rowsPerPage,
          searchName,
          responseData: customerListFetchOrganize(responseData),
          isUserNotFound: responseData.length === 0,
          handleSearchByName,
          handleRowClick,
          handleChangePage,
        }))()}
      />
    </Page>
  );
}
