import { useState } from 'react';
// @mui
import { InputAdornment, IconButton, TextField } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
// components
import Iconify from 'src/components/atoms/Iconify';

export default function SearchInput({ onChange, placeholder, defaultValue, ...other }: any) {
  const [value, setValue] = useState(defaultValue ?? '');
  const handleChange = (newValue: any) => {
    if (newValue !== null) {
      setValue(newValue.target.value);
      onChange(newValue.target.value);
    } else {
      setValue('');
      onChange('');
    }
  };
  return (
    <>
      <TextField
        value={value}
        placeholder={placeholder}
        onChange={handleChange}
        {...other}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled', width: 20, height: 20 }} />
            </InputAdornment>
          ),
          endAdornment: (
            <IconButton
              sx={{ visibility: value ? 'visible' : 'hidden' }}
              onClick={() => {
                handleChange(null);
              }}
              disabled={!value}
              style={{ order: 1 }}
            >
              <ClearIcon color="disabled" fontSize="small" />
            </IconButton>
          ),
        }}
      />
      {/* <SearchStyle
        sx={{ width: 300 }}
        onChange={handleChange}
        placeholder={placeholder}
        defaultValue={value}
        startAdornment={
          <InputAdornment position="start">
            <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled', width: 20, height: 20 }} />
          </InputAdornment>
        }
        endAdornment={
          <IconButton sx={{ visibility: value ? 'visible' : 'hidden' }} onClick={handleChange(null)}>
            <ClearIcon />
          </IconButton>
        }
      /> */}
    </>
  );
}
