import { FC, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Stack, Card, CardHeader, Grid, Box } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { FormProvider, RHFTextField, RHFSelectBox, RHFRadioButtonGroup } from 'src/components/atoms/hook-form';
import { optionValidationSchema } from 'src/functions/validation-schema';
import useFetch from 'src/hooks/use-fetch';
import { Option } from 'codegen/axios/photo/photo_studio';
import { useParams } from 'react-router-dom';
import usePush from 'src/hooks/use-push';
import DeleteModal from 'src/components/molecules/delete-modal';
import { optionFormFetchOrganize } from 'src/functions/fetch-value-organize';
import { minutesOption } from 'src/utils/option';

export type OptionFormType = Omit<Option, 'photoStudioOptionId'>;

export const defaultValues: OptionFormType = {
  name: '',
  price: 0,
  reserveMinutes: 0,
  reserveMinutesForCustomer: 0,
  invalidFlg: true,
  content: '',
};

export const OptionForm: FC = () => {
  /* hook宣言 */
  const push = usePush();
  const { id: optionId } = useParams();

  /* 定数宣言 */
  const editMode = !optionId;
  const validationSchema = optionValidationSchema();

  /* データ取得 */
  const { fetchedData: responseData } = useFetch<OptionFormType>({
    iniState: defaultValues,
    reqProp: {
      pathKey: 'option',
      pathParam: optionId,
    },
    disable: editMode,
    organize: optionFormFetchOrganize,
  });

  const methods = useForm<OptionFormType>({
    resolver: yupResolver(validationSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  useEffect(() => {
    methods.reset(responseData);
  }, [responseData, methods]);

  const onSubmit = (formValue: OptionFormType) =>
    new Promise((resolve) => {
      push(resolve, {
        reqProp: {
          pathKey: 'option',
          pathParam: optionId,
          method: editMode ? 'post' : 'put',
          data: formValue,
        },
        aftProp: {
          resolve,
          nextPath: `/setting/option`,
          showMessage: {
            state: {
              snackBar: { open: true, viewType: 'success', message: editMode ? '登録しました' : '保存しました' },
            },
          },
        },
      });
    });
  const onDelete = () => {
    new Promise((resolve) =>
      push(resolve, {
        reqProp: {
          pathKey: 'option',
          method: 'delete',
          pathParam: optionId,
        },
        aftProp: {
          resolve,
          nextPath: `/setting/option`,
          showMessage: {
            state: { snackBar: { open: true, viewType: 'info', message: '削除しました' } },
          },
        },
      })
    );
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      {!editMode && (
        <Stack direction="row" alignItems="center" justifyContent="start" mb={2} ml={1} mt={'3%'} mr={0}>
          <Box sx={{ ml: 'auto' }}>
            <DeleteModal
              sx={{
                bgcolor: 'background.paper',
                minWidth: 50,
                minHeight: 47,
                boxShadow: 1,
                borderRadius: 2,
                ml: 2,
              }}
              OnDelete={onDelete}
            />
          </Box>
        </Stack>
      )}
      <Card>
        <CardHeader title="基本情報" />

        <Stack my={7} mx={4}>
          <Grid container rowSpacing={3} columnSpacing={3}>
            <Grid item pt={4} xs={6} sm={3} md={9}>
              <RHFTextField name="name" label="オプション名" placeholder="例：ヘアアレンジ変更希望" />
            </Grid>
            <Grid item pt={4} xs={3} sm={3} md={3}>
              <RHFTextField name="price" label="料金" placeholder="例：3000" />
            </Grid>

            <Grid item pt={4} xs={6} sm={3} md={3}>
              <RHFSelectBox name="reserveMinutes" label="所要時間（分）" option={minutesOption} />
            </Grid>

            <Grid item pt={4} xs={6} sm={3} md={3}>
              <RHFSelectBox name="reserveMinutesForCustomer" label="お客様への表示時間（分）" option={minutesOption} />
            </Grid>
            <Grid item pl={8} xs={12} sm={12} md={6}>
              <RHFRadioButtonGroup
                label="お客様へ表示"
                name="invalidFlg"
                options={[
                  {
                    value: true,
                    label: 'する',
                  },
                  {
                    value: false,
                    label: 'しない',
                  },
                ]}
              />
            </Grid>
            <Grid item pt={4} xs={6} sm={3} md={12}>
              <RHFTextField
                multiline
                name="content"
                label="内容"
                placeholder={`例：\n・専属のヘアスタイリストによるヘアアレンジ変更\n・変更可能なヘアアレンジは、ヘアアップスタイル、ハーフアップスタイル、カール、ストレートなど\n・ヘアアレンジの変更希望は事前に予約していただく必要があります`}
              />
            </Grid>
          </Grid>
        </Stack>
      </Card>

      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }} />

      <Stack direction="row" alignItems="center" justifyContent="flex-end" sx={{ m: 1 }} spacing={8}>
        <LoadingButton sx={{ width: 200 }} size="large" type="submit" variant="contained" loading={isSubmitting}>
          {editMode ? '登録' : '保存'}
        </LoadingButton>
      </Stack>
    </FormProvider>
  );
};
