import { useLocation } from 'react-router-dom';
import { useCookies } from 'react-cookie';
// @mui
import { styled } from '@mui/material/styles';
import { Card, Container, Typography } from '@mui/material';
// hooks
import useResponsive from 'src/hooks/use-responsive';
// components
import Page from 'src/components/molecules/page';
import Logo from 'src/components/molecules/logo';
// sections
import TwoFactorAuthForm from 'src/components/organisms/auth/two-factor-auth-form';
// f
import usePush from 'src/hooks/use-push';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const HeaderStyle = styled('header')(({ theme }) => ({
  top: 0,
  zIndex: 9,
  lineHeight: 0,
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  position: 'absolute',
  padding: theme.spacing(3),
  justifyContent: 'space-between',
  [theme.breakpoints.up('md')]: {
    alignItems: 'flex-start',
    padding: theme.spacing(7, 5, 0, 7),
  },
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 2),
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function TwoFactorAuth() {
  const [, ,] = useCookies(['loginValue']);
  const location = useLocation();
  const { pathname } = useLocation();
  const mdUp = useResponsive('up', 'md', null, null);
  const push = usePush();

  const defaultValues = {
    cord: '',
  };

  const switchPath = (staff: string) => {
    switch (staff) {
      case 'photoStudioStaff':
        return '/schedule';
      case 'marriageAgencyStaff':
        return '/list';
      default:
        return '';
    }
  };

  const LoginOnSubmit = (formValue: any) =>
    new Promise((resolve) => {
      push(resolve, {
        reqProp: {
          pathKey:
            pathname.split('/')[1] !== 'marriageAgencyStaff' ? 'twoFactorAuth' : 'twoFactorAuthForMarriageAgencyStaff',
          method: 'post',
          data: { oneTimePass: formValue.cord },
        },
        aftProp: {
          resolve,
          nextPath:
            location.state &&
            (location as any).state.loginFrom &&
            (location as any).state.loginFrom !== '/login/twoFactorAuth' &&
            (location as any).state.loginFrom
              ? (location as any).state.loginFrom
              : switchPath(pathname.split('/')[1]),
          showMessage: {
            state: { snackBar: { open: true, viewType: 'success', message: 'ログインしました' } },
          },
        },
      });
    });

  return (
    <Page title="Login">
      <RootStyle>
        <HeaderStyle>{mdUp && <Logo />}</HeaderStyle>

        {mdUp && (
          <SectionStyle>
            <Typography variant="h3" sx={{ px: 5, mt: 0, mb: 5 }}>
              Sent Mail
            </Typography>

            <img src="/static/illustrations/undraw_mail_sent_re_0ofv.svg" alt="login" />
          </SectionStyle>
        )}

        <Container maxWidth="sm">
          <ContentStyle>
            <Typography variant="h4" gutterBottom>
              2段階認証
            </Typography>

            <Typography sx={{ color: 'text.secondary', mb: 5 }}>
              メールアドレスに届いた6桁のコードを入力してください
            </Typography>

            <TwoFactorAuthForm defaultValues={defaultValues} LoginOnSubmit={() => LoginOnSubmit} />
          </ContentStyle>
        </Container>
      </RootStyle>
    </Page>
  );
}
