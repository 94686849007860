// カンパニーID
const SELFIT_COMPANY_ID = '216d2c68-6021-45ea-a9b1-e092cbb37acd'; // 株式会社セルフィット

// スタジオID
const OSAKA_UMEDA_STUDIO_ID = '06df9741-6dbd-4959-9d9a-c6fb84753dbd'; // 大阪梅田店
const ABENO_LUCIAS_STUDIO_ID = '07df9741-6dbd-4959-9d9a-c6fb84753dbd'; // あべのルシアス店
const KYOTO_SHIJO_KARASUMA_STUDIO_ID = '08df9741-6dbd-4959-9d9a-c6fb84753dbd'; // 京都四条烏丸店
const NAGOYA_SAKAE_STUDIO_ID = '09df9741-6dbd-4959-9d9a-c6fb84753dbd'; // 名古屋栄店
const TOKYO_SHINJUKU_STUDIO_ID = 'a3b2c544-0df7-4434-a6fd-1cd458cc0c41'; // 東京新宿店
const OSAKA_UMEDA_SHOWROOM_STUDIO_ID = '0cb33f42-9dac-4395-9acf-c3da34dbaf91'; // 大阪梅田ショールーム
const KYOTO_TENJIKAI_STUDIO_ID = '76ebbd7e-f9bd-47d2-93af-61b969ebbcc1'; // 京都展示会
const NAGOYA_TENJIKAI_DIA_MEITETSU_BLDG_STUDIO_ID = '64230418-3fa3-4d99-9d20-a44bb7a60b00'; // 名古屋展示会＠ダイヤメイテツビル
const NAGOYA_TENJIKAI_NADYA_PARK_STUDIO_ID = '710fe688-b6a5-47c1-b5f3-a517ce8b560e'; // 名古屋展示会＠ナディアパーク
const TOKYO_TENJIKAI_STUDIO_ID = 'e3ffd4d5-e963-4fe7-8bc3-5db1698dc667'; // 東京展示会
const YOKOHAMA_TENJIKAI_STUDIO_ID = 'c428cc07-d2fe-4a26-8a7f-2661da54d056'; // 横浜展示会

// プランID
const AUDITION_PLAN_ID = '51880ac4-6c98-060c-e9f6-bb8c354fc2c0'; // オーディション
const ENSOUKAI_PLAN_ID = '7c0bd027-9945-c4a2-9fcc-437e99fbc9ec'; // 演奏会
const SEIZEN_IEI_PLAN_ID = '3965794a-7c16-2e71-e10d-7cf89ff570cb'; // 生前遺影
const SONOTA_PROFILE_PLAN_ID = '077e2479-c3d9-0292-59bd-bceee145e03c'; // その他プロフィール
const PA_PHOTO_PLAN_ID = 'e509353b-425e-4b7d-baf9-62e478bed1fc'; // PA婚活フォトプラン
const KIDS_PLAN_ID = 'de7df173-f2e4-49a1-8fe0-bbf9f0f283ff'; // キッズプラン（小学生以下・願書用）
const BUSINESS_PLAN_ID = '7a845dc8-1cf8-c21c-d506-c9e8c730dbbf'; // ビジネス
const OMIAI_KONKATSU_PLAN_ID = 'ef7df173-f2e4-49a1-8fe0-bbf9f0f283ff'; // お見合い・婚活（専用フォトプラン含む）
const AIRLINE_MASUKOMI_ZENSHIN_PLAN_ID = 'bc7df173-f2e4-49a1-8fe0-bbf9f0f283ff'; // エアラインマスコミ（全身のみ）プラン　※スナップは電話予約※
const AIRLINE_MASUKOMI_SHOMEI_PLAN_ID = 'dc7df173-f2e4-49a1-8fe0-bbf9f0f283ff'; // エアラインマスコミ（証明のみ）プラン　※スナップは電話予約※
const STANDARD_INTERN_FEMALE_PLAN_ID = 'ae7df173-f2e4-49a1-8fe0-bbf9f0f283ff'; // スタンダードプラン（￥12900）/インターン特別プラン(女性)
const STANDARD_INTERN_MALE_PLAN_ID = 'be7df173-f2e4-49a1-8fe0-bbf9f0f283ff'; // スタンダードプラン（￥12900）/インターン特別プラン(男性)
const FURISODE_KENGAKU_PLAN_ID = 'c756d484-136b-46e0-a1cf-de1c69cea82f'; // 振袖見学
const HAKAMA_KENGAKU_PLAN_ID = '91b956b9-edbf-4670-94b6-64431f461660'; // 袴見学
const IBJ_PHOTO_PLAN_ID = 'a850615e-f07f-4e06-967f-a82f6e6051e5'; // IBJフォトプラン
const SATSUEI_NOMI_ISHOU_RENTAL_PLAN_ID = '976945df-6e42-4b30-af1e-bef706fa0cc6'; // 撮影のみ衣装レンタル

type ViewItem = {
  [key: number]: {
    viewTitle: string;
    photoPlanIdsString: string;
    photoStudioIds: string[];
  };
};
const viewItem: ViewItem = {
  1: {
    viewTitle: '証明全体',
    photoPlanIdsString: [
      STANDARD_INTERN_FEMALE_PLAN_ID,
      STANDARD_INTERN_MALE_PLAN_ID,
      AIRLINE_MASUKOMI_ZENSHIN_PLAN_ID,
      AIRLINE_MASUKOMI_SHOMEI_PLAN_ID,
      KIDS_PLAN_ID,
    ].join(','),
    photoStudioIds: [
      OSAKA_UMEDA_STUDIO_ID,
      ABENO_LUCIAS_STUDIO_ID,
      KYOTO_SHIJO_KARASUMA_STUDIO_ID,
      NAGOYA_SAKAE_STUDIO_ID,
    ],
  },
  2: {
    viewTitle: 'スタンダードプランのみ',
    photoPlanIdsString: [
      STANDARD_INTERN_FEMALE_PLAN_ID,
      STANDARD_INTERN_MALE_PLAN_ID,
    ].join(','),
    photoStudioIds: [
      OSAKA_UMEDA_STUDIO_ID,
      ABENO_LUCIAS_STUDIO_ID,
      KYOTO_SHIJO_KARASUMA_STUDIO_ID,
      NAGOYA_SAKAE_STUDIO_ID,
    ],
  },
  3: {
    viewTitle: '就活証明写真スタンダードプラン・エアラインマスコミプラン',
    photoPlanIdsString: [
      STANDARD_INTERN_FEMALE_PLAN_ID,
      STANDARD_INTERN_MALE_PLAN_ID,
      AIRLINE_MASUKOMI_ZENSHIN_PLAN_ID,
      AIRLINE_MASUKOMI_SHOMEI_PLAN_ID
    ].join(','),
    photoStudioIds: [
      OSAKA_UMEDA_STUDIO_ID,
      ABENO_LUCIAS_STUDIO_ID,
      KYOTO_SHIJO_KARASUMA_STUDIO_ID,
      NAGOYA_SAKAE_STUDIO_ID,
    ],
  },
  4: {
    viewTitle: '願書用',
    photoPlanIdsString: [
      KIDS_PLAN_ID,
    ].join(','),
    photoStudioIds: [
      OSAKA_UMEDA_STUDIO_ID,
      ABENO_LUCIAS_STUDIO_ID,
      KYOTO_SHIJO_KARASUMA_STUDIO_ID,
      NAGOYA_SAKAE_STUDIO_ID,
    ],
  },
  5: {
    viewTitle: 'プロフィール全体',
    photoPlanIdsString: [
      OMIAI_KONKATSU_PLAN_ID,
      BUSINESS_PLAN_ID,
      AUDITION_PLAN_ID,
      ENSOUKAI_PLAN_ID,
      SEIZEN_IEI_PLAN_ID,
      SONOTA_PROFILE_PLAN_ID,
    ].join(','),
    photoStudioIds: [
      OSAKA_UMEDA_STUDIO_ID,
      ABENO_LUCIAS_STUDIO_ID,
      KYOTO_SHIJO_KARASUMA_STUDIO_ID,
      NAGOYA_SAKAE_STUDIO_ID,
    ],
  },
  6: {
    viewTitle: 'お見合い（専用フォトプラン含む）',
    photoPlanIdsString: [
      OMIAI_KONKATSU_PLAN_ID,
    ].join(','),
    photoStudioIds: [
      OSAKA_UMEDA_STUDIO_ID,
      ABENO_LUCIAS_STUDIO_ID,
      KYOTO_SHIJO_KARASUMA_STUDIO_ID,
      NAGOYA_SAKAE_STUDIO_ID,
      TOKYO_SHINJUKU_STUDIO_ID,
    ],
  },
  7: {
    viewTitle: 'オーディション',
    photoPlanIdsString: [
      AUDITION_PLAN_ID,
      ENSOUKAI_PLAN_ID,
    ].join(','),
    photoStudioIds: [
      OSAKA_UMEDA_STUDIO_ID,
      ABENO_LUCIAS_STUDIO_ID,
      KYOTO_SHIJO_KARASUMA_STUDIO_ID,
      NAGOYA_SAKAE_STUDIO_ID,
      TOKYO_SHINJUKU_STUDIO_ID,
    ],
  },
  8: {
    viewTitle: '生前遺影',
    photoPlanIdsString: [
      SEIZEN_IEI_PLAN_ID,
    ].join(','),
    photoStudioIds: [
      OSAKA_UMEDA_STUDIO_ID,
      ABENO_LUCIAS_STUDIO_ID,
      KYOTO_SHIJO_KARASUMA_STUDIO_ID,
      NAGOYA_SAKAE_STUDIO_ID,
      TOKYO_SHINJUKU_STUDIO_ID,
    ],
  },
  9: {
    viewTitle: 'ビジネス その他プロフィール',
    photoPlanIdsString: [
      BUSINESS_PLAN_ID,
      SONOTA_PROFILE_PLAN_ID,
    ].join(','),
    photoStudioIds: [
      OSAKA_UMEDA_STUDIO_ID,
      ABENO_LUCIAS_STUDIO_ID,
      KYOTO_SHIJO_KARASUMA_STUDIO_ID,
      NAGOYA_SAKAE_STUDIO_ID,
      TOKYO_SHINJUKU_STUDIO_ID,
    ],
  },
  10: {
    viewTitle: 'お見合い（1社限定PA様用）',
    photoPlanIdsString: [
      PA_PHOTO_PLAN_ID,
    ].join(','),
    photoStudioIds: [
      OSAKA_UMEDA_STUDIO_ID,
      ABENO_LUCIAS_STUDIO_ID,
      KYOTO_SHIJO_KARASUMA_STUDIO_ID,
      NAGOYA_SAKAE_STUDIO_ID,
    ],
  },
  11: {
    viewTitle: '着物レンタル',
    photoPlanIdsString: [
      HAKAMA_KENGAKU_PLAN_ID,
      FURISODE_KENGAKU_PLAN_ID,
      SATSUEI_NOMI_ISHOU_RENTAL_PLAN_ID,
    ].join(','),
    photoStudioIds: [
      OSAKA_UMEDA_SHOWROOM_STUDIO_ID,
      KYOTO_TENJIKAI_STUDIO_ID,
      NAGOYA_TENJIKAI_DIA_MEITETSU_BLDG_STUDIO_ID,
      NAGOYA_TENJIKAI_NADYA_PARK_STUDIO_ID,
      TOKYO_TENJIKAI_STUDIO_ID,
      YOKOHAMA_TENJIKAI_STUDIO_ID,
    ],
  },
  12: {
    viewTitle: 'IBJフォトプラン',
    photoPlanIdsString: [
      IBJ_PHOTO_PLAN_ID,
    ].join(','),
    photoStudioIds: [
      OSAKA_UMEDA_STUDIO_ID,
      ABENO_LUCIAS_STUDIO_ID,
      KYOTO_SHIJO_KARASUMA_STUDIO_ID,
      NAGOYA_SAKAE_STUDIO_ID,
      TOKYO_SHINJUKU_STUDIO_ID,
    ],
  },
};

export function getPlanViewTitle(id: number) {
  return { photoCompanyId: SELFIT_COMPANY_ID, ...viewItem[id] };
}

export function getPlanDoneText(id: string) {
  const doneText: { [key: string]: string } = {
    [STANDARD_INTERN_FEMALE_PLAN_ID]:
      'お友達割引の適用を希望の場合は、下記IDが必要です。\n ご紹介されるお友達にお知らせください。詳しくは、自動返信メールをご確認ください。※キッズプランのIDは友達割引対象外',
    [STANDARD_INTERN_MALE_PLAN_ID]:
      'お友達割引の適用を希望の場合は、下記IDが必要です。\n ご紹介されるお友達にお知らせください。詳しくは、自動返信メールをご確認ください。※キッズプランのIDは友達割引対象外',
    [AIRLINE_MASUKOMI_ZENSHIN_PLAN_ID]:
      'お友達割引の適用を希望の場合は、下記IDが必要です。\n ご紹介されるお友達にお知らせください。詳しくは、自動返信メールをご確認ください。※キッズプランのIDは友達割引対象外',
    [AIRLINE_MASUKOMI_SHOMEI_PLAN_ID]:
      'お友達割引の適用を希望の場合は、下記IDが必要です。\n ご紹介されるお友達にお知らせください。詳しくは、自動返信メールをご確認ください。※キッズプランのIDは友達割引対象外',
  };
  const r = doneText[id] ?? '';
  return r;
}
