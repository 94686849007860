import { Box, Container, Stack } from '@mui/material';
import { MarriageAgencyStaff } from 'codegen/axios/photo/adca';
import { FC } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import EditButton from 'src/components/atoms/edit-button';
import HeadLineText from 'src/components/atoms/head-line-text';
import DeleteModal from 'src/components/molecules/delete-modal';
import { staffDetailFetchOrganize } from 'src/functions/fetch-value-organize';
import useFetch from 'src/hooks/use-fetch';
import usePush from 'src/hooks/use-push';
import useStaffInformation from 'src/hooks/use-staff-information';
import DetailCard from 'src/components/molecules/detail-card';
import { defaultValues } from 'src/components/organisms/staff/form';

export const StaffDetail: FC = () => {
  /* 定数宣言 */
  const title = 'スタッフ詳細';
  /* hooks宣言 */
  const staff = useStaffInformation();
  const { id: marriageAgencyStaffId } = useParams();
  const navigate = useNavigate();
  const push = usePush();

  /* データ取得 */
  const { fetchedData: responseData } = useFetch<MarriageAgencyStaff>({
    iniState: defaultValues,
    reqProp: {
      pathKey: 'marriageAgencyStaff',
      pathParam: marriageAgencyStaffId,
    },
    organize: staffDetailFetchOrganize,
  });

  const handleEditButtonOnClick = () => {
    navigate(`/${staff.userType}/staff/${marriageAgencyStaffId}/staff/edit`);
  };

  const onDelete = () => {
    new Promise((resolve) =>
      push(resolve, {
        reqProp: {
          pathKey: 'marriageAgencyStaff',
          method: 'delete',
          pathParam: marriageAgencyStaffId,
        },
        aftProp: {
          resolve,
          nextPath: '/staff',
          showMessage: {
            state: { snackBar: { open: true, viewType: 'info', message: '削除しました' } },
          },
        },
      })
    );
  };

  return (
    <Container>
      <Stack direction="row" alignItems="center" justifyContent="start" mb={2} ml={1} mt={'3%'} mr={0}>
        <HeadLineText text={title} />

        <Box sx={{ ml: 'auto' }}>
          <DeleteModal
            sx={{
              bgcolor: 'background.paper',
              minWidth: 50,
              minHeight: 47,
              boxShadow: 1,
              borderRadius: 2,
              ml: 2,
            }}
            OnDelete={onDelete}
          />
          <EditButton handleEditOnClick={handleEditButtonOnClick} />
        </Box>
      </Stack>

      <DetailCard
        defaultValues={responseData}
        labelProperty={{
          管理者: 'info',
          一般: 'success',
        }}
      />
    </Container>
  );
};
