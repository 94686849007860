import axios from 'axios';
import { Fragment, useEffect } from 'react';
import { prefecture } from 'src/utils/prefecture';
import { Typography, Container, Grid, Button, Stack, useTheme, useMediaQuery, Box } from '@mui/material';
import { customerRsvInputFormValidationSchema } from 'src/functions/validation-schema';
import { useForm } from 'react-hook-form';
import {
  RHFTextField,
  FormProvider,
  RHFRadioButtonGroup,
  RHFZipCode,
  RHFTextInputPrefecture,
  RHFCheckboxGroup,
  RHFTextInputSelectBox,
  RHFTextArea,
} from 'src/components/atoms/hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import log from 'src/utils/log';
import { formatDate } from 'src/functions/format';
import { PostReserveRequestBodyForPhotoStudioCustomerFormInner } from 'codegen/axios/photo/photo_studio_customer';
import { convertOptions } from 'src/components/organisms/reservation/create-edit/form/inputs';
import { useSearchParams } from 'react-router-dom';
import RequiredTag from 'src/components/atoms/RequiredTag';
import { kidsPlanId } from 'src/utils/planId';
import { getPlanViewTitle } from 'src/utils/customerIndexView';
import { CustomStepper } from '../../components/CustomStepper';

export const CustomerReservationInputForm = ({
  onSubmit,
  formInputs,
  defaultValues,
}: {
  formInputs: any;
  onSubmit: (formValue: any) => void;
  defaultValues: any;
}) => {
  const theme = useTheme();
  const [searchParams] = useSearchParams();
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'));
  const paramsId = searchParams.get('id') ?? 1;
  const { viewTitle } = getPlanViewTitle(Number(paramsId));
  const validationSchema = customerRsvInputFormValidationSchema(
    formInputs,
    [{ reservationTime: false }],
    defaultValues.photoStudioPlan.photoStudioPlanId,
    defaultValues.photoStudioOptions
  );
  const methods = useForm({ resolver: yupResolver(validationSchema), defaultValues });
  const {
    handleSubmit,
    formState: { errors },
  } = methods;

  const formValues = methods.getValues();
  // ブラウザバック時の復元
  useEffect(() => {
    const savedData = sessionStorage.getItem('cmr-rsv-form-data');
    if (savedData) {
      const data = JSON.parse(savedData);
      Object.entries(data).forEach(([name, value]) => {
        methods.setValue(name, value);
      });
      sessionStorage.removeItem('cmr-rsv-form-data');
    }
  }, []);

  const handleZipCode = (value: any) => {
    addressGet(value);
  };

  const addressGet = async (e: any) => {
    if (e.replace(/-/, '').match(/\d{7}/)) {
      try {
        const zipCode = `${e.replace(/-/, '').slice(0, 3)}-${e.replace(/-/, '').slice(3, e.replace(/-/, '').length)}`;
        log(zipCode);
        const res = await axios.get(
          `https://madefor.github.io/postal-code-api/api/v1/${zipCode.replace(/-/, '/')}.json`
        );
        const address = res.data.data[0].ja;
        methods.setValue('prefecture', address.prefecture);
        methods.setValue('city', `${address.address1}${address.address2}`);
      } catch {
        log('住所の取得に失敗しました。');
      }
    }
  };

  return (
    <Container
      maxWidth={!isMdUp ? 'lg' : undefined}
      sx={
        !isMdUp
          ? {
              paddingLeft: 0,
              paddingRight: 0,
            }
          : undefined
      }
    >
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Box sx={{ pb: isMdUp ? 1 : 2, pt: isMdUp ? 3 : 0, pl: isMdUp ? 3 : 0 }}>
          <Typography variant="h4" color="text.secondary" sx={{ mb: 1.5 }}>
            撮影予約
          </Typography>
          <Typography color="primary" variant={isMdUp ? 'h6' : 'subtitle2'} sx={{ display: 'inline' }}>
            {viewTitle}
          </Typography>
        </Box>
        <CustomStepper activeStep={1} />
        <Grid container spacing={isMdUp ? 3.5 : 1.5} sx={{ pb: 4 }}>
          <Grid item xs={4} sm={4} md={6}>
            <Typography
              variant={isMdUp ? 'subtitle1' : 'subtitle2'}
              color="text.secondary"
              align="left"
              sx={{ pl: isMdUp ? 42 : 0 }}
            >
              店舗
            </Typography>
          </Grid>
          <Grid item xs={8} sm={8} md={6}>
            <Typography variant={isMdUp ? 'subtitle1' : 'subtitle2'} color="text.secondary">
              {formValues.photoStudio.name}
            </Typography>
          </Grid>
          <Grid item xs={4} sm={4} md={6}>
            <Typography
              variant={isMdUp ? 'subtitle1' : 'subtitle2'}
              color="text.secondary"
              align="left"
              sx={{ pl: isMdUp ? 42 : 0 }}
            >
              プラン
            </Typography>
          </Grid>
          <Grid item xs={8} sm={8} md={6}>
            <Typography variant={isMdUp ? 'subtitle1' : 'subtitle2'} color="text.secondary">
              {formValues.photoStudioPlan.name}
            </Typography>
          </Grid>
          <Grid item xs={4} sm={4} md={6}>
            <Typography
              variant={isMdUp ? 'subtitle1' : 'subtitle2'}
              color="text.secondary"
              align="left"
              sx={{ pl: isMdUp ? 42 : 0 }}
            >
              オプション
            </Typography>
          </Grid>
          <Grid item xs={8} sm={8} md={6}>
            <Typography variant={isMdUp ? 'subtitle1' : 'subtitle2'} color="text.secondary">
              {formValues.photoStudioOptions.map((option: any) => option.name).join(', ')}
            </Typography>
          </Grid>
          <Grid item xs={4} sm={4} md={6}>
            <Typography
              variant={isMdUp ? 'subtitle1' : 'subtitle2'}
              color="text.secondary"
              align="left"
              sx={{ pl: isMdUp ? 42 : 0 }}
            >
              撮影開始日時
            </Typography>
          </Grid>
          <Grid item xs={8} sm={8} md={6}>
            <Typography variant={isMdUp ? 'subtitle1' : 'subtitle2'} color="text.secondary">
              {formatDate(formValues.reservationStartDateTime)}〜
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant={isMdUp ? 'subtitle1' : 'h6'}
              color="text.secondary"
              align="left"
              sx={{ pl: isMdUp ? 38 : 0, pt: 4 }}
            >
              基本情報
            </Typography>
          </Grid>
          <Grid item xs={4} sm={4} md={6}>
            <Typography
              variant={isMdUp ? 'subtitle1' : 'subtitle2'}
              color="text.secondary"
              align="left"
              sx={{ pl: isMdUp ? 42 : 0, pt: 1 }}
            >
              <RequiredTag /> お名前
            </Typography>
          </Grid>
          <Grid item xs={8} sm={8} md={6}>
            <RHFTextField size="small" sx={{ pr: isMdUp ? 20 : 1 }} name="customerName" placeholder="例：田中 太郎" />
          </Grid>
          <Grid item xs={4} sm={4} md={6}>
            <Typography
              variant={isMdUp ? 'subtitle1' : 'subtitle2'}
              color="text.secondary"
              align="left"
              sx={{ pl: isMdUp ? 42 : 0, pt: 1 }}
            >
              <RequiredTag /> ふりがな
            </Typography>
          </Grid>
          <Grid item xs={8} sm={8} md={6}>
            <RHFTextField
              size="small"
              sx={{ pr: isMdUp ? 20 : 1 }}
              name="customerNameKana"
              placeholder="例：たなか たろう"
            />
          </Grid>
          <Grid item xs={4} sm={4} md={6}>
            <Typography
              variant={isMdUp ? 'subtitle1' : 'subtitle2'}
              color="text.secondary"
              align="left"
              sx={{ pl: isMdUp ? 42 : 0, pt: 1 }}
            >
              <RequiredTag /> メールアドレス
            </Typography>
          </Grid>
          <Grid item xs={8} sm={8} md={6}>
            <RHFTextField
              size="small"
              sx={{ pr: isMdUp ? 20 : 1 }}
              name="customerEmail"
              placeholder="例：exmple@photoapan.jp"
            />
          </Grid>
          <Grid item xs={4} sm={4} md={6}>
            <Typography
              variant={isMdUp ? 'subtitle1' : 'subtitle2'}
              color="text.secondary"
              align="left"
              sx={{ pl: isMdUp ? 42 : 0, pt: 1 }}
            >
              <RequiredTag />
              電話番号
            </Typography>
          </Grid>
          <Grid item xs={8} sm={8} md={6}>
            <RHFTextField
              size="small"
              sx={{ pr: isMdUp ? 20 : 1 }}
              name="customerTelno"
              placeholder="例：09012345678"
            />
          </Grid>
          <Grid item xs={4} sm={4} md={6}>
            <Typography
              variant={isMdUp ? 'subtitle1' : 'subtitle2'}
              color="text.secondary"
              align="left"
              sx={{ pl: isMdUp ? 42 : 0, pt: 1 }}
            >
              <RequiredTag /> 性別
            </Typography>
          </Grid>
          <Grid item xs={8} sm={8} md={6}>
            <RHFRadioButtonGroup
              name="customerGender"
              options={[
                {
                  value: 'man',
                  label: '男性',
                },
                {
                  value: 'woman',
                  label: '女性',
                },
              ]}
            />
          </Grid>
          {/*   */}

          {kidsPlanId === defaultValues.photoStudioPlan.photoStudioPlanId && (
            <>
              <Grid item xs={12}>
                <Typography
                  variant={isMdUp ? 'subtitle1' : 'h6'}
                  color="text.secondary"
                  align="left"
                  sx={{ pl: isMdUp ? 38 : 0, pt: 4 }}
                >
                  住所
                </Typography>
              </Grid>
              <Grid item xs={4} sm={4} md={6}>
                <Typography
                  variant={isMdUp ? 'subtitle1' : 'subtitle2'}
                  color="text.secondary"
                  align="left"
                  sx={{ pl: isMdUp ? 42 : 0, pt: 1 }}
                >
                  郵便番号
                </Typography>
              </Grid>
              <Grid item xs={8} sm={8} md={6}>
                <RHFZipCode
                  size="small"
                  sx={{ pr: isMdUp ? 20 : 1 }}
                  name="zip"
                  methods={methods}
                  onChange={handleZipCode}
                  placeholder="例：1234567"
                />
              </Grid>
              <Grid item xs={4} sm={4} md={6}>
                <Typography
                  variant={isMdUp ? 'subtitle1' : 'subtitle2'}
                  color="text.secondary"
                  align="left"
                  sx={{ pl: isMdUp ? 42 : 0, pt: 1 }}
                >
                  都道府県
                </Typography>
              </Grid>
              <Grid item xs={8} sm={8} md={6}>
                <RHFTextInputPrefecture
                  size="small"
                  sx={{ pr: isMdUp ? 20 : 1 }}
                  isOptionEqualToValue={(option: any, value: any) => option === value}
                  option={prefecture}
                  name="prefecture"
                  label="都道府県"
                />
              </Grid>
              <Grid item xs={4} sm={4} md={6}>
                <Typography
                  variant={isMdUp ? 'subtitle1' : 'subtitle2'}
                  color="text.secondary"
                  align="left"
                  sx={{ pl: isMdUp ? 42 : 0, pt: 1 }}
                >
                  市町村
                </Typography>
              </Grid>
              <Grid item xs={8} sm={8} md={6}>
                <RHFTextField size="small" sx={{ pr: isMdUp ? 20 : 1 }} name="city" placeholder="例：新宿区西新宿" />
              </Grid>
              <Grid item xs={4} sm={4} md={6}>
                <Typography
                  variant={isMdUp ? 'subtitle1' : 'subtitle2'}
                  color="text.secondary"
                  align="left"
                  sx={{ pl: isMdUp ? 42 : 0, pt: 1 }}
                >
                  それ以降
                </Typography>
              </Grid>
              <Grid item xs={8} sm={8} md={6}>
                <RHFTextField
                  size="small"
                  sx={{ pr: isMdUp ? 20 : 1 }}
                  name="street"
                  placeholder="例：1-23-7 〇〇ビル 5F"
                />
              </Grid>
            </>
          )}
          {formInputs.map((group: PostReserveRequestBodyForPhotoStudioCustomerFormInner, index: any) => (
            <Fragment key={index}>
              {group.inputs?.some((input) => input.customerViewFlg === true) &&
                group.inputs?.some((input) =>
                  input.availablePlans.some(
                    (obj) => obj.photoStudioPlanId === formValues.photoStudioPlan.photoStudioPlanId
                  )
                ) && (
                  <Grid item xs={12}>
                    <Typography
                      variant={isMdUp ? 'subtitle1' : 'h6'}
                      color="text.secondary"
                      align="left"
                      sx={{ pl: isMdUp ? 38 : 0, pt: 4 }}
                    >
                      {group.groupName}
                    </Typography>
                  </Grid>
                )}
              {group.inputs?.map((input, idx) => {
                if (input.customerViewFlg === false) return undefined;
                if (
                  !input.availablePlans.some(
                    (obj: any) => obj.photoStudioPlanId === formValues.photoStudioPlan.photoStudioPlanId
                  )
                ) {
                  return undefined;
                }

                // フォームのavailableOptionIdsが空だったら全部表示、あれば選択されたオプションがふくまれているかどうか
                if (
                  input.availableOptionIds.length > 0 &&
                  !input.availableOptionIds!.some((id: any) =>
                    formValues.photoStudioOptions.find((selectedOp: any) => selectedOp.photoStudioOptionId === id)
                  )
                )
                  return null;

                switch (input.inputType) {
                  case 'checkbox':
                    return (
                      <Fragment key={idx}>
                        <Grid item xs={12} sm={5} md={6}>
                          <Typography
                            variant={isMdUp ? 'subtitle1' : 'subtitle2'}
                            color="text.secondary"
                            align="left"
                            sx={{ pl: isMdUp ? 42 : 0, pt: 1 }}
                          >
                            {input.requiredFlg && <RequiredTag />} {input.name}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={7} md={6}>
                          <RHFCheckboxGroup
                            name={input.name || ''}
                            options={input.options ? convertOptions(input.options) : [{ value: '', label: '' }]}
                            componentsProps={{
                              typography: { variant: isMdUp ? 'subtitle1' : 'body2', color: 'text.secondary' },
                            }}
                          />
                        </Grid>
                      </Fragment>
                    );
                  case 'selectbox':
                    return (
                      <Fragment key={idx}>
                        <Grid item xs={4} sm={4} md={6}>
                          <Typography
                            variant={isMdUp ? 'subtitle1' : 'subtitle2'}
                            color="text.secondary"
                            align="left"
                            sx={{ pl: isMdUp ? 42 : 0, pt: 1 }}
                          >
                            {input.requiredFlg && <RequiredTag />} {input.name}
                          </Typography>
                        </Grid>
                        <Grid item xs={8} sm={8} md={6}>
                          <RHFTextInputSelectBox
                            size="small"
                            sx={{ pr: isMdUp ? 20 : 1 }}
                            name={input.name}
                            option={input.options.map((obj) => ({ label: obj.label, name: obj.label }))}
                          />
                        </Grid>
                      </Fragment>
                    );
                  case 'radiobutton':
                    return (
                      <Fragment key={idx}>
                        <Grid item xs={4} sm={4} md={6}>
                          <Typography
                            variant={isMdUp ? 'subtitle1' : 'subtitle2'}
                            color="text.secondary"
                            align="left"
                            sx={{ pl: isMdUp ? 42 : 0, pt: 1 }}
                          >
                            {input.requiredFlg && <RequiredTag />} {input.name}
                          </Typography>
                        </Grid>
                        <Grid item xs={8} sm={8} md={6}>
                          <RHFRadioButtonGroup
                            name={input.name}
                            options={input.options.map((obj) => ({ label: obj.label, value: obj.label }))}
                            optionTextSx={!isMdUp && { variant: 'body2', color: 'text.secondary' }}
                          />
                          <Typography variant={isMdUp ? 'subtitle1' : 'body2'} color="text.secondary" align="left">
                            {input && input.placeholder}
                          </Typography>
                        </Grid>
                      </Fragment>
                    );
                  case 'textarea':
                    return (
                      <Fragment key={idx}>
                        <Grid item xs={4} sm={4} md={6}>
                          <Typography
                            variant={isMdUp ? 'subtitle1' : 'subtitle2'}
                            color="text.secondary"
                            align="left"
                            sx={{ pl: isMdUp ? 42 : 0, pt: 1 }}
                          >
                            {input.requiredFlg && <RequiredTag />} {input.name}
                          </Typography>
                        </Grid>
                        <Grid item xs={8} sm={8} md={6}>
                          <RHFTextArea
                            size="small"
                            sx={{ pr: isMdUp ? 20 : 1 }}
                            name={input.name}
                            placeholder={input.placeholder}
                            multiline
                            minRows={2}
                          />
                        </Grid>
                      </Fragment>
                    );
                  case 'textbox':
                    return (
                      <Fragment key={idx}>
                        <Grid item xs={4} sm={4} md={6}>
                          <Typography
                            variant={isMdUp ? 'subtitle1' : 'subtitle2'}
                            color="text.secondary"
                            align="left"
                            sx={{ pl: isMdUp ? 42 : 0, pt: 1 }}
                          >
                            {input.requiredFlg && <RequiredTag />} {input.name}
                          </Typography>
                        </Grid>
                        <Grid item xs={8} sm={8} md={6}>
                          <RHFTextField
                            size="small"
                            sx={{ pr: isMdUp ? 20 : 1 }}
                            name={input.name}
                            placeholder={input.placeholder}
                          />
                        </Grid>
                      </Fragment>
                    );
                  default:
                    return null;
                }
              })}
            </Fragment>
          ))}
        </Grid>
        {Object.keys(errors).length !== 0 && (
          <Stack direction="row" alignItems="center" justifyContent="center">
            <Typography variant="body2" color="error">
              ※未入力項目があります
            </Typography>
          </Stack>
        )}
        <Stack direction="row" alignItems="center" justifyContent="center" sx={{ m: 1, pt: 3 }} spacing={5}>
          <Button variant="outlined" sx={{ width: 100, height: 47 }} size="large" onClick={() => window.history.back()}>
            戻る
          </Button>
          <Button
            variant="contained"
            sx={{ width: 100, height: 47 }}
            size="large"
            type="submit"
            disabled={Object.keys(errors).length !== 0}
          >
            次へ
          </Button>
        </Stack>
      </FormProvider>
    </Container>
  );
};
