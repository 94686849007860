import { Card, CardHeader, Stack } from '@mui/material';
import { PhotoStudioBusinessHour } from 'codegen/axios/photo/photo_studio';
import { FC, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import EditButton from 'src/components/atoms/edit-button';
import DeleteModal from 'src/components/molecules/delete-modal';
import DetailCard from 'src/components/molecules/detail-card';
import { businessHourFetchOrganize } from 'src/functions/fetch-value-organize';
import useFetch from 'src/hooks/use-fetch';
import usePush from 'src/hooks/use-push';

export type BusinessHourDisplayType = Pick<PhotoStudioBusinessHour, 'photoStudioId' | 'remark'> & {
  salesTime: string;
  sundaySalesTime: string;
  mondaySalesTime: string;
  tuesdaySalesTime: string;
  wednesdaySalesTime: string;
  thursdaySalesTime: string;
  fridaySalesTime: string;
  saturdaySalesTime: string;
};

const defaultValues: BusinessHourDisplayType = {
  photoStudioId: '',
  salesTime: '',
  sundaySalesTime: '',
  mondaySalesTime: '',
  tuesdaySalesTime: '',
  wednesdaySalesTime: '',
  thursdaySalesTime: '',
  fridaySalesTime: '',
  saturdaySalesTime: '',
  remark: '',
};

export const PhotoStudioBusinessHourCard: FC = () => {
  /* 定数宣言 */
  const title = '営業時間';

  /* hook宣言 */
  const navigate = useNavigate();
  const push = usePush();
  const { id: photoStudioId } = useParams();
  const [listUpdate, setListUpdate] = useState(0);

  /* データ取得 */
  const { fetchedData: responseData } = useFetch<PhotoStudioBusinessHour>({
    iniState: defaultValues,
    reqProp: {
      pathKey: 'businessHour',
      queryParams: { photoStudioId },
    },
    observable: [listUpdate],
  });

  const handleBusinessHourEditButtonOnClick = () => {
    responseData.photoStudioBusinessHourId === undefined
      ? navigate(`/photoStudioStaff/setting/photoStudio/${photoStudioId}/businessHour/new`)
      : navigate(
          `/photoStudioStaff/setting/photoStudio/${photoStudioId}/businessHour/${responseData.photoStudioBusinessHourId}/edit`
        );
  };

  const onDelete = () => {
    new Promise((resolve) =>
      push(resolve, {
        reqProp: {
          pathKey: 'businessHour',
          method: 'delete',
          pathParam: responseData.photoStudioBusinessHourId,
        },
        aftProp: {
          resolve,
          nextPath: `/setting/photoStudio/${photoStudioId}`,
          showMessage: {
            state: { snackBar: { open: true, viewType: 'info', message: '削除しました' } },
          },
        },
      })
    ).then(() => {
      setListUpdate(listUpdate + 1);
    });
  };

  return (
    <>
      <Stack direction="row" alignItems="center" justifyContent="flex-end" mt={9}>
        {responseData.photoStudioBusinessHourId !== undefined && (
          <DeleteModal
            sx={{
              bgcolor: 'background.paper',
              minWidth: 50,
              minHeight: 47,
              boxShadow: 1,
              borderRadius: 2,
              ml: 2,
            }}
            OnDelete={onDelete}
          />
        )}

        <EditButton handleEditOnClick={handleBusinessHourEditButtonOnClick} />
      </Stack>

      <Card sx={{ mt: 5 }}>
        <CardHeader title={title} sx={{ mb: 3, mt: 1 }} />

        <DetailCard defaultValues={businessHourFetchOrganize(responseData)} />
      </Card>
    </>
  );
};
