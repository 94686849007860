import { create } from 'zustand';
import { persist } from 'zustand/middleware';

type TotalCountState = {
  fetchTotalCount: boolean;
  enableFetchTotalCount: () => void;
  disableFetchTotalCount: () => void;
};

const createPersisted = () =>
  persist<TotalCountState>(
    (set) => ({
      fetchTotalCount: true, // デフォルトでtrue
      enableFetchTotalCount: () => set({ fetchTotalCount: true }),
      disableFetchTotalCount: () => set({ fetchTotalCount: false }),
    }),
    {
      name: 'total-count-storage', // ユニークな名前
      getStorage: () => localStorage, // localStorageを使用
    }
  );

export const useTotalCountStore = create(createPersisted());
