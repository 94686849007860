import Link from '@mui/material/Link';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import { RouterLink } from 'src/components/routes/components';
import Iconify from 'src/components/atoms/Iconify';
import { styled } from '@mui/material';
import { NavItemProps } from '../types';

import { StyledNavItem } from './styles';

// ----------------------------------------------------------------------

export default function NavItem({ item, open, active, externalLink, ...other }: NavItemProps) {
  const renderContent = (
    <StyledNavItem active={active} {...other}>
      <ListItemIconStyle>{item.icon && item.icon}</ListItemIconStyle>
      <ListItemText disableTypography primary={item.title} />
      {!!item.children && <Iconify width={16} icon={open ? 'carbon:chevron-down' : 'carbon:chevron-right'} sx={{}} />}
    </StyledNavItem>
  );

  // ExternalLink
  if (externalLink) {
    return (
      <Link href={item.path} target="_blank" rel="noopener" underline="none">
        {renderContent}
      </Link>
    );
  }

  // Has child
  if (item.children) {
    return renderContent;
  }

  // Default
  return (
    <Link component={RouterLink} href={item.path} underline="none">
      {renderContent}
    </Link>
  );
}

const ListItemIconStyle = styled(ListItemIcon)({
  marginRight: -18,
  width: 16,
  height: 16,
  color: 'inherit',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'left',
});
