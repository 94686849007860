import { Card, CardHeader, Grid, Stack, Typography } from '@mui/material';
import { PhotoStudioReservationFrameAvailablePlansInner } from 'codegen/axios/photo/photo_studio';
import { FC } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import EditButton from 'src/components/atoms/edit-button';
import useStaffInformation from 'src/hooks/use-staff-information';

type Props = {
  plans: Array<PhotoStudioReservationFrameAvailablePlansInner>;
};

export const AvailablePlanCard: FC<Props> = ({ plans }) => {
  /* 定数宣言 */
  const staff = useStaffInformation();
  const { id: planStudioReservationId } = useParams();
  const navigate = useNavigate();

  const handleEditButtonOnClick = () => {
    navigate(`/${staff.userType}/setting/frame/${planStudioReservationId}/available/plan`);
  };

  return (
    <>
      <Stack direction="row" alignItems="center" justifyContent="flex-end" mt={9}>
        <EditButton handleEditOnClick={handleEditButtonOnClick} />
      </Stack>

      <Card sx={{ mt: 5 }}>
        <CardHeader title="選択可能プラン" sx={{ mb: 0, mt: 1 }} />
        {!plans ||
          (plans && plans.length === 0 && (
            <Typography variant="body2" color="warning.main" sx={{ ml: 3 }}>
              ※注意:対応可能プランが設定されていないと枠が正しく機能しません
            </Typography>
          ))}

        <Stack my={7} mx={4}>
          <Grid container rowSpacing={3} columnSpacing={3}>
            <Grid item pt={4} xs={6} sm={3} md={6}>
              <Typography variant="body2">プラン名</Typography>
            </Grid>

            <Grid item pt={4} xs={6} sm={3} md={6}>
              {plans && (
                <Stack spacing={2}>
                  {plans.map((plan: PhotoStudioReservationFrameAvailablePlansInner) => (
                    <Typography key={plan.photoStudioPlanId} variant="body2">
                      ・　{plan.name}
                    </Typography>
                  ))}
                </Stack>
              )}
            </Grid>
          </Grid>
        </Stack>
      </Card>
    </>
  );
};
