import Page from 'src/components/molecules/page';
import useStaffInformation from 'src/hooks/use-staff-information';
import { Container, Stack } from '@mui/material';
import HeadLineText from 'src/components/atoms/head-line-text';
import CreateButton from 'src/components/atoms/create-button';
import { FC } from 'react';
import { OptionList } from 'src/components/organisms/option/list';

export const PhotoStudioOptionListPage: FC = () => {
  /* hook宣言 */
  const staff = useStaffInformation();

  /* 定数宣言 */
  const title = 'オプション一覧';

  return (
    <Page title={title}>
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" m={5}>
          <HeadLineText text={title} />
          <CreateButton text="オプション登録" path={`/${staff.userType}/setting/option/new`} />
        </Stack>
        <OptionList />
      </Container>
    </Page>
  );
};
