import { useNavigate, useLocation } from 'react-router-dom';
import { apiCall, createApiUrl } from 'src/functions/api/call';
import useStaffInformation from 'src/hooks/use-staff-information';
import { ApiEndpoints } from 'src/utils/api';

interface UsePushProps {
  reqProp: {
    method?: 'get' | 'post' | 'put' | 'delete';
    pathKey: keyof ApiEndpoints;
    pathParam?: string;
    queryParams?: { [key: string]: any };
    data?: any;
    addOption?: any;
    notAlert?: { status: number; message: string };
  };
  aftProp?: {
    calledFunc?: (res: any) => void;
    errorFunc?: (err: any) => void;
    resolve?: (res: any) => void;
    nextPath?: string;
    showMessage?: any;
    nextPathUseResponseData?: boolean;
    errorMessage?: string;
  };
  setState?: {
    set?: (_: any) => void;
    organize?: (_: any) => void;
  };
}

export default function usePush() {
  const navigate = useNavigate();
  const { pathname, search } = useLocation();
  const staff = useStaffInformation();

  return (
    resolve: (v: unknown) => void,
    {
      reqProp: { method = 'get', pathKey, pathParam, queryParams, data, addOption, notAlert },
      aftProp: { calledFunc, errorFunc, nextPath, showMessage, nextPathUseResponseData, errorMessage } = {},
    }: UsePushProps
  ) => {
    const url = createApiUrl({
      staff,
      pathKey,
      pathParam,
      queryParams,
    });
    return apiCall({
      reqProp: { method, url, data, pathKey, addOption, notAlert },
      curtProp: { staff, pathname, search },
      aftProp: {
        calledFunc,
        errorFunc,
        resolve,
        nextPath,
        navigate,
        showMessage,
        nextPathUseResponseData,
        errorMessage,
      },
    });
  };
}
