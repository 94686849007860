import { useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  Stack,
  Tooltip,
} from '@mui/material';
import { MdOutlineCancel } from 'react-icons/md';
import { useForm } from 'react-hook-form';
import { RHFRadioButtonGroup, FormProvider } from 'src/components/atoms/hook-form';
import { LoadingButton } from '@mui/lab';
import { BiErrorCircle } from 'react-icons/bi';

export const CancelButton = ({ onSubmit, isSubmitting, ...props }: any) => {
  const [deleteMdl, setDeleteMdl] = useState<boolean>(false);

  const methods = useForm({
    defaultValues: {
      ステータス: 'キャンセル',
      sendAlertFlg: 'true',
      sendAlertType: 'reserve_cancel',
    },
  });
  const { handleSubmit } = methods;
  const onSubmitWithModalClose = async (data: any) => {
    await onSubmit(data);
    setDeleteMdl(false);
  };

  return (
    <>
      <Tooltip title="キャンセル">
        <IconButton {...props} aria-label="delete" size="small" onClick={() => setDeleteMdl(true)}>
          <Box sx={{ pl: 1.5 }}>キャンセル</Box>
          <Box sx={{ pr: 1.5, pt: 0.5, pl: 1 }}>
            <MdOutlineCancel size={22} />
          </Box>
        </IconButton>
      </Tooltip>

      <Dialog open={deleteMdl} onClose={() => setDeleteMdl(false)}>
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmitWithModalClose)}>
          <Stack justifyContent="center" alignItems="center" sx={styles.container}>
            {
              <>
                <Box sx={styles.iconConstainer}>
                  <BiErrorCircle />
                </Box>
                <DialogTitle id="alert-dialog-title" sx={styles.title}>
                  本当にキャンセルしますか？
                </DialogTitle>
                <DialogContent>
                  <DialogContentText> キャンセルの取り消しはできません</DialogContentText>
                </DialogContent>
                <Box sx={{ p: 3 }}>
                  <Grid container>
                    <Grid item xs={12} sm={12} md={12}>
                      <RHFRadioButtonGroup
                        label="お客様へのメール送信"
                        name="sendAlertFlg"
                        options={[
                          {
                            value: true,
                            label: 'する',
                          },
                          {
                            value: false,
                            label: 'しない',
                          },
                        ]}
                      />
                    </Grid>
                  </Grid>
                </Box>
              </>
            }
            <DialogActions>
              <Button variant="outlined" color="primary" onClick={() => setDeleteMdl(false)}>
                キャンセル
              </Button>
              <LoadingButton variant="contained" sx={{ width: 100 }} color="error" type="submit" loading={isSubmitting}>
                OK
              </LoadingButton>
            </DialogActions>
          </Stack>
        </FormProvider>
      </Dialog>
    </>
  );
};
const styles = {
  container: {
    p: 2,
  },
  iconConstainer: {
    fontSize: 100,
    color: '#BF6761',
  },
  title: {
    fontWeight: 'bold',
  },
};
