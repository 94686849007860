import { FC } from 'react';
import { Container, Stack, Typography } from '@mui/material';
import Page from 'src/components/molecules/page';
import { FrameForm } from 'src/components/organisms/reservation-frame/form';

export const PhotoStudioFrameEditPage: FC = () => {
  /* 定数宣言 */
  const title = '予約枠編集';

  return (
    <Page title={title}>
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            {title}
          </Typography>
        </Stack>
        <FrameForm />
      </Container>
    </Page>
  );
};
