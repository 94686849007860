import { Box, Grid } from '@mui/material';
import { PhotoStudioStaffPhotoStudio } from 'codegen/axios/photo/photo_studio';
import { ReactElement, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { FormProvider, RHFCheckboxGroup } from 'src/components/atoms/hook-form';
import useFetch from 'src/hooks/use-fetch';

export type TStudioSelect = {
  studioSelectState: any;
};

export const StudioSelect = ({ studioSelectState }: TStudioSelect): ReactElement => {
  const methods = useForm({
    defaultValues: { photoStudioIds: studioSelectState ? studioSelectState.checkedPsIds : '' },
  });
  useEffect(() => {
    if (!methods) return;
    studioSelectState.setCheckedPsIds(methods.getValues().photoStudioIds);
  }, [methods.watch()]);

  const { fetchedData: restaurantOption } = useFetch<PhotoStudioStaffPhotoStudio[]>({
    reqProp: {
      pathKey: 'studio',
      queryParams: { limit: 100, offset: 0 },
    },
    disable: false,
  });

  return (
    <Box sx={{ mx: 3, mt: 1 }}>
      <FormProvider methods={methods}>
        <Grid style={{ textAlign: 'center' }} item xs={12} sm={12} md={12}>
          <RHFCheckboxGroup
            name="photoStudioIds"
            options={restaurantOption.map((r) => ({ label: r.photoStudioName, value: r.photoStudioId }))}
          />
        </Grid>
      </FormProvider>
    </Box>
  );
};
