import { Link as RouterLink, useLocation } from 'react-router-dom';
import { Box } from '@mui/material';

export default function Logo({ disabledLink = false, sx }: any) {
  const { pathname } = useLocation();
  const basePath = pathname.split('/')[1];
  const logo = (
    <Box sx={{ width: 140, ...sx }}>
      <img src={`${process.env.PUBLIC_URL}/static/logo@2x.png`} alt="Logo" />
    </Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }
  if (basePath === 'photoStudioCustomer') {
    return <>{logo}</>;
  }

  return <RouterLink to={`/${basePath}`}>{logo}</RouterLink>;
}
