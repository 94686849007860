import React from 'react';
import ja from 'date-fns/locale/ja';
// form
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import { TextField } from '@mui/material';
import { TimePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

// ----------------------------------------------------------------------

export default function RHFTimePicker({ name, custom, ...other }: any) {
  const [open, setOpen] = React.useState(false);
  const { control } = useFormContext();

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ja}>
      <Controller
        name={name}
        control={control}
        render={({ field, fieldState: { error } }) => (
          <TimePicker
            open={open}
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
            {...custom}
            showToolbar
            {...other}
            {...field}
            value={field.value}
            onChange={(date) => {
              field.onChange(date);
            }}
            name={name}
            renderInput={(params) => (
              <TextField
                onClick={() => setOpen(true)}
                {...params}
                label={name}
                error={!!error}
                helperText={error?.message}
                {...other}
              />
            )}
          />
        )}
      />
    </LocalizationProvider>
  );
}
