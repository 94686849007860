import { LoadingButton } from '@mui/lab';
import { Card, Checkbox, Container, FormControlLabel, Stack, Table, TableContainer } from '@mui/material';
import { AvailablePlan, Rule } from 'codegen/axios/photo/photo_studio';
import { FC, useEffect } from 'react';
import { useForm, SubmitHandler, useFieldArray } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { FormProvider } from 'src/components/atoms/hook-form';
import NotFoundTableBody from 'src/components/molecules/not-found-table-body';
import { availablePlanFetchOrganize } from 'src/functions/fetch-value-organize';
import { availablePlanValueOrganize } from 'src/functions/push-value-organize';
import useFetch from 'src/hooks/use-fetch';
import usePush from 'src/hooks/use-push';

export type AvailablePlanFormType = {
  plans: Array<AvailablePlan>;
};

export const AvailablePlanForm: FC = () => {
  /* hook宣言 */
  const { id: photoStudioReservationFrameId } = useParams();
  const push = usePush();

  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
    reset,
  } = useForm<AvailablePlanFormType>({
    defaultValues: {
      plans: [],
    },
  });

  const { fields, append } = useFieldArray({
    control,
    name: 'plans',
  });

  /* ルールのデータ取得 */
  const { fetchedData: rule } = useFetch<Rule>({
    iniState: null,
    reqProp: {
      pathKey: 'rule',
      queryParams: { offset: 0, limit: 500 },
    },
    organize: (obj: Rule[]) => {
      // 与えられたrule配列から"ruleType": "status_color_change"の要素だけを抽出
      const statusColorChangeRules = obj.filter((r) => r.ruleType === 'status_plan_id');
      if (!statusColorChangeRules) {
        alert('ステータスプランIDのルールが存在しません。');
        return null;
      }
      const statusRule = statusColorChangeRules.find(
        (r) => r.conditionKey === 'ステータス' && r.conditionValue === '仮押え'
      );
      if (!statusRule) {
        alert('ステータスプランIDのルールが存在しません。');
        return null;
      }
      return statusRule;
    },
  });

  const { fetchedData: responseData } = useFetch<Array<AvailablePlan>>({
    iniState: [],
    reqProp: {
      pathKey: 'frame',
      pathParam: `${photoStudioReservationFrameId}/plans`,
    },
    organize: availablePlanFetchOrganize,
    disable: !rule,
    observable: [rule],
  });

  useEffect(() => {
    reset({ plans: responseData.filter((r) => r.photoStudioPlanId !== rule.actionValue) });
  }, [responseData]);

  const handleCheckboxChange = (index: number, checked: boolean) => {
    const newFields = [...fields];
    newFields[index].availableFlg = checked;
    reset({ plans: [] });
    append(newFields);
  };

  const onSubmit: SubmitHandler<AvailablePlanFormType> = (data: AvailablePlanFormType) => {
    const prePlan = responseData.find((r) => r.photoStudioPlanId === rule.actionValue);
    if (!prePlan) return;
    prePlan.availableFlg = true; // 強制的に仮抑えを有効にする
    new Promise((resolve) => {
      push(resolve, {
        reqProp: {
          pathKey: 'frame',
          pathParam: `${photoStudioReservationFrameId}/plans`,
          method: 'put',
          data: availablePlanValueOrganize([...data.plans, prePlan]),
        },
        aftProp: {
          resolve,
          nextPath: `/setting/frame/${photoStudioReservationFrameId}`,
          showMessage: {
            state: {
              snackBar: { open: true, viewType: 'success', message: '保存しました' },
            },
          },
        },
      });
    });
  };

  const isPlanNotFound = responseData.length !== 0;

  return (
    <FormProvider onSubmit={handleSubmit(onSubmit)}>
      {isPlanNotFound && (
        <Container>
          <Card sx={{ mt: 5, p: 5 }}>
            <Stack>
              {fields.map((field, index) => (
                <FormControlLabel
                  key={`${field.id}-${index}`}
                  label={field.name}
                  control={
                    <Checkbox
                      checked={field.availableFlg}
                      onChange={(e) => handleCheckboxChange(index, e.target.checked)}
                    />
                  }
                />
              ))}
            </Stack>
          </Card>

          <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }} />

          <Stack direction="row" alignItems="center" justifyContent="flex-end" sx={{ m: 1 }} spacing={8}>
            <LoadingButton type="submit" sx={{ width: 200 }} size="large" variant="contained" loading={isSubmitting}>
              保存
            </LoadingButton>
          </Stack>
        </Container>
      )}
      {!isPlanNotFound && (
        <Card>
          <TableContainer sx={{ minWidth: 800 }}>
            <Table>
              <NotFoundTableBody overrideMsg="プランが見つかりませんでした。「設定 > プラン」からプランを登録してください。" />
            </Table>
          </TableContainer>
        </Card>
      )}
    </FormProvider>
  );
};
