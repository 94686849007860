import { Dialog, Button, Grid, Stack, Box } from '@mui/material';
import log from 'src/utils/log';
import {
  PutReserve,
  PutReserveRequestBody,
  ReserveDetail,
  ReserveDetailFormInnerInputsInnerOptionsInner,
} from 'codegen/axios/photo/photo_studio';
import useStaffInformation from 'src/hooks/use-staff-information';
import { useLocation, useNavigate } from 'react-router-dom';
import { FormProvider, RHFRadioButtonGroup } from 'src/components/atoms/hook-form';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { statusChangeModalValidationSchema } from 'src/functions/validation-schema';
import usePush from 'src/hooks/use-push';
import { LoadingButton } from '@mui/lab';
import { useState } from 'react';
import { findFormObj } from '../semi-detail';

interface StatusChangeModalProps {
  modalRsvId: string;
  setStatusMdlRsvId: (value: string | undefined) => void;
  rsvDetail: ReserveDetail;
  fetchTriggerTimestampState: [number | null, React.Dispatch<React.SetStateAction<number>>];
}

interface StatusChangeFormValues {
  status: string;
}

export function StatusChangeModal({
  modalRsvId,
  setStatusMdlRsvId,
  rsvDetail,
  fetchTriggerTimestampState,
}: StatusChangeModalProps) {
  const location = useLocation();
  const [, setFetchTriggerTimestamp] = fetchTriggerTimestampState;
  const staff = useStaffInformation();
  const navigate = useNavigate();

  const push = usePush();
  const statusObj = findFormObj(rsvDetail.form, 'ステータス', 'ステータス');
  if (!statusObj) alert('ステータスが見つかりません');
  const handleClick = (ret: string) => {
    setStatusMdlRsvId(undefined);
    if (ret === 'rsvChange') {
      navigate(`/${staff.userType}/reservation/${modalRsvId}/edit`, { state: { beforeParams: location } });
    }
    if (ret === 'cancel') {
      log('削除する:Cancel時の処理を実行する');
    }
  };

  const methods = useForm({
    resolver: yupResolver(statusChangeModalValidationSchema()),
    defaultValues: {
      ステータス: statusObj!.input!.value![0] || '',
      sendAlertFlg: 'false',
    },
  });
  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  /* handle */
  const onSubmit = (formValue: any) =>
    new Promise((resolve) => {
      push(resolve, {
        reqProp: {
          pathKey: 'reservation',
          pathParam: modalRsvId,
          method: 'put',
          data: updateRsvDetailWithFormValueStatus(formValue, rsvDetail),
        },
        aftProp: {
          calledFunc: () => {
            setFetchTriggerTimestamp(Date.now());
            setStatusMdlRsvId(undefined);
          },
          resolve,
          nextPath: '',
          showMessage: {
            state: {
              snackBar: {
                open: true,
                viewType: 'success',
                message: '変更を保存しました',
              },
            },
          },
        },
      });
    });
  return (
    <>
      <Dialog open onClose={() => handleClick('close')}>
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <Stack justifyContent="center" alignItems="center" sx={styles.container}>
            {statusObj && (
              <>
                <Box sx={{ p: 3 }}>
                  <Grid container>
                    {/* todo:ここ rsvDetail.ReserveDetailFormInner.map */}

                    <Grid item xs={12} sm={12} md={12}>
                      <RHFRadioButtonGroup
                        label={'ステータス'}
                        name={'ステータス'}
                        options={
                          statusObj.input!.options
                            ? convertOptions(statusObj.input!.options)
                            : [{ value: '', label: '' }]
                        }
                        onChange={(e: any) => {
                          methods.setValue('ステータス', e.target.value); // フォームの値を更新します
                          handleSubmit(onSubmit)(); // onSubmit処理を呼び出します
                          handleClick('close'); // ダイアログを閉じます
                        }}
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={12} sx={{ mt: 3 }}>
                      <RHFRadioButtonGroup
                        label="お客様へのメール送信"
                        name="sendAlertFlg"
                        options={[
                          {
                            value: true,
                            label: 'する',
                          },
                          {
                            value: false,
                            label: 'しない',
                          },
                        ]}
                      />
                    </Grid>
                  </Grid>
                </Box>
              </>
            )}

            {/* 保存ボタンとキャンセルボタン */}
            <Stack direction="row" spacing={2} sx={{ mb: 2, mt: 2 }}>
              <Button variant="outlined" color="primary" onClick={() => handleClick('close')}>
                キャンセル
              </Button>
              <LoadingButton
                variant="contained"
                sx={{ width: 100 }}
                color="primary"
                type="submit"
                loading={isSubmitting}
              >
                保存
              </LoadingButton>
            </Stack>
          </Stack>
        </FormProvider>
      </Dialog>
    </>
  );
}
const styles = {
  container: {
    p: 2,
  },
  iconConstainer: {
    fontSize: 100,
    color: '#BF6761',
  },
  title: {
    fontWeight: 'bold',
  },
};
function convertOptions(options: ReserveDetailFormInnerInputsInnerOptionsInner[]): { value: string; label: string }[] {
  return options.map((option) => ({
    value: option.label || '',
    label: option.label || '',
  }));
}

type FormValue = {
  [key: string]: string;
};

export function updateRsvDetailWithFormValueStatus(formValue: any, rsvDetail: ReserveDetail): PutReserveRequestBody {
  const updatedForm = rsvDetail.form.map((group) => ({
    ...group,
    inputs:
      group.inputs?.map((input) => {
        if (input?.name && formValue[input.name] !== undefined) {
          return { ...input, value: [formValue[input.name]] };
        }
        return input;
      }) || [],
  }));
  // から文字をkeyから削除
  const reserve = Object.entries(rsvDetail.reserve)
    .filter(([key, value]) => value !== '')
    .reduce((accumulator: any, [key, value]) => {
      accumulator[key] = value;
      return accumulator;
    }, {});

  const sendAlertType = formValue.ステータス === 'キャンセル' ? 'reserve_cancel' : 'reserve_update';

  return {
    reserve: {
      ...reserve,
      photoStudioOptions: rsvDetail.selectedOption,
      reservationStartDateTime: rsvDetail.reserve.reservationStartDateTime,
      photoStudioId: rsvDetail.reserve.photoStudioId,
      photoStudioFrameId: rsvDetail.reserve.photoStudioFrameId,
      sendAlertFlg: formValue.sendAlertFlg === 'true',
      // メール送信タイプ
      ...(formValue.sendAlertFlg === 'true' ? { sendAlertType } : {}),
      ...(formValue.ステータス === 'キャンセル' ? { cancelFlg: true } : {}),
    },
    form: updatedForm,
  };
}
