// form
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import { TextField } from '@mui/material';

export default function RHFZipCode({ name, onChange, methods, ...other }: any) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <TextField
          inputProps={{ maxLength: 8 }}
          {...field}
          fullWidth
          value={typeof field.value === 'number' && field.value === 0 ? '' : field.value}
          onChange={(e) => {
            field.onChange(e);
            onChange && onChange(e.target.value);
          }}
          onBlur={(e) => {
            e.target.value.replace(/[０-９]/g, (s) => String.fromCharCode(s.charCodeAt(0) - 0xfee0));
            if (e.target.value.replace(/-/, '').match(/\d{7}/)) {
              methods.setValue('zip', e.target.value.replace(/-/, ''));
            }
          }}
          error={!!error}
          helperText={error?.message}
          {...other}
        />
      )}
    />
  );
}
