import { toApiDateTimeFormat, toApiTimeFormat, toApiDateFormat } from 'src/functions/date-time-organize';
import { phoneNumberDropHyphen } from 'src/functions/phone-number-format';
import { returnInputType, zipDropHyphen } from 'src/functions/format';
import { CompanyForm } from 'src/types/company-form';
import { CustomerForm } from 'src/types/customer-form';
import { PhotoStudioFormType } from 'src/components/organisms/photo-studio/form';
import { BusinessHourFormType } from 'src/components/organisms/photo-studio/detail/business-hour/form';
import {
  AvailableOption,
  AvailablePlan,
  PostPhotoReservationAutoMailSettingRequestBody,
} from 'codegen/axios/photo/photo_studio';
import { IrregularSettingFormType } from 'src/components/organisms/reservation-frame/detail/irregular-setting/form';
import { ReservationFrameFormType } from 'src/components/organisms/reservation-frame/form';
import { PostReserveRequest } from 'codegen/axios/photo/adca';
import { StaffFormType } from 'src/components/organisms/staff/form';
import { AccountFormType } from 'src/components/organisms/account/form';
import { PhotoFormUiPartsType } from 'src/components/organisms/reservation-form-setting/form';

export const formSettingValueOrganize = (
  data: PhotoFormUiPartsType,
  plans: Array<{ value: string; label: string }>
) => ({
  ...data,
  inputType: returnInputType(data.inputType),
  availableOptionIds: data.availableOptionIds,
  availablePlans: plans.map((plan: { value: string; label: string }) => ({
    photoStudioPlanId: plan.value,
    availableFlg: data.availablePlans.includes(plan.value),
  })),
  options: data.options.map((option: string) => ({ label: option })),
});

export const reservationForAdcaValueOrganize = (data: PostReserveRequest) => ({
  ...data,
  reservationStartDateTime: toApiDateTimeFormat(data.reservationStartDateTime),
  reservationEndDateTime: toApiDateTimeFormat(data.reservationEndDateTime),
  telno: phoneNumberDropHyphen(data.telno).value,
});

export const availablePlanValueOrganize = (plans: Array<AvailablePlan>) =>
  plans.map((v: AvailablePlan) => ({
    photoStudioPlanId: (v && v.photoStudioPlanId) ?? '',
    availableFlg: (v && v.availableFlg) ?? false,
  }));

export const availableOptionValueOrganize = (options: Array<AvailableOption>) =>
  options.map((v: AvailableOption) => ({
    photoStudioOptionId: (v && v.photoStudioOptionId) ?? '',
    availableFlg: (v && v.availableFlg) ?? false,
  }));

export const reservationFrameValueOrganize = (reservationFrame: ReservationFrameFormType) => {
  const { photoStudio, ...rest } = reservationFrame;
  return {
    ...rest,
    photoStudioId: photoStudio.id,
    photoStudioName: photoStudio.label,
    validStartDate: toApiDateFormat(reservationFrame.validStartDate),
    validEndDate: toApiDateFormat(reservationFrame.validEndDate),
    reservationStartTime: toApiTimeFormat(reservationFrame.reservationStartTime),
    reservationEndTime: toApiTimeFormat(reservationFrame.reservationEndTime),
  };
};

export const irregularSettingValueOrganize = (irregularSetting: IrregularSettingFormType) => ({
  ...irregularSetting,
  validStartDate: toApiDateFormat(irregularSetting.validStartDate),
  validEndDate: toApiDateFormat(irregularSetting.validEndDate),
  reservationStartTime: toApiTimeFormat(irregularSetting.reservationStartTime),
  reservationEndTime: toApiTimeFormat(irregularSetting.reservationEndTime),
});

export const photoStudioValueOrganize = (formValue: PhotoStudioFormType) => ({
  ...formValue,
  telno: phoneNumberDropHyphen(formValue.telno).value,
  zip: zipDropHyphen(formValue.zip),
  prefecture: formValue.prefecture ?? '',
});

export const businessHourValueOrganize = (formValue: BusinessHourFormType) => ({
  photoStudioId: formValue.photoStudioId ?? '',
  salesStartTime: toApiTimeFormat(formValue.salesStartTime),
  salesEndTime: toApiTimeFormat(formValue.salesEndTime),
  mondaySalesStartTime: toApiTimeFormat(formValue.mondaySalesStartTime),
  mondaySalesEndTime: toApiTimeFormat(formValue.mondaySalesEndTime),
  tuesdaySalesStartTime: toApiTimeFormat(formValue.tuesdaySalesStartTime),
  tuesdaySalesEndTime: toApiTimeFormat(formValue.tuesdaySalesEndTime),
  wednesdaySalesStartTime: toApiTimeFormat(formValue.wednesdaySalesStartTime),
  wednesdaySalesEndTime: toApiTimeFormat(formValue.wednesdaySalesEndTime),
  thursdaySalesStartTime: toApiTimeFormat(formValue.thursdaySalesStartTime),
  thursdaySalesEndTime: toApiTimeFormat(formValue.thursdaySalesEndTime),
  fridaySalesStartTime: toApiTimeFormat(formValue.fridaySalesStartTime),
  fridaySalesEndTime: toApiTimeFormat(formValue.fridaySalesEndTime),
  saturdaySalesStartTime: toApiTimeFormat(formValue.saturdaySalesStartTime),
  saturdaySalesEndTime: toApiTimeFormat(formValue.saturdaySalesEndTime),
  sundaySalesStartTime: toApiTimeFormat(formValue.sundaySalesStartTime),
  sundaySalesEndTime: toApiTimeFormat(formValue.sundaySalesEndTime),
  remark: formValue.remark ?? '',
});

export const regularHolidayValueOrganize = (formValue: any) => {
  if (formValue.monday === 'true' || formValue.monday === 'false') {
    formValue.monday = JSON.parse(formValue.monday.toLowerCase());
  }
  if (formValue.tuesday === 'true' || formValue.tuesday === 'false') {
    formValue.tuesday = JSON.parse(formValue.tuesday.toLowerCase());
  }
  if (formValue.wednesday === 'true' || formValue.wednesday === 'false') {
    formValue.wednesday = JSON.parse(formValue.wednesday.toLowerCase());
  }
  if (formValue.thursday === 'true' || formValue.thursday === 'false') {
    formValue.thursday = JSON.parse(formValue.thursday.toLowerCase());
  }
  if (formValue.friday === 'true' || formValue.friday === 'false') {
    formValue.friday = JSON.parse(formValue.friday.toLowerCase());
  }
  if (formValue.saturday === 'true' || formValue.saturday === 'false') {
    formValue.saturday = JSON.parse(formValue.saturday.toLowerCase());
  }
  if (formValue.sunday === 'true' || formValue.sunday === 'false') {
    formValue.sunday = JSON.parse(formValue.sunday.toLowerCase());
  }
  return formValue;
};

export const staffValueOrganize = (formValue: StaffFormType) => {
  const { team, ...rest } = formValue;
  return {
    ...rest,
    telno: phoneNumberDropHyphen(formValue.telno).value,
    marriageAgencyStaffTeamId: team[0].marriageAgencyStaffTeamId ?? '', // FIXME
  };
};

export const autoMailSettingOrganize = (formValue: any): PostPhotoReservationAutoMailSettingRequestBody => {
  const { team, ...rest } = formValue;
  console.log(formValue.plan);
  return {
    ...rest,
    type: formValue.type.name,
    photoStudioPlanId: formValue.plan.name,
    photoStudioId: formValue.photoStudio.name,
  };
};

export const accountValueOrganize = (formValue: AccountFormType) => ({
  ...formValue,
  telno: phoneNumberDropHyphen(formValue.telno).value,
});

export function customerValueOrganize(formValue: CustomerForm) {
  formValue.telno = phoneNumberDropHyphen(formValue.telno).value;
  return formValue;
}
export function companyValueOrganize(formValue: CompanyForm) {
  formValue.zip = zipDropHyphen(formValue.zip);
  formValue.telno = phoneNumberDropHyphen(formValue.telno).value;
  return formValue;
}

export function permissionValueOrganize({ formValue, staffId }: { formValue: { id: string }; staffId: string }) {
  return { photoStudioId: formValue.id, photoStaffId: staffId };
}

export function permissionValueDeleteOrganize(value: any) {
  const valueHash = {};
  (valueHash as any).restaurantStaffViewPermissionId = value;
  return valueHash;
}

export function searchNameValueOrganize(input: any) {
  input = input.replace(/\s+/g, '');
  if (input.match(/[0-9]/g)) {
    // 数字が入っていたらハイフンを削除
    input = input.replace(/-/g, '');
  }
  return input;
}
