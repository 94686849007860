// material
import { alpha, styled } from '@mui/material/styles';
import { Box, AppBar, Toolbar } from '@mui/material';
// components
import { FC } from 'react';
import { usePathname } from 'src/hooks/use-pathname';
import useResponsive from 'src/hooks/use-responsive';
import Header from '../main/header';
import { DashboardSidebar } from './DashboardSidebar';

// ----------------------------------------------------------------------
const pathsOnDark = ['/photoStudioStaff/schedule'];
const DRAWER_WIDTH = 280;
const APPBAR_MOBILE = 60;
const APPBAR_TABLET = 60;
const APPBAR_DESKTOP = 64;

const RootStyle = styled(AppBar)(({ theme }) => ({
  boxShadow: 'none',
  // backdropFilter: 'blur(6px)', // なんか重いので消した
  WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
  backgroundColor: alpha(theme.palette.background.default, 0.72),
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  minHeight: APPBAR_MOBILE,
  [theme.breakpoints.up('md')]: {
    minHeight: APPBAR_TABLET,
    padding: theme.spacing(0, 5),
  },
  [theme.breakpoints.up('lg')]: {
    minHeight: APPBAR_DESKTOP,
    padding: theme.spacing(0, 5),
  },
}));

type Props = {
  onOpenSidebar: (value: React.SetStateAction<boolean>) => void;
};

export const DashboardNavbar: FC<Props> = ({ onOpenSidebar }) => {
  const mdUp = useResponsive('up', 'md');
  const pathname = usePathname();
  const phStaff = pathname.includes('/photoStudioStaff');

  const actionPage = (arr: string[]) => arr.some((path) => pathname === path);

  return (
    <RootStyle>
      <ToolbarStyle>
        {/* モバイルかつphotoStaffだったらこっちのnav使ってレスポンシズに対応、todo:いつか統一して */}
        {!mdUp && phStaff ? (
          <Header headerOnDark={actionPage(pathsOnDark)} />
        ) : (
          <DashboardSidebar isOpenSidebar onCloseSidebar={() => {}} />
        )}

        <Box sx={{ flexGrow: 1 }} />
      </ToolbarStyle>
    </RootStyle>
  );
};
