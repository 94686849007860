import { FC, useState, useRef, useCallback, useMemo, useEffect } from 'react';
import FullCalendar, { EventClickArg, DatesSetArg } from '@fullcalendar/react';
import { useSearchParams } from 'react-router-dom';
import { resourceFetchOrganize, scheduleForCalendarFetchOrganize } from 'src/functions/fetch-value-organize';
import useFetch from 'src/hooks/use-fetch';
import { Box, Card, Grid, LinearProgress } from '@mui/material';
import { PhotoStudioReservationFrame, ReserveRequestBody, Rule } from 'codegen/axios/photo/photo_studio';
import { toApiDateTimeFormat } from 'src/functions/date-time-organize';
import { ReservationEmptyDetail } from 'src/components/organisms/reservation/list/emptyDetail';
import { ReservationSemiDetail } from 'src/components/organisms/reservation/list/semi-detail';
import { CalendarHeader } from 'src/components/organisms/schedule/reservation-schedule/header';
import { formatDateToTimeSimple } from 'src/functions/format';
import { OFFSET_MIN } from 'src/utils/timeEnv';
import usePush from 'src/hooks/use-push';
import { FRAME_INDEX_PARAMS } from "src/utils/requestParams";
import { calcNextDay, calcOneWeekLater } from 'src/functions/date';
import { DynamicFilterBar } from './dynamic-filter-bar';
import { ScheduleCalendar } from './schedule-calendar';

export const reservationListDynamicExcludedKeys = [
  'photoStudioId',
  'photoStudioPlanId',
  'photoStudioFrameId',
  'viewFilterItem',
  'viewItem',
  'searchName',
  'periodStart',
  'periodEnd',
  'reserveIndexFilterView',
  'photoStudio',
  'photoStudioFrame',
  'photoStudioPlan',
];

export const ReservationSchedule: FC = () => {
  /* searchParams */
  const [searchParams] = useSearchParams();
  const viewType = searchParams.get('viewType') ?? 'resourceTimeGridFourDay';
  const push = usePush();
  const [searchFilterParams, setSearchFilterParams] = useState<{ [key: string]: any } | null>(null);
  const [fetchTriggerTimestamp, setFetchTriggerTimestamp] = useState<number | null>(null);
  // <ScheduleCalendar/> が動いていない場合に<DynamicFilterBar/>や<CalendarHeader/>から'予約枠取得してください'の通知を直接もらう用
  const [frameFetchTriggerTimestamp, setFrameFetchTriggerTimestamp] = useState<number | null>(null);

  /* hook宣言 */
  const [loading, setLoading] = useState(false);
  const [eventAndRsvData, setEventAndRsvData] = useState<{ eventEmptyOnly: any[]; rsvSearch: any[] }>({
    eventEmptyOnly: [],
    rsvSearch: [],
  });
  const [rule, setRule] = useState([]);

  // 現在ページのURLパラメータをもとに、表示期間の開始日と終了日を取得する
  const getStartAndEndDate = () => {
    const dateSearchParam = searchParams.get('date');
    const start = dateSearchParam ? new Date((new Date(dateSearchParam)).toDateString()) : new Date((new Date()).toDateString());
    const viewTypeSearchParam = searchParams.get('viewType')
    const end = viewTypeSearchParam === 'resourceTimeGridFourDay' || viewTypeSearchParam === null ? calcNextDay(start) : calcOneWeekLater(start);

    return { start, end }
  }
  const { start: initialStartDate, end: initialEndDate } = getStartAndEndDate();

  const [startDate, setStartDate] = useState<Date | undefined>(initialStartDate);
  const [endDate, setEndDate] = useState<Date | undefined>(initialEndDate);

  const [selectSlotDuration, setSelectSlotDuration] = useState<{ value: string; label: string }>({
    label: '3分',
    value: '0:03',
  });
  const [semiDetailRsvId, setSemiDetailRsvId] = useState<undefined | string>();
  const [emptyDetail, setEmptyDetail] = useState({ open: false, info: {} });
  const bodyData: ReserveRequestBody = {
    reserve: {
      photoStudioId: searchFilterParams?.photoStudioId,
      photoStudioPlanId: searchFilterParams?.photoStudioPlanId,
      photoStudioFrameId: searchFilterParams?.photoStudioFrameId,
    },
    formInput:
      searchFilterParams &&
      Object.keys(searchFilterParams).reduce((obj: any, key) => {
        if (
          !reservationListDynamicExcludedKeys.includes(key) &&
          searchFilterParams[key] !== '' &&
          !(Array.isArray(searchFilterParams[key]) && searchFilterParams[key].length === 0)
        ) {
          obj[key] = typeof searchFilterParams[key] === 'string' ? [searchFilterParams[key]] : searchFilterParams[key];
        }
        return obj;
      }, {}),
  };
  const endDateObj = new Date(endDate || '');
  const oneSecondBeforeEndDate = new Date(endDateObj.setSeconds(endDateObj.getSeconds() - 1)); // 1を引く
  /* データ取得 */

  useEffect(() => {
    const limitRefresh = async (): Promise<void> => {
      if (
        startDate === undefined ||
        endDate === undefined ||
        oneSecondBeforeEndDate === undefined ||
        searchFilterParams === null
      )
        return undefined;

      if (isWeek(new Date(endDate), new Date(startDate))) {
        // if (data.photoStudioId.length !== 1) searchParams.delete('viewType');
        // setSearchParams(searchParams);これで2個更新されるので入れてる、viewtypeが変更するとカレンダーがstartを変えてしまう
        if (searchFilterParams.photoStudioId.length !== 1) {
          return undefined;
        }
      }
      setLoading(true);
      const response = await push(() => 1, {
        reqProp: {
          pathKey: 'schedule',
          method: 'post',
          queryParams: {
            startDateTime: startDate && toApiDateTimeFormat(startDate),
            endDateTime: oneSecondBeforeEndDate && toApiDateTimeFormat(oneSecondBeforeEndDate),
          },
          data: {
            ...(searchFilterParams.photoStudioId.length === 1
              ? { photoStudioId: searchFilterParams?.photoStudioId[0] }
              : {}),
          },
        },
        aftProp: {
          errorFunc: () => 1,
          nextPath: ``,
        },
      });
      const rsvResponse = await push(() => 1, {
        reqProp: {
          pathKey: 'reservationSearch',
          method: 'post',
          queryParams: {
            startDatetime: startDate && toApiDateTimeFormat(startDate),
            endDatetime: oneSecondBeforeEndDate && toApiDateTimeFormat(oneSecondBeforeEndDate),
            offset: 0,
            limit: 2000,
          },
          data: bodyData,
        },
        aftProp: {
          errorFunc: () => 1,
          nextPath: ``,
        },
      });
      setEventAndRsvData({
        eventEmptyOnly: response as any,
        rsvSearch: rsvResponse as any,
      });
      return setLoading(false);
    };

    limitRefresh();
  }, [startDate?.toString(), endDate?.toString(), fetchTriggerTimestamp]);

  useEffect(() => {
    const { start, end } = getStartAndEndDate();
    setStartDate(start);
    setEndDate(end);
  }, [frameFetchTriggerTimestamp]);

  /* 予約枠取得 */
  const { fetchedData: resources } = useFetch<PhotoStudioReservationFrame[]>({
    reqProp: {
      pathKey: 'frame',
      queryParams: {
        offset: FRAME_INDEX_PARAMS.offset,
        limit: FRAME_INDEX_PARAMS.limit,
        ...(startDate && { startDatetime: toApiDateTimeFormat(startDate) }),
        ...(endDate && { endDatetime: toApiDateTimeFormat(oneSecondBeforeEndDate) }),
      },
    },
    observable: [startDate?.toString(), endDate?.toString()],
  });

  useFetch<Array<Rule>>({
    reqProp: {
      pathKey: 'rule',
      queryParams: { offset: 0, limit: 500 },
    },
    setStateFunc: setRule,
    disable: rule.length > 0,
  });

  // 予約イベント取得用の日付セット
  const datesSet = useCallback(
    (arg: DatesSetArg) => {
      setStartDate(arg.start);
      setEndDate(arg.end);
    },
    [setStartDate, setEndDate]
  );

  // 予約枠クリックで予約登録
  const eventClick = useCallback(
    (info: EventClickArg) => {
      if (info.event.extendedProps.id === '') {
        setSemiDetailRsvId(undefined);
        setEmptyDetail({ open: true, info });
      } else {
        setEmptyDetail({ open: false, info });
        setSemiDetailRsvId(info.event.extendedProps.id);
      }
    },
    [setSemiDetailRsvId, setEmptyDetail]
  );

  const searchFilterParamsState = useMemo(
    () => ({
      searchFilterParams,
      setSearchFilterParams,
    }),
    [searchFilterParams, setSearchFilterParams]
  );
  const refresh = useMemo(
    () => ({
      loading,
      trigger: () => setFetchTriggerTimestamp(Date.now()),
      // <ScheduleCalendar/> が動いていない場合に<DynamicFilterBar/>や<CalendarHeader/>から'予約枠取得してください'の通知を直接もらう用
      frameTrigger: () => setFrameFetchTriggerTimestamp(Date.now()),
    }),
    [loading, setFetchTriggerTimestamp]
  );

  const calendarRef = useRef<FullCalendar>(null!);
  const cResource = useMemo(
    () => resourceFetchOrganize(resources, searchFilterParams),
    [resources, searchFilterParams]
  );
  const cEvent = useMemo(() => {
    if (startDate && endDate && rule.length > 0 && resources.length > 0) {
      return mergeEvents(
        eventAndRsvData.eventEmptyOnly,
        scheduleForCalendarFetchOrganize(eventAndRsvData.rsvSearch, rule),
        resources
      );
    }
    return [];
  }, [startDate, endDate, rule, resources, eventAndRsvData.eventEmptyOnly, scheduleForCalendarFetchOrganize]);
  const emptyFilteredResource = useMemo(() => emptyResourceDelete(cEvent, cResource), [cEvent, cResource]);
  return (
    <Box sx={{ width: '100%' }}>
      <Box>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={12}>
            <Card>
              <DynamicFilterBar
                searchFilterParamsState={searchFilterParamsState}
                resources={resources}
                loading={loading}
                calendarRef={calendarRef}
                refresh={refresh}
              />
            </Card>
          </Grid>
          <Grid
            item
            xs={semiDetailRsvId || emptyDetail.open ? 12 : 12}
            sm={semiDetailRsvId || emptyDetail.open ? 6 : 12}
            md={semiDetailRsvId || emptyDetail.open ? 9 : 12}
          >
            <Card sx={{ pt: 3 }}>
              <CalendarHeader
                filterUseProperty={false}
                calendarRef={calendarRef}
                selectSlotDuration={selectSlotDuration}
                setSelectSlotDuration={setSelectSlotDuration}
                weekButtonView={!(searchFilterParams?.photoStudioId.length !== 1)}
                refresh={refresh}
              />
              {loading && <LinearProgress />}
              {resources.length > 0 && (
                <ScheduleCalendar
                  datesSet={datesSet}
                  calendarRef={calendarRef}
                  selectSlotDuration={selectSlotDuration}
                  resources={resources}
                  viewType={viewType}
                  eventClick={eventClick}
                  emptyFilteredResource={emptyFilteredResource}
                  cEvent={cEvent}
                />
              )}
            </Card>
          </Grid>

          {semiDetailRsvId && (
            <Grid item xs={12} sm={6} md={3}>
              <Card sx={{ mt: 0 }}>
                <ReservationSemiDetail
                  fetchTriggerTimestampState={[fetchTriggerTimestamp, setFetchTriggerTimestamp]}
                  rsvId={semiDetailRsvId}
                />
              </Card>
            </Grid>
          )}
          {emptyDetail.open && (
            <Grid item xs={12} sm={6} md={3}>
              <Card sx={{ mt: 0 }}>
                <ReservationEmptyDetail
                  emptyDetailState={[emptyDetail, setEmptyDetail]}
                  setSemiDetailRsvId={setSemiDetailRsvId}
                  fetchTriggerTimestampState={[fetchTriggerTimestamp, setFetchTriggerTimestamp]}
                />
              </Card>
            </Grid>
          )}
        </Grid>
      </Box>
    </Box>
  );
};

function formatLocalDateTimeSample(date: Date): string {
  return `${date.toISOString().split('.')[0]}Z`;
}

interface Event {
  photoStudioReserveId: string;
  title: string;
  start: string;
  end: string;
  color?: string;
  resourceIds: string[];
  extendedProps: {
    id: string;
    customerNameKana: string;
    photoStudioFrameName: string | undefined;
    photoStudioFrameId: string;
    gender: string;
    plan: any;
    staffRemark: string[] | undefined;
    photoStudioName: string | undefined;
    photoStudioId: string;
    time: string;
  };
}

export function emptyResourceDelete(event: Event[], resource: any) {
  // eventのresourceIdsに一回も登場しないresourceを削除する
  const resourceIds = event.map((e) => e.resourceIds);
  const resourceIdsFlat = resourceIds.flat();
  const resourceIdsSet = new Set(resourceIdsFlat); // 重複を削除
  const resourceIdsArray = Array.from(resourceIdsSet); // 重複を削除したresourceIdsを配列に変換
  const resourceFiltered = resource.filter((r: any) => resourceIdsArray.includes(r.id));
  return resourceFiltered;
}

export function mergeEvents(
  emptyEvents: any[],
  bookedEvents: Event[],
  resources: PhotoStudioReservationFrame[]
): Event[] {
  const mergedEvents: Event[] = [];

  const futureTime = new Date(new Date().getTime() + OFFSET_MIN * 60000); // 60分 = 60 * 60,000ミリ秒
  emptyEvents.forEach((emptyEvent: any) => {
    const startDateTime = new Date(emptyEvent.startDatetime);
    const endDateTime = new Date(emptyEvent.endDatetime);
    const photoStudioReservationFrameId = emptyEvent.photoStudioFrameId;
    const frame = resources.find(
      (resource) => resource.photoStudioReservationFrameId === photoStudioReservationFrameId
    );
    const availableEvent: Event = {
      photoStudioReserveId: '',
      title: '空き',
      start: formatLocalDateTimeSample(startDateTime),
      end: formatLocalDateTimeSample(endDateTime),
      ...(futureTime > startDateTime ? { color: '#ccc' } : {}),
      resourceIds: [photoStudioReservationFrameId],
      extendedProps: {
        id: '',
        customerNameKana: '',
        photoStudioFrameName: frame?.name,
        photoStudioFrameId: photoStudioReservationFrameId,
        gender: '',
        plan: '',
        staffRemark: [''],
        photoStudioName: frame?.photoStudioName,
        photoStudioId: frame?.photoStudioId!,
        time: formatDateToTimeSimple(startDateTime),
      },
    };
    mergedEvents.push(availableEvent);
  });

  bookedEvents.forEach((bookedEvent) => {
    mergedEvents.push(bookedEvent);
  });
  return mergedEvents;
}

export const renderEventContent = (eventInfo: any) => (
  <div style={{ textAlign: 'center', lineHeight: '1' }}>
    <span>{eventInfo.event.extendedProps.time}~</span>
    <br />
    {eventInfo.event.extendedProps.status && (
      <>
        <span style={{ fontSize: '12px' }}>
          {'《'}
          {eventInfo.event.extendedProps.status}
          {'》'}
        </span>
        <br />
      </>
    )}
    <b style={{ fontSize: '12px' }}>{eventInfo.event.title}</b>
    <br />
    {eventInfo.event.extendedProps.customerNameKana && eventInfo.event.extendedProps.gender && (
      <>
        <span style={{ fontSize: '10px' }}>
          {'('}
          {eventInfo.event.extendedProps.customerNameKana}
          {')'}
        </span>
        <br />
        <span style={{ fontSize: '12px' }}>{eventInfo.event.extendedProps.gender}</span>
      </>
    )}
    <br />
    {eventInfo.event.extendedProps.plan && typeof eventInfo.event.extendedProps.plan === 'string' && (
      <b style={{ fontSize: '10px' }}>{`・${eventInfo.event.extendedProps.plan.split('※')[0]}`}</b>
    )}

    <br />
    <span style={{ fontSize: '10px' }}>
      {eventInfo.event.extendedProps.staffRemark && typeof eventInfo.event.extendedProps.staffRemark[0] === 'string'
        ? eventInfo.event.extendedProps.staffRemark[0].split('\n').map((item: any, key: any) => (
            <span key={key}>
              {item}
              <br />
            </span>
          ))
        : ''}
    </span>
    <br />
  </div>
);

function isWeek(end: Date, start: Date) {
  return end.getTime() - start.getTime() === 604800000;
}
