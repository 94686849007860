import Page from 'src/components/molecules/page';
import { Container } from '@mui/material';
import { FC } from 'react';
import { CustomerReservationSelect } from 'src/components/organisms/customer-reservation/reservation-select';

export const CustomerReservationIndex: FC = () => {
  /* 定数宣言 */
  const title = '店舗・日時選択';

  return (
    <Page title={title}>
      <Container maxWidth={false}>
        <CustomerReservationSelect />
      </Container>
    </Page>
  );
};
