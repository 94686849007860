import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, Typography, Box, CircularProgress } from '@mui/material';
import log from 'src/utils/log';
import useFetch from 'src/hooks/use-fetch';
import {
  ReserveDetail,
  ReserveDetailFormInner,
  ReserveDetailFormInnerInputsInner,
} from 'codegen/axios/photo/photo_studio';
import useStaffInformation from 'src/hooks/use-staff-information';
import usePush from 'src/hooks/use-push';
import { toDateDefault, toDateTimeDefault, toTimeDefault, toTimeOnly } from 'src/functions/date-time-organize';

import { format } from 'date-fns';
import { LoadingButton } from '@mui/lab';
import { phoneNumberAddHyphen } from 'src/functions/phone-number-format';
import { StatusChangeModal } from '../status-change-modal';
import { usePdfExport } from '../hooks/use-pdf-export';

// ----------------------------------------------------------------------

interface ReservationSemiDetailProps {
  rsvId: string;
  handleSubmit?: (ids: string[]) => void;
  fetchTriggerTimestampState: [number | null, React.Dispatch<React.SetStateAction<any>>];
}

export function ReservationSemiDetail({ rsvId, handleSubmit, fetchTriggerTimestampState }: ReservationSemiDetailProps) {
  const [pdfButtonVisible, setPdfButtonVisible] = useState(false);
  const [fetchTriggerTimestamp] = fetchTriggerTimestampState;
  const { exportPdf, verifyExportPdf, loading: pdfLoading } = usePdfExport();
  const staff = useStaffInformation();
  const [statusMdlRsvId, setStatusMdlRsvId] = useState<undefined | string>();

  /* データ取得 */
  const { fetchedData: rsvDetail, loading } = useFetch<ReserveDetail>({
    iniState: null,
    reqProp: {
      pathKey: 'reservation',
      pathParam: `${rsvId}`,
    },
    // organize: reserveListFetchOrganize,
    observable: [rsvId, fetchTriggerTimestamp],
  });

  useEffect(() => {
    const verifyPdf = async () => {
      if (rsvDetail) {
        const result = await verifyExportPdf([rsvDetail], [rsvId]);
        setPdfButtonVisible(result);
      }
    };
    verifyPdf();
  }, [rsvDetail, rsvId]);

  return (
    <>
      {statusMdlRsvId && rsvDetail && (
        <StatusChangeModal
          modalRsvId={rsvId}
          setStatusMdlRsvId={setStatusMdlRsvId}
          rsvDetail={rsvDetail}
          fetchTriggerTimestampState={fetchTriggerTimestampState}
        />
      )}
      {loading ? (
        <Box sx={{ p: 10, display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh' }}>
          <CircularProgress size={30} />
        </Box>
      ) : (
        <>
          {rsvDetail && (
            <Box sx={{ p: 3 }}>
              <Button variant="contained" onClick={() => setStatusMdlRsvId(rsvId)} sx={{ m: 2, ml: 'auto' }}>
                <strong>ステータス: </strong>{' '}
                {(() => {
                  const statusObj = findFormObj(rsvDetail.form, 'ステータス', 'ステータス');

                  return statusObj && statusObj.input?.value?.toString();
                })()}
              </Button>
              <Typography color="text.secondary" variant="body1">
                <strong>店舗:</strong> {rsvDetail.reserve?.photoStudioName}
              </Typography>{' '}
              <Typography color="text.secondary" variant="body1">
                <strong>予約枠:</strong> {rsvDetail.reserve?.photoStudioFrameName}
              </Typography>{' '}
              <Typography color="text.secondary" variant="body1">
                <strong>利用日時:</strong> {toDateDefault(rsvDetail.reserve?.reservationStartDateTime)}{' '}
                <strong>
                  {`${toTimeOnly(rsvDetail.reserve?.reservationStartDateTime)} ~ ${toTimeOnly(
                    rsvDetail.reserve?.reservationEndDateTime
                  )}`}
                </strong>
              </Typography>
              <Typography color="text.secondary" variant="body1">
                <strong>お名前:</strong>{' '}
                {`${rsvDetail.reserve?.customerFirstName} ${rsvDetail.reserve?.customerLastName}`}
              </Typography>
              <Typography color="text.secondary" variant="body1" sx={{ ml: 5.2 }}>
                {(rsvDetail.reserve?.customerFirstNameKana || rsvDetail.reserve?.customerLastNameKana) && (
                  <> {`（${rsvDetail.reserve?.customerFirstNameKana} ${rsvDetail.reserve?.customerLastNameKana}）`}</>
                )}
              </Typography>
              <Typography color="text.secondary" variant="body1">
                <strong>電話番号:</strong> {phoneNumberAddHyphen(rsvDetail.reserve?.customerTelno)}
              </Typography>
              <br />
              <Typography color="text.secondary" variant="body1">
                <strong>プラン:</strong> <br />・{rsvDetail.reserve?.photoStudioPlanName}
              </Typography>
              <Typography color="text.secondary" variant="body1">
                <strong>オプション:</strong> <br />
                {rsvDetail.reserve?.photoStudioOptions.flatMap((v) => [
                  `・${v.name}`,
                  <>
                    <br />
                  </>,
                ])}
              </Typography>
              <Typography color="text.secondary" variant="body1">
                <strong>お見合い会社:</strong>
                {(() => {
                  const fromObj = findFormObj(rsvDetail.form, '撮影情報', 'お見合い会社・仲人');
                  return fromObj
                    ? fromObj.input?.value[0].split('\n').map((item, key) => (
                        <span key={key}>
                          {item}
                          <br />
                        </span>
                      ))
                    : '';
                })()}
              </Typography>
              {(() => {
                const obj = findFormObj(rsvDetail.form, '撮影情報', '撮影プラン（キッズ）');
                if (!obj) return false;
                if (
                  obj.input?.availablePlans.find(
                    (plan) => plan.photoStudioPlanId === rsvDetail.reserve.photoStudioPlanId
                  )
                )
                  return true;
              })() && (
                <Typography color="text.secondary" variant="body1">
                  <strong>撮影プラン（キッズ）:</strong> <br />
                  {(() => {
                    const fromObj = findFormObj(rsvDetail.form, '撮影情報', '撮影プラン（キッズ）');
                    return fromObj
                      ? fromObj.input?.value[0].split('\n').map((item, key) => (
                          <span key={key}>
                            {item}
                            <br />
                          </span>
                        ))
                      : '';
                  })()}
                </Typography>
              )}
              {(() => {
                const obj = findFormObj(rsvDetail.form, '撮影情報', '備考（証明）');
                if (!obj) return false;
                if (
                  obj.input?.availablePlans.find(
                    (plan) => plan.photoStudioPlanId === rsvDetail.reserve.photoStudioPlanId
                  )
                )
                  return true;
              })() && (
                <Typography color="text.secondary" variant="body1">
                  <strong>備考（証明）:</strong> <br />
                  {(() => {
                    const fromObj = findFormObj(rsvDetail.form, '撮影情報', '備考（証明）');
                    return fromObj
                      ? fromObj.input?.value[0].split('\n').map((item, key) => (
                          <span key={key}>
                            {item}
                            <br />
                          </span>
                        ))
                      : '';
                  })()}
                </Typography>
              )}
              {(() => {
                const obj = findFormObj(rsvDetail.form, '撮影情報', '備考');
                if (!obj) return false;
                if (
                  obj.input?.availablePlans.find(
                    (plan) => plan.photoStudioPlanId === rsvDetail.reserve.photoStudioPlanId
                  )
                )
                  return true;
              })() && (
                <Typography color="text.secondary" variant="body1">
                  <strong>備考:</strong> <br />
                  {(() => {
                    const fromObj = findFormObj(rsvDetail.form, '撮影情報', '備考');
                    return fromObj
                      ? fromObj.input?.value[0].split('\n').map((item, key) => (
                          <span key={key}>
                            {item}
                            <br />
                          </span>
                        ))
                      : '';
                  })()}
                </Typography>
              )}
              {(() => {
                const obj = findFormObj(rsvDetail.form, '撮影情報', '【PA】備考');
                if (!obj) return false;
                if (
                  obj.input?.availablePlans.find(
                    (plan) => plan.photoStudioPlanId === rsvDetail.reserve.photoStudioPlanId
                  )
                )
                  return true;
              })() && (
                <Typography color="text.secondary" variant="body1">
                  <strong>【PA】備考:</strong> <br />
                  {(() => {
                    const fromObj = findFormObj(rsvDetail.form, '撮影情報', '【PA】備考');
                    return fromObj
                      ? fromObj.input?.value[0].split('\n').map((item, key) => (
                          <span key={key}>
                            {item}
                            <br />
                          </span>
                        ))
                      : '';
                  })()}
                </Typography>
              )}
              {(() => {
                const obj = findFormObj(rsvDetail.form, '撮影情報', 'お子様名');
                if (!obj) return false;
                if (
                  obj.input?.availablePlans.find(
                    (plan) => plan.photoStudioPlanId === rsvDetail.reserve.photoStudioPlanId
                  )
                )
                  return true;
              })() && (
                <Typography color="text.secondary" variant="body1">
                  <strong>お子様名:</strong>
                  {(() => {
                    const fromObj = findFormObj(rsvDetail.form, '撮影情報', 'お子様名');
                    const fromObjKana = findFormObj(rsvDetail.form, '撮影情報', 'お子様フリガナ');
                    return fromObj
                      ? fromObj.input?.value[0].split('\n').map((item, key) => (
                          <span key={key}>
                            {item}
                            <br />
                            <Box sx={{ ml: 6.5 }}>
                              {fromObjKana
                                ? `（${fromObjKana.input?.value[0].split('\n')}）
                            `
                                : ''}
                            </Box>
                          </span>
                        ))
                      : '';
                  })()}
                </Typography>
              )}
              {(() => {
                const obj = findFormObj(rsvDetail.form, '撮影情報', 'お子様性別');
                if (!obj) return false;
                if (
                  obj.input?.availablePlans.find(
                    (plan) => plan.photoStudioPlanId === rsvDetail.reserve.photoStudioPlanId
                  )
                )
                  return true;
              })() && (
                <Typography color="text.secondary" variant="body1">
                  <strong>お子様性別:</strong>
                  {(() => {
                    const fromObj = findFormObj(rsvDetail.form, '撮影情報', 'お子様性別');
                    return fromObj
                      ? fromObj.input?.value[0].split('\n').map((item, key) => (
                          <span key={key}>
                            {item}
                            <br />
                          </span>
                        ))
                      : '';
                  })()}
                </Typography>
              )}
              <Typography color="text.secondary" variant="body1">
                <strong>スタッフ備考:</strong> <br />
                {(() => {
                  const fromObj = findFormObj(rsvDetail.form, '注意事項', 'スタッフ備考');
                  return fromObj
                    ? fromObj.input?.value[0].split('\n').map((item, key) => (
                        <span key={key}>
                          {item}
                          <br />
                        </span>
                      ))
                    : '';
                })()}
              </Typography>
              <br />
            </Box>
          )}
        </>
      )}

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          pb: 2,
        }}
      >
        {rsvDetail && (
          <>
            {pdfButtonVisible && (
              <LoadingButton
                variant="contained"
                disabled={pdfLoading}
                color="inherit"
                size="small"
                onClick={() => exportPdf([rsvDetail], [rsvId])}
                sx={{ mr: 2 }}
              >
                {'PDF出力'}
              </LoadingButton>
            )}
          </>
        )}

        <Button
          variant="contained"
          color={'primary'}
          target="_blank"
          size="small"
          component={Link}
          to={`/${staff.userType}/reservation/${rsvId}`}
        >
          {'詳細'}
        </Button>
      </Box>
    </>
  );
}

type FindFormObjResult = {
  group: ReserveDetailFormInner;
  input?: ReserveDetailFormInnerInputsInner;
} | null;

export function findFormObj(
  ReserveDetailFormInner: ReserveDetailFormInner[],
  groupName: string,
  inputName?: string
): FindFormObjResult {
  const foundGroup = ReserveDetailFormInner.find((formGroup) => formGroup.groupName === groupName);
  if (!foundGroup) return null;
  if (inputName) {
    const foundInput = foundGroup.inputs?.find((input) => input.name === inputName);
    if (!foundInput) return null;
    return { group: foundGroup, input: foundInput };
  }
  return { group: foundGroup };
}
