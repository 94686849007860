// form
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import { TextField } from '@mui/material';

import { phoneNumberDropHyphen } from 'src/functions/phone-number-format';

export default function RHFTextField({ name, methods, ...other }: any) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <TextField
          {...field}
          fullWidth
          value={typeof field.value === 'number' && field.value === 0 ? '' : field.value}
          error={!!error}
          onBlur={(event) => {
            const result = phoneNumberDropHyphen(event.target.value);
            methods.setValue('telno', result.value);
            if (result.valid) {
              methods.trigger('telno');
            }
          }}
          helperText={error?.message}
          {...other}
        />
      )}
    />
  );
}
