import * as React from 'react';
import { Box, CircularProgress, Container } from '@mui/material';
import useStaffInformation from 'src/hooks/use-staff-information';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import {
  PostReserveRequestBodyForPhotoStudioCustomer,
  PostReserveRequestBodyForPhotoStudioCustomerFormInner,
  PostReserveRequestBodyForPhotoStudioCustomerFormInnerInputsInner,
  PostReserveRequestBodyForPhotoStudioCustomerFormInnerInputsInnerInputTypeEnum,
  PhotoStudioCustomerPhotoFormUiPartsList,
  PostReserveForPhotoStudioCustomer,
} from 'codegen/axios/photo/photo_studio_customer';
import { getPlanViewTitle } from 'src/utils/customerIndexView';
import useFetchQuery from 'src/hooks/use-custom-fetch';
import { CustomerReservationInputForm } from './form';

const organizeFlattenedData = (data: PhotoStudioCustomerPhotoFormUiPartsList[]) => {
  const flattenedData: { [key: string]: any } = {
    customerName: '',
    customerNameKana: '',
    customerEmail: '',
    customerTelno: '',
    customerGender: '',
    zip: '',
    prefecture: '',
    city: '',
    street: '',
  };
  data.forEach((group) => {
    (group.inputs || []).forEach((input) => {
      switch (input.inputType) {
        case 'radiobutton':
          if (!input.options) return;
          flattenedData[input.name] = '';
          break;
        case 'checkbox':
          if (!input.options) return;
          flattenedData[input.name] = [];
          break;
        case 'selectbox':
          if (!input.options) return;
          flattenedData[input.name] = null;
          break;
        default:
          flattenedData[input.name] = '';
      }
      // ここでステータスを予約確定に固定
      if (group.groupName === 'ステータス' && input.name === 'ステータス') {
        flattenedData[input.name] = '予約確定';
      }
    });
  });
  return flattenedData;
};

export const CustomerReservationInput: React.FC = () => {
  const staff = useStaffInformation();
  const navigate = useNavigate();
  const location = useLocation();
  const { search, hash } = useLocation();
  const [searchParams] = useSearchParams();
  const paramsId = searchParams.get('id') ?? 1;
  const { photoCompanyId } = getPlanViewTitle(Number(paramsId));
  const { state } = location as any;
  if (state === null) navigate(`/${staff.userType}/reservation`);
  /* データ取得 */
  const { data: formInputs } = useFetchQuery<any>({
    pathKey: 'formInputs',
    queryParams: { photoCompanyId },
  });

  /* handle */
  const onSubmit = (formValue: InputFormData) => {
    sessionStorage.setItem('cmr-rsv-form-data', JSON.stringify(formValue));
    navigate(`/${staff.userType}/reservation/check${search}${hash}`, {
      state: {
        postData: convertInputFormToPostReserveRequestBody(formValue, formInputs),
        otherData: {
          reservationStartDateTime: formValue.reservationStartDateTime,
          photoStudio: formValue.photoStudio,
          photoStudioPlan: formValue.photoStudioPlan,
          photoStudioOptions: formValue.photoStudioOptions,
        },
      },
    });
  };

  return (
    <Container>
      {formInputs && state ? (
        <CustomerReservationInputForm
          onSubmit={onSubmit}
          formInputs={formInputs}
          defaultValues={{ ...organizeFlattenedData(formInputs), ...state }}
        />
      ) : (
        <Box>
          <CircularProgress
            size={60}
            sx={{
              position: 'fixed',
              left: '45%',
              top: '50%',
              transform: 'translate(-50%, -50%)',
              zIndex: 2,
            }}
          />
        </Box>
      )}
    </Container>
  );
};

type InputFormData = {
  customerName: string;
  customerNameKana: string;
  email: string;
  telno: string;
  gender: string;
  zip: string;
  prefecture: string;
  city: string;
  street: string;
  [key: string]: any;
};
const filterEmptyKeys = (obj: PostReserveForPhotoStudioCustomer): Partial<PostReserveForPhotoStudioCustomer> =>
  Object.fromEntries(Object.entries(obj).filter(([, value]) => value !== ''));

function convertInputFormToPostReserveRequestBody(
  inputFormData: InputFormData,
  fetchedData: PhotoStudioCustomerPhotoFormUiPartsList[]
): PostReserveRequestBodyForPhotoStudioCustomer {
  const [customerFirstName, customerLastName] = inputFormData.customerName.trim().split(/[\s\u3000]+/);
  const [customerFirstNameKana, customerLastNameKana] = inputFormData.customerNameKana.trim().split(/[\s\u3000]+/);
  const form = convertGroups(inputFormData, fetchedData);
  const reserve = {
    reservationStartDateTime: inputFormData.reservationStartDateTime,
    photoStudioId: inputFormData.photoStudio.photoStudioId,
    photoStudioPlanId: inputFormData.photoStudioPlan.photoStudioPlanId,
    photoStudioOptions: inputFormData.photoStudioOptions.map(
      (option: { photoStudioOptionId: string; name: string }) => ({
        photoStudioOptionId: option.photoStudioOptionId,
      })
    ),
    customerEmail: inputFormData.customerEmail,
    customerTelno: inputFormData.customerTelno.replace(/-/g, ''),
    customerFirstName,
    customerFirstNameKana,
    customerLastName,
    customerLastNameKana,
    customerGender: inputFormData.customerGender,
    zip: inputFormData.zip.replace(/-/g, ''),
    prefecture: inputFormData.prefecture,
    city: inputFormData.city,
    street: inputFormData.street,
    remark: 'inputFormData.remark',
  };

  return {
    reserve: filterEmptyKeys(reserve) as PostReserveForPhotoStudioCustomer, // これできたらキャスト辞めたい
    form,
  };
}

function convertGroups(
  inputFormData: InputFormData,
  fetchedData: PhotoStudioCustomerPhotoFormUiPartsList[]
): PostReserveRequestBodyForPhotoStudioCustomerFormInner[] {
  return fetchedData.map((group) => {
    const inputs = convertInputs(inputFormData, group);
    return {
      groupName: group.groupName,
      orderNumber: group.orderNumber,
      inputs,
    };
  });
}

function convertInputs(
  inputFormData: InputFormData,
  group: PhotoStudioCustomerPhotoFormUiPartsList
): PostReserveRequestBodyForPhotoStudioCustomerFormInnerInputsInner[] {
  return (group.inputs || []).map((input) => {
    let value: Array<string> = [];
    switch (input.inputType) {
      case 'selectbox':
        if (input.name) value = [inputFormData[input.name]?.name || ''];
        break;
      case 'checkbox':
        if (input.name) value = inputFormData[input.name] || [];
        break;

      default:
        if (input.name) {
          value = [inputFormData[input.name] || ''];
        }
        break;
    }

    const options = input.options
      ? input.options.map((option) => ({
          label: option.label,
          value: option.label,
        }))
      : [];

    return {
      requiredFlg: input.requiredFlg,
      customerViewFlg: input.customerViewFlg,
      availablePlans: input.availablePlans.map((obj) => ({ photoStudioPlanId: obj.photoStudioPlanId || '' })),
      availableOptionIds: input.availableOptionIds,
      orderNumber: input.orderNumber,
      inputType: input.inputType as PostReserveRequestBodyForPhotoStudioCustomerFormInnerInputsInnerInputTypeEnum,
      label: input.name,
      name: input.name,
      placeholder: input.placeholder || '',
      value,
      options: options.map((obj) => ({ label: obj.label || '' })),
    };
  });
}
