import { useParams } from 'react-router-dom';
import usePush from 'src/hooks/use-push';
import { autoMailSettingOrganize as valueOrganize } from 'src/functions/push-value-organize';
import { FC } from 'react';
import useFetch from 'src/hooks/use-fetch';
import { PhotoStudioStaff, PostPhotoStudioStaffRequestBody } from 'codegen/axios/photo/photo_studio';
import { mailSettingFetchForFormOrganize } from 'src/functions/fetch-value-organize';
import { AutoMailSettingForm } from 'src/components/organisms/auto-mail-setting/edit-create/form';

export type AccountFormType = Omit<PostPhotoStudioStaffRequestBody, 'photoStudioStaffId'>;

export const AutoMailSettingEditCreate: FC = () => {
  /* hook宣言 */
  const push = usePush();
  const { id } = useParams();

  /* 定数宣言 */
  const editMode = !id;
  // /* データ取得 */
  const { fetchedData: responseData } = useFetch<PhotoStudioStaff>({
    iniState: null,
    reqProp: {
      pathKey: 'autoMailSetting',
      pathParam: `${id}`,
    },
    disable: editMode,
    organize: mailSettingFetchForFormOrganize,
  });

  /* handle */
  const onSubmit = (formValue: any) =>
    new Promise((resolve) => {
      push(resolve, {
        reqProp: {
          pathKey: 'autoMailSetting',
          pathParam: id,
          method: editMode ? 'post' : 'put',
          data: valueOrganize(formValue),
        },
        aftProp: {
          resolve,
          nextPath: `/setting/autoMailSetting`,
          showMessage: {
            state: {
              snackBar: { open: true, viewType: 'success', message: editMode ? '登録しました' : '保存しました' },
            },
          },
        },
      });
    });
  return (
    <>{(responseData || !id) && <AutoMailSettingForm defaultValues={responseData} onSubmit={onSubmit} id={id} />}</>
  );
};
