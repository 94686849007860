import { FC, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Stack, Grid, Card, CardHeader } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { FormProvider, RHFTextArea, RHFRadioButtonGroup } from 'src/components/atoms/hook-form';
import { photoStudioRegularHolidayValidationSchema } from 'src/functions/validation-schema';
import usePush from 'src/hooks/use-push';
import { useParams } from 'react-router-dom';
import useFetch from 'src/hooks/use-fetch';
import { regularHolidayFormFetchOrganize } from 'src/functions/fetch-value-organize';
import { PhotoStudioRegularHoliday } from 'codegen/axios/photo/photo_studio';

export type RegularHolidayFormType = Omit<PhotoStudioRegularHoliday, 'photoStudioRegularHolidayId'>;

export const defaultValues: RegularHolidayFormType = {
  photoStudioId: '',
  sunday: false,
  monday: false,
  tuesday: false,
  wednesday: false,
  thursday: false,
  friday: false,
  saturday: false,
  remark: '',
};

export const RegularHolidayForm: FC = () => {
  /* hook宣言 */
  const push = usePush();
  const { id: photoStudioId } = useParams();
  const { regularHolidayId: photoStudioRegularHolidayId } = useParams();
  const holidayOrNotOptions = [
    {
      value: false,
      label: '営業日',
    },
    {
      value: true,
      label: '定休日',
    },
  ];

  /* 定数宣言 */
  const editMode = !photoStudioRegularHolidayId;
  const validationSchema = photoStudioRegularHolidayValidationSchema();

  /* データ取得 */
  const { fetchedData: responseData } = useFetch<PhotoStudioRegularHoliday>({
    iniState: defaultValues,
    reqProp: {
      pathKey: 'regularHoliday',
      pathParam: photoStudioRegularHolidayId,
    },
    disable: editMode,
    organize: regularHolidayFormFetchOrganize,
  });

  const methods = useForm<RegularHolidayFormType>({
    resolver: yupResolver(validationSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  useEffect(() => {
    methods.reset(responseData);
  }, [responseData, methods]);

  const onSubmit = (formValue: RegularHolidayFormType) =>
    new Promise((resolve) => {
      push(resolve, {
        reqProp: {
          pathKey: 'regularHoliday',
          pathParam: photoStudioRegularHolidayId,
          method: editMode ? 'post' : 'put',
          data: { ...formValue, photoStudioId },
        },
        aftProp: {
          resolve,
          nextPath: `/setting/photoStudio/${photoStudioId}`,
          showMessage: {
            state: {
              snackBar: { open: true, viewType: 'success', message: editMode ? '登録しました' : '保存しました' },
            },
          },
        },
      });
    });
  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Card>
        <CardHeader title="定休日" />

        <Stack my={7} mx={4}>
          <Grid container rowSpacing={3} columnSpacing={3}>
            <Grid item pl={8} xs={12} sm={12} md={6}>
              <RHFRadioButtonGroup label="月曜日" name="monday" options={holidayOrNotOptions} />
            </Grid>

            <Grid item pl={8} xs={12} sm={12} md={6}>
              <RHFRadioButtonGroup label="火曜日" name="tuesday" options={holidayOrNotOptions} />
            </Grid>

            <Grid item pl={8} xs={12} sm={12} md={6}>
              <RHFRadioButtonGroup label="水曜日" name="wednesday" options={holidayOrNotOptions} />
            </Grid>

            <Grid item pl={8} xs={12} sm={12} md={6}>
              <RHFRadioButtonGroup label="木曜日" name="thursday" options={holidayOrNotOptions} />
            </Grid>

            <Grid item pl={8} xs={12} sm={12} md={6}>
              <RHFRadioButtonGroup label="金曜日" name="friday" options={holidayOrNotOptions} />
            </Grid>

            <Grid item pl={8} xs={12} sm={12} md={6}>
              <RHFRadioButtonGroup label="土曜日" name="saturday" options={holidayOrNotOptions} />
            </Grid>

            <Grid item pl={8} xs={12} sm={12} md={6}>
              <RHFRadioButtonGroup label="日曜日" name="sunday" options={holidayOrNotOptions} />
            </Grid>

            <Grid item xs={12} sm={6} md={6}>
              <RHFTextArea
                name="remark"
                label="定休日に関する備考"
                placeholder="例：日曜日※12月は日曜日も営業いたします。"
              />
            </Grid>
          </Grid>
        </Stack>
      </Card>

      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }} />

      <Stack direction="row" alignItems="center" justifyContent="flex-end" sx={{ m: 1 }} spacing={8}>
        <LoadingButton sx={{ width: 200 }} size="large" type="submit" variant="contained" loading={isSubmitting}>
          {editMode ? '登録' : '保存'}
        </LoadingButton>
      </Stack>
    </FormProvider>
  );
};
