import { Box, Container, Grid, Pagination, Stack, Typography } from '@mui/material';
import { FC, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { createQueryParameter } from 'src/functions/api-parameter';
import { partnerStudioListFetchOrganize } from 'src/functions/fetch-value-organize';
import { createParams } from 'src/functions/params';
import useFetch from 'src/hooks/use-fetch';
import ListToolbar from 'src/components/molecules/list-toolbar';
import usePush from 'src/hooks/use-push';
import useStaffInformation from 'src/hooks/use-staff-information';
import { RegionSelect } from './region-select';
import { CardList, PhotoStudioCardType } from './card-list';

type Props = {
  title: string;
  pathKey: 'studio' | 'bookmark';
};

export const PartnerStudioList: FC<Props> = ({ title, pathKey }) => {
  /* 定数宣言 */
  const rowsPerPage = 9;
  const isBookmark = pathKey === 'bookmark';

  /* hooks */
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const staff = useStaffInformation();
  const push = usePush();
  const pageParam = Number(searchParams.get('page')) ?? 1;
  const page = pageParam === 0 || pageParam < 0 ? 1 : pageParam;
  const order = searchParams.get('order') ?? 'asc';
  const regionFilterValue = searchParams.get('regionFilterValue') ?? '';
  const searchName = searchParams.get('searchName') ?? '';
  const query = createQueryParameter({
    regionFilterValue: { key: 'region', value: regionFilterValue },
    searchName: { key: 'searchName', value: searchName },
    order: { key: 'order', value: order },
  });

  const [listUpdate, setListUpdate] = useState(0);

  /* データ取得 */
  const { fetchedData: responseData } = useFetch<Array<PhotoStudioCardType>>({
    iniState: [],
    reqProp: {
      pathKey,
      queryParams: { offset: (page - 1) * rowsPerPage, limit: rowsPerPage, ...query },
    },
    observable: [searchParams, listUpdate],
    organize: partnerStudioListFetchOrganize,
  });

  // 次のページがあるかどうか
  const { fetchedData: nextPageCheck } = useFetch<number>({
    reqProp: {
      pathKey,
      pathParam: `count/`,
      queryParams: { ...query },
    },
    observable: [searchParams, listUpdate],
  });

  const count = Math.ceil(nextPageCheck / rowsPerPage);
  const isNotFound = responseData.length === 0;
  const isNotFountMessage = (message: string) => <Typography variant="h6">{message}</Typography>;

  /* handle */
  const handleSearchByName = (value: string) => {
    setSearchParams(createParams({ keyValues: { searchName: value }, searchParams, page: 1 }));
  };

  const handleChangePage = (_: any, newPage: number) => {
    setSearchParams(createParams({ keyValues: { page: newPage }, searchParams, setSearchParams }));
  };

  const onClickBookmark = (
    event: React.MouseEvent<HTMLButtonElement>,
    photoStudioId: string,
    bookmarkFlg?: boolean
  ) => {
    event.stopPropagation();
    new Promise((resolve) => {
      push(resolve, {
        reqProp: {
          pathKey: 'bookmark',
          method: 'put',
          data: { photoStudioId, bookmarkFlg: !bookmarkFlg },
        },
      }).then(() => {
        setListUpdate(listUpdate + 1);
        if (responseData.length === 1 && bookmarkFlg && isBookmark) handleChangePage('', page - 1);
      });
    });
  };

  const onClickDetailPage = (id: string) => {
    navigate(`/${staff.userType}/list/${id}`);
  };

  return (
    <Container maxWidth="xl">
      <Grid container rowSpacing={5} columnSpacing={3}>
        <Grid mt={4} item xs={12} sm={6} md={8}>
          <Typography variant="h4">{title}</Typography>
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <ListToolbar
            placeholder={!isBookmark ? 'フォトスタジオ名、エリア名' : 'フォトスタジオ名'}
            searchName={searchName}
            handleSearchByName={handleSearchByName}
            width={400}
          />
        </Grid>

        {!isBookmark && (
          <Grid item xs={12} sm={12} md={12}>
            <RegionSelect />
          </Grid>
        )}

        {!isNotFound && (
          <Grid item xs={12} sm={12} md={12}>
            <CardList
              photoStudios={responseData}
              onClickDetailPage={onClickDetailPage}
              onClickBookmark={onClickBookmark}
            />
          </Grid>
        )}
      </Grid>

      {!isNotFound && (
        <Stack direction="row" justifyContent="center" alignItems="center" sx={{ mt: 5 }}>
          <Pagination color="primary" page={page} count={count} onChange={handleChangePage} />
        </Stack>
      )}

      {isNotFound && (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '40vh' }}>
          {isNotFountMessage(!isBookmark ? 'フォトスタジオが見つかりませんでした' : 'ブックマークが登録されていません')}
        </Box>
      )}
    </Container>
  );
};
