import { FC, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Container } from '@mui/material';
import useFetch from 'src/hooks/use-fetch';
import { PhotoFormUiPartsList, PhotoPlan } from 'codegen/axios/photo/photo_studio';
import usePush from 'src/hooks/use-push';
import useStaffInformation from 'src/hooks/use-staff-information';
import { rsvCreateFetchOrganize } from '../functions/fetch-value-organize';
import { ReservationEditForm } from '../form';
import { rsvInputDefaultValues } from '../types/index.d';
import { convertInputFormToPostReserveRequestBody } from '../functions/push-value-organize';
import { filterAvailable } from '../functions';

export const ReservationCreate: FC = () => {
  /* hook宣言 */
  const staff = useStaffInformation();
  const navigate = useNavigate();
  //  export const scheduleForCalendarFetchOrganize = (values: Array<ReserveForCal
  const push = usePush();
  const [searchParams] = useSearchParams();
  const photoStudioName = searchParams.get('photoStudioName');
  const photoStudioId = searchParams.get('photoStudioId');
  const photoStudioFrameName = searchParams.get('photoStudioFrameName');
  const photoStudioFrameId = searchParams.get('photoStudioFrameId');
  const reservationStartDateTime = searchParams.get('startDateTime');
  const reservationEndDateTime = searchParams.get('endDateTime');
  const [selectPlanId, setSelectPlanId] = useState(null);
  const [selectFrameId, setSelectFrameId] = useState(null);
  const [options, setOptions] = useState(null);

  /* データ取得 */
  const { fetchedData: formInputs } = useFetch<PhotoFormUiPartsList[]>({
    iniState: null,
    reqProp: {
      pathKey: 'formInputs',
      queryParams: { limit: 200, offset: 0 },
    },
  });
  const { fetchedData: plans } = useFetch<PhotoPlan[]>({
    iniState: null,
    reqProp: {
      pathKey: 'frame',
      pathParam: `${selectFrameId || photoStudioFrameId}/plans`,
    },
    disable: !photoStudioFrameId,
    observable: [photoStudioFrameId, selectFrameId],
  });

  useFetch<any>({
    iniState: null,
    setStateFunc: setOptions,
    reqProp: {
      pathKey: 'plan',
      pathParam: `${selectPlanId}/options`,
    },
    disable: !selectPlanId,
    observable: [selectPlanId],
  });

  /* handle */
  const onSubmit = (formValue: rsvInputDefaultValues) =>
    new Promise((resolve) => {
      push(resolve, {
        reqProp: {
          pathKey: 'reservation',
          method: 'post',
          data: convertInputFormToPostReserveRequestBody(formValue, formInputs),
        },
        aftProp: {
          resolve,
          nextPath: ``,
          showMessage: {
            state: {
              snackBar: {
                open: true,
                viewType: 'success',
                message: '変更を保存しました',
              },
            },
          },
        },
      }).then((response) => {
        navigate(`/${staff.userType}/reservation/${(response as any).reserve.photoStudioReserveId}`);
      });
    });

  return (
    <Container>
      {formInputs && plans && (
        <ReservationEditForm<rsvInputDefaultValues>
          edit={false}
          onSubmit={onSubmit}
          defaultValues={{
            ...rsvCreateFetchOrganize(formInputs, [reservationStartDateTime || '', reservationEndDateTime || '']),
            photoStudioName,
            photoStudioId,
            photoStudioFrameName,
            photoStudioFrameId,
            reservationStartDateTime,
            reservationEndDateTime,
          }}
          formInputs={formInputs}
          plans={filterAvailable<any>(plans)}
          selectPlanState={[selectPlanId, setSelectPlanId]}
          selectFrameState={[selectFrameId, setSelectFrameId]}
          optionsState={[filterAvailable<any>(options), setOptions]}
        />
      )}
    </Container>
  );
};
