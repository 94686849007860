import Page from 'src/components/molecules/page';
import { FC } from 'react';
import { ReservationSchedule } from 'src/components/organisms/schedule/reservation-schedule';
import { Container } from '@mui/material';

export const ReservationSchedulePage: FC = () => {
  /* 定数宣言 */
  const title = 'スケジュール';

  return (
    <Page title={title}>
      {/* <Container maxWidth={false}> */}
      <ReservationSchedule />
      {/* </Container> */}
    </Page>
  );
};
