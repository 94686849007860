import { parse, format } from 'date-fns';
import * as Sentry from '@sentry/react';

export function toDateTimeDefault(data: any) {
  const dateTime = new Date(data);

  const year = `${dateTime.getFullYear().toString()}`;
  const month = `${(dateTime.getMonth() + 1).toString().padStart(2, '0')}`;
  const date = `${dateTime.getDate().toString().padStart(2, '0')}`;
  const hours = `${dateTime.getHours().toString().padStart(2, '0')}`;
  const minutes = `${dateTime.getMinutes().toString().padStart(2, '0')}`;
  const dayOfWeek = ['日', '月', '火', '水', '木', '金', '土'][dateTime.getDay()];

  return `${year}/${month}/${date} (${dayOfWeek}) ${hours}:${minutes}`;
}

export function toDateDefault(data: any) {
  const dateTime = new Date(data);

  const year = `${dateTime.getFullYear().toString()}`;
  const month = `${(dateTime.getMonth() + 1).toString().padStart(2, '0')}`;
  const date = `${dateTime.getDate().toString().padStart(2, '0')}`;
  const dayOfWeek = ['日', '月', '火', '水', '木', '金', '土'][dateTime.getDay()];

  return `${year}/${month}/${date} (${dayOfWeek})`;
}

export const toTimeDefault = (timeString: string): string => {
  const timeRegex = /^([0-1]?[0-9]|2[0-3]):([0-5][0-9]):([0-5][0-9])$/;
  if (!timeRegex.test(timeString)) {
    Sentry.captureMessage(`Invalid timeString string|toTimeDefault:${timeString}`, 'error');
    // throw new Error(`Invalid time string${timeString}`);
  }
  const [hours, minutes] = timeString.split(':').map(Number);
  if (hours === 24 || minutes >= 60) {
    throw new Error(`Invalid time value${timeString}`);
  }
  return `${Number(hours)}:${minutes.toString().padStart(2, '0')}`;
};

export const toTimeOnly = (dateString: string): string => {
  const date = new Date(dateString);
  const time = format(date, 'HH:mm'); // 時間を取得
  return time;
};

export function toApiTimeFormat(value: any) {
  const dateObj = new Date(value);

  if (isNaN(dateObj.getTime())) {
    console.log(`Invalid time string|value:${value}`);
    Sentry.captureMessage(`Invalid time string|value:${value}`, 'error');
  }

  const hours = dateObj.getHours().toString().padStart(2, '0');
  const minutes = dateObj.getMinutes().toString().padStart(2, '0');
  const seconds = dateObj.getSeconds().toString().padStart(2, '0');

  return `${hours}:${minutes}:${seconds}`;
}

export function toApiDateFormat(value: Date | string) {
  const dateObj = new Date(value);
  const years = dateObj.getFullYear();
  const months = (dateObj.getMonth() + 1).toString().padStart(2, '0');
  const days = dateObj.getDate().toString().padStart(2, '0');
  return `${years}-${months}-${days}`;
}

export function toApiDateTimeFormat(date: Date | string, start?: boolean, end?: boolean) {
  let newDate = date;
  if (typeof newDate === 'string') {
    newDate = new Date(date);
  }
  const str = `${newDate.getFullYear()}/${(newDate.getMonth() + 1).toString().padStart(2, '0')}/${newDate
    .getDate()
    .toString()
    .padStart(2, '0')} ${(() => {
    if (start) return '00';
    if (end) return '23';
    return newDate.getHours().toString().padStart(2, '0');
  })()}:${(() => {
    if (start) return '00';
    if (end) return '59';
    return newDate.getMinutes().toString().padStart(2, '0');
  })()}:${(() => {
    if (start) return '00';
    if (end) return '59';
    return newDate.getSeconds().toString().padStart(2, '0');
  })()}`;
  return str;
}

export const toStringYmdD = (date: Date | string): string => {
  let newDate = date;
  if (typeof newDate === 'string') {
    newDate = new Date(date);
  }
  const year = newDate.getFullYear();
  const month = newDate.getMonth() + 1;
  const day = newDate.getDate();
  const dayOfWeek = newDate.toLocaleString('ja', { weekday: 'short' });
  return `${year} 年 ${month} 月 ${day} 日 (${dayOfWeek})`;
};

export const toStringYm = (date: Date): string => {
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  return `${year} 年 ${month} 月`;
};

export const formatFlgToDayOfWeek = (
  sundayFlg?: boolean,
  mondayFlg?: boolean,
  tuesdayFlg?: boolean,
  wednesdayFlg?: boolean,
  thursdayFlg?: boolean,
  fridayFlg?: boolean,
  saturdayFlg?: boolean
): string => {
  const sunday = !sundayFlg ? `日` : ``;
  const monday = !mondayFlg ? `月` : ``;
  const tuesday = !tuesdayFlg ? `火` : ``;
  const wednesday = !wednesdayFlg ? `水` : ``;
  const thursday = !thursdayFlg ? `木` : ``;
  const friday = !fridayFlg ? `金` : ``;
  const saturday = !saturdayFlg ? `土` : ``;

  return `${sunday}${monday}${tuesday}${wednesday}${thursday}${friday}${saturday}`;
};

export const toScheduleApiDateTimeFormat = (date: Date): string => {
  const dateObj = new Date(date);
  const year = `${dateObj.getFullYear()}`;
  const month = `${(dateObj.getMonth() + 1).toString().padStart(2, '0')}`;
  const day = `${dateObj.getDate().toString().padStart(2, '0')}`;
  const hour = `${dateObj.getHours().toString().padStart(2, '0')}`;
  const minutes = `${dateObj.getMinutes().toString().padStart(2, '0')}`;
  return `${year}/${month}/${day} ${hour}:${minutes}:00`;
};

export const addHours = (date: string, hours: number): string => {
  const dateObject = new Date(date);
  dateObject.setHours(dateObject.getHours() + hours);
  return dateObject.toLocaleString('ja-JP', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
  });
};

export const toIsoString = (date: string): string =>
  date
    .replace(
      /(\d{4})\/(\d{1,2})\/(\d{1,2})/,
      (match, year, month, day) => `${year}-${String(month).padStart(2, '0')}-${String(day).padStart(2, '0')}`
    )
    .replace(
      / (\d{1,2}):(\d{1,2}):(\d{1,2})/,
      (match, hours, minutes, seconds) =>
        `T${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`
    )
    .replace(/ \+[0-9]{4}$/, '');
