import { FC } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { Image, Introduction } from 'codegen/axios/photo/adca';
import { ImageSlideshow } from 'src/components/organisms/reservation-for-adca/detail/introduction-card/image-slide-show';
import { imageGalleryFetchOrganize } from 'src/functions/fetch-value-organize';

type Props = {
  introduction: Introduction;
  imagesTop: Array<Image>;
};

export const IntroductionCard: FC<Props> = ({ introduction, imagesTop }) => (
  <>
    {imagesTop.length !== 0 && (
      <Grid item xs={12} sm={6}>
        <ImageSlideshow images={imageGalleryFetchOrganize(imagesTop)} />
      </Grid>
    )}

    <Grid item xs={12} sm={imagesTop.length !== 0 ? 6 : 12}>
      <Box>
        <Typography variant="h5" sx={{ mb: 2 }} style={{ textAlign: imagesTop.length !== 0 ? 'center' : 'left' }}>
          {introduction.commentTitle}
        </Typography>
        <Typography variant="body1" sx={{ mb: 2 }}>
          {introduction.commentBody}
        </Typography>
      </Box>
    </Grid>
  </>
);
