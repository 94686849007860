// form
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import { TextField, Autocomplete } from '@mui/material';

export default function RHFTextInputPrefecture({
  option,
  name,

  getOptionLabel,

  isOptionEqualToValue,

  onChange,

  textFieldOnChange,
  ...other
}: any) {
  const { control } = useFormContext();
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <Autocomplete
          isOptionEqualToValue={isOptionEqualToValue}
          getOptionLabel={getOptionLabel}
          {...field}
          onChange={(event, item) => {
            field.onChange(item);
            if (onChange) {
              onChange(item);
            }
          }}
          value={typeof field.value === 'number' && field.value === null ? '' : field.value}
          disablePortal
          id="combo-box-demo"
          options={option}
          renderOption={(props, option) => (
            <li {...props} key={option}>
              {option}
            </li>
          )}
          renderInput={(params) => (
            <TextField
              onChange={(event) => {
                textFieldOnChange && textFieldOnChange(event.target.value);
              }}
              {...params}
              label={name}
              error={!!error}
              helperText={error?.message}
              {...other}
              inputProps={{
                ...params.inputProps,
                autoComplete: 'new-password',
              }}
            />
          )}
        />
      )}
    />
  );
}
