import { Card, CardHeader, Grid, Stack, Typography } from '@mui/material';
import { PhotoPlanAvailableOptionsInner } from 'codegen/axios/photo/photo_studio';
import { FC } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import EditButton from 'src/components/atoms/edit-button';
import useStaffInformation from 'src/hooks/use-staff-information';

type Option = {
  options: Array<PhotoPlanAvailableOptionsInner>;
};

export const AvailableOptionCard: FC<Option> = ({ options }) => {
  /* 定数宣言 */
  const staff = useStaffInformation();
  const { id: planId } = useParams();
  const navigate = useNavigate();

  const handleEditButtonOnClick = () => {
    navigate(`/${staff.userType}/setting/plan/${planId}/available/option`);
  };

  return (
    <>
      <Stack direction="row" alignItems="center" justifyContent="flex-end" mt={9}>
        <EditButton handleEditOnClick={handleEditButtonOnClick} />
      </Stack>

      <Card sx={{ mt: 5 }}>
        <CardHeader title="対応可能オプション" sx={{ mb: 3, mt: 1 }} />
        <Stack my={7} mx={4}>
          <Grid container rowSpacing={3} columnSpacing={3}>
            <Grid item pt={4} xs={6} sm={3} md={6}>
              <Typography variant="body2">オプション名</Typography>
            </Grid>

            <Grid item pt={4} xs={6} sm={3} md={6}>
              <Stack spacing={2}>
                {options.map((option: PhotoPlanAvailableOptionsInner) => (
                  <Typography key={option.photoStudioOptionId} variant="body2">
                    ・　{option.name}
                  </Typography>
                ))}
              </Stack>
            </Grid>
          </Grid>
        </Stack>
      </Card>
    </>
  );
};
