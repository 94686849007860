// @mui
import { Container, Stack } from '@mui/material';
// components
import InputForm from 'src/components/organisms/company/company-input-form';
import HeadLineText from 'src/components/atoms/head-line-text';

export default function CompanyCreateTemplate({ OnSubmit, title, defaultValues }: any) {
  return (
    <Container>
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2} ml={1} mt={'3%'} mr={0}>
        <HeadLineText text={title} />
      </Stack>

      <InputForm OnSubmit={OnSubmit} defaultValues={defaultValues} />
    </Container>
  );
}
