import {
  Box,
  Stack,
  Typography,
  Container,
  Card,
  Table,
  TableContainer,
  TablePagination,
  Grid,
  LinearProgress,
  Switch,
} from '@mui/material';
import ExcelJS from 'exceljs';
import Scrollbar from 'src/components/molecules/scroll-bar';
import ListHead from 'src/components/molecules/list-head';
import CustomTableBody from 'src/components/molecules/custom-table-body';
import NotFoundTableBody from 'src/components/molecules/not-found-table-body';
import useFetch from 'src/hooks/use-fetch';
import { FC, useEffect, useState } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { createQueryParameter } from 'src/functions/api-parameter';
import { createParams } from 'src/functions/params';
import { outputFetchOrganize, reserveListFetchOrganize } from 'src/functions/fetch-value-organize';
import { count, labelDisplayedRows } from 'src/functions/pagination';
import {
  PhotoFormUiPartsList,
  PhotoStudioReservationFrame,
  ReserveDetail,
  ReserveRequestBody,
} from 'codegen/axios/photo/photo_studio';
import Iconify from 'src/components/atoms/Iconify';
import { LoadingButton } from '@mui/lab';
import usePush from 'src/hooks/use-push';
import { useTotalCountStore } from 'src/stores/totalCountStore';
import { toApiDateTimeFormat } from 'src/functions/date-time-organize';
import { FRAME_INDEX_PARAMS } from 'src/utils/requestParams';
import { calcOneWeekLater } from 'src/functions/date';
import { ReservationSemiDetail } from './semi-detail';
import { usePdfExport } from './hooks/use-pdf-export';
import { DynamicFilterBar } from '../../schedule/reservation-schedule/dynamic-filter-bar';

export const reservationListDynamicExcludedKeys = [
  'photoStudioId',
  'photoStudioPlanId',
  'photoStudioFrameId',
  'viewFilterItem',
  'viewItem',
  'searchName',
  'periodStart',
  'periodEnd',
  'reserveIndexFilterView',
];

export const ReservationList: FC = () => {
  /* hooks */
  const [searchParams] = useSearchParams();
  const [semiDetailRsvId, setSemiDetailRsvId] = useState<undefined | string>();
  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  const [fetchTriggerTimestamp, setFetchTriggerTimestamp] = useState<null | number>(null);
  const { pathname } = useLocation();
  const initialSearchFilterParamsStr = localStorage.getItem(pathname);
  const [searchFilterParams, setSearchFilterParams] = useState<{ [key: string]: any } | null>(initialSearchFilterParamsStr ? JSON.parse(initialSearchFilterParamsStr) : null);
  const { exportPdf, loading: pdfLoading } = usePdfExport();
  const [csvLoading, setCsvLoading] = useState(false);
  const push = usePush();
  const { fetchTotalCount } = useTotalCountStore();

  const DEFAULT_START_DATE = new Date();
  const DEFAULT_END_DATE = calcOneWeekLater(DEFAULT_START_DATE);

  useEffect(() => {
    const parsedParams = initialSearchFilterParamsStr ? JSON.parse(initialSearchFilterParamsStr) : {};

    const updatedParams = {
      ...parsedParams,
      periodStart: parsedParams.periodStart || DEFAULT_START_DATE,
      periodEnd: parsedParams.periodEnd || DEFAULT_END_DATE,
    };

    localStorage.setItem(pathname, JSON.stringify(updatedParams));
    setSearchFilterParams(updatedParams);
  }, []);

  /* 定数宣言 */
  const rowsPerPage = 30;
  const page = Number(searchParams.get('page')) ?? 0;
  const order = searchParams.get('order') ?? 'desc';
  const orderBy = searchParams.get('orderBy') ?? 'reservationStartDateTime';
  const query = createQueryParameter({
    orderBy: { key: 'orderBy', value: orderBy },
    order: { key: 'order', value: order },
    searchName: { key: 'searchName', value: searchFilterParams?.searchName },
    periodStart: { key: 'startDatetime', value: searchFilterParams?.periodStart },
    periodEnd: { key: 'endDatetime', value: searchFilterParams?.periodEnd },
  });
  const bodyData: ReserveRequestBody = {
    reserve: {
      photoStudioId: searchFilterParams?.photoStudioId,
      photoStudioPlanId: searchFilterParams?.photoStudioPlanId,
      photoStudioFrameId: searchFilterParams?.photoStudioFrameId,
    },
    formInput:
      searchFilterParams &&
      Object.keys(searchFilterParams).reduce((obj: any, key) => {
        if (
          !reservationListDynamicExcludedKeys.includes(key) &&
          searchFilterParams[key] !== '' &&
          !(Array.isArray(searchFilterParams[key]) && searchFilterParams[key].length === 0)
        ) {
          obj[key] = typeof searchFilterParams[key] === 'string' ? [searchFilterParams[key]] : searchFilterParams[key];
        }
        return obj;
      }, {}),
  };
  const startDatetimeForSearchFrame = toApiDateTimeFormat(searchFilterParams?.periodStart || DEFAULT_START_DATE);
  const endDatetimeForSearchFrame = toApiDateTimeFormat(searchFilterParams?.periodEnd || DEFAULT_END_DATE, false, true); // 時刻を23:59:59にする

  /* データ取得 */
  const { fetchedData: responseData, loading } = useFetch<Array<ReserveDetail>>({
    reqProp: {
      method: 'post',
      pathKey: 'reservationSearch',
      queryParams: {
        offset: page * rowsPerPage,
        limit: rowsPerPage,
        ...query,
      },
      data: bodyData,
    },
    disable: searchFilterParams === null,
    observable: [searchParams, searchFilterParams, fetchTriggerTimestamp],
  });

  const { fetchedData: nextPageCheck, loading: countLoading } = useFetch<boolean>({
    reqProp: {
      method: 'post',
      pathKey: 'reservationSearch',
      queryParams: { offset: (page + 1) * rowsPerPage, limit: 2, ...query },
      data: bodyData,
    },
    disable: searchFilterParams === null,
    observable: [searchParams, searchFilterParams, fetchTriggerTimestamp],
  });
  const { fetchedData: resources } = useFetch<PhotoStudioReservationFrame[]>({
    reqProp: {
      pathKey: 'frame',
      queryParams: {
        offset: FRAME_INDEX_PARAMS.offset,
        limit: FRAME_INDEX_PARAMS.limit,
        startDatetime: startDatetimeForSearchFrame,
        endDatetime: endDatetimeForSearchFrame,
      },
    },
    observable: [searchFilterParams?.periodStart?.toString(), searchFilterParams?.periodEnd?.toString()],
  });

  const { fetchedData: totalCount, loading: totalCountLoading } = useFetch<Number>({
    reqProp: {
      method: 'post',
      pathKey: 'reservationSearchCount',
      queryParams: {
        ...query,
      },
      data: bodyData,
    },
    disable: !fetchTotalCount,
    observable: [searchFilterParams, fetchTriggerTimestamp],
  });

  /* handle */
  const handleRowClick = (id: any) => setSemiDetailRsvId(id);
  const handlerClickDownload = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, format: 'xlsx') => {
    setCsvLoading(true);
    e.preventDefault();
    const workbook = new ExcelJS.Workbook();
    workbook.addWorksheet('sheet1');
    const worksheet = workbook.getWorksheet('sheet1');

    const formInput = await push(() => setCsvLoading(true), {
      reqProp: {
        pathKey: 'formInputs',
        queryParams: { limit: 200, offset: 0 },
      },
      aftProp: {
        errorFunc: () => setCsvLoading(false),
        nextPath: ``,
      },
    });

    const response = await push(() => setCsvLoading(false), {
      reqProp: {
        pathKey: 'reservationSearch',
        method: 'post',
        queryParams: {
          offset: 0,
          limit: 10000,
          ...query,
        },
        data: bodyData,
      },
      aftProp: {
        errorFunc: () => setCsvLoading(false),
        nextPath: ``,
      },
    });

    const { header, listValues } = outputFetchOrganize(response as any, formInput as PhotoFormUiPartsList[]);

    const columns = [
      { key: 'createdAt', header: '登録時間' },
      { key: 'reservationStartDateTime', header: '予約時間' },
      { key: 'reservationFrameName', header: '予約枠' },
      { key: 'customerName', header: '名前' },
      { key: 'customerNameKana', header: 'なまえ' },
      { key: 'customerGender', header: '性別' },
      { key: 'customerTelno', header: '電話番号' },
      { key: 'customerEmail', header: 'メールアドレス' },
      { key: 'zip', header: '郵便番号' },
      { key: 'prefecture', header: '都道府県' },
      { key: 'city', header: '市区町村' },
      { key: 'street', header: 'それ以降' },
      { key: 'photoStudioName', header: '店舗' },
      { key: 'planName', header: 'プラン' },
      { key: 'optionName', header: 'オプション' },
      ...Object.keys(header).map((key) => ({ key, header: key })),
    ];

    worksheet.columns = columns;
    worksheet.addRows(listValues);

    const uint8Array = await workbook.xlsx.writeBuffer();
    const blob = new Blob([uint8Array], { type: 'application/octet-binary' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `出力データ.${format}`;
    a.click();
    a.remove();
  };
  const emptyRows = page > 0 ? Math.max(0, rowsPerPage - responseData.length) : 0;
  const isUserNotFound = responseData.length === 0;

  return (
    <Box sx={{ width: '100%' }}>
      <Box>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={12}>
            <Container sx={{ mt: 1, mb: 4 }} maxWidth={false}>
              <Stack sx={{ mx: 3 }} direction="row" alignItems="center" justifyContent="space-between">
                <Typography variant="h4" gutterBottom>
                  予約
                </Typography>
                <Box>
                  {selectedRows.length > 0 && (
                    <LoadingButton
                      startIcon={<Iconify icon="material-symbols:download-rounded" />}
                      onClick={() => exportPdf(responseData, selectedRows)}
                      variant="contained"
                      color="primary"
                      sx={{ mr: 3 }}
                      loading={pdfLoading}
                    >
                      PDF出力
                    </LoadingButton>
                  )}
                  <LoadingButton
                    variant="contained"
                    startIcon={<Iconify icon="material-symbols:download-rounded" />}
                    onClick={(e) => handlerClickDownload(e, 'xlsx')}
                    loading={csvLoading}
                  >
                    Excel出力
                  </LoadingButton>
                </Box>
              </Stack>
            </Container>
            <Card>
              <DynamicFilterBar
                searchFilterParamsState={{
                  searchFilterParams,
                  setSearchFilterParams,
                }}
                resources={resources}
                loading={loading || countLoading || totalCountLoading}
                period
                viewItem
              />
            </Card>
          </Grid>
          <Grid item xs={semiDetailRsvId ? 12 : 12} sm={semiDetailRsvId ? 9 : 12} md={semiDetailRsvId ? 9 : 12}>
            <Card>
              {loading && <LinearProgress />}
              {responseData.length > 0 && (
                <DynamicList
                  total={fetchTotalCount ? totalCount : null}
                  loading={loading || countLoading || totalCountLoading}
                  nextPageCheck={nextPageCheck}
                  order={order}
                  orderBy={orderBy}
                  responseData={responseData}
                  handleRowClick={handleRowClick}
                  emptyRows={emptyRows}
                  isUserNotFound={isUserNotFound}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  count={count}
                  labelDisplayedRows={labelDisplayedRows}
                  selectedState={[selectedRows, setSelectedRows]}
                  viewItem={searchFilterParams && searchFilterParams.viewItem}
                />
              )}
            </Card>
          </Grid>

          {semiDetailRsvId && (
            <Grid item xs={12} sm={3} md={3}>
              <Card>
                <ReservationSemiDetail
                  fetchTriggerTimestampState={[fetchTriggerTimestamp, setFetchTriggerTimestamp]}
                  rsvId={semiDetailRsvId}
                  handleSubmit={(ids: string[]) => exportPdf(responseData, ids)}
                />
              </Card>
            </Grid>
          )}
        </Grid>
      </Box>
    </Box>
  );
};

const DynamicList = ({
  total,
  loading,
  nextPageCheck,
  order,
  orderBy,
  responseData,
  handleRowClick,
  emptyRows,
  isUserNotFound,
  rowsPerPage,
  page,
  count,
  labelDisplayedRows,
  selectedState,
  viewItem,
}: any) => {
  const [header, rsvData] = reserveListFetchOrganize(responseData, viewItem);
  const [selectedRows, setSelectedRows] = selectedState;
  const [searchParams, setSearchParams] = useSearchParams();
  const { fetchTotalCount, enableFetchTotalCount, disableFetchTotalCount } = useTotalCountStore();

  const handleSwitchChange = (event: any) => {
    if (event.target.checked) {
      enableFetchTotalCount();
    } else {
      disableFetchTotalCount();
    }
  };
  const handleChangePage = (_: any, newPage: any) => {
    setSearchParams(createParams({ keyValues: { page: newPage }, searchParams, setSearchParams }));
  };
  const handleRequestSort = (_: any, property: any) => {
    const isOrder = orderBy === property && order === 'asc' ? 'desc' : 'asc';
    setSearchParams(createParams({ keyValues: { order: isOrder, orderBy: property }, searchParams, setSearchParams }));
  };
  const handleSelectAllClick = (event: any) => {
    if (event.target.checked) {
      const newSelectIds = responseData.map((n: any) => n.reserve.photoStudioReserveId);
      setSelectedRows(newSelectIds);
      return;
    }
    setSelectedRows([]);
  };
  const handleClick = (_: React.MouseEvent<HTMLButtonElement>, id: string) => {
    const selectedIndex = selectedRows.indexOf(id);
    let newSelected: string[] = [];
    switch (selectedIndex) {
      case -1:
        newSelected = [...selectedRows, id];
        break;
      case 0:
        newSelected = selectedRows.slice(1);
        break;
      case selectedRows.length - 1:
        newSelected = selectedRows.slice(0, -1);
        break;
      default:
        newSelected = [...selectedRows.slice(0, selectedIndex), ...selectedRows.slice(selectedIndex + 1)];
        break;
    }
    setSelectedRows(newSelected);
  };

  const TABLE_HEAD = [
    {
      checkBoxProperty: {
        selected: selectedRows,
        handleClick,
        rowCount: rsvData.length,
        numSelected: selectedRows.length,
        onSelectAllClick: handleSelectAllClick,
      },
    },
    {
      id: 'reservationStartDateTime',
      label: '予約時間',
      align: 'center',
      typographyProperty: { variant: 'subtitle2', noWrap: true },
      sortable: true,
    },
    { id: 'reservationFrameName', label: '予約枠', align: 'left' },
    {
      id: 'customerName',
      label: '名前',
      align: 'left',
      typographyProperty: { variant: 'subtitle2', noWrap: true },
    },
    { id: 'photoStudioName', label: '店舗', align: 'left' },
    { id: 'planName', label: 'プラン', align: 'left' },
    ...Object.keys(header).map((key) => ({
      id: key,
      label: key,
      align: 'left',
    })),
    { id: 'createdAt', label: '登録日', align: 'left' },
  ];

  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Switch
          checked={fetchTotalCount}
          onChange={handleSwitchChange}
          name="fetchTotalCountSwitch"
          inputProps={{ 'aria-label': 'Enable total count switch' }}
        />
        <Box sx={{ pt: 1, mr: 3 }}> 合計表示(ONにすると重くなります) {total && `合計：${total}件`}</Box>
      </Box>
      <Scrollbar>
        <TableContainer>
          <Table>
            <ListHead order={order} orderBy={orderBy} headLabel={TABLE_HEAD} onRequestSort={handleRequestSort} />
            <CustomTableBody
              {...{
                TABLE_HEAD,
                responseData: rsvData,
                handleRowClick,
                emptyRows,
              }}
            />
            {isUserNotFound && <NotFoundTableBody searchName={'この条件'} />}
          </Table>
        </TableContainer>
      </Scrollbar>
      {loading && <LinearProgress />}
      <TablePagination
        rowsPerPageOptions={[5]}
        component="div"
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        count={count(loading ? [] : nextPageCheck, rowsPerPage, page)} // ロード中は次のページがないと認識させる
        labelDisplayedRows={({ from, to }) => labelDisplayedRows(rsvData, nextPageCheck, from, to)}
      />
    </>
  );
};
