import * as React from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { SxProps } from '@mui/material/styles';

type BasicSelectProps = {
  sx: SxProps;
  label: string;
  items: { value: string | number; label: string }[];
  value: string | number;
  handleChange: (event: SelectChangeEvent<string | number>, child: React.ReactNode) => void;
};

export default function BasicSelect({ label, items, value, handleChange, sx }: BasicSelectProps) {
  return (
    <Box sx={sx}>
      <FormControl fullWidth>
        <InputLabel>{label}</InputLabel>
        <Select id={`select-${label}`} value={value} label={label} onChange={handleChange}>
          {items.map((i: any, _: number) => (
              <MenuItem key={_} value={i.value}>
                {i.label}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    </Box>
  );
}
