// component
import { hasPermission } from 'src/utils/accessControl';
// staff
import useStaffInformation from 'src/hooks/use-staff-information';
import { getIcon } from 'src/components/atoms/Iconify';

// ----------------------------------------------------------------------

export default function NavConfig() {
  const staff = useStaffInformation();

  return [
    hasPermission(staff, 'reservationForAdca', 'navMenuView') && {
      title: 'フォトスタジオ',
      path: `/${staff.userType}/list`,
      icon: getIcon('bi:camera-fill'),
    },
    hasPermission(staff, 'bookmark', 'navMenuView') && {
      title: 'ブックマーク',
      path: `/${staff.userType}/bookmark`,
      icon: getIcon('ph:bookmark-simple-fill'),
    },
    hasPermission(staff, 'schedule', 'navMenuView') && {
      title: 'スケジュール',
      path: `/${staff.userType}/schedule`,
      icon: getIcon('uis:schedule'),
    },
    hasPermission(staff, 'reservation', 'navMenuView') && {
      title: '予約一覧',
      path: `/${staff.userType}/reservation`,
      icon: getIcon('eva:file-text-fill'),
    },
    hasPermission(staff, 'customer', 'navMenuView') && {
      title: '顧客詳細',
      path: `/${staff.userType}/customer`,
      icon: getIcon('eva:people-fill'),
    },
    hasPermission(staff, 'staff', 'navMenuView') && {
      title: 'スタッフ',
      path: `/${staff.userType}/staff`,
      icon: getIcon('icon-park-solid:file-staff'),
    },
    hasPermission(staff, 'staff', 'navMenuView') && {
      title: 'チーム',
      path: `/${staff.userType}/team/edit`,
      icon: getIcon('fluent:people-team-16-filled'),
    },
    hasPermission(staff, 'customerTop', 'navMenuView') && {
      title: 'TOP',
      path: `/${staff.userType}/customerTop`,
      icon: getIcon('material-symbols:home-rounded'),
    },
    hasPermission(staff, 'notes', 'navMenuView') && {
      title: '注意',
      path: `/${staff.userType}/notes`,
      icon: getIcon('mdi:error-outline'),
    },
    hasPermission(staff, 'setting', 'navMenuView') && {
      title: '設定',
      path: `/${staff.userType}/setting`,
      icon: getIcon('ant-design:setting-filled'),
      children: [
        {
          title: '店舗',
          path: `/${staff.userType}/setting/photoStudio`,
        },
        {
          title: 'プラン',
          path: `/${staff.userType}/setting/plan`,
        },
        {
          title: 'オプション',
          path: `/${staff.userType}/setting/option`,
        },
        {
          title: '予約枠',
          path: `/${staff.userType}/setting/frame`,
        },
        {
          title: '自動返信メール',
          path: `/${staff.userType}/setting/autoMailSetting`,
        },
        {
          title: '予約フォーム設定',
          path: `/${staff.userType}/setting/reservationFormSetting`,
        },
        {
          title: 'アカウント',
          path: `/${staff.userType}/setting/account`,
        },
      ],
    },
  ].filter(Boolean);
}
