import { Card, CardHeader, Grid, Stack } from '@mui/material';
import { autoMailSettingValidationSchema } from 'src/functions/validation-schema';
import { useForm } from 'react-hook-form';
import { FC, useState } from 'react';
import { FormProvider, RHFTextArea, RHFTextField, RHFTextInputSelectBox } from 'src/components/atoms/hook-form';
import { LoadingButton } from '@mui/lab';
import { yupResolver } from '@hookform/resolvers/yup';
import { PhotoPlan, PostPhotoStudioStaffRequestBody } from 'codegen/axios/photo/photo_studio';
import useFetch from 'src/hooks/use-fetch';
import { photoStudioOptionFetchOrganize, planOptionFetchOrganize } from 'src/functions/fetch-value-organize';
import { searchNameValueOrganize } from 'src/functions/push-value-organize';

export type AccountFormType = Omit<PostPhotoStudioStaffRequestBody, 'photoStudioStaffId'>;

type Props = {
  defaultValues: any;
  onSubmit: any;
  id: string | undefined;
};

export const AutoMailSettingForm: FC<Props> = ({ defaultValues, onSubmit, id }) => {
  /* 定数宣言 */
  const editMode = !id;
  const validationSchema = autoMailSettingValidationSchema(id);

  const { fetchedData: photoStudioOptions } = useFetch<Array<any>>({
    reqProp: {
      pathKey: 'studio',
      queryParams: {
        offset: 0,
        limit: 500,
      },
    },
    organize: photoStudioOptionFetchOrganize,
  });

  const { fetchedData: plans } = useFetch<PhotoPlan[]>({
    reqProp: {
      pathKey: 'plan',
      queryParams: {
        offset: 0,
        limit: 500,
      },
    },
    organize: planOptionFetchOrganize,
  });
  const methods = useForm<AccountFormType>({
    resolver: yupResolver(validationSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;
  return (
    <>
      {
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <Card>
            <CardHeader title={'基本情報'} />

            <Stack my={7} mx={4}>
              <Grid container rowSpacing={3} columnSpacing={3}>
                <Grid item xs={12} sm={6} md={3}>
                  <RHFTextField name="title" label="タイトル" placeholder="例：" />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <RHFTextInputSelectBox
                    name="type"
                    label="タイミング"
                    placeholder="例："
                    option={[
                      { label: '予約確定', name: 'reserve_confirm' },
                      { label: '予約変更', name: 'reserve_update' },
                      { label: 'キャンセル', name: 'reserve_cancel' },
                    ]}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <RHFTextInputSelectBox
                    option={photoStudioOptions}
                    isOptionEqualToValue={(option: any, value: any) => option.id === value.id}
                    getOptionLabel={(option: any) => option.label || ''}
                    name="photoStudio"
                    label="店舗名"
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <RHFTextInputSelectBox
                    option={plans}
                    isOptionEqualToValue={(option: any, value: any) => option.id === value.id}
                    getOptionLabel={(option: any) => option.label || ''}
                    name="plan"
                    label="プラン"
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <RHFTextField name="fromAddress" label="* fromアドレス" placeholder="例：" />
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <RHFTextField name="distributorName" label="配信者名" placeholder="例：" />
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <RHFTextArea name="salutationText" label="挨拶文" placeholder="例：" />
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  <RHFTextArea name="content" label="本文" placeholder="例：" />
                </Grid>

                <Grid item xs={12} sm={6} md={3}>
                  <RHFTextArea name="signature" label="署名" placeholder="例：" />
                </Grid>
              </Grid>
            </Stack>
          </Card>

          <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }} />

          <Stack direction="row" alignItems="center" justifyContent="flex-end" sx={{ m: 1 }} spacing={8}>
            <LoadingButton sx={{ width: 200 }} size="large" type="submit" variant="contained" loading={isSubmitting}>
              {editMode ? '登録' : '保存'}
            </LoadingButton>
          </Stack>
        </FormProvider>
      }
    </>
  );
};
