import * as AutoKana from 'vanilla-autokana';
// @mui
import { Grid } from '@mui/material';
// components
import { RHFTextField } from 'src/components/atoms/hook-form';

// ----------------------------------------------------------------------

export default function HumanNameInput({ methods, identifier }: any) {
  const autoKanaFirst = document.getElementById(`${identifier}FirstName`) && AutoKana.bind(`#${identifier}FirstName`);
  const autoKanaLast = document.getElementById(`${identifier}LastName`) && AutoKana.bind(`#${identifier}LastName`);
  const handleFirstName = () => {
    setTimeout(() => {
      // @ts-expect-error TS(2531): Object is possibly 'null'.
      methods.setValue(`${identifier}FirstNameKana`, autoKanaFirst.getFurigana());
    }, 100);
  };
  const handleLastName = () => {
    setTimeout(() => {
      // @ts-expect-error TS(2531): Object is possibly 'null'.
      methods.setValue(`${identifier}LastNameKana`, autoKanaLast.getFurigana());
    }, 100);
  };

  return (
    <>
      <Grid item xs={12} sm={6} md={3}>
        <RHFTextField
          name={`${identifier}FirstName`}
          onChange={handleFirstName}
          label="姓"
          id={`${identifier}FirstName`}
          placeholder="例：佐藤"
        />
      </Grid>

      <Grid item xs={12} sm={6} md={3}>
        <RHFTextField
          name={`${identifier}LastName`}
          onChange={handleLastName}
          label="名"
          placeholder="例：太郎"
          id={`${identifier}LastName`}
        />
      </Grid>

      <Grid item xs={12} sm={0} md={6} style={{ padding: 0 }} />

      <Grid item xs={12} sm={6} md={3}>
        <RHFTextField name={`${identifier}FirstNameKana`} label="姓(かな)" placeholder="例：さとう" />
      </Grid>

      <Grid item xs={12} sm={6} md={3}>
        <RHFTextField name={`${identifier}LastNameKana`} label="名(かな)" placeholder="例：たろう" />
      </Grid>
    </>
  );
}
