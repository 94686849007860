// components
import Page from 'src/components/molecules/page';
// staff
import { Container, Stack } from '@mui/material';
import HeadLineText from 'src/components/atoms/head-line-text';
import { FC } from 'react';
import { AvailableOptionForm } from 'src/components/organisms/plan/detail/available-options/form';

export const PhotoStudioAvailableOptionEditPage: FC = () => {
  /* 定数宣言 */
  const title = '対応可能オプション';

  return (
    <Page title={title}>
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" m={5}>
          <HeadLineText text={title} />
        </Stack>
        <AvailableOptionForm />
      </Container>
    </Page>
  );
};
