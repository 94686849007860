import { FC } from 'react';
import Page from 'src/components/molecules/page';
import { ReservationForAdcaForm } from 'src/components/organisms/reservation-for-adca/form';

export const ReservationForAdcaCreatePage: FC = () => {
  /* 定数宣言 */
  const title = '予約';

  return (
    <Page title={title}>
      <ReservationForAdcaForm />
    </Page>
  );
};
