import { FC } from 'react';
import { Container, Stack, Typography } from '@mui/material';
import { BusinessHourForm } from 'src/components/organisms/photo-studio/detail/business-hour/form';
import Page from 'src/components/molecules/page';

export const BusinessHourCreatePage: FC = () => {
  const title = '営業時間登録';

  return (
    <Page title={title}>
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            {title}
          </Typography>
        </Stack>
        <BusinessHourForm />
      </Container>
    </Page>
  );
};
