import { Fragment } from 'react'; // @mui
import { Stack, Grid, Card, CardHeader } from '@mui/material';
// components
import Label from 'src/components/molecules/status-label';

export default function DetailCard({ defaultValues, labelProperty, title }: any) {
  return (
    <Card>
      {title && <CardHeader title={title} subheader={''} />}

      <Stack my={7} mx={4}>
        {defaultValues && (
          <Grid container rowSpacing={3} columnSpacing={3} style={{ wordBreak: 'break-all', whiteSpace: 'pre-line' }}>
            {defaultValues &&
              Object.keys(defaultValues).map((key) => (
                <Fragment key={key}>
                  <Grid item xs={12} sm={6} md={6}>
                    {`${key}`}
                  </Grid>

                  <Grid item xs={12} sm={6} md={6}>
                    {labelProperty && labelProperty[defaultValues[key]] ? (
                      <Label variant="ghost" color={(() => labelProperty[defaultValues[key]])()}>
                        {defaultValues[key]}
                      </Label>
                    ) : (
                      `${defaultValues[key]}`
                    )}
                  </Grid>
                </Fragment>
              ))}
          </Grid>
        )}
      </Stack>
    </Card>
  );
}
