import usePush from 'src/hooks/use-push';
import { PdfRequestBodyInner, ReserveDetail } from 'codegen/axios/photo/photo_studio';
import { useState } from 'react';
import log from 'src/utils/log';
import { findFormObj } from '../../semi-detail';

export const usePdfExport = () => {
  const push = usePush();
  const [loading, setLoading] = useState(false);

  const exportPdf = async (responseData: ReserveDetail[], selectedIds: string[]) => {
    const resRule = await fetchRules();

    const rules = (resRule as any).filter((rule: any) => rule.ruleType === 'pdf_file_change');

    const selectedData = findSelectedData(selectedIds, responseData);
    const data = createData(selectedData, rules);
    if (data.length === 0) return;
    const pdfBlob = await generatePDF(data);
    downloadPDF(pdfBlob);
  };

  const verifyExportPdf = async (responseData: ReserveDetail[], selectedIds: string[]) => {
    const resRule = await fetchRules();

    const rules = (resRule as any).filter((rule: any) => rule.ruleType === 'pdf_file_change');

    const selectedData = findSelectedData(selectedIds, responseData);
    const data = createData(selectedData, rules);
    if (data.length === 0) return false;
    return true;
  };

  const fetchRules = () =>
    new Promise((resolve) =>
      push(resolve, {
        reqProp: {
          pathKey: 'rule',
          method: 'get',
          queryParams: { offset: 0, limit: 500 },
        },
        aftProp: {
          resolve,
        },
      })
    );

  const findSelectedData = (selected: any, responseData: any) =>
    selected.map((id: string) => responseData?.find((data: any) => data.reserve.photoStudioReserveId === id));

  const createData = (selectedData: any, rules: any): PdfRequestBodyInner[] =>
    selectedData
      .map((rsvDetail: any) => {
        const templateFile = findRule(rsvDetail, rules, 'template_file', '撮影情報');
        const backgroundImageUrl = findRule(rsvDetail, rules, 'background_image_url', '撮影情報');
        if (backgroundImageUrl.actionValue === '') return null;
        return {
          photoStudioReserveId: rsvDetail.reserve.photoStudioReserveId,
          templateFile: templateFile.actionValue,
          backgroundImageUrl: backgroundImageUrl.actionValue,
        };
      })
      .filter((item: any) => item !== null);

  const findRule = (rsvDetail: any, rules: any, actionKey: any, formName: any) => {
    log({ rsvDetail, rules, actionKey, formName });
    let rule = rules.find(
      (rule: any) =>
        rule.actionKey === actionKey &&
        findFormObj(rsvDetail.form, formName, rule.conditionKey)?.input?.value.includes(rule.conditionValue)
    );
    log({ rule });
    if (!rule) {
      rule = rules.find((rule: any) => rule.actionKey === actionKey && rule.conditionKey === 'default');
    }
    return rule;
  };

  const generatePDF = async (data: any) =>
    new Promise((resolve) => {
      setLoading(true);
      push(resolve, {
        reqProp: {
          pathKey: 'pdf',
          pathParam: 'export',
          method: 'post',
          data,
          addOption: {
            responseType: 'blob',
            headers: {
              'Content-Type': 'application/json',
            },
          },
        },
        aftProp: {
          resolve,
          calledFunc: () => setLoading(false),
        },
      });
    });

  const downloadPDF = (blob: any) => {
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = 'file.pdf';
    link.addEventListener('click', () => {
      setTimeout(() => {
        URL.revokeObjectURL(url);
      }, 1000);
    });
    link.click();
  };

  return { exportPdf, verifyExportPdf, loading };
};
