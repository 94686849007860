import { Button, Card, CardMedia, Grid, Typography } from '@mui/material';
import { FC } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import useStaffInformation from 'src/hooks/use-staff-information';

export type PhotoPlanCardType = {
  photoStudioPlanId: string;
  name: string;
  price: string;
  imageUrl: string;
  content: string;
  reserveMinutesForCustomer: string;
};

type Props = {
  plan: PhotoPlanCardType;
} & { button?: boolean };

export const PlanCard: FC<Props> = ({ plan, button = false }) => {
  const { photoStudioPlanId, name, price, imageUrl, content, reserveMinutesForCustomer } = plan;
  const { id: photoStudioId } = useParams();
  const navigate = useNavigate();
  const staff = useStaffInformation();
  const handleClick = () => {
    navigate(`/${staff.userType}/list/${photoStudioId}/${photoStudioPlanId}/new`);
  };

  return (
    <Card sx={{ p: 4, position: 'relative' }}>
      <Grid item xs={12} sm container spacing={4} direction="row" justifyContent="flex-center" alignItems="flex-center">
        {imageUrl && (
          <Grid item>
            <CardMedia component="img" sx={{ width: 300, borderRadius: 1 }} image={imageUrl} />
          </Grid>
        )}
        <Grid item xs container direction="column" spacing={1}>
          <Grid item>
            <Typography variant="h6" mb={1}>
              {name}
            </Typography>

            <Typography variant="body1">
              {price}　{reserveMinutesForCustomer}
            </Typography>
          </Grid>

          <Grid item>
            <Typography variant="body2">{content}</Typography>
          </Grid>

          {button && (
            <Button variant="contained" onClick={handleClick} sx={{ position: 'absolute', top: 20, right: 30 }}>
              このプランを予約する
            </Button>
          )}
        </Grid>
      </Grid>
    </Card>
  );
};
