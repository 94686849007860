import { Card, CardHeader, Stack, Table, TableContainer, TablePagination } from '@mui/material';
import { PhotoReservationFrameIrregularSetting } from 'codegen/axios/photo/photo_studio';
import { FC } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import CreateButton from 'src/components/atoms/create-button';
import CustomTableBody from 'src/components/molecules/custom-table-body';
import ListHead from 'src/components/molecules/list-head';
import NotFoundTableBody from 'src/components/molecules/not-found-table-body';
import Scrollbar from 'src/components/molecules/scroll-bar';
import { irregularSettingFetchOrganize } from 'src/functions/fetch-value-organize';
import { count, labelDisplayedRows } from 'src/functions/pagination';
import { createParams } from 'src/functions/params';
import useFetch from 'src/hooks/use-fetch';
import useStaffInformation from 'src/hooks/use-staff-information';

const TABLE_HEAD = [
  { id: 'validPeriod', label: '有効期間', align: 'left' },
  { id: 'validDay', label: '有効曜日', align: 'center' },
  { id: 'validTime', label: '実施時間', align: 'center' },
  {
    id: 'openOrCloseTag',
    label: 'OPEN/CLOSE',
    align: 'center',
    labelProperty: {
      OPEN: 'success',
      CLOSE: 'cancel',
    },
  },
];

type Props = {
  photoStudioId: string;
  rsvStartEnd: {
    reservationStartTime: string;
    reservationEndTime: string;
  };
};

export const IrregularSettingList: FC<Props> = ({ photoStudioId, rsvStartEnd }) => {
  /* 定数宣言 */
  const rowsPerPage = 30;
  const title = 'イレギュラー設定';

  /* hooks */
  const staff = useStaffInformation();
  const navigate = useNavigate();
  const { id: photoStudioReservationFrameId } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const page = Number(searchParams.get('page')) ?? 0;
  const searchName = searchParams.get('searchName') ?? '';

  /* データ取得 */
  const { fetchedData: responseData } = useFetch<Array<PhotoReservationFrameIrregularSetting>>({
    iniState: [],
    reqProp: {
      pathKey: 'frame',
      pathParam: `${photoStudioReservationFrameId}/irregulars`,
      queryParams: {
        offset: page * rowsPerPage,
        limit: rowsPerPage,
      },
    },
    observable: [searchParams],
    organize: irregularSettingFetchOrganize,
  });

  // 次のページがあるかどうか
  const { fetchedData: nextPageCheck } = useFetch<boolean>({
    reqProp: {
      pathKey: 'frame',
      pathParam: `${photoStudioReservationFrameId}/irregulars`,
      queryParams: {
        offset: (page + 1) * rowsPerPage,
        limit: 2,
      },
    },
    observable: [searchParams],
  });

  const handleRowClick = (id: string) => {
    navigate(`/${staff.userType}/setting/frame/${photoStudioReservationFrameId}/irregular/${id}/edit`);
  };

  /* handle */
  const handleChangePage = (_: any, newPage: number) => {
    setSearchParams(createParams({ keyValues: { page: newPage }, searchParams }));
  };

  const emptyRows = page > 0 ? Math.max(0, rowsPerPage - responseData.length) : 0;
  const isUserNotFound = responseData.length === 0;

  return (
    <>
      <Stack direction="row" alignItems="center" justifyContent="end" m={5}>
        <CreateButton
          text="新規作成"
          path={`/${staff.userType}/setting/frame/${photoStudioReservationFrameId}/irregular/${photoStudioId}/new?start=${rsvStartEnd.reservationStartTime}&end=${rsvStartEnd.reservationEndTime}`}
        />
      </Stack>
      <Card sx={{ mt: 5 }}>
        <CardHeader title={title} sx={{ mb: 3, mt: 1 }} />
        <Scrollbar>
          <TableContainer sx={{ minWidth: 800 }}>
            <Table>
              <ListHead headLabel={TABLE_HEAD} />

              <CustomTableBody
                {...{
                  TABLE_HEAD,
                  responseData,
                  handleRowClick,
                  emptyRows,
                }}
              />

              {isUserNotFound && <NotFoundTableBody searchName={searchName} />}
            </Table>
          </TableContainer>
        </Scrollbar>

        {!isUserNotFound && (
          <TablePagination
            rowsPerPageOptions={[30]}
            component="div"
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            count={count(nextPageCheck, rowsPerPage, page)}
            labelDisplayedRows={({ from, to }) => labelDisplayedRows(responseData, nextPageCheck, from, to)}
          />
        )}
      </Card>
    </>
  );
};
