import React, { useEffect, useState } from 'react';
import { useFormContext, useFieldArray, Controller } from 'react-hook-form';
import { Button, Grid, IconButton, TextField, Typography } from '@mui/material';
import { Delete, ArrowUpward, ArrowDownward } from '@mui/icons-material';

interface RHFMultipleTextFieldProps {
  name: string;
  label: string;
  placeholder: string;
}

const RHFMultipleTextField: React.FC<RHFMultipleTextFieldProps> = ({ name, label, placeholder }) => {
  const { control } = useFormContext();
  const { fields, append, remove, swap } = useFieldArray({
    control,
    name,
  });

  const [highlightedIndex, setHighlightedIndex] = useState<number | null>(null);

  const handleSwap = (index1: number, index2: number) => {
    swap(index1, index2);
    setHighlightedIndex(index2);
  };

  useEffect(() => {
    if (highlightedIndex !== null) {
      const timeoutId = setTimeout(() => {
        setHighlightedIndex(null);
      }, 150); // Change to however long you want the highlight to last

      // Clean up the timeout when component unmounts or if highlightedIndex changes
      return () => clearTimeout(timeoutId);
    }
  }, [highlightedIndex]);

  return (
    <>
      <Typography
        variant="h6"
        sx={{
          marginBottom: '10px',
        }}
      >
        選択肢の設定
      </Typography>

      {fields.map((field, index) => (
        <div key={field.id} style={{ marginBottom: '15px' }}>
          <Grid container alignItems="center" spacing={2}>
            <Grid item>
              <IconButton
                size="small"
                disabled={index === fields.length - 1}
                onClick={() => handleSwap(index, index + 1)}
              >
                <ArrowDownward />
              </IconButton>
              <IconButton size="small" disabled={index === 0} onClick={() => handleSwap(index, index - 1)}>
                <ArrowUpward />
              </IconButton>
            </Grid>
            <Grid item xs>
              <Controller
                control={control}
                name={`${name}.${index}`}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    sx={{ backgroundColor: highlightedIndex === index ? 'yellow' : 'transparent' }}
                    {...field}
                    placeholder={placeholder}
                    error={!!error}
                    helperText={error?.message}
                    fullWidth
                  />
                )}
              />
            </Grid>
            <Grid item>
              <IconButton size="small" onClick={() => remove(index)}>
                <Delete />
              </IconButton>
            </Grid>
          </Grid>
        </div>
      ))}
      <Button variant="outlined" onClick={() => append('')}>
        ＋項目を追加
      </Button>
    </>
  );
};

export default RHFMultipleTextField;
