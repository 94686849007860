import { useState, createContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Router from 'src/routes';
import ThemeProvider from 'src/theme';
import SnackBar from 'src/components/molecules/snack-bar';
import ScrollToTop from 'src/components/atoms/scroll-to-top';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import * as Sentry from '@sentry/react';
import { apiCall, createApiUrl } from './functions/api/call';

export type Role = 'admin' | 'normal';
export type UserType = 'adminStaff' | 'marriageAgencyStaff' | 'photoStudioStaff' | 'photoStudioCustomer';

export type Staff = {
  loggedName: string;
  loggedRole: Role;
  userType: UserType;
  marriageAgencyStaffId: string;
};

export const StaffInformation = createContext<Staff>({
  loggedName: '',
  loggedRole: 'admin',
  userType: 'adminStaff',
  marriageAgencyStaffId: '',
});

Sentry.init({
  dsn: 'https://b9a04836949b115665b480473b2aa18b@o4505701089607680.ingest.sentry.io/4505701092229120',
  integrations: [new Sentry.BrowserTracing({ tracingOrigins: ['*'] }), new Sentry.Replay()],
  tracesSampleRate: 1.0,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  beforeSend(event) {
    if (window.location.href.includes('https://photo.store-reservation-form.com/photoStudioCustomer')) {
      return event; // イベントをそのまま送信
    }
    return null; // イベントを送信しない
  },
});

export default function App() {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        retry: 3,
        retryDelay: 3000,
        refetchOnWindowFocus: false,
      },
    },
  });
  const navigate = useNavigate();
  const { pathname, search } = useLocation();
  const savedStaff = getStaffInfo();
  const [loggedName, setLoggedName] = useState<string>(savedStaff ? savedStaff.loggedName : '');
  const [loggedRole, setLoggedRole] = useState<Role>(savedStaff ? savedStaff.loggedRole : '');

  const staff: Staff = {
    loggedName: savedStaff ? savedStaff.loggedName : '',
    loggedRole: savedStaff ? savedStaff.loggedRole : '',
    userType: pathname.split('/')[1] as
      | 'adminStaff'
      | 'marriageAgencyStaff'
      | 'photoStudioStaff'
      | 'photoStudioCustomer',
    marriageAgencyStaffId: '',
  };

  // ログインしているユーザーの情報を取得(ここではstaffに入れる処理を行うので、staffがとれる前提のuseFetchは使わない)
  if (!savedStaff && !pathname.match('/login') && pathname.split('/')[1] !== 'photoStudioCustomer') {
    const url = createApiUrl({
      staff,
      pathKey: pathname.split('/')[1] !== 'marriageAgencyStaff' ? 'name' : 'marriageAgencyStaffName',
    });

    (async () => {
      const response = await apiCall<any>({
        reqProp: {
          method: 'get',
          url,
          pathKey: pathname.split('/')[1] !== 'marriageAgencyStaff' ? 'name' : 'marriageAgencyStaffName',
        },
        curtProp: { staff, pathname, search },
        aftProp: { navigate },
      });
      if (staff.userType === 'adminStaff') {
        setLoggedRole('admin');
        setLoggedName(`${response.adminStaffFirstName} ${response.adminStaffLastName}`);
      } else if (staff.userType === 'photoStudioStaff') {
        setLoggedName(`${response.photoStudioStaffFirstName} ${response.photoStudioStaffLastName}`);
      } else {
        setLoggedName(`${response.firstName} ${response.lastName}`);
      }
    })();
  }

  if (
    !savedStaff &&
    !pathname.match(/twoFactorAuth/) &&
    !pathname.match(/adminStaff/) &&
    pathname.split('/')[1] !== 'photoStudioCustomer'
  ) {
    const url = createApiUrl({
      staff,
      pathKey: pathname.split('/')[1] !== 'marriageAgencyStaff' ? 'name' : 'marriageAgencyStaffName',
    });

    (async () => {
      const response = await apiCall<any>({
        reqProp: {
          method: 'get',
          url,
          pathKey: 'role',
        },
        curtProp: { staff, pathname, search },
        aftProp: { navigate },
      });
      setLoggedRole(response.role);
    })();
  }

  saveStaffInfo({
    loggedName,
    loggedRole,
    userType: staff.userType,
    marriageAgencyStaffId: staff.marriageAgencyStaffId,
  });
  return (
    <>
      <QueryClientProvider client={queryClient}>
        <StaffInformation.Provider
          value={{
            loggedName,
            loggedRole,
            userType: staff.userType,
            marriageAgencyStaffId: staff.marriageAgencyStaffId,
          }}
        >
          <ThemeProvider>
            <ScrollToTop />
            <Router />
            <SnackBar />
          </ThemeProvider>
        </StaffInformation.Provider>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </>
  );
}

function saveStaffInfo(staff: any) {
  localStorage.setItem('staffInfo', JSON.stringify(staff));
}

function getStaffInfo() {
  const savedStaff = localStorage.getItem('staffInfo');
  return savedStaff ? JSON.parse(savedStaff) : null;
}
