// form
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import { TextField, Autocomplete } from '@mui/material';

export default function RHFTextInputSelectBox({
  option,
  name,
  getOptionLabel,
  isOptionEqualToValue,
  onChange,
  textFieldOnChange,
  filterOptions,
  inputProps,
  label,
  ...other
}: any) {
  const { control } = useFormContext();
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <Autocomplete
          {...(() => {
            if (filterOptions) return { filterOptions };
            return false;
          })()}
          isOptionEqualToValue={isOptionEqualToValue}
          getOptionLabel={getOptionLabel}
          {...field}
          onChange={(event, item) => {
            field.onChange(item);
            if (onChange) {
              onChange(event, item);
            }
          }}
          renderOption={(props, option) => (
            <li {...props} key={option.name}>
              {option.label}
            </li>
          )}
          value={typeof field.value === 'number' && field.value === null ? '' : field.value}
          disablePortal
          id="combo-box-demo"
          options={option}
          renderInput={(params) => (
            <TextField
              onChange={(event) => {
                textFieldOnChange && textFieldOnChange(event.target.value);
              }}
              {...params}
              label={label}
              error={!!error}
              // 秘伝のタレになっちゃったネストしたValidationSchema書いた時先頭のメッセージを表示してるhttps://github.com/react-hook-form/resolvers/issues/63ここで似たような解決してるからこれで
              helperText={error?.message ?? (error && ((error as any)[Object.keys(error)[0]] as any).message)}
              {...other}
              {...(() => {
                if (inputProps) return { inputProps: { ...params.inputProps, ...inputProps } };
                return false;
              })()}
            />
          )}
        />
      )}
    />
  );
}
