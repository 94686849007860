import { Container, Stack } from '@mui/material';
import { FC } from 'react';
import { StaffList } from 'src/components/organisms/staff/list';
import Page from 'src/components/molecules/page';
import CreateButton from 'src/components/atoms/create-button';
import HeadLineText from 'src/components/atoms/head-line-text';
import useStaffInformation from 'src/hooks/use-staff-information';

export const StaffListPage: FC = () => {
  const title = 'スタッフ一覧';
  const staff = useStaffInformation();

  return (
    <Page title={title}>
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" m={5} mt={'5%'}>
          <HeadLineText text={title} />

          <CreateButton text="スタッフ登録" path={`/${staff.userType}/staff/new`} />
        </Stack>
        <StaffList />
      </Container>
    </Page>
  );
};
