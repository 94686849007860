import * as AutoKana from 'vanilla-autokana';
import { useParams } from 'react-router-dom';
import usePush from 'src/hooks/use-push';
import { staffValueOrganize } from 'src/functions/push-value-organize';
import { Card, CardHeader, Grid, Stack } from '@mui/material';
import { staffValidationSchema } from 'src/functions/validation-schema';
import { useForm } from 'react-hook-form';
import { FC, useEffect } from 'react';
import { FormProvider, RHFPhoneNumber, RHFRadioButtonGroup, RHFTextField } from 'src/components/atoms/hook-form';
import { LoadingButton } from '@mui/lab';
import { MarriageAgencyStaff } from 'codegen/axios/photo/adca';
import { yupResolver } from '@hookform/resolvers/yup';
import useFetch from 'src/hooks/use-fetch';
import { staffForFormFetchOrganize } from 'src/functions/fetch-value-organize';

export type StaffFormType = Omit<Required<MarriageAgencyStaff>, 'marriageAgencyStaffId'> & { password: string };

export const defaultValues: StaffFormType = {
  firstName: '',
  lastName: '',
  firstNameKana: '',
  lastNameKana: '',
  email: '',
  telno: '',
  role: 'normal',
  team: [{ marriageAgencyStaffTeamId: '', name: '', email: '' }],
  password: '',
};

export const StaffForm: FC = () => {
  /* hook宣言 */
  const push = usePush();
  const { id: marriageAgencyStaffId } = useParams();

  /* 定数宣言 */
  const editMode = !marriageAgencyStaffId;
  const validationSchema = staffValidationSchema(marriageAgencyStaffId);
  const autoKanaFirst = document.getElementById('firstName') && AutoKana.bind(`firstName`);
  const autoKanaLast = document.getElementById('lastName') && AutoKana.bind(`lastName`);

  /* データ取得 */
  const { fetchedData: responseData } = useFetch<MarriageAgencyStaff>({
    iniState: defaultValues,
    reqProp: {
      pathKey: 'marriageAgencyStaff',
      pathParam: marriageAgencyStaffId,
    },
    disable: editMode,
    organize: staffForFormFetchOrganize,
  });

  const methods = useForm<StaffFormType>({
    resolver: yupResolver(validationSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  useEffect(() => {
    methods.reset(responseData);
  }, [responseData, methods]);

  /* handle */
  const handleFirstName = () => {
    setTimeout(() => {
      if (autoKanaFirst) {
        methods.setValue('firstNameKana', autoKanaFirst.getFurigana());
      }
    }, 100);
  };

  const handleLastName = () => {
    setTimeout(() => {
      if (autoKanaLast) {
        methods.setValue('lastNameKana', autoKanaLast.getFurigana());
      }
    }, 100);
  };

  const onSubmit = (formValue: StaffFormType) =>
    new Promise((resolve) => {
      push(resolve, {
        reqProp: {
          pathKey: 'marriageAgencyStaff',
          pathParam: marriageAgencyStaffId,
          method: editMode ? 'post' : 'put',
          data: staffValueOrganize(formValue),
        },
        aftProp: {
          resolve,
          nextPath: `/staff`,
          showMessage: {
            state: {
              snackBar: { open: true, viewType: 'success', message: editMode ? '登録しました' : '保存しました' },
            },
          },
        },
      });
    });
  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Card>
        <CardHeader title={'基本情報'} />

        <Stack my={7} mx={4}>
          <Grid container rowSpacing={3} columnSpacing={3}>
            <Grid item pl={8} xs={12} sm={12} md={6}>
              <RHFRadioButtonGroup
                label="権限"
                name="role"
                value="normal"
                options={[
                  {
                    value: 'admin',
                    label: '管理者',
                  },
                  {
                    value: 'normal',
                    label: '一般',
                  },
                ]}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              <RHFTextField name="firstName" onChange={handleFirstName} label="姓" placeholder="例：佐藤" />
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              <RHFTextField name="lastName" onChange={handleLastName} label="名" placeholder="例：太郎" />
            </Grid>

            <Grid item xs={12} sm={0} md={6} style={{ padding: 0 }} />

            <Grid item xs={12} sm={6} md={3}>
              <RHFTextField name="firstNameKana" label="姓(かな)" placeholder="例：さとう" />
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              <RHFTextField name="lastNameKana" label="名(かな)" placeholder="例：たろう" />
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
              <RHFTextField
                name="password"
                label={editMode ? 'パスワード' : 'パスワード (更新時のみ入力)'}
                placeholder="半角英字・数字の組み合わせ8文字以上"
              />
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
              <RHFTextField name="email" label="メールアドレス" placeholder="例：xxx@photoapan.jp" />
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
              <RHFPhoneNumber methods={methods} name="telno" label="電話番号" placeholder="例：09000001234" />
            </Grid>
          </Grid>
        </Stack>
      </Card>

      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }} />

      <Stack direction="row" alignItems="center" justifyContent="flex-end" sx={{ m: 1 }} spacing={8}>
        <LoadingButton sx={{ width: 200 }} size="large" type="submit" variant="contained" loading={isSubmitting}>
          {editMode ? '登録' : '保存'}
        </LoadingButton>
      </Stack>
    </FormProvider>
  );
};
