import log from 'src/utils/log';
import { exceptionMessage } from 'src/functions/exception';
import { Staff } from 'src/App';
import { th } from 'date-fns/locale';

export const responseCheck = ({
  staff,
  errorFunc,
  response,
  navigate,
  pathname,
  method,
  pathKey,
  search,
  errorMessage,
  notAlert,
}: {
  staff: Staff;
  errorFunc?: () => void;
  response: any;
  navigate: (path: string, options?: any) => void;
  pathname: string;
  method: string;
  pathKey: string;
  search: string;
  resolve?: () => void;
  errorMessage: any;
  notAlert?: { status: number; message: string };
}) => {
  const path = pathname.substring(pathname.indexOf('/', pathname.indexOf('/') + 1));

  if (!loginCheck(response, path)) {
    processStopToLoginPage(staff, path, search, navigate);
    errorFunc && errorFunc();
    return false;
  }

  if (notAlert) {
    if (notAlert.status === response.status && response.data.message.detail === notAlert.message) return true;
  }

  if (!statusCheck(response)) {
    errorNotify(response, pathKey, method, errorMessage);
    errorFunc && errorFunc();
    return false;
  }
  return true;
};

export const loginCheck = (response: any, pathname: any) => !(response.status === 401 && pathname !== '/login');

export const statusCheck = (response: any) => response.status >= 200 && response.status <= 299;

export const processStopToLoginPage = (staff: Staff, pathname: any, search: any, navigate: any) => {
  navigate(`/${staff.userType}/login`, { state: { from: `${pathname}${search}` }, replace: true });
  throw new Error();
};
export const setShowMessage = (showMessage: { state: { snackBar: { message: string; viewType: string } } }) => {
  localStorage.setItem('snackBarOpen', 'true');
  localStorage.setItem('snackBarMessage', showMessage.state.snackBar.message ?? '');
  localStorage.setItem('snackBarViewType', showMessage.state.snackBar.viewType ?? '');
};
export const errorNotify = (response: any, pathKey: any, method: any, errorMessage: any) => {
  log('エラー  -----------');
  log(response);
  log('エラー  -----------');

  if (errorMessage) {
    alert(`${errorMessage} \n${response.data.message.detail}`);
    return;
  }
  if (response.status !== 401) {
    if (response.data?.status) {
      alert(exceptionMessage({ response: response.data, method, pathKey }));
    } else {
      alert('通信エラーが発生しました。電波の状況を確認してください。');
    }
  }
};
