import {
  PhotoFormUiPartsList,
  PhotoFormUiPartsListInputsInner,
  ReserveDetail,
  ReserveDetailFormInner,
  ReserveDetailFormInnerInputsInner,
} from 'codegen/axios/photo/photo_studio';

export const rsvEditFetchOrganize = (data: ReserveDetail) => {
  const reserveData = data.reserve!;
  const flattenedData: { [key: string]: any } = {
    ...reserveData,
    needTimeChange: false,
    customerName: reserveData.customerFirstName
      ? `${reserveData.customerFirstName} ${reserveData.customerLastName}`
      : '',
    customerNameKana: reserveData.customerFirstNameKana
      ? `${reserveData.customerFirstNameKana} ${reserveData.customerLastNameKana}`
      : '',
    plan: { id: reserveData.photoStudioPlanId, label: reserveData.photoStudioPlanName },
    option: data.selectedOption.map((option) => option.photoStudioOptionId),
    sendAlertFlg: false,
  };
  data.form.forEach((group: ReserveDetailFormInner) => {
    group.inputs!.forEach((input: ReserveDetailFormInnerInputsInner) => {
      if (!(input.options && input.name && input.value)) return;
      switch (input.inputType) {
        case 'checkbox':
          flattenedData[input.name] = input.value;
          break;
        default:
          flattenedData[input.name] = input.value[0];
          break;
      }
    });
  });
  flattenedData.reservationTime = '';
  flattenedData.changedFrameName = '';
  return flattenedData;
};

export const rsvCreateFetchOrganize = (data: PhotoFormUiPartsList[], [startDateTime, endDateTime]: string[]) => {
  const flattenedData: { [key: string]: any } = {
    customerName: '',
    customerNameKana: '',
    customerEmail: '',
    customerTelno: '',
    plan: null,
    option: [],
    customerGender: '',
    zip: '',
    prefecture: '',
    city: '',
    street: '',
    endTime: elapsedTime(startDateTime, endDateTime),
    sendAlertFlg: 'false',
  };
  data.forEach((group: PhotoFormUiPartsList) => {
    group.inputs!.forEach((input: PhotoFormUiPartsListInputsInner) => {
      if (!(input.options && input.name)) return;
      switch (input.inputType) {
        case 'radiobutton':
          flattenedData[input.name] = '';
          break;
        case 'checkbox':
          flattenedData[input.name] = [];
          break;
        default:
          flattenedData[input.name] = '';
      }
      if (input.name === 'ステータス') {
        flattenedData[input.name] = input.options[0].label;
      }
    });
  });
  return flattenedData;
};

function elapsedTime(start: string, end: string): string {
  const elapsedMinutes = (new Date(end).getTime() - new Date(start).getTime()) / 60000;

  return `${new Date(end).toLocaleTimeString([], {
    hour: '2-digit',
    minute: '2-digit',
    hour12: false,
  })}(${elapsedMinutes}分)`;
}
