import { FiEdit } from 'react-icons/fi';
// @mui
import { ToggleButton } from '@mui/material';

export default function EditButton({ handleEditOnClick }: any) {
  return (
    <ToggleButton
      onClick={handleEditOnClick}
      color="primary"
      sx={{
        bgcolor: 'background.paper',
        minWidth: 90,
        minHeight: 47,
        boxShadow: 1,
        borderRadius: 2,
        ml: 2,
      }}
      value="check"
    >
      <FiEdit size={20} />
    </ToggleButton>
  );
}
