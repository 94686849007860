import { Container, Stack } from '@mui/material';
import { FC } from 'react';
import CreateButton from 'src/components/atoms/create-button';
import HeadLineText from 'src/components/atoms/head-line-text';
import Page from 'src/components/molecules/page';
import { AutoMailSettingList } from 'src/components/organisms/auto-mail-setting/list';
import useStaffInformation from 'src/hooks/use-staff-information';

export const AutoMailSettingListPage: FC = () => {
  /* hook宣言 */
  const staff = useStaffInformation();
  /* 定数宣言 */
  const title = '自動返信メール設定';

  return (
    <Page title={title}>
      <Container maxWidth={false}>
        <Stack direction="row" alignItems="center" justifyContent="space-between" m={5}>
          <HeadLineText text={title} />

          <CreateButton path={`/${staff.userType}/setting/autoMailSetting/new`} text="新規作成" />
        </Stack>
        <AutoMailSettingList />
      </Container>
    </Page>
  );
};
