// @mui
import { Typography } from '@mui/material';

export default function HeadLineText({ text }: any) {
  return (
    <Typography variant="h4" gutterBottom>
      {text}
    </Typography>
  );
}
