// form
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import { TextField, TextareaAutosize } from '@mui/material';

export default function RHFTextArea({ name, ...other }: any) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <TextField
          {...field}
          multiline
          InputProps={{
            inputComponent: TextareaAutosize,
          }}
          fullWidth
          value={field.value === null || field.value === undefined ? '' : field.value}
          error={!!error}
          helperText={error?.message}
          {...other}
        />
      )}
    />
  );
}
