// @mui
import { Container, Stack, Box, Card, CardHeader, Table, TableContainer, TablePagination } from '@mui/material';
// components
import DeleteModal from 'src/components/molecules/delete-modal';
import CreateButton from 'src/components/atoms/create-button';
import Scrollbar from 'src/components/molecules/scroll-bar';
import NotFoundTableBody from 'src/components/molecules/not-found-table-body';
import ListHead from 'src/components/molecules/list-head';
import DetailCard from 'src/components/molecules/detail-card';
import CustomTableBody from 'src/components/molecules/custom-table-body';
import EditButton from 'src/components/atoms/edit-button';
import HeadLineText from 'src/components/atoms/head-line-text';
// function
import { count, labelDisplayedRows } from 'src/functions/pagination';
// staff
import useStaffInformation from 'src/hooks/use-staff-information';

const TABLE_HEAD: any[] = [
  {
    id: 'reservationStartDatetime',
    label: '来店日時',
    align: 'center',
    typographyProperty: { variant: 'subtitle2', noWrap: true },
  },
  { id: 'participants', label: '人数', align: 'left' },
  { id: 'photoStudioName', label: '店舗', align: 'left', typographyProperty: { variant: 'subtitle2', noWrap: true } },
  { id: 'createdAt', label: '予約申込日時', align: 'left' },
  {
    id: 'status',
    label: 'ステータス',
    align: 'left',
    labelProperty: {
      仮予約: 'warning',
      予約確定: 'success',
      来店済み: 'info',
      キャンセル: 'cancel',
    },
  },
];

export default function CustomerDetailTemplate({
  title,
  handleRequestSort,
  defaultValues,
  customerId,
  order,
  orderBy,
  reservesResponseData,
  handleRowClick,
  isUserNotFound,
  rowsPerPage,
  nextPageCheck,
  page,
  handleChangePage,
  OnDelete,
  handleEditButtonOnClick,
  searchName,
}: any) {
  const emptyRows = page > 0 ? Math.max(0, rowsPerPage - reservesResponseData.length) : 0;
  const staff = useStaffInformation();
  return (
    <Container>
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2} ml={1} mt={'3%'} mr={0}>
        <HeadLineText text={title} />

        <Box sx={{ ml: 'auto' }}>
          <DeleteModal
            sx={{
              bgcolor: 'background.paper',
              minWidth: 50,
              minHeight: 47,
              boxShadow: 1,
              borderRadius: 2,
              ml: 2,
            }}
            OnDelete={OnDelete}
          />

          <EditButton handleEditOnClick={handleEditButtonOnClick} />
        </Box>
      </Stack>

      <DetailCard defaultValues={defaultValues} />

      <Stack direction="row" alignItems="center" justifyContent="flex-end" mt={9}>
        <CreateButton text="予約登録" path={`/${staff.userType}/customers/${customerId}/reserves/new`} />
      </Stack>

      <Card sx={{ mt: 4 }}>
        <CardHeader title={'予約履歴'} subheader={''} />

        <Scrollbar>
          <TableContainer sx={{ minWidth: 800 }}>
            <Table>
              <ListHead order={order} orderBy={orderBy} headLabel={TABLE_HEAD} onRequestSort={handleRequestSort} />

              <CustomTableBody
                {...(() => ({
                  TABLE_HEAD,
                  responseData: reservesResponseData,
                  handleRowClick,
                  emptyRows,
                }))()}
              />

              {isUserNotFound && <NotFoundTableBody searchName={searchName} />}
            </Table>
          </TableContainer>
        </Scrollbar>

        <TablePagination
          rowsPerPageOptions={[5]}
          component="div"
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          count={count(nextPageCheck, rowsPerPage, page)}
          labelDisplayedRows={({ from, to }) => labelDisplayedRows(reservesResponseData, nextPageCheck, from, to)}
        />
      </Card>
    </Container>
  );
}
