import { Box, Container, Grid, Stack, Typography } from '@mui/material';
import { FC } from 'react';
import useFetch from 'src/hooks/use-fetch';
import { MarriageAgencyStaffPhotoPlan, MarriageAgencyStaffPhotoStudioDetail } from 'codegen/axios/photo/adca';
import { useParams } from 'react-router-dom';
import {
  partnerStudioDetailFetchOrganize,
  informationForCardOrganize,
  planCardOrganize,
} from 'src/functions/fetch-value-organize';
import { IntroductionCard } from './introduction-card';
import { InformationCard } from './information-card';
import { PlanCard } from './plan-card';

export type PartnerStudioDetailType = Required<
  Omit<MarriageAgencyStaffPhotoStudioDetail, 'salesStartTime' | 'salesEndTime' | 'regularHoliday'> & {
    salesTime: string;
    regularHoliday: string;
  }
>;

export const defaultValues: PartnerStudioDetailType = {
  photoStudioId: '',
  name: '',
  telno: '',
  email: '',
  zip: '',
  prefecture: '',
  city: '',
  street: '',
  building: '',
  salesTime: '',
  remark: '',
  introduction: {
    commentTitle: '',
    commentBody: '',
  },
  information: {
    photoStudioInformationId: '',
    caption: '',
    access: '',
    paymentMethod: '',
    paymentRemark: '',
    changingRoomFlg: false,
    changingRoomRemark: '',
    reservationMethod: '',
    deliveryMethod: '',
    specialNote: '',
    url: '',
    remark: '',
  },
  regularHoliday: '',
  plans: [],
  imagesTop: [],
};

export const PartnerStudioDetail: FC = () => {
  const { id: photoStudioId } = useParams();

  /* データ取得 */
  const {
    fetchedData: { name, introduction, imagesTop, plans, telno, salesTime, regularHoliday, information },
  } = useFetch<PartnerStudioDetailType>({
    iniState: defaultValues,
    reqProp: {
      pathKey: 'studio',
      pathParam: photoStudioId,
    },
    organize: partnerStudioDetailFetchOrganize,
  });

  const isPlanNotFound = plans && plans.length !== 0;

  return (
    <Container sx={{ pt: 4, pb: 8 }}>
      <Grid container spacing={4}>
        <Grid item xs={12} sm={12}>
          <Typography variant="h4">{name}</Typography>
        </Grid>

        <IntroductionCard introduction={introduction} imagesTop={imagesTop ?? []} />

        {isPlanNotFound && (
          <Grid item xs={12} sm={12}>
            <Typography variant="h5">プランを選択する</Typography>

            <Stack direction="column" justifyContent="center" alignItems="stretch" spacing={3}>
              {plans &&
                plans.map((plan: MarriageAgencyStaffPhotoPlan, i: number) => (
                  <Box sx={{ mt: 2 }} key={`${plan.photoStudioPlanId}-${i}`}>
                    <PlanCard plan={planCardOrganize(plan)} button />
                  </Box>
                ))}
            </Stack>
          </Grid>
        )}

        <Grid item xs={12} sm={12}>
          <InformationCard
            name={name}
            telno={telno}
            salesTime={salesTime}
            regularHoliday={regularHoliday}
            info={informationForCardOrganize(information)}
          />
        </Grid>
      </Grid>
    </Container>
  );
};
