import { Button, Grid, Typography } from '@mui/material';
import { MarriageAgencyStaffPhotoOption } from 'codegen/axios/photo/adca';
import { FC } from 'react';
import Iconify from 'src/components/atoms/Iconify';
import { formatPrice } from 'src/functions/format';

type Props = {
  options: Array<MarriageAgencyStaffPhotoOption>;
  selectedOptions: Array<MarriageAgencyStaffPhotoOption>;
  setSelectedOptions: React.Dispatch<React.SetStateAction<MarriageAgencyStaffPhotoOption[]>>;
};

export const OptionForm: FC<Props> = ({ options, selectedOptions, setSelectedOptions }) => {
  const handleClick = (option: MarriageAgencyStaffPhotoOption) => {
    const index = selectedOptions.findIndex(
      (selectedOption) => selectedOption.photoStudioOptionId === option.photoStudioOptionId
    );
    if (index !== -1) {
      setSelectedOptions((prevSelectedOptions) => [
        ...prevSelectedOptions.slice(0, index),
        ...prevSelectedOptions.slice(index + 1),
      ]);
    } else {
      setSelectedOptions([...selectedOptions, option]);
    }
  };

  const isSelected = (option: MarriageAgencyStaffPhotoOption) =>
    selectedOptions.some((selectedOption) => selectedOption.photoStudioOptionId === option.photoStudioOptionId);

  return (
    <>
      {options.length !== 0 && (
        <Typography variant="h5" sx={{ mb: 3 }}>
          オプションを選択する
        </Typography>
      )}
      <Grid container spacing={2}>
        {options &&
          options.map((option: MarriageAgencyStaffPhotoOption) => (
            <Grid key={option.photoStudioOptionId} item xs={12} sm={12} md={6}>
              <Button
                variant={isSelected(option) ? 'contained' : 'outlined'}
                sx={{ px: 1, py: 2, fontWeight: 'bold', fontSize: 'auto', width: '100%', borderWidth: 2 }}
                startIcon={
                  isSelected(option) ? (
                    <Iconify icon="ph:check" width={20} height={20} />
                  ) : (
                    <Iconify icon="fluent:checkbox-unchecked-24-regular" width={20} height={20} />
                  )
                }
                onClick={() => handleClick(option)}
              >{`${option.name}（＋${option.reserveMinutesForCustomer}分 / ${formatPrice(
                option.price ?? 0
              )}円）`}</Button>
            </Grid>
          ))}
      </Grid>
    </>
  );
};
