import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { reserveInputValidationSchema } from 'src/functions/validation-schema';
import { Option, PhotoPlan } from 'codegen/axios/photo/photo_studio';
import { useState } from 'react';
import { ReservationCreateEditFormInputs } from './inputs';
import { useReservationEdit } from '../hooks/plan-option-change';
import { RsvScheduleSelectModal } from '../select-modal';

type ReservationEditFormProps<TFormValue> = {
  formInputs: any;
  onSubmit: (formValue: TFormValue) => void;
  defaultValues: { [x: string]: any };
  plans: PhotoPlan[] | null;
  selectPlanState: [selectPlanId: any, setSelectPlanId: React.Dispatch<React.SetStateAction<any>>];
  selectFrameState: [selectFrameId: any, setSelectFrameId: React.Dispatch<React.SetStateAction<any>>];
  optionsState: [optionsState: Option[], setOptions: React.Dispatch<React.SetStateAction<any>>];
  edit: boolean | undefined;
};

export const ReservationEditForm = <TFormValue,>({
  onSubmit,
  formInputs,
  defaultValues,
  plans,
  selectPlanState,
  selectFrameState,
  optionsState,
  edit,
}: ReservationEditFormProps<TFormValue>) => {
  const [rsvSelectMdlData, setRsvSelectMdlData] = useState();
  const [selectPlanId, setSelectPlanId] = selectPlanState;
  const [selectFrameId, setSelectFrameId] = selectFrameState;
  const validationSchema = reserveInputValidationSchema(formInputs, [{ reservationTime: false }], selectPlanId);
  const methods = useForm({ resolver: yupResolver(validationSchema), defaultValues: defaultValues as any });
  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;
  const [options, setOptions] = optionsState;

  const rsvMin = useReservationEdit(
    methods,
    plans,
    options,
    selectPlanId,
    setSelectPlanId,
    setSelectFrameId,
    setOptions
  );
  return (
    <>
      {rsvSelectMdlData && (
        <RsvScheduleSelectModal rsvSelectMdlDataState={[rsvSelectMdlData, setRsvSelectMdlData]} methods={methods} />
      )}
      <ReservationCreateEditFormInputs
        setRsvSelectMdlData={setRsvSelectMdlData}
        onSubmit={onSubmit}
        formInputs={formInputs}
        plans={plans}
        methods={methods}
        handleSubmit={handleSubmit}
        rsvMin={rsvMin}
        options={options}
        selectPlanId={selectPlanId}
        isSubmitting={isSubmitting}
      />
    </>
  );
};
