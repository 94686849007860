import { useState } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';

// components
import Page from 'src/components/molecules/page';
import CompanyIndexTemplate from 'src/components/templates/company/company-index-template';
import { companyListFetchOrganize, nextPageCheckOrganize } from 'src/functions/fetch-value-organize';
// hooks
import useFetch from 'src/hooks/use-fetch';
// f
import { createQueryParameter } from 'src/functions/api-parameter';
import { createParams } from 'src/functions/params';
// staff
import useStaffInformation from 'src/hooks/use-staff-information';
import { Company } from 'codegen/axios/photo/admin/api';

export default function List() {
  /* hook宣言 */
  const staff = useStaffInformation();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  /* 定数宣言 */
  const title = '会社一覧';
  const rowsPerPage = 30;
  const page = Number(searchParams.get('page')) ?? 0;
  const searchName = searchParams.get('searchName') ?? null;
  const query = createQueryParameter({
    searchName: { key: 'searchName', value: searchName },
  });

  /* データ取得 */
  const { fetchedData: responseData } = useFetch<Company[]>({
    reqProp: {
      pathKey: 'company',
      queryParams: {
        offset: page * rowsPerPage,
        limit: rowsPerPage,
        ...query,
      },
    },
    observable: [searchParams],
  });
  // 次のページがあるかどうか
  const { fetchedData: nextPageCheck } = useFetch<Company[]>({
    reqProp: {
      pathKey: 'company',
      queryParams: {
        offset: (page + 1) * rowsPerPage,
        limit: 2,
        ...query,
      },
    },
    observable: [searchParams],
  });

  /* handle */
  const handleChangePage = (_: any, newPage: any) => {
    setSearchParams(createParams({ keyValues: { page: newPage }, searchParams }));
  };
  const handleSearchByName = (value: any) => {
    setSearchParams(createParams({ keyValues: { searchName: value }, searchParams, page: 0 }));
  };
  const handleRowClick = (id: any) => {
    navigate(`/${staff.userType}/companies/${id}`);
  };

  return (
    <Page title={title}>
      <CompanyIndexTemplate
        {...(() => ({
          page,
          title,
          searchName,
          rowsPerPage,
          nextPageCheck: nextPageCheckOrganize(nextPageCheck),
          responseData: companyListFetchOrganize(responseData),
          isUserNotFound: responseData.length === 0,
          handleChangePage,
          handleSearchByName,
          handleRowClick,
        }))()}
      />
    </Page>
  );
}
