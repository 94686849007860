import * as React from 'react';
import ja from 'date-fns/locale/ja';
import { TextField } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';

export default function MaterialUIPicker({
  label,
  variant,
  onChange,
  value,
  inputFormat,
  width,
  textValue,
  size,
  sx = {},
  inputPropsStyle = {},
}: any) {
  const [open, setOpen] = React.useState(false);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ja}>
      <DesktopDatePicker
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        label={label}
        inputFormat={inputFormat}
        disableMaskedInput
        value={value || null}
        onChange={onChange}
        renderInput={(params) => (
          <TextField
            sx={{ width, ...sx }}
            variant={variant}
            size={size}
            onClick={() => setOpen(true)}
            {...(() => params)()}
            inputProps={{
              ...params.inputProps,
              value: textValue,
              readOnly: true,
              style: { fontSize: 17, fontWeight: 'bold', ...inputPropsStyle },
            }}
          />
        )}
      />
    </LocalizationProvider>
  );
}
