import { Stack, Typography, Container, Grid, Button, useTheme, useMediaQuery } from '@mui/material';
import { FC, Fragment } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { externalSiteUrl } from 'src/pages/customer-reservation/top';
import { getPlanDoneText } from 'src/utils/customerIndexView';
import { CustomStepper } from '../components/CustomStepper';

export const CustomerReservationDone: FC = () => {
  const theme = useTheme();
  const [searchParams] = useSearchParams();
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'));
  const url = sessionStorage.getItem('top-return-url');
  const location = useLocation();
  const { state } = location as any;
  const photoStudioPlanId = searchParams.get('photoStudioPlanId') ?? '';
  const doneText = getPlanDoneText(photoStudioPlanId);

  // state.resIdがあればcookieに保存
  if (state?.resId) {
    localStorage.setItem('done/resId', state.resId);
  }

  return (
    <Container>
      <Typography variant="h4" color="text.secondary" sx={{ pb: 1, pt: 2, pl: 3 }}>
        撮影予約
      </Typography>
      <CustomStepper activeStep={3} />
      <Grid container spacing={3.5} sx={{ pb: 4 }}>
        <Grid item xs={12}>
          <Typography variant="h3" color="text.secondary" align="center" sx={{ pt: 3 }}>
            予約が完了しました
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography
            variant={isMdUp ? 'subtitle1' : 'subtitle2'}
            color="text.secondary"
            align="center"
            sx={{ pt: isMdUp ? 4 : 1 }}
          >
            入力されたメールアドレスにご予約内容の確認メールを送信しました。
            <br />
            自動返信メールが届かない場合、ご予約が確定できていない可能性がある為、お電話にてご連絡ください。 <br />
            <br />
            {doneText.split('\n').map((line, index) => (
              <Fragment key={index}>
                {line}
                <br />
              </Fragment>
            ))}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography
            sx={{
              mt: 5,
              py: 0.6,
              mx: isMdUp ? 45 : 1,
              bgcolor: 'grey.300',
              borderRadius: 0.7,
            }}
            variant="h4"
            color="text.secondary"
            align="center"
          >
            {`${doneText === '' ? '予約' : 'お友達紹介'}ID: ${
              state ? state.resId : localStorage.getItem('done/resId')
            }`}
          </Typography>
        </Grid>
      </Grid>
      <Stack direction="row" alignItems="center" justifyContent="center" sx={{ m: 1, pt: 3 }} spacing={5}>
        <Button
          variant="contained"
          sx={{ width: 170, height: 47 }}
          size="large"
          onClick={() => {
            window.location.href = url || externalSiteUrl; // ここで externalSiteUrl を定義するか、または外部から受け取る必要があります
          }}
        >
          予約トップに戻る
        </Button>
      </Stack>
    </Container>
  );
};
