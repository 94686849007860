import { Box, Button, DialogActions, DialogContent, Grid, Typography } from '@mui/material';
import { FC } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, RHFRadioButtonGroup, RHFTextArea, RHFTextField } from 'src/components/atoms/hook-form';
import {
  MarriageAgencyStaffPhotoPlan,
  MarriageAgencyStaffPhotoOption,
  PostReserveRequest,
} from 'codegen/axios/photo/adca';
import usePush from 'src/hooks/use-push';
import { reservationForAdcaValidationSchema } from 'src/functions/validation-schema';
import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { formatDate, formatDateToTime } from 'src/functions/format';
import LoadingButton from '@mui/lab/LoadingButton/LoadingButton';
import { reservationForAdcaValueOrganize } from 'src/functions/push-value-organize';

export type ReservationFormModalProps = {
  plan: MarriageAgencyStaffPhotoPlan;
  selectedOptions: Array<MarriageAgencyStaffPhotoOption>;
  defaultValues: PostReserveRequest;
  setDefaultValues: React.Dispatch<React.SetStateAction<PostReserveRequest>>;
  onClose: () => void;
};

export const ReservationFormModal: FC<ReservationFormModalProps> = ({
  plan,
  selectedOptions,
  defaultValues,
  setDefaultValues,
  onClose,
}) => {
  /* 定数宣言 */
  const validationSchema = reservationForAdcaValidationSchema();

  /* hook宣言 */
  const { id: photoStudioId } = useParams();
  const push = usePush();
  const methods = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = (formValue: PostReserveRequest) =>
    photoStudioId &&
    new Promise((resolve) => {
      push(resolve, {
        reqProp: {
          pathKey: 'reservation',
          method: 'post',
          data: reservationForAdcaValueOrganize({
            ...formValue,
            photoStudioId,
          }),
        },
        aftProp: {
          calledFunc: onClose,
          resolve,
          nextPath: '/list',
          showMessage: {
            state: {
              snackBar: {
                open: true,
                viewType: 'success',
                message: 'メールを送信して登録しました',
              },
            },
          },
        },
      });
    });

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <DialogContent dividers sx={{ width: 'auto', height: 'auto' }}>
        <Box m={2}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={8} md={12}>
              <Typography variant="h4">新規予約</Typography>
            </Grid>
            <Grid item xs={12} sm={8} md={12}>
              日時：{formatDate(defaultValues.reservationStartDateTime)}〜
              {formatDateToTime(defaultValues.reservationEndDateTime)}
            </Grid>

            <Grid item xs={12} sm={12} md={12}>
              選択中のプラン：{plan.name}
            </Grid>

            <Grid item xs={12} sm={12} md={12}>
              選択中のオプション：
              {selectedOptions.length !== 0 ? (
                <>
                  {selectedOptions.map((selectedOption: MarriageAgencyStaffPhotoOption) => (
                    <Grid key={selectedOption.photoStudioOptionId} item xs={12} sm={6} md={12}>
                      ・{selectedOption.name}
                    </Grid>
                  ))}
                </>
              ) : (
                `なし`
              )}
            </Grid>

            <Grid item xs={12} sm={8} md={6}>
              <RHFTextField
                id={'customerFirstName'}
                name={'customerFirstName'}
                label="姓（必須）"
                placeholder="例：佐藤"
              />
            </Grid>

            <Grid item xs={12} sm={8} md={6}>
              <RHFTextField
                id={'customerLastName'}
                name={'customerLastName'}
                label="名（必須）"
                placeholder="例：太郎"
              />
            </Grid>

            <Grid item xs={12} sm={6} md={12}>
              <RHFTextField
                id={'email'}
                name={'email'}
                label="メールアドレス（必須）"
                placeholder="例：exmple@photoapan.jp"
              />
            </Grid>

            <Grid item xs={12} sm={6} md={12}>
              <RHFTextField id={'telno'} name={'telno'} label="電話番号（任意）" placeholder="例：090-1234-5678" />
            </Grid>

            <Grid item xs={12} sm={12} md={12}>
              <RHFRadioButtonGroup
                label="性別（任意）"
                name="gender"
                options={[
                  {
                    value: 'man',
                    label: '男性',
                  },
                  {
                    value: 'woman',
                    label: '女性',
                  },
                  // {
                  //   value: 'unknown',
                  //   label: '選択しない',
                  // },
                ]}
              />
            </Grid>

            {/* <Grid item pt={4} xs={12} sm={6} md={12}>
              <RHFTextArea
                name="remark"
                placeholder="例)個室の席でお願いします→ 個室でのご予約を承りました"
                label="お客様からのご要望（任意）"
                multiline
                minRows={2}
              />
            </Grid> */}
          </Grid>
        </Box>
      </DialogContent>

      <DialogActions sx={{ flexDirection: 'column', mx: 3, my: 2 }}>
        <Box sx={{ display: 'flex', gap: 2, flexWrap: 'wrap' }}>
          <Button variant="outlined" onClick={() => onClose()}>
            キャンセル
          </Button>
          <LoadingButton sx={{ width: 100 }} size="large" type="submit" variant="contained" loading={isSubmitting}>
            送信
          </LoadingButton>
        </Box>
      </DialogActions>
    </FormProvider>
  );
};
