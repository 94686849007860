import { Card, CardHeader, Stack } from '@mui/material';
import { PhotoStudioInformation } from 'codegen/axios/photo/photo_studio';
import { FC, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import EditButton from 'src/components/atoms/edit-button';
import DeleteModal from 'src/components/molecules/delete-modal';
import DetailCard from 'src/components/molecules/detail-card';
import { informationFetchOrganize } from 'src/functions/fetch-value-organize';
import useFetch from 'src/hooks/use-fetch';
import usePush from 'src/hooks/use-push';
import { defaultValues } from 'src/components/organisms/photo-studio/detail/information/form';

export const PhotoStudioInformationCard: FC = () => {
  /* 定数宣言 */
  const title = '詳細情報';

  /* hook宣言 */
  const navigate = useNavigate();
  const push = usePush();
  const { id: photoStudioId } = useParams();
  const [listUpdate, setListUpdate] = useState(0);

  /* データ取得 */
  const { fetchedData: responseData } = useFetch<PhotoStudioInformation>({
    iniState: defaultValues,
    reqProp: {
      pathKey: 'information',
      queryParams: { photoStudioId },
    },
    observable: [listUpdate],
  });

  const handleInformationEditButtonOnClick = () => {
    responseData.photoStudioId === undefined
      ? navigate(`/photoStudioStaff/setting/photoStudio/${photoStudioId}/information/new`)
      : navigate(
          `/photoStudioStaff/setting/photoStudio/${photoStudioId}/information/${responseData.photoStudioInformationId}/edit`
        );
  };

  const onDelete = () => {
    new Promise((resolve) =>
      push(resolve, {
        reqProp: {
          pathKey: 'information',
          method: 'delete',
          pathParam: responseData.photoStudioInformationId,
        },
        aftProp: {
          resolve,
          nextPath: `/setting/photoStudio/${photoStudioId}`,
          showMessage: {
            state: { snackBar: { open: true, viewType: 'info', message: '削除しました' } },
          },
        },
      })
    ).then(() => {
      setListUpdate(listUpdate + 1);
    });
  };

  return (
    <>
      <Stack direction="row" alignItems="center" justifyContent="flex-end" mt={9}>
        {responseData.photoStudioInformationId !== undefined && (
          <DeleteModal
            sx={{
              bgcolor: 'background.paper',
              minWidth: 50,
              minHeight: 47,
              boxShadow: 1,
              borderRadius: 2,
              ml: 2,
            }}
            OnDelete={onDelete}
          />
        )}

        <EditButton handleEditOnClick={handleInformationEditButtonOnClick} />
      </Stack>

      <Card sx={{ mt: 5 }}>
        <CardHeader title={title} sx={{ mb: 3, mt: 1 }} />

        <DetailCard defaultValues={informationFetchOrganize(responseData)} />
      </Card>
    </>
  );
};
