import { FC, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Stack, Grid, Card, CardHeader } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { FormProvider, RHFTextField, RHFTextArea, RHFRadioButtonGroup } from 'src/components/atoms/hook-form';
import { photoStudioInformationValidationSchema } from 'src/functions/validation-schema';
import usePush from 'src/hooks/use-push';
import { useParams } from 'react-router-dom';
import useFetch from 'src/hooks/use-fetch';
import { PhotoStudioInformation } from 'codegen/axios/photo/photo_studio';
import { informationFormFetchOrganize } from 'src/functions/fetch-value-organize';

export type InformationFormType = Omit<PhotoStudioInformation, 'photoStudioInformationId'>;

export const defaultValues: InformationFormType = {
  photoStudioId: '',
  caption: '',
  url: '',
  access: '',
  paymentMethod: '',
  paymentRemark: '',
  changingRoomFlg: false,
  changingRoomRemark: '',
  deliveryMethod: '',
  reservationMethod: '',
  specialNote: '',
  remark: '',
};

export const InformationForm: FC = () => {
  /* hook宣言 */
  const push = usePush();
  const { id: photoStudioId } = useParams();
  const { informationId: photoStudioInformationId } = useParams();

  /* 定数宣言 */
  const editMode = !photoStudioInformationId;
  const validationSchema = photoStudioInformationValidationSchema();

  /* データ取得 */
  const { fetchedData: responseData } = useFetch<InformationFormType>({
    iniState: defaultValues,
    reqProp: {
      pathKey: 'information',
      pathParam: photoStudioInformationId,
    },
    disable: editMode,
    organize: informationFormFetchOrganize,
  });

  const methods = useForm<InformationFormType>({
    resolver: yupResolver(validationSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  useEffect(() => {
    methods.reset(responseData);
  }, [responseData, methods]);

  const onSubmit = (formValue: InformationFormType) =>
    new Promise((resolve) => {
      push(resolve, {
        reqProp: {
          pathKey: 'information',
          pathParam: photoStudioInformationId,
          method: editMode ? 'post' : 'put',
          data: { ...formValue, photoStudioId },
        },
        aftProp: {
          resolve,
          nextPath: `/setting/photoStudio/${photoStudioId}`,
          showMessage: {
            state: {
              snackBar: { open: true, viewType: 'success', message: editMode ? '登録しました' : '保存しました' },
            },
          },
        },
      });
    });
  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Card>
        <CardHeader title={'詳細情報'} />
        <Stack my={7} mx={4}>
          <Grid container rowSpacing={3} columnSpacing={3}>
            <Grid item xs={12} sm={6} md={12}>
              <RHFTextField name="caption" label="概要" placeholder="例：高品質でリーズナブルな写真館" />
            </Grid>
            <Grid item xs={12} sm={6} md={12}>
              <RHFTextField name="access" label="アクセス" placeholder="例：新宿駅西口徒歩5分" />
            </Grid>
            <Grid item xs={12} sm={6} md={12}>
              <RHFTextField
                name="reservationMethod"
                label="予約方法"
                placeholder="ホームページから予約を承っております。事前にご予約いただくことで、スムーズな撮影が可能です"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={12}>
              <RHFTextField name="url" label="URL" placeholder="例：http://photoapan.jp" />
            </Grid>

            <Grid item xs={12} sm={6} md={12}>
              <RHFTextField
                name="paymentMethod"
                label="お支払い方法"
                placeholder="例：クレジットカード決済(VISA、Mastercard、JCB、American Express、Diners Club) / QR・バーコード決済(PayPay) / 現金支払い"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={12}>
              <RHFTextField
                name="paymentRemark"
                label="お支払い方法に関する備考"
                placeholder="例：当日にレジにてお支払いをお願いいたします"
              />
            </Grid>
            <Grid item pl={8} xs={12} sm={12} md={12}>
              <RHFRadioButtonGroup
                label="更衣室"
                name="changingRoomFlg"
                options={[
                  {
                    value: true,
                    label: 'あり',
                  },
                  {
                    value: false,
                    label: 'なし',
                  },
                ]}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={12}>
              <RHFTextField
                name="changingRoomRemark"
                label="更衣室に関する備考"
                placeholder="例：お着替え用のスペースをご用意しております"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={12}>
              <RHFTextField
                name="deliveryMethod"
                label="撮影データの納品方法"
                placeholder="例：当日撮影データをご指定のメールアドレスへ送付させていただきます"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={12}>
              <RHFTextArea
                name="specialNote"
                label="注意事項"
                placeholder="例：ノーメイク（スキンケアのみ）で来店ください"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={12}>
              <RHFTextArea name="remark" label="その他備考" placeholder="例：ご不明点があれば、お問合せください" />
            </Grid>
          </Grid>
        </Stack>
      </Card>

      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }} />

      <Stack direction="row" alignItems="center" justifyContent="flex-end" sx={{ m: 1 }} spacing={8}>
        <LoadingButton sx={{ width: 200 }} size="large" type="submit" variant="contained" loading={isSubmitting}>
          {editMode ? '登録' : '保存'}
        </LoadingButton>
      </Stack>
    </FormProvider>
  );
};
