import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { useTheme, styled } from '@mui/material/styles';

import useResponsive from 'src/hooks/use-responsive';

import { useOffSetTop } from 'src/hooks/use-off-set-top';
import Logo from 'src/components/molecules/logo';
import LogoutModal from 'src/components/molecules/logout-modal';
import usePush from 'src/hooks/use-push';
import { Avatar, Typography } from '@mui/material';
import useStaffInformation from 'src/hooks/use-staff-information';
import { navConfig } from './config-navigation';
import NavDesktop from './nav/desktop/nav-desktop';
import NavMobile from './nav/mobile/nav-mobile';
import { HEADER } from '../config-layout';
import HeaderShadow from '../common/header-shadow';
// ----------------------------------------------------------------------

type Props = {
  headerOnDark: boolean;
};
export default function Header({ headerOnDark }: Props) {
  const theme = useTheme();
  const push = usePush();
  const offset = useOffSetTop();

  const mdUp = useResponsive('up', 'md');
  const staff = useStaffInformation();
  const handleLogout = () => {
    new Promise((resolve) => {
      push(resolve, {
        reqProp: { pathKey: 'signOut', method: 'post' },
        aftProp: {
          resolve,
          nextPath: `/login`,
          showMessage: {
            state: { snackBar: { open: true, viewType: 'success', message: 'ログアウトしました' } },
          },
        },
      });
    });
  };

  return (
    <AppBar>
      <Toolbar
        disableGutters
        sx={{
          backgroundColor: '#F9FAFB',
          color: 'text.primary',
          height: {
            xs: HEADER.H_MOBILE,
            md: HEADER.H_DESKTOP,
          },
          transition: theme.transitions.create(['height', 'background-color'], {
            easing: theme.transitions.easing.easeInOut,
            duration: theme.transitions.duration.shorter,
          }),
          ...(headerOnDark && {
            color: 'text.primary',
          }),
          ...(offset && {
            height: {},
          }),
        }}
      >
        <Box
          sx={{
            height: 1,
            mx: mdUp ? 9 : 3,
            display: 'flex',
            width: '100%',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Box
            sx={{
              lineHeight: 0,
              mr: 9,
              position: 'relative',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-start',
            }}
          >
            <Box sx={{ mr: 9 }}>
              <Logo />
            </Box>

            {mdUp && <NavDesktop data={navConfig} />}
          </Box>

          {mdUp && (
            <Stack spacing={2} direction="row" alignItems="center" justifyContent="flex-end">
              <AccountStyle>
                <Box sx={{ mr: 2 }}>
                  <Avatar alt="photoURL" />
                </Box>

                <Box>
                  <Typography variant="subtitle2" sx={{ color: 'text.primary' }}>
                    {staff.loggedName}
                  </Typography>

                  <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                    {staff.loggedRole === 'normal' ? '一般' : '管理者'}
                  </Typography>
                </Box>
              </AccountStyle>
              <LogoutModal OnDelete={handleLogout} />
            </Stack>
          )}

          {!mdUp && <NavMobile data={navConfig} />}
        </Box>
      </Toolbar>

      {offset && <HeaderShadow />}
    </AppBar>
  );
}

const AccountStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0.5, 2.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  backgroundColor: theme.palette.grey[500_12],
}));
