import { FC, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Box, CircularProgress, Container } from '@mui/material';
import useFetch from 'src/hooks/use-fetch';
import { Option, PhotoFormUiPartsList, PhotoPlan, ReserveDetail } from 'codegen/axios/photo/photo_studio';
import usePush from 'src/hooks/use-push';
import { ReservationEditForm } from '../form';
import { rsvEditFetchOrganize } from '../functions/fetch-value-organize';
import { addPhotoOptionIfNotExists, addPhotoPlanIfNotExists, filterAvailable } from '../functions';
import { convertInputFormToPostReserveRequestBody } from '../functions/push-value-organize';
import { mergeForm } from './func';

export const ReservationCreateEdit: FC = () => {
  /* hook宣言 */

  const [selectPlanId, setSelectPlanId] = useState(null);
  const [selectFrameId, setSelectFrameId] = useState(null);
  const push = usePush();
  const { id: rsvId } = useParams();

  /* データ取得 */
  const { fetchedData: formInputs } = useFetch<PhotoFormUiPartsList[] | null>({
    iniState: null,
    reqProp: { pathKey: 'formInputs' },
  });
  const { fetchedData: rsvDetail } = useFetch<ReserveDetail | null>({
    iniState: null,
    reqProp: {
      pathKey: 'reservation',
      pathParam: rsvId,
    },
  });

  const { fetchedData: plans } = useFetch<PhotoPlan[]>({
    iniState: null,
    reqProp: {
      pathKey: 'frame',
      pathParam: `${selectFrameId || (rsvDetail && rsvDetail.reserve.photoStudioFrameId)}/plans`,
    },

    disable: !rsvDetail,
    observable: [rsvDetail, selectFrameId],
  });

  const { fetchedData: options, setFetchedData: setOptions } = useFetch<Option[]>({
    iniState: null,
    reqProp: {
      pathKey: 'plan',
      pathParam: `${selectPlanId}/options`,
    },
    disable: !selectPlanId,
    observable: [selectPlanId],
  });
  const mergedForm = rsvDetail && formInputs ? mergeForm(rsvDetail.form, formInputs) : null;
  /* handle */
  const onSubmit = (formValue: any) =>
    new Promise((resolve) => {
      push(resolve, {
        reqProp: {
          pathKey: 'reservation',
          pathParam: rsvId,
          method: 'put',
          data: convertInputFormToPostReserveRequestBody(formValue, mergedForm, true),
        },
        aftProp: {
          resolve,
          nextPath: `/reservation/${rsvId}`,
          showMessage: {
            state: {
              snackBar: {
                open: true,
                viewType: 'success',
                message: '変更を保存しました',
              },
            },
          },
        },
      });
    });
  return (
    <Container>
      {rsvDetail && plans ? (
        formInputs && (
          <ReservationEditForm<ReserveDetail>
            edit
            onSubmit={onSubmit}
            formInputs={mergedForm}
            defaultValues={rsvEditFetchOrganize(rsvDetail)}
            plans={addPhotoPlanIfNotExists(filterAvailable<any>(plans), rsvDetail.selectedPlan)}
            selectPlanState={[selectPlanId, setSelectPlanId]}
            selectFrameState={[selectFrameId, setSelectFrameId]}
            optionsState={[
              addPhotoOptionIfNotExists(filterAvailable<any>(options), rsvDetail.selectedOption),
              setOptions,
            ]} // invalidFlgとスキーマには書いてあるがavailableFlgでかえる
          />
        )
      ) : (
        <Box sx={{ px: 10, display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100px' }}>
          <CircularProgress size={30} />
        </Box>
      )}
    </Container>
  );
};
