import { useState, Fragment } from 'react';
// @mui
import { TableRow, TableCell, Typography, IconButton, Checkbox, Box } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
// components
import Label from 'src/components/molecules/status-label';
import log from 'src/utils/log';

export default function CustomTableRow({ TABLE_HEAD, row, handleRowClick, hoverInvalid, rowOnClick }: any) {
  const [disabledDeleteButtonRow, setDisabledDeleteButtonRow] = useState(null);
  return (
    <TableRow
      hover={!hoverInvalid}
      tabIndex={-1}
      role="checkbox"
      {...(() => {
        if (handleRowClick) {
          return {
            onClick: () => handleRowClick(row.id),
          };
        }
        return false;
      })()}
      // onClick={() => handleRowClick(row.id)}
      style={{ textDecoration: 'none', whiteSpace: 'nowrap' }}
    >
      {TABLE_HEAD.map((column: any, index: any) => (
        <Fragment key={index}>
          {column.checkBoxProperty && (
            <TableCell padding="checkbox">
              <Checkbox
                checked={column.checkBoxProperty.selected.indexOf(row.id) !== -1}
                onChange={(event) => {
                  event.stopPropagation();
                  event.preventDefault();
                  column.checkBoxProperty.handleClick(event, row.id);
                }}
                onClick={(event) => {
                  event.stopPropagation();
                }}
              />
            </TableCell>
          )}
          {column.typographyProperty && (
            <TableCell align={column.align} sx={{ p: 2 }}>
              <Typography variant="subtitle2" alignItems="center" noWrap style={{ wordBreak: 'break-all' }}>
                {row[column.id]}
              </Typography>
            </TableCell>
          )}
          {column.labelProperty && (
            <TableCell align={column.align}>
              <Label
                variant="ghost"
                color={(() => {
                  const ary = column.labelProperty;
                  return ary[row[column.id]];
                })()}
              >
                {row[column.id]}
              </Label>
            </TableCell>
          )}
          {column.aryProperty && (
            <TableCell align={column.align}>
              {Array.isArray(row[column.id])
                ? row[column.id].map((value: any, index: any) => (
                    <Label key={index} variant="ghost" color={'warning'}>
                      {value}
                    </Label>
                  ))
                : log('aryPropertyが設定されてるのにデータは配列じゃないよ')}
            </TableCell>
          )}
          {column.buttonProperty && (
            <TableCell align={column.align}>
              <IconButton
                aria-label="delete"
                disabled={column.buttonProperty.disabled}
                onClick={(event) => {
                  event.stopPropagation();
                  column.buttonProperty.handleClick(event, row.id);
                }}
              >
                {column.icon}
              </IconButton>
            </TableCell>
          )}
          {!column.typographyProperty &&
            !column.labelProperty &&
            !column.buttonProperty &&
            !column.aryProperty &&
            !column.checkBoxProperty && (
              <TableCell align={column.align}>
                <Box
                  sx={{
                    maxWidth: 350,
                    minWidth: 100,
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                >
                  {row[column.id]}
                </Box>
              </TableCell>
            )}
        </Fragment>
      ))}
    </TableRow>
  );
}
