// ----------------------------------------------------------------------

import { getIcon } from 'src/components/atoms/Iconify';

export const pageLinks = [
  {
    order: '1',
    subheader: '設定',

    items: [
      { title: '店舗', path: '/photoStudioStaff/setting/photoStudio' },
      { title: 'プラン', path: '/photoStudioStaff/setting/plan' },
      { title: 'オプション', path: '/photoStudioStaff/setting/option' },
      { title: '予約枠', path: '/photoStudioStaff/setting/frame' },
      { title: '自動返信メール', path: '/photoStudioStaff/setting/autoMailSetting' },
      { title: '予約フォーム設定', path: '/photoStudioStaff/setting/reservationFormSetting' },
      { title: 'アカウント', path: '/photoStudioStaff/setting/account' },
    ],
  },
];

export const navConfig = [
  { title: 'スケジュール', path: '/photoStudioStaff/schedule', icon: getIcon('uis:schedule') },
  { title: '予約一覧', path: '/photoStudioStaff/reservation', icon: getIcon('eva:file-text-fill') },

  {
    title: '設定',
    path: '/setting',
    icon: getIcon('ant-design:setting-filled'),
    children: [pageLinks[0]],
  },
];
