import axios from 'axios';
import { Staff } from 'src/App';
import { hasPermission } from 'src/utils/accessControl';
import { api, ApiEndpoints } from 'src/utils/api';
import { API_LINK } from 'src/utils/env';
import { responseCheck } from 'src/functions/api/check';

type ApiParams = any;

type apiCallType = {
  reqProp: {
    method: 'get' | 'post' | 'put' | 'delete';
    url: string;
    data?: any;
    pathKey: string;
    addOption?: any;
    notAlert?: { status: number; message: string };
  };
  curtProp: {
    staff: Staff;
    pathname: string;
    search: string;
  };
  aftProp: {
    calledFunc?: any;
    errorFunc?: any;
    resolve?: any;
    nextPath?: any;
    navigate: (path: string, options?: any) => void;
    showMessage?: any;
    nextPathUseResponseData?: any;
    errorMessage?: any;
  };
};

export const apiCall = async <T>({
  reqProp: { method, url, data, pathKey, addOption, notAlert },
  curtProp: { staff, pathname, search },
  aftProp: { calledFunc, errorFunc, resolve, nextPath, navigate, showMessage, nextPathUseResponseData, errorMessage },
}: apiCallType): Promise<T> => {
  const option = addOption ? { ...addOption, withCredentials: true } : { withCredentials: true };
  try {
    let res;
    switch (method) {
      case 'get':
        res = await axios.get(url, option);
        break;
      case 'delete':
        res = await axios.delete(url, { data, ...option });
        break;
      default:
        res = await axios[method](url, data, option);
    }
    nextPath !== undefined &&
      moveNextPath(staff, res.data, navigate, nextPath, nextPathUseResponseData, pathname, search);
    showMessage && setShowMessage(showMessage);
    calledFunc && calledFunc();
    resolve && resolve(res.data);
    return res.data;
  } catch (e) {
    const stop = !responseCheck({
      staff,
      errorFunc,
      response: e && (e as any).response,
      navigate,
      pathname,
      method,
      pathKey,
      search,
      errorMessage,
      notAlert,
    });
    resolve && resolve();
    if (stop) throw e;
    console.log(e);
    return e as any;
  }
};

export const createApiUrl = ({
  staff,
  pathKey,
  pathParam,
  queryParams,
}: {
  staff: Staff;
  pathKey: keyof ApiEndpoints;
  pathParam?: string;
  queryParams?: ApiParams;
}): string => {
  const url = `${API_LINK}/${hasPermission(staff, 'apiPath', 'admin')}${api()[pathKey].path}`;
  return `${url}${pathParam ? `/${pathParam}` : ''}${queryParams ? `?${objectToURLSearchParams(queryParams)}` : ''}`;
};

export const setShowMessage = (showMessage: { state: { snackBar: { message: string; viewType: string } } }) => {
  localStorage.setItem('snackBarOpen', 'true');
  localStorage.setItem('snackBarMessage', showMessage.state.snackBar.message ?? '');
  localStorage.setItem('snackBarViewType', showMessage.state.snackBar.viewType ?? '');
};

export const moveNextPath = (
  staff: Staff,
  response: any,
  navigate: any,
  nextPath: any,
  nextPathUseResponseData: any,
  pathname: any,
  search: any
) => {
  if (nextPath === '') return navigate(`${pathname}${search}`);
  if (nextPathUseResponseData) return navigate(`/${staff.userType}${nextPath}/${response}`);
  return navigate(`/${staff.userType}${nextPath}`);
};

const objectToURLSearchParams = (obj: Record<string, any>): URLSearchParams => {
  const params = new URLSearchParams();
  Object.entries(obj).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      value.forEach((item) => {
        params.append(key, item);
      });
    } else {
      params.set(key, value);
    }
  });
  return params;
};
