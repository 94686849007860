import { FC, useEffect } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
import { Box, Link, Drawer, Typography, Avatar } from '@mui/material';
// hooks
import useResponsive from 'src/hooks/use-responsive';
// components
import Logo from 'src/components/molecules/logo';
import Scrollbar from 'src/components/molecules/scroll-bar';
import NavSection from 'src/components/molecules/nav-section';
import LogoutModal from 'src/components/molecules/logout-modal';
//
import navConfig from 'src/layouts/dashboard/NavConfig';
// グローバル変数
import useStaffInformation from 'src/hooks/use-staff-information';
import usePush from 'src/hooks/use-push';

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 200;

const RootStyle = styled('div')(({ theme }) => ({
  // [theme.breakpoints.up('lg')]: {
  //   flexShrink: 0,
  //   width: DRAWER_WIDTH,
  // },
}));

const AccountStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0.5, 2.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  backgroundColor: theme.palette.grey[500_12],
}));

type Props = {
  isOpenSidebar: boolean;
  onCloseSidebar: (value: React.SetStateAction<boolean>) => void;
};

export const DashboardSidebar: FC<Props> = ({ isOpenSidebar, onCloseSidebar }) => {
  const { pathname } = useLocation();
  const push = usePush();
  // const isDesktop = useResponsive('up', 'lg', false, false);

  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar(false);
    }
  }, [pathname]);

  const handleLogout = () => {
    new Promise((resolve) => {
      push(resolve, {
        reqProp: { pathKey: 'signOut', method: 'post' },
        aftProp: {
          resolve,
          nextPath: `/login`,
          showMessage: {
            state: { snackBar: { open: true, viewType: 'success', message: 'ログアウトしました' } },
          },
        },
      });
    });
  };
  const staff = useStaffInformation();
  const renderContent = (
    <>
      <Box sx={{ px: 2, display: 'inline-flex' }}>
        <Logo />
      </Box>

      <NavSection navConfig={navConfig} />
      {pathname.split('/')[1] !== 'photoStudioCustomer' && (
        <Box sx={{ pl: 20, display: 'inline-flex' }}>
          <AccountStyle>
            <Box sx={{ mr: 2 }}>
              <Avatar alt="photoURL" />
            </Box>

            <Box>
              <Typography variant="subtitle2" sx={{ color: 'text.primary' }}>
                {staff.loggedName}
              </Typography>

              <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                {staff.loggedRole === 'normal' ? '一般' : '管理者'}
              </Typography>
            </Box>
          </AccountStyle>
          <LogoutModal OnDelete={handleLogout} />
        </Box>
      )}
    </>
  );

  return <>{renderContent}</>;
};
