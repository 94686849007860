import { Dialog } from '@mui/material';
import { ReserveDetail } from 'codegen/axios/photo/photo_studio';
import { SelectModalReservationSchedule } from './reservation-schedule';

interface RsvScheduleSelectModalProps {
  rsvSelectMdlDataState: [any, React.Dispatch<React.SetStateAction<any>>];
  methods: any;
}

export function RsvScheduleSelectModal({ rsvSelectMdlDataState, methods }: RsvScheduleSelectModalProps) {
  const [, setRsvSelectMdlData] = rsvSelectMdlDataState;
  const handleClick = (ret: string) => {
    if (ret === 'rsvChange') {
      console.log('ok');
    }
    if (ret === 'close') {
      console.log('close');
      setRsvSelectMdlData(undefined);
    }
  };
  return (
    <>
      <Dialog
        open
        onClose={() => handleClick('close')}
        PaperProps={{
          style: {
            height: '90%',
            width: '90%',
            margin: 0,
            maxWidth: '90%',
          },
        }}
      >
        <SelectModalReservationSchedule methods={methods} onClose={() => handleClick('close')} />
      </Dialog>
    </>
  );
}
