import { FC } from 'react';
import Page from 'src/components/molecules/page';
import { PartnerStudioList } from 'src/components/organisms/reservation-for-adca/list';

export const ReservationForAdcaPage: FC = () => {
  /* 定数宣言 */
  const title = '提携店一覧';

  return (
    <Page title={title}>
      <PartnerStudioList title="提携のPHOTO推奨フォト" pathKey="studio" />
    </Page>
  );
};
