import Page from 'src/components/molecules/page';
import { Container, Stack } from '@mui/material';
import HeadLineText from 'src/components/atoms/head-line-text';
import { FC } from 'react';
import { AvailablePlanForm } from 'src/components/organisms/reservation-frame/detail/available-plan/form';

export const PhotoStudioAvailablePlanEditPage: FC = () => {
  /* 定数宣言 */
  const title = '選択可能プラン';

  return (
    <Page title={title}>
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" m={5}>
          <HeadLineText text={title} />
        </Stack>
        <AvailablePlanForm />
      </Container>
    </Page>
  );
};
