import { useState, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';
import FullCalendar from '@fullcalendar/react';
import type { EventClickArg, EventContentArg, DatesSetArg } from '@fullcalendar/react';
import scrollGridPlugin from '@fullcalendar/scrollgrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import LinearProgress from '@mui/material/LinearProgress';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import useFetchQuery from 'src/hooks/use-custom-fetch';
import { photoStudioCalendarAvailabilityFetchOrganize } from 'src/functions/fetch-value-organize';
import { toApiDateTimeFormat, toStringYmdD } from 'src/functions/date-time-organize';
import CustomDatePicker from 'src/components/atoms/date-picker';

type DateType = Date | null;

export const ReservationScheduleForCustomer = ({
  selectedStudioId,
  selectedPlanId,
  selectedOptionIds,
  calendarOnClick,
}: any) => {
  const { search } = useLocation();
  const savedData = sessionStorage.getItem(`cmr-rsv-selected-data-${search}`);
  const oldData = savedData ? JSON.parse(savedData) : null;
  const [date, setDate] = useState<Date | null>(new Date(oldData?.date || Date.now()));
  /* hook宣言 */
  const theme = useTheme();
  const [startDate, setStartDate] = useState<Date | undefined>(undefined);
  const [endDate, setEndDate] = useState<Date | undefined>(undefined);
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'));
  const endDateObj = new Date(endDate || '');
  const oneSecondBeforeEndDate = new Date(endDateObj.setSeconds(endDateObj.getSeconds() - 1)); // 1を引く
  const calendarRef = useRef<FullCalendar>(null!);

  /* データ取得 */

  const { data, isFetching } = useFetchQuery<any>({
    staleTime: 5000,
    enabled: !!(startDate && endDate && selectedStudioId && selectedPlanId),
    method: 'post',
    pathKey: 'calendar',
    queryParams: {
      startDateTime: startDate && toApiDateTimeFormat(startDate),
      endDateTime: oneSecondBeforeEndDate && toApiDateTimeFormat(oneSecondBeforeEndDate),
    },
    data: {
      photoStudioId: selectedStudioId,
      photoStudioPlanId: selectedPlanId,
      photoStudioOptionIds: selectedOptionIds,
    },
  });
  const event = data ? photoStudioCalendarAvailabilityFetchOrganize(data as any) : [];

  // 予約イベント取得用の日付セット
  const datesSet = (arg: DatesSetArg) => {
    setStartDate(arg.start);
    setEndDate(arg.end);
  };

  // クリックで予約フォームに値をセットして閉じる
  const eventClick = (info: EventClickArg) => {
    if (info.event.extendedProps.stock === 0) return;
    calendarOnClick(info, date);
  };

  const handleDateChange = (direction: 'prev' | 'today' | 'next'): void => {
    const calApi = calendarRef.current?.getApi();
    if (!calApi) return;
    calApi[direction]();
    setDate(calApi.getDate());
  };
  return (
    <Card sx={{ mt: 2 }}>
      <Box sx={{ height: '50%', m: 0 }}>
        {/* ヘッダー */}
        <>
          <Box sx={{ mb: 2, mt: isMdUp ? 2 : 1, mx: 2 }}>
            <Grid container rowSpacing={0} columnSpacing={0}>
              <Grid item xs={0} sm={0} md={4} />
              <Grid style={{ textAlign: 'center' }} item xs={6} sm={6} md={3} mt={1}>
                <CustomDatePicker
                  onChange={(newValue: Date | null): void => {
                    setDate(newValue);
                    calendarRef.current?.getApi().gotoDate(newValue && new Date(newValue.toString()));
                  }}
                  value={date}
                  size={!isMdUp ? 'small' : undefined}
                  sx={{ width: '100%' }}
                  inputPropsStyle={{ fontSize: isMdUp ? 17 : 11 }}
                  {...{ textValue: date && toStringYmdD(date), inputFormat: 'yyyy 年 MM 月 dd 日 (E)', width: 0.7 }}
                />
              </Grid>

              <Grid style={{ textAlign: 'right' }} item xs={6} sm={6} md={5} mt={1}>
                <Button
                  sx={{ mr: isMdUp ? 4 : 1, minWidth: '1px' }}
                  size={!isMdUp ? 'small' : undefined}
                  variant="outlined"
                  onClick={(): void => handleDateChange('today')}
                  disabled={!date || new Date(date.toString()).getDate() === new Date().getDate()}
                >
                  {isMdUp ? '今日' : '今週'}
                </Button>

                <Button
                  disabled={isFetching}
                  variant="contained"
                  sx={{ mr: isMdUp ? 1 : 0.5, minWidth: '1px' }}
                  size={!isMdUp ? 'small' : undefined}
                  onClick={(): void => handleDateChange('prev')}
                >
                  <IoIosArrowBack size={22} /> {isMdUp ? '前の週' : ''}
                </Button>
                <Button
                  disabled={isFetching}
                  variant="contained"
                  sx={{ mr: isMdUp ? 1 : 0.5, minWidth: '1px' }}
                  size={!isMdUp ? 'small' : undefined}
                  onClick={(): void => handleDateChange('next')}
                >
                  {isMdUp ? '次の週' : ''} <IoIosArrowForward size={22} />
                </Button>
              </Grid>
            </Grid>
          </Box>
        </>
        {/* ヘッダー */}
        {isFetching && <LinearProgress />}
        {
          <FullCalendar
            scrollTimeReset={false}
            contentHeight={window.innerHeight * 0.75}
            datesSet={datesSet}
            ref={calendarRef}
            schedulerLicenseKey="0439324989-fcs-1673827335"
            slotDuration={'0:05'}
            slotLabelFormat={{
              hour: 'numeric',
              minute: '2-digit',
              omitZeroMinute: false,
              meridiem: 'short',
            }}
            dayHeaderFormat={
              isMdUp
                ? undefined
                : {
                    day: 'numeric',
                    weekday: 'short',
                  }
            }
            slotMinTime="6:00"
            {...(() => {
              if (event.length === 0) return {};
              // 表示する期間内で最も開始時刻の早い枠、終了時刻の遅い枠を探し、カレンダーの表示範囲(縦軸の範囲)を決める
              const { start } = event.reduce((a: any, b: any) => (getHoursNum(a.start) < getHoursNum(b.start) ? a : b));
              const { end } = event.reduce((a: any, b: any) => (getHoursNum(a.end) > getHoursNum(b.end) ? a : b));

              // reservationStartTimeを":"で分割して時間部分だけを取得
              const startHours = start?.split(':')[0].split(' ')[1];
              const endHours = end?.split(':')[0].split(' ')[1];

              return {
                slotMinTime: `${startHours}:00`, // 分を"00"にして時間を返す
                slotMaxTime: `${Number(endHours) + 2}:00`, // 分を"00"にして時間を返す
              };
            })()}
            eventContent={renderEventContent}
            initialView={'timeGridWeek'}
            initialDate={new Date(oldData?.date || Date.now())}
            firstDay={(date || new Date()).getDay()} // ここで今日の日付を指定
            dayCellContent={(e) => e.dayNumberText.replace('日', '')}
            slotLabelInterval="01:00"
            eventClick={eventClick}
            nowIndicator
            plugins={[scrollGridPlugin, timeGridPlugin]}
            headerToolbar={false}
            events={event}
            locale="ja"
            dayMinWidth={isMdUp ? 100 : 40}
            slotEventOverlap={false}
            allDaySlot={false}
          />
        }
      </Box>
    </Card>
  );
};

const renderEventContent = (eventInfo: EventContentArg) => {
  const { title: startTimeOfReservationFrame, extendedProps: { content: remainingReservationFrame }, start: startDate, end: endDate } = eventInfo.event;
  const spacingElements = getSpacingElements(startDate, endDate);
  return (
    <div style={{ textAlign: 'center' }}>
      <b>{startTimeOfReservationFrame}</b>
      {spacingElements}
      <b>{remainingReservationFrame}</b>
    </div>
  );
};

const checkWithinFiveMinutes = (startDate: Date, endDate: Date): boolean => {
  if (!startDate || !endDate) return false;
  const diffInMinutes = getDateDifferenceInMinutes(startDate, endDate);
  return diffInMinutes <= 5;
};

const getDateDifferenceInMinutes = (startDate: Date, endDate: Date): number => {
  if (!startDate || !endDate) return 0;
  const startInMilliseconds = startDate.getTime();
  const endInMilliseconds = endDate.getTime();
  const diffInMilliseconds = Math.abs(endInMilliseconds - startInMilliseconds);
  return diffInMilliseconds / (1000 * 60);
};

export const getDateObj = (dt: string) => {
  const dtString = dt.replace(/-/g, '/');
  return new Date(dtString);
};

const getHoursNum = (dt: string): Number => {
  return getDateObj(dt).getHours();
}

const getSpacingElements = (startDate: DateType, endDate: DateType) => {
  if (!startDate || !endDate) return '';
  const isWithinFiveMinutes = checkWithinFiveMinutes(startDate, endDate);
  const spacingElements = isWithinFiveMinutes ? ' ' : <br />;
  return spacingElements;
};
