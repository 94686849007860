import {PhotoStudioCustomerPhotoStudio} from 'codegen/axios/photo/photo_studio_customer';

export const useHooks = (allStudios: PhotoStudioCustomerPhotoStudio[] | undefined, displayStudioIds: string[]) => {
  const sortedDisplayStudios = filterSortStudios(allStudios, displayStudioIds);

  return { sortedDisplayStudios };
}

const filterSortStudios = (allStudios: PhotoStudioCustomerPhotoStudio[] | undefined, displayStudioIds: string[]) => {
  if(!allStudios) return [];

  const filteredStudios = allStudios.filter((studios) => displayStudioIds.includes(studios.photoStudioId));

  return filteredStudios.sort((pre, crr) => {
    return displayStudioIds.indexOf(pre.photoStudioId) - displayStudioIds.indexOf(crr.photoStudioId);
  });
}
