import Page from 'src/components/molecules/page';
import { FC } from 'react';
import { PhotoStudioDetail } from 'src/components/organisms/photo-studio/detail';

export const PhotoStudioDetailPage: FC = () => {
  /* 定数宣言 */
  const title = '店舗詳細';

  return (
    <Page title={title}>
      <PhotoStudioDetail />
    </Page>
  );
};
