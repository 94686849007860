import Page from 'src/components/molecules/page';
import { Container } from '@mui/material';
import { FC } from 'react';

import { CustomerReservationInput } from 'src/components/organisms/customer-reservation/input';

export const CustomerReservationInputPage: FC = () => {
  /* 定数宣言 */
  const title = '情報入力';

  return (
    <Page title={title}>
      <Container maxWidth={false}>
        <CustomerReservationInput />
      </Container>
    </Page>
  );
};
