import { Box, SxProps } from '@mui/material';
import { alpha } from '@mui/material/styles';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';

interface SortIconProps {
  direction: 'asc' | 'desc';
  sx?: SxProps;
}
export default function SortIcon({ direction, sx }: SortIconProps) {
  return (
    <Box sx={{ ml: 1, display: 'inline-grid', height: '100%', ...sx }} fontSize={'2rem'}>
      <ArrowDropUpIcon
        sx={{ fontSize: 29 }}
        viewBox="1 -9 24 24"
        {...(() => {
          if (direction === 'asc') {
            return {
              color: 'primary',
            };
          }
          return {
            sx: { color: alpha('#919EAB', 0.32), fontSize: 29 },
          };
        })()}
      />

      <ArrowDropDownIcon
        sx={{ fontSize: 29 }}
        viewBox="1 9 24 24"
        {...(() => {
          if (direction === 'desc') {
            return {
              color: 'primary',
            };
          }
          return {
            sx: { color: alpha('#919EAB', 0.32), fontSize: 29 },
          };
        })()}
      />
    </Box>
  );
}
