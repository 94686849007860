// components
import Page from 'src/components/molecules/page';
// staff
import useStaffInformation from 'src/hooks/use-staff-information';
import { Container, Stack } from '@mui/material';
import HeadLineText from 'src/components/atoms/head-line-text';
import CreateButton from 'src/components/atoms/create-button';
import { FC } from 'react';
import { PlanList } from 'src/components/organisms/plan/list';

export const PhotoStudioPlanListPage: FC = () => {
  /* hook宣言 */
  const staff = useStaffInformation();

  /* 定数宣言 */
  const title = 'プラン一覧';

  return (
    <Page title={title}>
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" m={5}>
          <HeadLineText text={title} />
          <CreateButton text="プラン登録" path={`/${staff.userType}/setting/plan/new`} />
        </Stack>
        <PlanList />
      </Container>
    </Page>
  );
};
