import * as AutoKana from 'vanilla-autokana';
import { useParams } from 'react-router-dom';
import usePush from 'src/hooks/use-push';
import { accountValueOrganize } from 'src/functions/push-value-organize';
import { Card, CardHeader, Grid, Stack } from '@mui/material';
import { accountValidationSchema } from 'src/functions/validation-schema';
import { useForm } from 'react-hook-form';
import { FC, useEffect } from 'react';
import { FormProvider, RHFPhoneNumber, RHFRadioButtonGroup, RHFTextField } from 'src/components/atoms/hook-form';
import { LoadingButton } from '@mui/lab';
import { yupResolver } from '@hookform/resolvers/yup';
import useFetch from 'src/hooks/use-fetch';
import { PhotoStudioStaff, PostPhotoStudioStaffRequestBody } from 'codegen/axios/photo/photo_studio';
import { accountForFormFetchOrganize } from 'src/functions/fetch-value-organize';

export type AccountFormType = Omit<PostPhotoStudioStaffRequestBody, 'photoStudioStaffId'>;

export const defaultValues: AccountFormType = {
  photoStudioStaffFirstName: '',
  photoStudioStaffLastName: '',
  photoStudioStaffFirstNameKana: '',
  photoStudioStaffLastNameKana: '',
  email: '',
  telno: '',
  role: 'normal',
  password: '',
};

export const AccountForm: FC = () => {
  /* hook宣言 */
  const push = usePush();
  const { id: photoStudioStaffId } = useParams();

  /* 定数宣言 */
  const editMode = !photoStudioStaffId;
  const validationSchema = accountValidationSchema(photoStudioStaffId);
  const autoKanaFirst = document.getElementById('firstName') && AutoKana.bind('firstName');
  const autoKanaLast = document.getElementById('lastName') && AutoKana.bind('lastName');

  // /* データ取得 */
  const { fetchedData: responseData } = useFetch<PhotoStudioStaff>({
    iniState: defaultValues,
    reqProp: {
      pathKey: 'account',
      pathParam: photoStudioStaffId,
    },
    disable: editMode,
    organize: accountForFormFetchOrganize,
  });

  const methods = useForm<AccountFormType>({
    resolver: yupResolver(validationSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  useEffect(() => {
    methods.reset(responseData);
  }, [responseData, methods]);

  /* handle */
  const handleFirstName = () => {
    setTimeout(() => {
      if (autoKanaFirst) {
        methods.setValue('photoStudioStaffFirstNameKana', autoKanaFirst.getFurigana());
      }
    }, 100);
  };
  const handleLastName = () => {
    setTimeout(() => {
      if (autoKanaLast) {
        methods.setValue('photoStudioStaffLastNameKana', autoKanaLast.getFurigana());
      }
    }, 100);
  };

  const onSubmit = (formValue: AccountFormType) =>
    new Promise((resolve) => {
      push(resolve, {
        reqProp: {
          pathKey: 'account',
          pathParam: photoStudioStaffId,
          method: editMode ? 'post' : 'put',
          data: accountValueOrganize(formValue),
        },
        aftProp: {
          resolve,
          nextPath: `/setting/account`,
          showMessage: {
            state: {
              snackBar: { open: true, viewType: 'success', message: editMode ? '登録しました' : '保存しました' },
            },
          },
        },
      });
    });
  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Card>
        <CardHeader title={'基本情報'} />

        <Stack my={7} mx={4}>
          <Grid container rowSpacing={3} columnSpacing={3}>
            <Grid item pl={8} xs={12} sm={12} md={6}>
              <RHFRadioButtonGroup
                label="権限"
                name="role"
                options={[
                  {
                    value: 'admin',
                    label: '管理者',
                  },
                  {
                    value: 'normal',
                    label: '一般',
                  },
                ]}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              <RHFTextField
                name="photoStudioStaffFirstName"
                onChange={handleFirstName}
                label="姓"
                placeholder="例：佐藤"
              />
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              <RHFTextField
                name="photoStudioStaffLastName"
                onChange={handleLastName}
                label="名"
                placeholder="例：太郎"
              />
            </Grid>

            <Grid item xs={12} sm={0} md={6} style={{ padding: 0 }} />

            <Grid item xs={12} sm={6} md={3}>
              <RHFTextField name="photoStudioStaffFirstNameKana" label="姓(かな)" placeholder="例：さとう" />
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              <RHFTextField name="photoStudioStaffLastNameKana" label="名(かな)" placeholder="例：たろう" />
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
              <RHFTextField
                name="password"
                label={editMode ? 'パスワード' : 'パスワード (更新時のみ入力)'}
                placeholder="半角英字・数字の組み合わせ8文字以上"
              />
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
              <RHFTextField name="email" label="メールアドレス" placeholder="例：xxx@photoapan.jp" />
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
              <RHFPhoneNumber methods={methods} name="telno" label="電話番号" placeholder="例：09000001234" />
            </Grid>
          </Grid>
        </Stack>
      </Card>

      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }} />

      <Stack direction="row" alignItems="center" justifyContent="flex-end" sx={{ m: 1 }} spacing={8}>
        <LoadingButton sx={{ width: 200 }} size="large" type="submit" variant="contained" loading={isSubmitting}>
          {editMode ? '登録' : '保存'}
        </LoadingButton>
      </Stack>
    </FormProvider>
  );
};
