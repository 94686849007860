import React from 'react';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/system';
import useMediaQuery from '@mui/material/useMediaQuery';

type CustomStepperProps = {
  activeStep: number;
};

export const CustomStepper: React.FC<CustomStepperProps> = ({ activeStep }) => {
  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'));
  const isSmUp = useMediaQuery(theme.breakpoints.up('sm'));

  const customStyles = {
    ...(isMdUp && { pl: 2, pr: 2 }),
    ...(isSmUp && { flexDirection: 'column' }),
  };

  const isStepCompleted = (step: number) => activeStep >= step;

  return (
    <Stepper
      activeStep={activeStep}
      alternativeLabel
      sx={{
        pb: isMdUp ? 6 : 3,
        pt: isMdUp ? 3 : 1,
        ...(isMdUp && { px: 10 }),
        ...(isSmUp && { flexDirection: 'row' }),
      }}
    >
      <Step sx={{ ...(isSmUp && { flex: 1 }) }}>
        <StepLabel sx={customStyles}>
          <Typography variant={isMdUp ? 'body2' : 'caption'} color={isStepCompleted(0) ? 'primary' : 'inherit'}>
            店舗・日時選択
          </Typography>
        </StepLabel>
      </Step>
      <Step sx={{ ...(isSmUp && { flex: 1 }) }}>
        <StepLabel sx={customStyles}>
          <Typography variant={isMdUp ? 'body2' : 'caption'} color={isStepCompleted(1) ? 'primary' : 'inherit'}>
            情報入力
          </Typography>
        </StepLabel>
      </Step>
      <Step sx={{ ...(isSmUp && { flex: 1 }) }}>
        <StepLabel sx={customStyles}>
          <Typography variant={isMdUp ? 'body2' : 'caption'} color={isStepCompleted(2) ? 'primary' : 'inherit'}>
            確認・送信
          </Typography>
        </StepLabel>
      </Step>
    </Stepper>
  );
};
