import * as Yup from 'yup';
import { AnySchema } from 'yup';
import 'yup-phone';
import { hiraganaRegExp, passwordRegExp, postCodeRegExp } from 'src/utils/regexp';
import { OptionalObjectSchema } from 'yup/lib/object';
import { CompanyForm } from 'src/types/company-form';
import { CustomerForm } from 'src/types/customer-form';
import { PostMarriageAgencyStaffRequestBody, PostReserveRequest, TeamResponse } from 'codegen/axios/photo/adca';
import { PhotoStudioFormType } from 'src/components/organisms/photo-studio/form';
import { InformationFormType } from 'src/components/organisms/photo-studio/detail/information/form';
import { BusinessHourFormType } from 'src/components/organisms/photo-studio/detail/business-hour/form';
import { OptionFormType } from 'src/components/organisms/option/form';
import { PlanFormType } from 'src/components/organisms/plan/form';
import { ReservationFrameFormType } from 'src/components/organisms/reservation-frame/form';
import { IrregularSettingFormType } from 'src/components/organisms/reservation-frame/detail/irregular-setting/form';
import { AccountFormType } from 'src/components/organisms/account/form';
import { PhotoFormUiPartsType } from 'src/components/organisms/reservation-form-setting/form';
import { PhotoStudioCustomerPhotoFormUiPartsList } from 'codegen/axios/photo/photo_studio_customer';

export const formSettingValidationSchema = (): OptionalObjectSchema<Record<keyof PhotoFormUiPartsType, AnySchema>> =>
  Yup.object().shape({
    availableOptionIds: Yup.array(),
    photoReservationFormGroupId: Yup.string(),
    inputType: Yup.string().required('※ 必須'),
    name: Yup.string().required('※ 必須'),
    orderNumber: Yup.number().typeError('※ 必須').min(1, '※ 必須').required('※ 必須'),
    placeholder: Yup.string().notRequired(),
    requiredFlg: Yup.boolean().required(),
    customerViewFlg: Yup.boolean().required(),
    options: Yup.array().when('inputType', {
      is: (inputType: string) =>
        inputType === 'セレクトボックス' || inputType === 'ラジオボタン' || inputType === 'チェックボックス',
      then: Yup.array().min(1, '※ 必須'),
      otherwise: Yup.array().notRequired(),
    }),
    availablePlans: Yup.array(),
  });

export const photoStudioValidationSchema = (): OptionalObjectSchema<Record<keyof PhotoStudioFormType, AnySchema>> =>
  Yup.object().shape({
    photoStudioName: Yup.string().required('※ 必須').max(40, '40文字以内で入力してください'),
    photoStudioNameKana: Yup.string().required('※ 必須').max(60, '60文字以内で入力してください'),
    email: emailYup({ required: true }),
    telno: telnoYup({ required: true }),
    zip: zipYup(),
    prefecture: Yup.string().required('※ 必須'),
    city: Yup.string().required('※ 必須'),
    street: Yup.string().required('※ 必須'),
    building: Yup.string(),
  });

export const photoStudioInformationValidationSchema = (): OptionalObjectSchema<
  Record<keyof InformationFormType, AnySchema>
> =>
  Yup.object().shape({
    photoStudioId: Yup.string(),
    caption: Yup.string().max(80, '80文字以内で入力してください'),
    access: Yup.string().required('※ 必須').max(100, '100文字以内で入力してください'),
    reservationMethod: Yup.string().required('※ 必須'),
    url: Yup.string().nullable().url('URLの形式に誤りがあります'),
    paymentMethod: Yup.string(),
    paymentRemark: Yup.string(),
    changingRoomFlg: Yup.boolean().required('※ 必須'),
    changingRoomRemark: Yup.string(),
    deliveryMethod: Yup.string(),
    specialNote: Yup.string(),
    remark: Yup.string(),
  });

export const photoStudioIntroductionValidationSchema = () =>
  Yup.object().shape({
    commentTitle: Yup.string().required('※ 必須').max(50, '50文字以内で入力してください'),
    commentBody: Yup.string().required('※ 必須').max(550, '550文字以内で入力してください'),
  });

export const photoStudioBusinessHourValidationSchema = (): OptionalObjectSchema<
  Record<keyof BusinessHourFormType, AnySchema>
> =>
  Yup.object().shape({
    photoStudioId: Yup.string(),
    salesStartTime: Yup.string().required('※ 必須'),
    salesEndTime: Yup.string().required('※ 必須'),
    sundaySalesStartTime: Yup.string().required('※ 必須'),
    sundaySalesEndTime: Yup.string().required('※ 必須'),
    mondaySalesStartTime: Yup.string().required('※ 必須'),
    mondaySalesEndTime: Yup.string().required('※ 必須'),
    tuesdaySalesStartTime: Yup.string().required('※ 必須'),
    tuesdaySalesEndTime: Yup.string().required('※ 必須'),
    wednesdaySalesStartTime: Yup.string().required('※ 必須'),
    wednesdaySalesEndTime: Yup.string().required('※ 必須'),
    thursdaySalesStartTime: Yup.string().required('※ 必須'),
    thursdaySalesEndTime: Yup.string().required('※ 必須'),
    fridaySalesStartTime: Yup.string().required('※ 必須'),
    fridaySalesEndTime: Yup.string().required('※ 必須'),
    saturdaySalesStartTime: Yup.string().required('※ 必須'),
    saturdaySalesEndTime: Yup.string().required('※ 必須'),
    remark: Yup.string(),
  });

export const photoStudioIrregularSettingValidationSchema = (): OptionalObjectSchema<
  Record<keyof IrregularSettingFormType, AnySchema>
> =>
  Yup.object().shape({
    photoStudioId: Yup.string(),
    validStartDate: Yup.string().nullable().required('※ 必須'),
    validEndDate: Yup.string().nullable().required('※ 必須'),
    reservationStartTime: Yup.string().nullable().required('※ 必須'),
    reservationEndTime: Yup.string().nullable().required('※ 必須'),
    mondayAvailableFlg: Yup.boolean().required(),
    tuesdayAvailableFlg: Yup.boolean().required(),
    wednesdayAvailableFlg: Yup.boolean().required(),
    thursdayAvailableFlg: Yup.boolean().required(),
    fridayAvailableFlg: Yup.boolean().required(),
    saturdayAvailableFlg: Yup.boolean().required(),
    sundayAvailableFlg: Yup.boolean().required(),
    openOrCloseTag: Yup.string().required(),
  });

export const accountValidationSchema = (
  accountId: string | undefined
): OptionalObjectSchema<Record<keyof AccountFormType, AnySchema>> =>
  Yup.object().shape({
    photoStudioStaffFirstName: stringRequiredNoBlankYup(),
    photoStudioStaffLastName: stringRequiredNoBlankYup(),
    photoStudioStaffFirstNameKana: kanaYup('姓'),
    photoStudioStaffLastNameKana: kanaYup('名'),
    email: emailYup({ required: true }),
    telno: telnoYup({ required: true }),
    role: Yup.string().required(),
    password: passwordYup({ required: !accountId }),
  });

export const autoMailSettingValidationSchema = (id: any) =>
  Yup.object().shape({
    title: Yup.string().required(),
    type: selectYup({ enable: true }),
    signature: Yup.string().required(),
    content: Yup.string().required(),
    photoStudio: selectYup({ enable: true }),
    plan: selectYup({ enable: true }),
  });

export const genreValidationSchema = () =>
  Yup.object().shape({
    title: Yup.string().required('※ 必須'),
  });
export const serviceValidationSchema = () =>
  Yup.object().shape({
    serviceName: Yup.string().required('※ 必須'),
  });
export const loginValidationSchema = () =>
  Yup.object().shape({
    email: loginEmailYup(),
    password: loginPasswordYup(),
  });

export const twoFactorAuthSchema = () =>
  Yup.object().shape({
    cord: twoFactorAuthYup(),
  });

export const companyValidationSchema = (): OptionalObjectSchema<Record<keyof CompanyForm, AnySchema>> =>
  Yup.object().shape({
    companyName: Yup.string().required('※ 必須'),
    companyNameKana: kanaAllowBlankYup('会社名'),
    zip: zipYup(),
    prefecture: Yup.string().nullable().required('※ 必須'),
    city: stringRequiredNoBlankYup(),
    street: Yup.string().nullable().required('※ 必須'),
    email: emailYup({ required: true }),
    telno: telnoYup({ required: true }),
  });

export const customerValidationSchema = (): OptionalObjectSchema<Record<keyof CustomerForm, AnySchema>> =>
  Yup.object().shape({
    customerFirstName: stringRequiredNoBlankYup(),
    customerLastName: stringRequiredNoBlankYup(),
    customerFirstNameKana: kanaYup('姓'),
    customerLastNameKana: kanaYup('名'),
    email: emailYup({ required: true }),
    telno: telnoYup({ required: true }),
  });

export const planValidationSchema = (): OptionalObjectSchema<Record<keyof PlanFormType, AnySchema>> =>
  Yup.object().shape({
    name: Yup.string().required('※ 必須'),
    content: Yup.string().max(250, '250文字以内で入力してください'),
    price: Yup.number().required('※ 必須').typeError('数字を入力してください').positive('※ 必須'),
    invalidFlg: Yup.boolean().required(),
    reserveMinutes: Yup.number().required('※ 必須').typeError('※ 必須').positive('※ 必須'),
    reserveMinutesForCustomer: Yup.number().required('※ 必須').typeError('※ 必須').positive('※ 必須'),
  });

export const optionValidationSchema = (): OptionalObjectSchema<Record<keyof OptionFormType, AnySchema>> =>
  Yup.object().shape({
    name: Yup.string().required('※ 必須'),
    content: Yup.string().max(250, '250文字以内で入力してください'),
    price: Yup.number().required('※ 必須').typeError('数字を入力してください').positive('※ 必須'),
    reserveMinutes: Yup.number().required('※ 必須').typeError('※ 必須').positive('※ 必須'),
    reserveMinutesForCustomer: Yup.number().required('※ 必須').typeError('※ 必須').positive('※ 必須'),
    invalidFlg: Yup.boolean(),
  });

export const reservationFrameValidationSchema = (): OptionalObjectSchema<
  Record<keyof ReservationFrameFormType, AnySchema>
> =>
  Yup.object().shape({
    name: Yup.string().required('※ 必須'),
    color: Yup.string().required('※ 必須'),
    validStartDate: Yup.string().required('※ 必須'),
    validEndDate: Yup.string().required('※ 必須'),
    unitTimeMin: Yup.number().typeError('※ 必須').positive('※ 必須').required('※ 必須'),
    priorityOrderNumber: Yup.number().typeError('※ 必須').positive('※ 必須').required('※ 必須'),
    reservationStartTime: Yup.string().required('※ 必須'),
    reservationEndTime: Yup.string().required('※ 必須'),
    sundayAvailableFlg: Yup.boolean().required(),
    mondayAvailableFlg: Yup.boolean().required(),
    tuesdayAvailableFlg: Yup.boolean().required(),
    wednesdayAvailableFlg: Yup.boolean().required(),
    thursdayAvailableFlg: Yup.boolean().required(),
    fridayAvailableFlg: Yup.boolean().required(),
    saturdayAvailableFlg: Yup.boolean().required(),
    pageViewFlg: Yup.boolean().required(),
    photoStudio: selectYup({ enable: true }),
  });

export const reservationForAdcaValidationSchema = (): OptionalObjectSchema<
  Record<keyof PostReserveRequest, AnySchema>
> =>
  Yup.object().shape({
    reservationStartDateTime: Yup.string().required(),
    reservationEndDateTime: Yup.string().required(),
    photoStudioId: Yup.string(),
    photoStudioPlanId: Yup.string().required(),
    photoStudioOptions: Yup.array(),
    customerFirstName: Yup.string().required('※ 必須'),
    customerLastName: Yup.string().required('※ 必須'),
    gender: Yup.string().required(),
    email: emailYup({ required: true }),
    telno: telnoYup({ required: false }),
    remark: Yup.string(),
  });

export const statusChangeModalValidationSchema = () =>
  Yup.object().shape({
    ステータス: Yup.string().required('※ 必須'),
  });

export const customerRsvInputFormValidationSchema = (
  formInputs: PhotoStudioCustomerPhotoFormUiPartsList[],
  op: any,
  selectPlanId: string,
  selectOptions: any[]
) => {
  const schema: any = {};
  formInputs.forEach((group) => {
    (group.inputs || []).forEach((input) => {
      if (
        input.requiredFlg &&
        input.customerViewFlg &&
        input.availablePlans.some((obj: any) => obj.photoStudioPlanId === selectPlanId) &&
        (input.availableOptionIds.length === 0 ||
          input.availableOptionIds.some((id: any) => selectOptions.map((op) => op.photoStudioOptionId).includes(id)))
      ) {
        switch (input.inputType) {
          case 'checkbox':
            schema[input.name] = Yup.array().min(input.options.length, '※ 必須');
            break;
          case 'selectbox':
            schema[input.name] = selectYup({ enable: true });
            break;
          case 'radiobutton':
            schema[input.name] = Yup.string().required('※ 必須');
            break;
          case 'textbox':
            schema[input.name] = Yup.string().required('※ 必須');
            break;
          case 'textarea':
            schema[input.name] = Yup.string().required('※ 必須');
            break;
          default:
            break;
        }
      } else {
        switch (input.inputType) {
          case 'checkbox':
            schema[input.name] = Yup.array();
            break;
          case 'selectbox':
            schema[input.name] = selectYup({ enable: false });
            break;
          case 'radiobutton':
            schema[input.name] = Yup.string();
            break;
          case 'textbox':
            schema[input.name] = Yup.string();
            break;
          case 'textarea':
            schema[input.name] = Yup.string();
            break;
          default:
            break;
        }
      }
    });
  });

  schema.customerName = Yup.string()
    .required('※ 必須')
    .matches(/^[^\s]+\s+[^\s]+$/, '苗字と名前の間に空白を含めてください');

  schema.customerNameKana = Yup.string()
    .required('※ 必須')
    .matches(/^[^\s]+\s+[^\s]+$/, '苗字と名前の間に空白を含めてください');
  schema.customerEmail = emailYup({ required: true });
  schema.customerTelno = telnoYup({ required: true });
  // todo:ひらがなのみにする

  schema.zip = zipYup(false);
  schema.customerGender = Yup.string().required('※ 必須');
  if (op[0].reservationTime === true) {
    schema.reservationTime = Yup.string().required('※ 時間を再設定してください');
  } else {
    schema.reservationTime = Yup.string();
  }

  return Yup.object().shape(schema);
};

export const reserveInputValidationSchema = (formInputs: any, op: any, selectPlanId: null | string) => {
  const schema: any = {};
  schema.plan = selectYup({ enable: true });
  formInputs.forEach((group: any) => {
    group.inputs.forEach((input: any) => {
      switch (input.inputType) {
        case 'checkbox':
          schema[input.name] = Yup.array();
          break;
        case 'selectbox':
          schema[input.name] = Yup.string();
          break;
        case 'radiobutton':
          schema[input.name] = Yup.string();
          break;
        case 'textarea':
          schema[input.name] = Yup.string();
          break;
        default:
          break;
      }
    });
  });

  schema.customerName = Yup.string();

  schema.customerNameKana = Yup.string().matches(/^[\s]*([ぁ-んー\s]*?)[\s]*$/, '名前はひらがなのみ許可されます。');

  schema.customerEmail = emailYup({ required: false });
  schema.customerTelno = telnoYup({ required: false });
  // todo:ひらがなのみにする

  schema.zip = zipYup(false);
  schema.needTimeChange = Yup.boolean().oneOf([false]);
  if (op[0].reservationTime === true) {
    schema.reservationTime = Yup.string().required('※ 時間を再設定してください');
  } else {
    schema.reservationTime = Yup.string();
  }

  return Yup.object().shape(schema);
};

export const photoStudioRegularHolidayValidationSchema = () =>
  Yup.object().shape({
    monday: Yup.boolean().required(),
    tuesday: Yup.boolean().required(),
    wednesday: Yup.boolean().required(),
    thursday: Yup.boolean().required(),
    friday: Yup.boolean().required(),
    saturday: Yup.boolean().required(),
    sunday: Yup.boolean().required(),
    remark: Yup.string().nullable(),
  });

export const filterBarValidationSchema = () =>
  Yup.object().shape({
    periodStart: Yup.string().nullable().required('※ 必須'),
    periodEnd: Yup.string().nullable().required('※ 必須'),
  });

export const staffValidationSchema = (
  staffId: string | undefined
): OptionalObjectSchema<Record<keyof PostMarriageAgencyStaffRequestBody, AnySchema>> =>
  Yup.object().shape({
    marriageAgencyStaffTeamId: Yup.string().nullable(),
    firstName: stringRequiredNoBlankYup(),
    lastName: stringRequiredNoBlankYup(),
    firstNameKana: kanaYup('姓'),
    lastNameKana: kanaYup('名'),
    email: emailYup({ required: true }),
    telno: telnoYup({ required: true }),
    role: Yup.string().required(),
    password: passwordYup({ required: !staffId }),
  });

export const teamValidationSchema = (): OptionalObjectSchema<Record<keyof TeamResponse, AnySchema>> =>
  Yup.object().shape({
    name: stringRequiredNoBlankYup(),
    email: emailYup({ required: true }),
  });

export const twoFactorAuthYup = () =>
  Yup.string()
    .required('※ 必須')
    .matches(/^(?!.*\s).*$/g, '空白を削除してください')
    .matches(/^[0-9]{6}$/, '6桁の半角数字を入力してください');

export const zipYup = (required = true) =>
  required
    ? Yup.string().required('※ 必須').matches(postCodeRegExp, '郵便番号の形式に誤りがあります')
    : Yup.string().matches(postCodeRegExp, '郵便番号の形式に誤りがあります');

export const telnoYup = ({ required }: { required: boolean }) =>
  required
    ? Yup.string()
        .required('※ 必須')
        .matches(/^0.*/, '先頭は半角の0で始まる必要があります')
        .matches(/^[0-9()-]*$/, 'ハイフン(-)、カッコ()、半角数字のみ使用できます')
        .phone('JP', true, '電話番号の形式に誤りがあります')
    : Yup.string()
        .notRequired()
        .matches(/^[0-9()-]*$/, '電話番号の形式が正しくありません');

export const selectYup = ({ enable }: any) =>
  enable &&
  Yup.object({
    label: Yup.string().nullable().required('※ 必須'),
  })
    .nullable()
    .required('※ 必須');

export const emailYup = ({ required }: any) =>
  required
    ? Yup.string()
        .required('※ 必須')
        .matches(/^(?!.*\s).*$/g, '空白を削除してください')
        .matches(/^[\x20-\x7F]*$/, '全角文字は使用できません')
        .email('メールアドレスの形式に誤りがあります')
    : Yup.string()
        .matches(/^(?!.*\s).*$/g, '空白を削除してください')
        .matches(/^[\x20-\x7F]*$/, '全角文字は使用できません')
        .email('メールアドレスの形式に誤りがあります');
export const loginEmailYup = () => Yup.string().email('正しいメールアドレスを入力してください').required('※ 必須');
export const stringRequiredNoBlankYup = () =>
  Yup.string()
    .required('※ 必須')
    .matches(/^(?!.*\s).*$/g, '空白を削除してください');
export const kanaYup = (value: any) =>
  Yup.string()
    .required('※ 必須')
    .matches(/^(?!.*\s).*$/g, '空白を削除してください')
    .matches(hiraganaRegExp, `${value}(かな)の入力形式に誤りがあります`);
export const kanaAllowBlankYup = (value: any) =>
  Yup.string().required('※ 必須').matches(hiraganaRegExp, `${value}(かな)の入力形式に誤りがあります`);
export const passwordYup = ({ required }: any) =>
  required
    ? Yup.string()
        .required('※ 必須')
        .matches(/^(?!.*\s).*$/g, '空白を削除してください')
        .matches(passwordRegExp, 'パスワードの入力形式に誤りがあります')
    : Yup.string()
        .matches(/^(?!.*\s).*$/g, '空白を削除してください')
        .matches(passwordRegExp, 'パスワードの入力形式に誤りがあります');
export const loginPasswordYup = () => Yup.string().required('※ 必須');

export const arrayNotEmpty = (message = '※ 必須') =>
  Yup.mixed().test({
    name: 'arrayNotEmpty',
    test: (value: any) => Array.isArray(value) && value.length > 0,
    message,
  });
