import usePush from 'src/hooks/use-push';
import { Card, CardHeader, Grid, Stack } from '@mui/material';
import { useForm } from 'react-hook-form';
import { FC, useEffect } from 'react';
import { FormProvider, RHFTextField } from 'src/components/atoms/hook-form';
import { LoadingButton } from '@mui/lab';
import { TeamResponse } from 'codegen/axios/photo/adca';
import useFetch from 'src/hooks/use-fetch';
import { yupResolver } from '@hookform/resolvers/yup';
import { teamValidationSchema } from 'src/functions/validation-schema';

export type TeamFormType = { name: string; email: string };

export const defaultValues: TeamFormType = {
  name: '',
  email: '',
};

export const TeamForm: FC = () => {
  /* hook宣言 */
  const push = usePush();

  /* 定数宣言 */
  const validationSchema = teamValidationSchema();

  // /* データ取得 */
  const { fetchedData: responseData } = useFetch<TeamResponse>({
    iniState: defaultValues,
    reqProp: {
      pathKey: 'team',
    },
  });

  const methods = useForm<TeamFormType>({
    resolver: yupResolver(validationSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  useEffect(() => {
    methods.reset(responseData);
  }, [responseData, methods]);

  const onSubmit = (formValue: TeamFormType) =>
    new Promise((resolve) => {
      push(resolve, {
        reqProp: {
          pathKey: 'team',
          method: 'put',
          data: formValue,
        },
        aftProp: {
          resolve,
          nextPath: `/staff`,
          showMessage: {
            state: {
              snackBar: { open: true, viewType: 'success', message: '保存しました' },
            },
          },
        },
      });
    });

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Card>
        <CardHeader title={'基本情報'} />

        <Stack my={7} mx={4}>
          <Grid container rowSpacing={3} columnSpacing={3}>
            <Grid item xs={12} sm={6} md={6}>
              <RHFTextField name="name" label="所属チーム名" placeholder="例：関東Aチーム" />
            </Grid>

            <Grid item xs={12} sm={6} md={6}>
              <RHFTextField name="email" label="メールアドレス" placeholder="例：xxx@photoapan.jp" />
            </Grid>
          </Grid>
        </Stack>
      </Card>

      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }} />

      <Stack direction="row" alignItems="center" justifyContent="flex-end" sx={{ m: 1 }} spacing={8}>
        <LoadingButton sx={{ width: 200 }} size="large" type="submit" variant="contained" loading={isSubmitting}>
          保存
        </LoadingButton>
      </Stack>
    </FormProvider>
  );
};
