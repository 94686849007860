import { useState, Fragment } from 'react';

// @mui
import { styled } from '@mui/material/styles';
import { Toolbar, Tooltip, IconButton, Typography, Stack } from '@mui/material';
// component
import PeriodDatePicker from 'src/components/atoms/period-date-picker';
import Iconify from 'src/components/atoms/Iconify';
import MultipleSelect from 'src/components/molecules/multiple-select-input';
import SearchInput from 'src/components/molecules/search-input';
// f
import { companyOptionFetchOrganize } from 'src/functions/fetch-value-organize';
// staff
import useStaffInformation from 'src/hooks/use-staff-information';
import { hasPermission } from 'src/utils/accessControl';
// hooks
import useFetch from 'src/hooks/use-fetch';

// ----------------------------------------------------------------------

const RootStyle = styled(Toolbar)(({ theme }) => ({
  height: 96,
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(0, 1, 0, 3),
}));

// ----------------------------------------------------------------------

export default function ListToolbar({
  handleFilterView,
  filterView,
  handlePeriodStart,
  handlePeriodEnd,
  periodStart,
  periodEnd,
  placeholder,
  numSelected,
  handleSearchByName,
  searchName,
  filterUseProperty,
  handleFilterByRestaurant,
  handleFilterByCompany,
  restaurantFilterValue,
  companyFilterValue,
  width,
}: any) {
  /* hook宣言 */
  const [textFieldRestaurantOption, setTextFieldRestaurantOption] = useState('');
  const [textFieldCompanyOption, setTextFieldCompanyOption] = useState('');
  const [timer, setTimer] = useState<NodeJS.Timeout | null>(null);
  const staff = useStaffInformation();

  /* データ取得 */
  const { fetchedData: restaurantOption } = useFetch<any>({
    reqProp: {
      pathKey: 'studio',
      queryParams: {
        offset: 0,
        limit: 100,
        ...(() => textFieldRestaurantOption && { query: textFieldRestaurantOption.replace(/\s+/g, '') })(),
      },
    },

    disable: !filterUseProperty,
    observable: [textFieldRestaurantOption],
  });

  const { fetchedData: companyOption } = useFetch<any>({
    reqProp: {
      pathKey: 'company',
      queryParams: {
        offset: 0,
        limit: 100,
        ...(() => textFieldCompanyOption && { query: textFieldCompanyOption.replace(/\s+/g, '') })(),
      },
    },
    disable: !filterUseProperty || !hasPermission(staff, 'listToolBar', 'company'),
    observable: [textFieldCompanyOption],
  });

  /* handle */
  const textFieldOnChangeRestaurantOption = (event: any) => {
    timer && clearTimeout(timer);
    const newTimer = setTimeout(() => {
      setTextFieldRestaurantOption(event.target.value);
    }, 400);
    setTimer(newTimer);
  };
  const textFieldOnChangeCompanyOption = (event: any) => {
    timer && clearTimeout(timer);
    const newTimer = setTimeout(() => {
      setTextFieldCompanyOption(event.target.value);
    }, 400);
    setTimer(newTimer);
  };
  const onSearchChange = (value: any) => {
    timer && clearTimeout(timer);
    const newTimer = setTimeout(() => {
      handleSearchByName(value);
    }, 400);
    setTimer(newTimer);
  };
  const changeFilterByRestaurant = (event: any, item: any) => {
    handleFilterByRestaurant(event, item);
    if (item === null) {
      setTextFieldRestaurantOption('');
    }
  };
  const changeFilterByCompany = (event: any, item: any) => {
    handleFilterByCompany(event, item);
    if (item === null) {
      setTextFieldCompanyOption('');
    }
  };

  return (
    <>
      <RootStyle
        sx={{
          ...(numSelected > 0 && {
            color: 'primary.main',
            bgcolor: 'primary.lighter',
          }),
        }}
      >
        {numSelected > 0 ? (
          <Typography component="div" variant="subtitle1">
            {numSelected} selected
          </Typography>
        ) : (
          <SearchInput sx={{ width }} onChange={onSearchChange} placeholder={placeholder} defaultValue={searchName} />
        )}
        {handlePeriodStart && (
          <>
            <PeriodDatePicker
              setValue={handlePeriodStart}
              defaultValue={periodStart}
              name={'periodStart'}
              label={'開始日'}
            />
          </>
        )}
        {handlePeriodEnd && (
          <>
            〜
            <PeriodDatePicker name={'periodEnd'} label={'終了日'} setValue={handlePeriodEnd} defaultValue={periodEnd} />
          </>
        )}
        {filterUseProperty && (
          <Tooltip title="フィルター">
            <IconButton
              {...(() => {
                if (restaurantFilterValue || companyFilterValue) {
                  return { color: 'primary' };
                }
                return false;
              })()}
              onClick={handleFilterView}
            >
              <Iconify icon="ic:round-filter-list" />
            </IconButton>
          </Tooltip>
        )}
      </RootStyle>
      {filterView && (
        <>
          <Stack direction="row" alignItems="center" justifyContent="flax" m={5}>
            <MultipleSelect
              filterOptions={(x: any) => x}
              defaultValue={restaurantFilterValue}
              onChange={changeFilterByRestaurant}
              textFieldOnChange={textFieldOnChangeRestaurantOption}
              option={restaurantOption}
              variant={'standard'}
              label={'店舗'}
              noOptionsText={'入力待機中...'}
              placeholder="入力して検索"
              isOptionEqualToValue={(option: any, value: any) => option.label === value}
            />
            {hasPermission(staff, 'listToolBar', 'company') && (
              <MultipleSelect
                filterOptions={(x: any) => x}
                defaultValue={companyFilterValue}
                onChange={changeFilterByCompany}
                textFieldOnChange={textFieldOnChangeCompanyOption}
                option={companyOptionFetchOrganize(companyOption)}
                variant={'standard'}
                noOptionsText={'入力待機中...'}
                placeholder="入力して検索"
                label={'会社'}
                isOptionEqualToValue={(option: any, value: any) => option.label === value}
              />
            )}
          </Stack>
        </>
      )}
    </>
  );
}
