import { Card, TableContainer, TablePagination, Table } from '@mui/material';
import ListHead from 'src/components/molecules/list-head';
import Scrollbar from 'src/components/molecules/scroll-bar';
import NotFoundTableBody from 'src/components/molecules/not-found-table-body';
import ListToolbar from 'src/components/molecules/list-toolbar';
import CustomTableBody from 'src/components/molecules/custom-table-body';
import { count, labelDisplayedRows } from 'src/functions/pagination';
import useFetch from 'src/hooks/use-fetch';
import useStaffInformation from 'src/hooks/use-staff-information';
import { FC } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { createQueryParameter } from 'src/functions/api-parameter';
import { createParams } from 'src/functions/params';
import { PhotoStudioStaff } from 'codegen/axios/photo/photo_studio';
import { accountListFetchOrganize } from 'src/functions/fetch-value-organize';

const TABLE_HEAD = [
  { id: 'name', label: '氏名', align: 'left', typographyProperty: { variant: 'subtitle2', noWrap: true } },
  { id: 'email', label: 'メールアドレス', align: 'left' },
  { id: 'telno', label: '電話番号', align: 'left' },
];

export const AccountList: FC = () => {
  /* 定数宣言 */
  const rowsPerPage = 30;

  /* hook宣言 */
  const staff = useStaffInformation();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const page = Number(searchParams.get('page')) ?? 0;
  const searchName = searchParams.get('searchName') ?? '';
  const query = createQueryParameter({
    searchName: { key: 'searchName', value: searchName },
  });

  /* データ取得 */
  const { fetchedData: responseData } = useFetch<Array<PhotoStudioStaff>>({
    reqProp: {
      pathKey: 'account',
      queryParams: {
        offset: page * rowsPerPage,
        limit: rowsPerPage,
        ...query,
      },
    },
    observable: [searchParams],
    organize: accountListFetchOrganize,
  });

  // 次のページがあるかどうか
  const { fetchedData: nextPageCheck } = useFetch<boolean>({
    reqProp: {
      pathKey: 'account',
      queryParams: {
        offset: (page + 1) * rowsPerPage,
        limit: 2,
        ...query,
      },
    },
    observable: [searchParams],
  });

  /* handle */
  const handleChangePage = (_: any, newPage: number) => {
    setSearchParams(createParams({ keyValues: { page: newPage }, searchParams }));
  };
  const handleSearchByName = (value: string) => {
    setSearchParams(createParams({ keyValues: { searchName: value }, page: 0, searchParams }));
  };
  const handleRowClick = (id: string) => {
    navigate(`/${staff.userType}/setting/account/${id}`);
  };

  const emptyRows = page > 0 ? Math.max(0, rowsPerPage - responseData.length) : 0;
  const isUserNotFound = responseData.length === 0;

  return (
    <Card>
      <ListToolbar
        searchName={searchName}
        handleSearchByName={handleSearchByName}
        placeholder={'氏名、メールアドレス、電話番号'}
        width={350}
      />

      <Scrollbar>
        <TableContainer sx={{ minWidth: 800 }}>
          <Table>
            <ListHead headLabel={TABLE_HEAD} />

            <CustomTableBody
              {...{
                TABLE_HEAD,
                responseData,
                handleRowClick,
                emptyRows,
              }}
            />

            {isUserNotFound && <NotFoundTableBody searchName={searchName} />}
          </Table>
        </TableContainer>
      </Scrollbar>

      <TablePagination
        rowsPerPageOptions={[30]}
        component="div"
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        count={count(nextPageCheck, rowsPerPage, page)}
        labelDisplayedRows={({ from, to }) => labelDisplayedRows(responseData, nextPageCheck, from, to)}
      />
    </Card>
  );
};
