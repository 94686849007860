import Page from 'src/components/molecules/page';
import useStaffInformation from 'src/hooks/use-staff-information';
import { Container, Stack } from '@mui/material';
import HeadLineText from 'src/components/atoms/head-line-text';
import CreateButton from 'src/components/atoms/create-button';
import { FC } from 'react';
import { FrameList } from 'src/components/organisms/reservation-frame/list';

export const PhotoStudioFrameListPage: FC = () => {
  /* hook宣言 */
  const staff = useStaffInformation();

  /* 定数宣言 */
  const title = '予約枠一覧';

  return (
    <Page title={title}>
      <Container maxWidth={false}>
        <Stack direction="row" alignItems="center" justifyContent="space-between" m={5}>
          <HeadLineText text={title} />
          <CreateButton text="予約枠登録" path={`/${staff.userType}/setting/frame/new`} />
        </Stack>
        <FrameList />
      </Container>
    </Page>
  );
};
