import * as React from 'react';
import ja from 'date-fns/locale/ja';
import { Stack, TextField, IconButton } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';

export default function MaterialUIPickers({ label,  setValue: set, defaultValue }: any) {
  const [value, setValue] = React.useState(defaultValue);
  const [open, setOpen] = React.useState(false);
  const handleChange = (newValue: any) => {
    setValue(newValue);
    set(newValue);
  };
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ja}>
      <Stack spacing={2}>
        <DesktopDatePicker
          open={open}
          onOpen={() => setOpen(true)}
          onClose={() => setOpen(false)}
          label={label}
          inputFormat="yyyy / MM / dd  (E)"
          toolbarFormat="yyyy / MM / dd  (E)"
          disableMaskedInput
          value={value || null}
          onChange={handleChange}
          renderInput={(params) => (
            <TextField
              variant={'standard'}
              onClick={() => setOpen(true)}
              {...(() => {
                // @ts-expect-error TS(2532): Object is possibly 'undefined'.
                params.InputProps.endAdornment = (
                  <IconButton
                    onClick={(e) => {
                      e.stopPropagation();
                      handleChange(null);
                    }}
                    disabled={!value}
                    style={{ order: 1 }}
                  >
                    <ClearIcon color="disabled" fontSize="small" />
                  </IconButton>
                );
                return params;
              })()}
            />
          )}
        />
      </Stack>
    </LocalizationProvider>
  );
}
