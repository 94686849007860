// material
import { Box, TableRow, TableCell, TableHead, TableSortLabel, Checkbox } from '@mui/material';
import { Fragment } from 'react';
// component
import SortIcon from 'src/components/atoms/sort-icon';

// ----------------------------------------------------------------------

const visuallyHidden = {
  border: 0,
  margin: -1,
  padding: 0,
  width: '1px',
  height: '1px',
  overflow: 'hidden',
  position: 'absolute',
  whiteSpace: 'nowrap',
  clip: 'rect(0 0 0 0)',
};

export default function ListHead({ order, orderBy, headLabel, onRequestSort }: any) {
  const createSortHandler = (property: any) => (event: any) => {
    onRequestSort(event, property);
  };
  return (
    <TableHead>
      <TableRow>
        {headLabel.map((headCell: any, index: any) => (
          <Fragment key={index}>
            {headCell.checkBoxProperty ? (
              <>
                <TableCell padding="checkbox">
                  <Checkbox
                    indeterminate={
                      headCell.checkBoxProperty.numSelected > 0 &&
                      headCell.checkBoxProperty.numSelected < headCell.checkBoxProperty.rowCount
                    }
                    checked={
                      headCell.checkBoxProperty.rowCount > 0 &&
                      headCell.checkBoxProperty.numSelected === headCell.checkBoxProperty.rowCount
                    }
                    onChange={headCell.checkBoxProperty.onSelectAllClick}
                  />
                </TableCell>
              </>
            ) : (
              <>
                <TableCell align={headCell.align}>
                  {headCell.sortable ? (
                    <TableSortLabel
                      IconComponent={() => <SortIcon direction={orderBy === headCell.id && order} />}
                      active={orderBy === headCell.id}
                      direction={orderBy === headCell.id ? headCell.order : 'asc'}
                      onClick={createSortHandler(headCell.id)}
                    >
                      {headCell.label}
                    </TableSortLabel>
                  ) : (
                    headCell.label
                  )}
                </TableCell>
              </>
            )}
          </Fragment>
        ))}
      </TableRow>
    </TableHead>
  );
}
