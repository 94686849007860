import { Option, PhotoPlan, ReserveDetailSelectedPlan } from 'codegen/axios/photo/photo_studio';

export const filterAvailable = <T extends { availableFlg: boolean }>(data: T[] | null): T[] =>
  data ? data.filter((item) => item.availableFlg === true) : [];

export const addPhotoPlanIfNotExists = (plans: PhotoPlan[], selectedPlan: ReserveDetailSelectedPlan) => {
  // plansにphotoStudioPlanIdが一致するものがなければ追加して返す
  if (!plans.some((plan) => plan.photoStudioPlanId === selectedPlan.photoStudioPlanId)) {
    plans.push({ ...selectedPlan, invalidFlg: selectedPlan.availableFlg }); // ここスキーマではinvalidFlgで来てる
  }
  return plans;
};
export const addPhotoOptionIfNotExists = (options: Option[], selectedOption: any) => {
  // optionsにphotoStudioOptionIdが一致するものがなければ追加して返す
  selectedOption.forEach((option: any) => {
    if (!options.some((item) => item.photoStudioOptionId === option.photoStudioOptionId)) options.push(option);
  });
  return options;
};
