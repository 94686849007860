import { FC } from 'react';
import { Container, Stack, Typography } from '@mui/material';
import Page from 'src/components/molecules/page';
import { RegularHolidayForm } from 'src/components/organisms/photo-studio/detail/regular-holiday/form';

export const RegularHolidayEditPage: FC = () => {
  const title = '定休日編集';

  return (
    <Page title={title}>
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            {title}
          </Typography>
        </Stack>
        <RegularHolidayForm />
      </Container>
    </Page>
  );
};
