import parsePhoneNumber from 'libphonenumber-js/max';

export const phoneNumberDropHyphen = (value: any) => {
  const phoneNumber = parsePhoneNumber(value, { defaultCountry: 'JP', extract: true });
  if (!phoneNumber) return { value, valid: false };
  if (!phoneNumber.isValid()) return { value, valid: false };
  return { value: phoneNumber.formatNational().replace(/\s+/g, '').replace(/-/g, ''), valid: true };
};
export const phoneNumberAddHyphen = (telno: any) => {
  if (!telno) return telno;
  const tel = telno.toString();
  const phoneNumber = parsePhoneNumber(tel, { defaultCountry: 'JP', extract: true });
  if (!phoneNumber) return tel;
  if (!phoneNumber.isValid()) return tel;
  return phoneNumber.formatNational();
};
