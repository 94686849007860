import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { FC, useEffect, useState } from 'react';
import { MarriageAgencyStaffPhotoOption, MarriageAgencyStaffPhotoPlan } from 'codegen/axios/photo/adca/api';
import { Typography } from '@mui/material';
import { formatPrice } from 'src/functions/format';

type Props = {
  plan: MarriageAgencyStaffPhotoPlan;
  selectedOptions: Array<MarriageAgencyStaffPhotoOption>;
};

export const CalTable: FC<Props> = ({ plan, selectedOptions }) => {
  const sumPrices = (selectedOptions: Array<MarriageAgencyStaffPhotoOption>) =>
    selectedOptions.reduce((total, selectedOption) => total + (selectedOption.price ?? 0), 0);

  const sumMinutes = (selectedOptions: Array<MarriageAgencyStaffPhotoOption>) =>
    selectedOptions.reduce((total, selectedOption) => total + (selectedOption.reserveMinutesForCustomer ?? 0), 0);

  const [totalPrice, setTotalPrice] = useState(0);
  const [totalMinutes, setTotalMinutes] = useState(0);

  useEffect(() => {
    setTotalPrice(plan.price + sumPrices(selectedOptions));
    setTotalMinutes(plan.reserveMinutesForCustomer + sumMinutes(selectedOptions));
  }, [selectedOptions]);

  return (
    <>
      <Typography variant="h5" sx={{ mb: 3 }}>
        選択中のプラン・オプション
      </Typography>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell align="right">時間</TableCell>
              <TableCell align="right">料金</TableCell>
            </TableRow>

            {selectedOptions.length !== 0 ? (
              <TableRow>
                <TableCell>合計</TableCell>
                <TableCell align="right">{totalMinutes}分</TableCell>
                <TableCell align="right">{formatPrice(totalPrice)}円</TableCell>
              </TableRow>
            ) : (
              <></>
            )}
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell align="left">{plan.name}</TableCell>
              <TableCell align="right">{plan.reserveMinutesForCustomer}分</TableCell>
              <TableCell align="right">{formatPrice(plan.price)}円</TableCell>
            </TableRow>
            {selectedOptions.map((selectedOption: MarriageAgencyStaffPhotoOption) => (
              <TableRow key={selectedOption.photoStudioOptionId}>
                <TableCell align="left">{selectedOption.name}</TableCell>
                <TableCell align="right">{selectedOption.reserveMinutesForCustomer}分</TableCell>
                <TableCell align="right">{formatPrice(selectedOption.price)}円</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};
