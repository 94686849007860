import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Stack } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { FormProvider, RHFTextField } from 'src/components/atoms/hook-form';
import { twoFactorAuthSchema } from 'src/functions/validation-schema';

export default function TwoFactorAuthForm(props: any) {
  const { LoginOnSubmit, defaultValues } = props;

  const validationSchema = twoFactorAuthSchema();

  const methods = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues,
  });

  const {
    setValue,
    watch,
    clearErrors,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  // ホワイトアウトのエラーこれじゃないかな、一旦外す
  // useEffect(() => {
  //   const subscription = watch((value = {}) => {
  //     if (value.cord.match(/^[0-9]+$/)) return;

  //     setValue('cord', hankakuZenkaku(value.cord));
  //   });
  //   return () => subscription.unsubscribe();
  // }, [watch]);

  function hankakuZenkaku(str: any) {
    return str
      .replace(/\s/g, '')
      .replace(/[Ａ-Ｚａ-ｚ０-９]/g, (s: any) => String.fromCharCode(s.charCodeAt(0) - 0xfee0));
  }

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(LoginOnSubmit())}>
      <Stack sx={{ mb: 5 }}>
        <RHFTextField
          name="cord"
          label="ワンタイムコード"
          onChange={handleSubmit(LoginOnSubmit(), () => clearErrors())}
        />
      </Stack>

      <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting}>
        Login
      </LoadingButton>
    </FormProvider>
  );
}
