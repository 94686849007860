import { ReserveStatusEnum } from 'codegen/axios/photo/admin/api';
import { toApiDateTimeFormat, toApiTimeFormat } from 'src/functions/date-time-organize';
import { searchNameValueOrganize } from 'src/functions/push-value-organize';

export const createQueryParameter = ({
  status,
  restaurantId,
  customerName,
  participantsFilterValue,
  photoStudioFilterValue,
  companyFilterValue,
  regionFilterValue,
  orderBy,
  order,
  searchName,
  periodStart,
  periodEnd,
  periodStartTime,
  periodEndTime,
  tab,
}: any) => ({
  ...(() => (isEmpty(status) ? {} : { [status.key]: status.value }))(),
  ...(() => (isEmpty(periodStartTime) ? {} : { [periodStartTime.key]: toApiTimeFormat(periodStartTime.value) }))(),
  ...(() => (isEmpty(periodEndTime) ? {} : { [periodEndTime.key]: toApiTimeFormat(periodEndTime.value) }))(),
  ...(() => (isEmpty(customerName) ? {} : { customerName: searchNameValueOrganize(customerName.value) }))(),
  ...(() => setParams(participantsFilterValue))(),
  ...(() => setParams(companyFilterValue))(),
  ...(() => setParams(photoStudioFilterValue))(),
  ...(() => setParams(regionFilterValue))(),
  ...(() => setParams(restaurantId))(),
  ...(() =>
    isEmpty(orderBy) ? {} : { [orderBy.key]: orderBy.value === 'name' ? 'customerFirstName' : orderBy.value })(),
  ...(() => setParams(order))(),
  ...(() => (isEmpty(searchName) ? {} : { query: searchNameValueOrganize(searchName.value) }))(),
  ...(() => (isEmpty(periodStart) ? {} : { [periodStart.key]: toApiDateTimeFormat(periodStart.value, true, false) }))(),
  ...(() => (isEmpty(periodEnd) ? {} : { [periodEnd.key]: toApiDateTimeFormat(periodEnd.value, false, true) }))(),
  ...(() => {
    const ary: { [key: number]: ReserveStatusEnum } = {
      1: 'pre',
      2: 'confirm',
      3: 'confirm',
      4: 'cancel',
    };
    return tab === 0 || !tab ? {} : { status: ary[tab] };
  })(),
  ...(() =>
    tab === 2 && {
      startDatetime: setPeriodStartParams(periodStart),
    })(),
  ...(() =>
    tab === 3 && {
      endDatetime: setPeriodEndParams(periodEnd),
    })(),
});

export const isEmpty = (input: any) => !input || input.value === '' || !input.value;

export const setParams = (input: any) => (isEmpty(input) ? {} : { [input.key]: input.value });

export const nowDateTime = toApiDateTimeFormat(new Date());

export const isAfterToday = (periodStart: Date) => new Date() < new Date(toApiDateTimeFormat(periodStart, true, false));

export const isBeforeToday = (periodEnd: Date) => new Date(toApiDateTimeFormat(periodEnd, false, true)) < new Date();

export const setPeriodStartParams = (periodStart: Date) =>
  periodStart !== null && isAfterToday(periodStart) ? toApiDateTimeFormat(periodStart, true, false) : nowDateTime;

export const setPeriodEndParams = (periodEnd: Date) =>
  periodEnd !== null && isBeforeToday(periodEnd) ? toApiDateTimeFormat(periodEnd, false, true) : nowDateTime;
