import { FC } from 'react';
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';
import './styles.css';

export type ImageGalleryType = Array<{ original: string; thumbnail: string }>;

type Props = {
  images: ImageGalleryType;
};

export const ImageSlideshow: FC<Props> = ({ images }) => (
  <ImageGallery
    items={images}
    showNav={false}
    autoPlay
    showFullscreenButton={false}
    useBrowserFullscreen={false}
    showPlayButton={false}
  />
);
