import * as Sentry from '@sentry/react';
import { api } from '../../utils/api';

export const exceptionMessage = ({ response, method, pathKey }: any) =>
  // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  (api()[pathKey].exception[method] &&
    // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    api()[pathKey].exception[method][response.status] &&
    // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    api()[pathKey].exception[method][response.status][response.message.detail]) ??
  `エラーが発生しました。このメッセージが何回も表示される場合は、以下のメーセージを開発者にお伝えください。
    ${method} ${response.status} ${(() => {
    Sentry.captureMessage(
      // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      `${method} ${response.status} ${api()[pathKey].path} ${
        (response.message && response.message.detail) ?? 'エラー'
      }`,
      'error'
    );
    // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    return api()[pathKey].path;
  })()}
    ${(response.message && response.message.detail) ?? 'エラー'} `;
