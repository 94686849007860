import { Box, Card, CardHeader, Container, Stack, Table, TableContainer } from '@mui/material';
import Scrollbar from 'src/components/molecules/scroll-bar';
import ListHead from 'src/components/molecules/list-head';
import CustomTableBody from 'src/components/molecules/custom-table-body';
import { createQueryParameter } from 'src/functions/api-parameter';
import useStaffInformation from 'src/hooks/use-staff-information';
import { FC } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import useFetch from 'src/hooks/use-fetch';
import { PhotoFormUiPartsList } from 'codegen/axios/photo/photo_studio';
import { formSettingFetchOrganize, formSettingListFetchOrganize } from 'src/functions/fetch-value-organize';
import NotFoundTableBody from 'src/components/molecules/not-found-table-body';
import HeadLineText from 'src/components/atoms/head-line-text';
import CreateButton from 'src/components/atoms/create-button';

const TABLE_HEAD = [
  { id: 'name', label: '項目名', align: 'left', typographyProperty: { variant: 'subtitle2', noWrap: true } },
  { id: 'orderNumber', label: '並び順', align: 'center' },
  {
    id: 'requiredFlg',
    label: '必須/任意',
    align: 'center',
    labelProperty: {
      必須: 'error',
      任意: 'info',
    },
  },
  {
    id: 'customerViewFlg',
    label: 'お客様へ表示',
    align: 'center',
    labelProperty: {
      する: 'success',
      しない: 'cancel',
    },
  },
  { id: 'inputType', label: 'タイプ', align: 'left' },
  { id: 'availablePlans', label: '紐付けプラン', align: 'center' },
];

export const FormSettingList: FC = () => {
  /* 定数宣言 */
  const rowsPerPage = 3;
  const title = '予約フォーム設定';

  /* hooks */
  const staff = useStaffInformation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const page = Number(searchParams.get('page')) ?? 0;
  const searchName = searchParams.get('searchName') ?? '';
  const query = createQueryParameter({
    searchName: { key: 'searchName', value: searchName },
  });

  /* データ取得 */
  const { fetchedData: responseData } = useFetch<Array<PhotoFormUiPartsList>>({
    reqProp: {
      pathKey: 'formInputs',
      queryParams: { offset: page * rowsPerPage, limit: rowsPerPage, ...query },
    },
    observable: [searchParams],
    organize: formSettingListFetchOrganize,
  });

  /* handle */
  const handleRowClick = (id: string) => {
    navigate(`/${staff.userType}/setting/reservationFormSetting/${id}/edit`);
  };

  const emptyRows = page > 0 ? Math.max(0, rowsPerPage - responseData.length) : 0;
  const isGroupNotFound = responseData.length === 0;

  return (
    <Container maxWidth={false}>
      <Stack alignItems="start" mt={5} mx={5}>
        <HeadLineText text={title} />
      </Stack>

      <Stack spacing={10}>
        {responseData.map((data: PhotoFormUiPartsList, i: number) => (
          <Box key={data.photoReservationFormGroupId}>
            <Stack direction="row" alignItems="end" justifyContent="end">
              <CreateButton
                text="新規作成"
                path={`/${staff.userType}/setting/reservationFormSetting/${responseData[i].photoReservationFormGroupId}/new`}
              />
            </Stack>
            <Card sx={{ mt: 3 }}>
              <CardHeader title={data.groupName} sx={{ mb: 3, mt: 1 }} />
              <Scrollbar>
                <TableContainer sx={{ minWidth: 800 }}>
                  <Table>
                    <ListHead headLabel={TABLE_HEAD} />

                    <CustomTableBody
                      {...{
                        TABLE_HEAD,
                        responseData: data.inputs
                          ? formSettingFetchOrganize(data.inputs, responseData[i].photoReservationFormGroupId)
                          : [],
                        handleRowClick,
                        emptyRows,
                      }}
                    />

                    {data.inputs && data.inputs.length === 0 && <NotFoundTableBody searchName={searchName} />}
                  </Table>
                </TableContainer>
              </Scrollbar>
            </Card>
          </Box>
        ))}
      </Stack>

      {isGroupNotFound && (
        <Card sx={{ mt: 5 }}>
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <NotFoundTableBody searchName={searchName} />
              </Table>
            </TableContainer>
          </Scrollbar>
        </Card>
      )}
    </Container>
  );
};
