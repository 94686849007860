import { useState } from 'react';
// form
// @mui
import { Button, Typography, Grid } from '@mui/material';
// components

// utils
import { Link } from 'react-router-dom';
import log from 'src/utils/log';
import { ReserveDetailFormInner, ReserveDetailFormInnerInputsInner } from 'codegen/axios/photo/photo_studio';
import useStaffInformation from 'src/hooks/use-staff-information';
import { EventClickArg } from '@fullcalendar/react';
import { toApiDateTimeFormat } from 'src/functions/date-time-organize';
import { EmptySetModal } from '../empty-set-modal';

// ----------------------------------------------------------------------

export function ReservationEmptyDetail({ emptyDetailState, fetchTriggerTimestampState, setSemiDetailRsvId }: any) {
  const [emptyDetail] = emptyDetailState;
  const [openMdl, setOpenMdl] = useState(false);
  const staff = useStaffInformation();
  const handleClick = (ret: string) => {
    if (ret === 'pre') {
      setOpenMdl(true);
    }
    if (ret === 'newRsv') {
      log('pdfOut');
    }
  };
  const {
    info: { event },
  }: { info: EventClickArg } = emptyDetail;
  const [dateStr, timeStr] = formatDateTime(event.start!, event.end!);

  const queryString = `?photoStudioName=${event.extendedProps.photoStudioName}&photoStudioFrameName=${
    event.extendedProps.photoStudioFrameName
  }&photoStudioId=${event.extendedProps.photoStudioId}&photoStudioFrameId=${
    event.extendedProps.photoStudioFrameId
  }&startDateTime=${toApiDateTimeFormat(event.start!)}&endDateTime=${toApiDateTimeFormat(event.end!)}`;

  return (
    <>
      {openMdl && (
        <EmptySetModal
          setOpenMdl={setOpenMdl}
          event={event}
          fetchTriggerTimestampState={fetchTriggerTimestampState}
          emptyDetailState={emptyDetailState}
          setSemiDetailRsvId={setSemiDetailRsvId}
        />
      )}

      <Grid container sx={{ p: 3 }}>
        <Grid item xs={4}>
          <Typography color="text.secondary" variant="body1">
            予約枠
          </Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography color="text.secondary" variant="body1">
            {event.extendedProps.photoStudioFrameName}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography color="text.secondary" variant="body1">
            日付
          </Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography color="text.secondary" variant="body1">
            {dateStr}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography color="text.secondary" variant="body1">
            時間
          </Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography color="text.secondary" variant="body1">
            {timeStr}
          </Typography>
        </Grid>

        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', pb: 2, mt: 3 }}>
          <Button
            sx={{ mr: 1 }}
            target="_blank"
            color={'primary'}
            size="small"
            component={Link}
            to={`/${staff.userType}/reservation/new${queryString}`}
          >
            {'新規予約'}
          </Button>
          <Button variant="contained" color={'primary'} size="small" onClick={() => handleClick('pre')}>
            {'仮押え'}
          </Button>
        </Grid>
      </Grid>
    </>
  );
}

function formatDateTime(start: Date, end: Date) {
  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    weekday: 'short',
  };

  const startStr = start.toLocaleString('ja-JP', options);
  const startTimeStr = start.toLocaleString('ja-JP', { hour: '2-digit', minute: '2-digit' });
  const endTimeStr = end.toLocaleString('ja-JP', { hour: '2-digit', minute: '2-digit' });

  const dateStr = `${startStr.slice(0, 4)}/${startStr.slice(5)}`;
  const timeStr = `${startTimeStr} ～ ${endTimeStr}`;

  return [dateStr, timeStr];
}
