// @mui
import { Container, Stack, Box, Card, Table, TableContainer, TablePagination } from '@mui/material';
// components
import DeleteModal from 'src/components/molecules/delete-modal';
import CreateButton from 'src/components/atoms/create-button';
import ListToolbar from 'src/components/molecules/list-toolbar';
import EditButton from 'src/components/atoms/edit-button';
import Scrollbar from 'src/components/molecules/scroll-bar';
import NotFoundTableBody from 'src/components/molecules/not-found-table-body';
import ListHead from 'src/components/molecules/list-head';
import DetailCard from 'src/components/molecules/detail-card';
import HeadLineText from 'src/components/atoms/head-line-text';
import CustomTableBody from 'src/components/molecules/custom-table-body';
// function
import { count, labelDisplayedRows } from 'src/functions/pagination';
// staff情報
import useStaffInformation from 'src/hooks/use-staff-information';

const TABLE_HEAD = [
  { id: 'name', label: '氏名', align: 'left', typographyProperty: { variant: 'subtitle2', noWrap: true } },
  { id: 'email', label: 'メールアドレス', align: 'left' },
  { id: 'telno', label: '電話番号', align: 'left' },
];

export default function CompanyDetailTemplate({
  title,
  defaultValues,
  companyId: id,
  staffResponseData,
  handleRowClick,
  isUserNotFound,
  nextPageCheck,
  rowsPerPage,
  page,
  handleChangePage,
  handleEditButtonOnClick,
  handleSearchByName,
  searchName,
  OnDelete,
}: any) {
  const emptyRows = page > 0 ? Math.max(0, rowsPerPage - staffResponseData.length) : 0;
  const staff = useStaffInformation();
  return (
    <Container>
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2} ml={1} mt={'3%'} mr={0}>
        <HeadLineText text={title} />

        <Box sx={{ ml: 'auto' }}>
          <DeleteModal
            sx={{
              bgcolor: 'background.paper',
              minWidth: 50,
              minHeight: 47,
              boxShadow: 1,
              borderRadius: 2,
              ml: 2,
            }}
            OnDelete={OnDelete}
          />

          <EditButton handleEditOnClick={handleEditButtonOnClick} />
        </Box>
      </Stack>

      <DetailCard defaultValues={defaultValues} />

      <Stack direction="row" alignItems="center" justifyContent="space-between" ml={1} mb={2} mt={11}>
        <HeadLineText text="所属スタッフ" />

        <CreateButton text="スタッフ登録" path={`/${staff.userType}/companies/${id}/staffs/new`} />
      </Stack>

      <Card>
        <ListToolbar
          searchName={searchName}
          handleSearchByName={handleSearchByName}
          placeholder={'氏名、メールアドレス、電話番号'}
          width={350}
        />

        <Scrollbar>
          <TableContainer sx={{ minWidth: 800 }}>
            <Table>
              <ListHead headLabel={TABLE_HEAD} />

              <CustomTableBody
                {...(() => ({
                  TABLE_HEAD,
                  responseData: staffResponseData,
                  handleRowClick,
                  emptyRows,
                }))()}
              />

              {isUserNotFound && <NotFoundTableBody searchName={searchName} />}
            </Table>
          </TableContainer>
        </Scrollbar>

        <TablePagination
          rowsPerPageOptions={[5]}
          component="div"
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          count={count(nextPageCheck, rowsPerPage, page)}
          labelDisplayedRows={({ from, to }) => labelDisplayedRows(staffResponseData, nextPageCheck, from, to)}
        />
      </Card>
    </Container>
  );
}
