import { Card, CardContent, CardHeader, Typography } from '@mui/material';
import { Information } from 'codegen/axios/photo/adca';
import { FC } from 'react';

type Props = {
  name: string;
  telno: string;
  salesTime: string;
  regularHoliday: string;
  info: Omit<Information, 'photoStudioInformationId' | 'photoStudioId' | 'caption' | 'changingRoomFlg'> & {
    changingRoomFlg: string;
  };
};

export const InformationCard: FC<Props> = ({ name, telno, salesTime, regularHoliday, info }) => (
  <Card sx={{ display: 'col' }}>
    <CardHeader title={'サービス・店舗情報'} subheader={''} />
    <CardContent sx={{ flex: 1 }}>
      <Typography variant="body2" color="text.secondary">
        【スタジオ名】{name}
      </Typography>
      <Typography variant="body2" color="text.secondary">
        【電話番号】 {telno}
      </Typography>
      <Typography variant="body2" color="text.secondary">
        【営業時間】 {salesTime}
      </Typography>
      <Typography variant="body2" color="text.secondary">
        【定休日】
        {regularHoliday}
      </Typography>
      <Typography variant="body2" color="text.secondary">
        【アクセス】 {info.access}
      </Typography>
      <Typography variant="body2" color="text.secondary">
        【予約方法】 {info.reservationMethod}
      </Typography>
      <Typography variant="body2" color="text.secondary">
        【URL】 {info.url}
      </Typography>
      <Typography variant="body2" color="text.secondary">
        【お支払い方法】 {info.paymentMethod}
      </Typography>
      <Typography variant="body2" color="text.secondary">
        【お支払い方法に関する備考】 {info.paymentRemark}
      </Typography>
      <Typography variant="body2" color="text.secondary">
        【更衣室】 {info.changingRoomFlg}
      </Typography>
      <Typography variant="body2" color="text.secondary">
        【更衣室に関する備考】 {info.changingRoomRemark}
      </Typography>
      <Typography variant="body2" color="text.secondary">
        【撮影データの納品方法】 {info.deliveryMethod}
      </Typography>
      <Typography variant="body2" color="text.secondary">
        【注意事項】 {info.specialNote}
      </Typography>
      <Typography variant="body2" color="text.secondary">
        【その他備考】 {info.remark}
      </Typography>
    </CardContent>
  </Card>
);
