// @mui
import { TableRow, TableBody, TableCell, Skeleton } from '@mui/material';
// components
import CustomTableRow from 'src/components/molecules/custom-table-row';

export default function CustomTableBody({
  TABLE_HEAD,
  responseData,
  handleRowClick,
  emptyRows,
  hoverInvalid,
  rowOnClick,
  rowsPerPage = 100,
  loading,
}: any) {
  return (
    <TableBody style={{ wordBreak: 'break-all', whiteSpace: 'pre-line' }}>
      {!responseData || loading
        ? // スケルトンの行を表示
          Array.from({ length: rowsPerPage - emptyRows }).map((_, index) => (
            <SkeletonTableRow key={index} tableHeadLength={TABLE_HEAD.length} />
          ))
        : responseData.map((row: any, index: any) => (
            <CustomTableRow
              key={index}
              {...{
                rowOnClick,
                hoverInvalid,
                row,
                TABLE_HEAD,
                handleRowClick,
              }}
            />
          ))}
      {emptyRows > 0 && (
        <TableRow style={{ height: 53 * emptyRows }}>
          <TableCell colSpan={6} />
        </TableRow>
      )}
    </TableBody>
  );
}

export function SkeletonTableRow({ tableHeadLength }: any) {
  return (
    <TableRow>
      {Array.from({ length: tableHeadLength }).map((_, index) => (
        <TableCell key={index}>
          <Skeleton animation="wave" width="70%" height={30} />
        </TableCell>
      ))}
    </TableRow>
  );
}
