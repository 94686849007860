import { Container, Stack, Grid, Typography } from '@mui/material';
import { FC } from 'react';
import HeadLineText from 'src/components/atoms/head-line-text';
import Page from 'src/components/molecules/page';

// 注意事項のimageパスの配列
const title = ['当日注意事項', 'シャツについて', 'ネクタイについて'];

const images = title.map((title) => ({ path: `/static/notes-images/${title}.png`, title }));

export const ImageGallery: FC = () => (
  <Grid container spacing={2}>
    {images.map((image, index) => (
      <Grid item xs={12} sm={6} md={4} key={index} sx={{ mb: 5 }}>
        <Typography variant="h5" display="block" color="text.secondary" gutterBottom>
          {image.title}
        </Typography>
        <img src={image.path} alt={`Gallery ${index}`} style={{ width: '100%', height: 'auto' }} />
      </Grid>
    ))}
  </Grid>
);

export const CustomerNotesPage: FC = () => {
  const title = '注意事項';

  return (
    <Page title={title}>
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" my={5}>
          <HeadLineText text={title} />
        </Stack>

        <ImageGallery />
      </Container>
    </Page>
  );
};
