import React, { FC } from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import { colorOption } from 'src/utils/option';
import { Typography } from '@mui/material';
import { ColorSinglePicker } from '../color-utils';

type Props = {
  name: string;
  label: string;
};

export const RHFColorPicker: FC<Props> = ({ name, label }) => {
  const { control } = useFormContext();

  return (
    <>
      <Typography variant="caption" color="#708090">
        {label}
      </Typography>
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <ColorSinglePicker
            {...field}
            onChange={(event: React.ChangeEvent<HTMLInputElement>, value: string) => {
              field.onChange(value);
            }}
            value={field.value}
            colors={colorOption}
          />
        )}
      />
    </>
  );
};
