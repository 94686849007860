import { Box, Button, Container, Stack } from '@mui/material';
import { PhotoStudioReservationFrame, Rule } from 'codegen/axios/photo/photo_studio';
import { FC, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import EditButton from 'src/components/atoms/edit-button';
import HeadLineText from 'src/components/atoms/head-line-text';
import DeleteModal from 'src/components/molecules/delete-modal';
import { frameDetailFetchOrganize } from 'src/functions/fetch-value-organize';
import useFetch from 'src/hooks/use-fetch';
import usePush from 'src/hooks/use-push';
import useStaffInformation from 'src/hooks/use-staff-information';
import DetailCard from 'src/components/molecules/detail-card';
import { IrregularSettingList } from 'src/components/organisms/reservation-frame/detail/irregular-setting/list';
import { AvailablePlanCard } from 'src/components/organisms/reservation-frame/detail/available-plan';
import { defaultValues } from 'src/components/organisms/reservation-frame/form';
import Iconify from 'src/components/atoms/Iconify';

export const FrameDetail: FC = () => {
  /* 定数宣言 */
  const title = '予約枠詳細';
  const staff = useStaffInformation();
  const { id: photoStudioReservationFrameId } = useParams();
  const navigate = useNavigate();
  const push = usePush();
  const [listUpdate, setListUpdate] = useState(0);
  const returnSearch = localStorage.getItem('photoStudioStaff/setting/frame-search');

  /* ルールのデータ取得 */
  const { fetchedData: rule } = useFetch<Rule>({
    // 仮抑えを非表示にするために必要なルールを取得
    iniState: null,
    reqProp: {
      pathKey: 'rule',
      queryParams: { offset: 0, limit: 500 },
    },
    organize: (obj: Rule[]) => {
      // 与えられたrule配列から"ruleType": "status_color_change"の要素だけを抽出
      const statusColorChangeRules = obj.filter((r) => r.ruleType === 'status_plan_id');
      if (!statusColorChangeRules) {
        alert('ステータスプランIDのルールが存在しません。');
        return null;
      }
      const statusRule = statusColorChangeRules.find(
        (r) => r.conditionKey === 'ステータス' && r.conditionValue === '仮押え'
      );
      if (!statusRule) {
        alert('ステータスプランIDのルールが存在しません。');
        return null;
      }
      return statusRule;
    },
  });

  const { fetchedData: responseData } = useFetch<PhotoStudioReservationFrame>({
    iniState: defaultValues,
    reqProp: {
      pathKey: 'frame',
      pathParam: photoStudioReservationFrameId,
    },
    disable: !rule,
    observable: [listUpdate, rule],
  });

  const handleEditButtonOnClick = () => {
    navigate(`/${staff.userType}/setting/frame/${photoStudioReservationFrameId}/edit`);
  };

  const onDelete = () => {
    new Promise((resolve) =>
      push(resolve, {
        reqProp: {
          pathKey: 'frame',
          method: 'delete',
          pathParam: photoStudioReservationFrameId,
        },
        aftProp: {
          resolve,
          nextPath: `/setting/frame${returnSearch}`,
          showMessage: {
            state: { snackBar: { open: true, viewType: 'info', message: '削除しました' } },
          },
          errorMessage: '以下の日時に予約が入っているので削除できません',
        },
      })
    ).then(() => {
      setListUpdate(listUpdate + 1);
    });
  };

  return (
    <Container>
      <Button
        variant="contained"
        component={Link}
        to={`/${staff.userType}/setting/frame/${returnSearch}`}
        startIcon={<Iconify icon="ion:return-up-back-outline" />}
      >
        検索一覧に戻る
      </Button>
      <Stack direction="row" alignItems="center" justifyContent="start" mb={2} ml={1} mt={'3%'} mr={0}>
        <HeadLineText text={title} />

        <Box sx={{ ml: 'auto' }}>
          <DeleteModal
            sx={{
              bgcolor: 'background.paper',
              minWidth: 50,
              minHeight: 47,
              boxShadow: 1,
              borderRadius: 2,
              ml: 2,
            }}
            OnDelete={onDelete}
          />
          <EditButton handleEditOnClick={handleEditButtonOnClick} />
        </Box>
      </Stack>
      <DetailCard
        defaultValues={frameDetailFetchOrganize(responseData)}
        labelProperty={{
          表示: 'success',
          非表示: 'cancel',
        }}
      />
      <AvailablePlanCard
        plans={
          (responseData.availablePlans &&
            responseData.availablePlans.filter((r) => r.photoStudioPlanId !== rule.actionValue)) ??
          []
        }
      />
      <IrregularSettingList
        photoStudioId={responseData.photoStudioId}
        rsvStartEnd={{
          reservationStartTime: responseData.reservationStartTime,
          reservationEndTime: responseData.reservationEndTime,
        }}
      />
    </Container>
  );
};
