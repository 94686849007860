import { PhotoFormUiPartsList, ReserveDetail } from 'codegen/axios/photo/photo_studio';

export const mergeForm = (rsvDetailForm: ReserveDetail['form'], formInput: PhotoFormUiPartsList[]) => {
  if (!rsvDetailForm) return null;
  if (!formInput) return null;

  // rsvDetailFormのinput nameとformInputのnameが一致するものを探す,見つかったらoptionをマージする
  formInput.forEach((group) => {
    const currentGroup = rsvDetailForm.find((g) => g.groupName === group.groupName);
    group.inputs?.forEach((input) => {
      const currentInput = currentGroup?.inputs?.find((i) => i.name === input.name);

      // currentInputがなかったらinputごと全部入れる
      if (!currentInput) {
        const { photoFormUiPartsId, ...other } = input;
        currentGroup?.inputs?.push({
          ...other,
          value: [],
          availablePlans: input.availablePlans!.map((plan) => ({
            photoStudioPlanId: plan.photoStudioPlanId!,
          })),
          availableOptionIds: input.availableOptionIds || [],
          options: input.options.map((option) => ({
            label: option.label!,
          })),
        });
        return null;
      }
      // currentInputがあったら

      // 古い項目が新しい項目になかったら先頭に追加
      currentInput.options.forEach((option) => {
        if (!input.options.find((o) => o.label === option.label)) {
          input.options.unshift(option);
        }
      });
      // optionsを置き換え(これで新しい項目も入る)
      currentInput.options = input.options.map((option) => ({
        label: option.label!,
      }));
      return null;
    });
    // inputならびかえ
    currentGroup?.inputs?.sort((a, b) => a.orderNumber! - b.orderNumber!);
  });

  return rsvDetailForm;
};
