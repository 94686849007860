import { Box, Container, Grid, Stack, Typography, Card, Divider, CircularProgress } from '@mui/material';
import { ReserveDetail } from 'codegen/axios/photo/photo_studio';
import { FC, useState, Fragment } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import EditButton from 'src/components/atoms/edit-button';
import HeadLineText from 'src/components/atoms/head-line-text';
import { CancelButton } from 'src/components/molecules/cancel-modal';
import DeleteModal from 'src/components/molecules/delete-modal';
import { toDateDefault, toDateTimeDefault, toTimeOnly } from 'src/functions/date-time-organize';
import useFetch from 'src/hooks/use-fetch';
import usePush from 'src/hooks/use-push';
import useStaffInformation from 'src/hooks/use-staff-information';
import { phoneNumberAddHyphen } from 'src/functions/phone-number-format';
import { updateRsvDetailWithFormValueStatus } from '../list/status-change-modal';

type Props = {
  title: string;
};

export const ReservationDetail: FC<Props> = ({ title }) => {
  /* 定数宣言 */
  const staff = useStaffInformation();
  const { id } = useParams();
  const navigate = useNavigate();
  const push = usePush();
  const [listUpdate, setListUpdate] = useState(0);
  const [isSubmitting, setIsSubmitting] = useState(false);

  /* データ取得 */
  const { fetchedData: rsvDetail } = useFetch<ReserveDetail>({
    iniState: null,
    reqProp: {
      pathKey: 'reservation',
      pathParam: `${id}`,
    },
    observable: [listUpdate],
  });

  const handleEditButtonOnClick = () => {
    navigate(`/${staff.userType}/reservation/${id}/edit`);
  };

  const onDelete = (formValue: any) => {
    setIsSubmitting(true);
    new Promise((resolve) =>
      push(resolve, {
        reqProp: {
          pathKey: 'reservation',
          pathParam: id,
          method: 'put',
          data: updateRsvDetailWithFormValueStatus(formValue, rsvDetail),
        },
        aftProp: {
          resolve,
          nextPath: '',
          errorFunc: () => setIsSubmitting(false),
          showMessage: {
            state: { snackBar: { open: true, viewType: 'info', message: 'キャンセルしました' } },
          },
        },
      })
    ).then(() => {
      setIsSubmitting(false);
      setListUpdate(listUpdate + 1);
    });
  };
  let customTitleInputDone = false;
  return (
    <Container>
      <Stack direction="row" alignItems="center" justifyContent="start" mb={2} ml={1} mt={'3%'} mr={0}>
        <HeadLineText text={title} />

        <Box sx={{ ml: 'auto' }}>
          <CancelButton
            sx={{
              bgcolor: 'background.paper',
              minWidth: 50,
              minHeight: 47,
              boxShadow: 1,
              borderRadius: 2,
              ml: 2,
            }}
            onSubmit={onDelete}
            isSubmitting={isSubmitting}
          />
          <EditButton handleEditOnClick={handleEditButtonOnClick} />
        </Box>
      </Stack>
      <Card>
        {rsvDetail ? (
          <>
            <Box sx={{ p: 3 }}>
              <Grid container>
                <Grid item xs={12} sm={12} md={12}>
                  <Typography variant="h6" color="text.secondary">
                    予約情報
                  </Typography>
                  <Divider sx={{ mb: 3, mt: 1 }} />
                </Grid>

                <Grid item xs={4} sm={4} md={4}>
                  <Typography color="text.secondary" variant="body1">
                    <strong>予約日:</strong>
                  </Typography>
                </Grid>
                <Grid item xs={8} sm={8} md={8}>
                  <Typography color="text.secondary" variant="body1">
                    {toDateDefault(rsvDetail.reserve?.reservationStartDateTime)}{' '}
                    {`${toTimeOnly(rsvDetail.reserve?.reservationStartDateTime)} ~ ${toTimeOnly(
                      rsvDetail.reserve?.reservationEndDateTime
                    )}`}
                  </Typography>
                </Grid>

                <Grid item xs={4} sm={4} md={4}>
                  <Typography color="text.secondary" variant="body1">
                    <strong>店舗: </strong>
                  </Typography>
                </Grid>
                <Grid item xs={8} sm={8} md={8}>
                  <Typography color="text.secondary" variant="body1">
                    {rsvDetail.reserve?.photoStudioName}
                  </Typography>
                </Grid>
                <Grid item xs={4} sm={4} md={4}>
                  <Typography color="text.secondary" variant="body1">
                    <strong>プラン: </strong>
                  </Typography>
                </Grid>
                <Grid item xs={8} sm={8} md={8}>
                  <Typography color="text.secondary" variant="body1">
                    {rsvDetail.reserve?.photoStudioPlanName}
                  </Typography>
                </Grid>
                <Grid item xs={4} sm={4} md={4}>
                  <Typography color="text.secondary" variant="body1">
                    <strong>オプション: </strong>
                  </Typography>
                </Grid>
                <Grid item xs={8} sm={8} md={8}>
                  <Typography color="text.secondary" variant="body1">
                    {rsvDetail.selectedOption.map((option) => option.name).join(', ')}
                  </Typography>
                </Grid>

                <Grid item xs={4} sm={4} md={4}>
                  <Typography color="text.secondary" variant="body1">
                    <strong>フレーム: </strong>
                  </Typography>
                </Grid>
                <Grid item xs={8} sm={8} md={8}>
                  <Typography color="text.secondary" variant="body1">
                    {rsvDetail.reserve?.photoStudioFrameName}
                  </Typography>
                </Grid>
                <Grid item xs={4} sm={4} md={4}>
                  <Typography color="text.secondary" variant="body1">
                    <strong>お客様向け利用日時:</strong>
                  </Typography>
                </Grid>

                <Grid item xs={8} sm={8} md={8}>
                  <Typography color="text.secondary" variant="body1">
                    {`${toTimeOnly(rsvDetail.reserve?.reservationStartDateTimeForCustomer)} ~ ${toTimeOnly(
                      rsvDetail.reserve?.reservationEndDateTimeForCustomer
                    )}`}
                  </Typography>
                </Grid>
                <Grid item xs={4} sm={4} md={4}>
                  <Typography color="text.secondary" variant="body1">
                    <strong>登録日時: </strong>
                  </Typography>
                </Grid>
                <Grid item xs={8} sm={8} md={8}>
                  <Typography color="text.secondary" variant="body1">
                    {toDateDefault(rsvDetail.reserve?.createdAt)} {rsvDetail.reserve?.createdAt?.split(' ')[1]}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <Typography variant="h6" color="text.secondary" sx={{ pt: 5 }}>
                    顧客情報
                    <Divider sx={{ mb: 3, mt: 1 }} />
                  </Typography>
                </Grid>
                <Grid item xs={4} sm={4} md={4}>
                  <Typography color="text.secondary" variant="body1">
                    <strong> お名前: </strong>
                  </Typography>
                </Grid>
                <Grid item xs={8} sm={8} md={8}>
                  <Typography color="text.secondary" variant="body1">
                    {rsvDetail.reserve?.customerFirstName} {rsvDetail.reserve?.customerLastName}
                  </Typography>
                </Grid>
                <Grid item xs={4} sm={4} md={4}>
                  <Typography color="text.secondary" variant="body1">
                    <strong> お名前(ふりがな): </strong>
                  </Typography>
                </Grid>
                <Grid item xs={8} sm={8} md={8}>
                  <Typography color="text.secondary" variant="body1">
                    {rsvDetail.reserve?.customerFirstNameKana} {rsvDetail.reserve?.customerLastNameKana}
                  </Typography>
                </Grid>
                <Grid item xs={4} sm={4} md={4}>
                  <Typography color="text.secondary" variant="body1">
                    <strong>メールアドレス: </strong>
                  </Typography>
                </Grid>
                <Grid item xs={8} sm={8} md={8}>
                  <Typography color="text.secondary" variant="body1">
                    {rsvDetail.reserve?.customerEmail}
                  </Typography>
                </Grid>
                <Grid item xs={4} sm={4} md={4}>
                  <Typography color="text.secondary" variant="body1">
                    <strong>電話番号: </strong>
                  </Typography>
                </Grid>
                <Grid item xs={8} sm={8} md={8}>
                  <Typography color="text.secondary" variant="body1">
                    {phoneNumberAddHyphen(rsvDetail.reserve?.customerTelno)}
                  </Typography>
                </Grid>
                <Grid item xs={4} sm={4} md={4}>
                  <Typography color="text.secondary" variant="body1">
                    <strong> 郵便番号: </strong>
                  </Typography>
                </Grid>
                <Grid item xs={8} sm={8} md={8}>
                  <Typography color="text.secondary" variant="body1">
                    {rsvDetail.reserve?.zip}
                  </Typography>
                </Grid>
                <Grid item xs={4} sm={4} md={4}>
                  <Typography color="text.secondary" variant="body1">
                    <strong> 住所: </strong>
                  </Typography>
                </Grid>
                <Grid item xs={8} sm={8} md={8}>
                  <Typography color="text.secondary" variant="body1">
                    {rsvDetail.reserve?.prefecture}
                    {rsvDetail.reserve?.city}
                    {rsvDetail.reserve?.street}
                  </Typography>
                </Grid>
                {/* todo:ここ rsvDetail.formGroups.map */}
                {rsvDetail.form.map((v, i) => (
                  <Fragment key={i}>
                    <Grid item xs={12} sm={12} md={12}>
                      <Typography variant="h6" color="text.secondary" sx={{ pt: 5 }}>
                        {v.groupName}
                      </Typography>
                      <Divider sx={{ mb: 3, mt: 1 }} />
                    </Grid>
                    {v.inputs?.map((input, i) => {
                      if (
                        !input.availablePlans.some(
                          (obj) => obj.photoStudioPlanId === rsvDetail.reserve?.photoStudioPlanId
                        )
                      ) {
                        return undefined;
                      }

                      if (
                        input.availableOptionIds.length > 0 &&
                        !input.availableOptionIds.some((id) =>
                          rsvDetail.reserve.photoStudioOptions.map((op) => op.photoStudioOptionId).includes(id)
                        )
                      ) {
                        return undefined;
                      }
                      return (
                        <Fragment key={i}>
                          {(() => {
                            if (input.name.includes('【スタッフ入力】') && !customTitleInputDone) {
                              customTitleInputDone = true;
                              return (
                                <Grid item xs={12} sm={12} md={12}>
                                  <Typography variant="h6" color="text.secondary" sx={{ pt: 5 }}>
                                    {'顧客入力'}
                                  </Typography>
                                  <Divider sx={{ mb: 3, mt: 1 }} />
                                </Grid>
                              );
                            }
                            return undefined;
                          })()}
                          <Grid item xs={4} sm={4} md={4}>
                            <Typography color="text.secondary" variant="body1">
                              <strong> {input.name}: </strong>
                            </Typography>
                          </Grid>
                          <Grid item xs={8} sm={8} md={8}>
                            <Typography color="text.secondary" variant="body1">
                              {input.inputType !== 'textarea'
                                ? input.value
                                : input.value[0]?.split('\n').map((item, key) => (
                                    <span key={key}>
                                      {item}
                                      <br />
                                    </span>
                                  ))}
                            </Typography>
                          </Grid>
                        </Fragment>
                      );
                    })}
                  </Fragment>
                ))}
              </Grid>
            </Box>
          </>
        ) : (
          <Box sx={{ px: 10, display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100px' }}>
            <CircularProgress size={30} />
          </Box>
        )}
      </Card>
    </Container>
  );
};
