import Page from 'src/components/molecules/page';
import { Container, Stack, Typography } from '@mui/material';
import { FC } from 'react';
import { ReservationCreate } from 'src/components/organisms/reservation/create-edit/create';

export const ReservationCreatePage: FC = () => {
  /* 定数宣言 */
  const title = '予約登録';

  return (
    <Page title={title}>
      <ReservationCreate />
    </Page>
  );
};
