import React, { FC, useState } from 'react';
import { Dialog, Stack } from '@mui/material';
import { ImageDisplay } from 'src/components/atoms/image-display';
import { ImageInput } from 'src/components/atoms/image-input';
import DeleteModal from 'src/components/molecules/delete-modal';
import useFetch from 'src/hooks/use-fetch';
import usePush from 'src/hooks/use-push';
import { PhotoStudioPlanImage } from 'codegen/axios/photo/photo_studio';
import { imageFetchOrganize } from 'src/functions/fetch-value-organize';
import { getCroppedImg } from 'src/utils/cropImage';
import { CropModal } from 'src/components/molecules/crop-modal';
import { useParams } from 'react-router-dom';
import { Area } from 'react-easy-crop';

export type PhotoStudioPlanImageDisplayType = PhotoStudioPlanImage & { newImageUrl: string };

export type Props = {
  label: 'photoStudioPlanTop';
  width: number;
  height: number;
  displayNumber: number;
};

export const PhotoPlanImageField: FC<Props> = ({ label, width, height, displayNumber }) => {
  /* 定数宣言 */
  const date = new Date();

  /* hook宣言 */
  const { id: photoStudioPlanId } = useParams();
  const push = usePush();
  const [openCrop, setOpenCrop] = useState<boolean>(false);
  const [listUpdate, setListUpdate] = useState(0);
  const [photoURL, setPhotoURL] = useState<string>('');
  const [disabled, setDisabled] = useState<boolean>(false);
  const [orderNumber, setOrderNumber] = useState<number>(0);
  const [photoImageId, setPhotoImageId] = useState<string | undefined>();

  /* データ取得 */
  const { fetchedData: responseData } = useFetch<Array<PhotoStudioPlanImageDisplayType>>({
    iniState: [],
    reqProp: {
      pathKey: 'plan',
      pathParam: 'images',
      queryParams: { offset: 0, limit: 2, photoStudioPlanId },
    },
    organize: imageFetchOrganize,
    observable: [openCrop, listUpdate],
  });

  /* handle */
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>, orderNumber: number, photoImageId?: string) => {
    if (e && e.target.files) {
      setOpenCrop(true);
      const file: File = e.target.files[0];
      setPhotoURL(URL.createObjectURL(file));
      setOrderNumber(orderNumber);
      setPhotoImageId(photoImageId);
    }
  };

  const uploadImage = async (croppedAreaPixels: Area, rotation: number) => {
    try {
      setDisabled(true);
      const fileName = `${label}/${date}.jpg`;
      const file: File = await getCroppedImg(photoURL, croppedAreaPixels, rotation, fileName);
      const params = { label, orderNumber, photoStudioPlanId };
      const formData = new FormData();
      const data = JSON.stringify(params);
      formData.append('data', data);
      formData.append('image', file);

      await new Promise((resolve) => {
        push(resolve, {
          reqProp: {
            pathKey: 'plan',
            pathParam: !photoImageId ? 'images' : `images/${photoImageId}`,
            method: !photoImageId ? 'post' : 'put',
            data: formData,
          },
          aftProp: {
            nextPath: `/setting/plan/${photoStudioPlanId}`,
            showMessage: {
              state: {
                snackBar: {
                  open: true,
                  viewType: 'success',
                  message: !photoImageId ? '画像を登録しました' : '画像を更新しました',
                },
              },
            },
          },
        }).then(() => {
          setListUpdate(listUpdate + 1);
        });
      });
    } catch {
      alert('エラー：画像のアップロードに失敗しました。');
    }
    setDisabled(false);
    closeCrop();
  };

  const deleteImage = async (photoImageId: string) => {
    new Promise((resolve) => {
      push(resolve, {
        reqProp: {
          pathKey: 'plan',
          pathParam: `images/${photoImageId}`,
          method: 'delete',
        },
        aftProp: {
          nextPath: `/setting/plan/${photoStudioPlanId}`,
          showMessage: {
            state: { snackBar: { open: true, viewType: 'info', message: '削除しました' } },
          },
        },
      });
    }).then(() => {
      setListUpdate(listUpdate + 1);
    });
  };

  const closeCrop = () => {
    setOpenCrop(false);
    setPhotoURL('');
  };

  return (
    <Stack
      direction="row"
      justifyContent={displayNumber < 3 ? 'center' : 'start'}
      spacing={4}
      p={4}
      sx={{ overflowX: 'auto' }}
    >
      {[...Array(displayNumber)].map((_, i) => {
        const image = responseData.find((data: PhotoStudioPlanImageDisplayType) => data.orderNumber === i);

        return (
          <React.Fragment key={i}>
            {image ? (
              <Stack direction="column" justifyContent="center" alignItems="center" spacing={0.5}>
                <ImageDisplay
                  orderNumber={i}
                  width={width}
                  height={height}
                  photoImageId={image.photoStudioPlanImageId}
                  photoURL={image.newImageUrl}
                  handleChange={handleChange}
                />
                <DeleteModal OnDelete={() => deleteImage(image.photoStudioPlanImageId)} />
              </Stack>
            ) : (
              <ImageInput orderNumber={i} width={width} height={height} handleChange={handleChange} />
            )}
            <Dialog maxWidth="sm" open={openCrop}>
              <CropModal
                {...{
                  width,
                  height,
                  photoURL,
                  disabled,
                  closeCrop,
                  uploadImage,
                }}
              />
            </Dialog>
          </React.Fragment>
        );
      })}
    </Stack>
  );
};
