// @mui
import { Card, Table, Stack, Container, Typography, TableContainer, TablePagination } from '@mui/material';
// components
import ListHead from 'src/components/molecules/list-head';
import Scrollbar from 'src/components/molecules/scroll-bar';
import NotFoundTableBody from 'src/components/molecules/not-found-table-body';
import ListToolbar from 'src/components/molecules/list-toolbar';
import CustomTableBody from 'src/components/molecules/custom-table-body';
// function
import { count, labelDisplayedRows } from 'src/functions/pagination';
import { CustomerList } from 'src/types/customer-form';

interface TableColumn {
  id: string;
  label: string;
  align: 'left' | 'right' | 'center';
  typographyProperty?: { variant: 'subtitle2' | 'body1' | 'caption'; noWrap: boolean };
}

const TABLE_HEAD: TableColumn[] = [
  { id: 'name', label: '氏名', align: 'left', typographyProperty: { variant: 'subtitle2', noWrap: true } },
  { id: 'email', label: 'メールアドレス', align: 'left' },
  { id: 'telno', label: '電話番号', align: 'left' },
];

export default function CustomerListTemplate({
  nextPageCheck,
  page,
  rowsPerPage,
  searchName,
  responseData,
  isUserNotFound,
  handleFilterByName,
  handleSearchByName,
  handleRowClick,
  handleChangePage,
}: any) {
  const emptyRows = page > 0 ? Math.max(0, rowsPerPage - responseData.length) : 0;

  return (
    <Container>
      <Stack direction="row" alignItems="center" justifyContent="space-between" my={5} mt={'3%'} ml={5} mr={5}>
        <Typography variant="h4" gutterBottom>
          顧客一覧
        </Typography>
      </Stack>

      <Card>
        <ListToolbar
          searchName={searchName}
          onFilterName={handleFilterByName}
          handleSearchByName={handleSearchByName}
          placeholder={'氏名、メールアドレス、電話番号'}
          width={350}
        />

        <Scrollbar>
          <TableContainer sx={{ minWidth: 800 }}>
            <Table>
              <ListHead headLabel={TABLE_HEAD} />

              <CustomTableBody
                {...(() => ({
                  TABLE_HEAD,
                  responseData,
                  handleRowClick,
                  emptyRows,
                }))()}
              />

              {isUserNotFound && <NotFoundTableBody searchName={searchName} />}
            </Table>
          </TableContainer>
        </Scrollbar>

        <TablePagination
          rowsPerPageOptions={[30]}
          component="div"
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          count={count(nextPageCheck, rowsPerPage, page)}
          labelDisplayedRows={({ from, to }) => labelDisplayedRows(responseData, nextPageCheck, from, to)}
        />
      </Card>
    </Container>
  );
}
