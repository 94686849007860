// @mui
import { TableRow, TableBody, TableCell } from '@mui/material';
// components
import SearchNotFound from 'src/components/molecules/search-not-found';

export default function NotFoundTableBody({ searchName, overrideMsg }: any) {
  return (
    <TableBody>
      <TableRow>
        <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
          <SearchNotFound searchQuery={searchName} overrideMsg={overrideMsg} />
        </TableCell>
      </TableRow>
    </TableBody>
  );
}
