import { Container, Stack } from '@mui/material';
import { FC } from 'react';
import CreateButton from 'src/components/atoms/create-button';
import HeadLineText from 'src/components/atoms/head-line-text';
import Page from 'src/components/molecules/page';
import { AccountList } from 'src/components/organisms/account/list';
import useStaffInformation from 'src/hooks/use-staff-information';

export const AccountListPage: FC = () => {
  /* hook宣言 */
  const staff = useStaffInformation();
  /* 定数宣言 */
  const title = 'アカウント一覧';

  return (
    <Page title={title}>
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" m={5}>
          <HeadLineText text={title} />

          <CreateButton path={`/${staff.userType}/setting/account/new`} text="アカウント登録" />
        </Stack>
        <AccountList />
      </Container>
    </Page>
  );
};
