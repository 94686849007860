import Page from 'src/components/molecules/page';
import { FC } from 'react';
import { ReservationCreateEdit } from 'src/components/organisms/reservation/create-edit/edit';

const title = '予約編集';

export const ReservationEditPage: FC = () => (
  <Page title={title}>
    <ReservationCreateEdit />
  </Page>
);
