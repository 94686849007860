import { FC } from 'react';
import Page from 'src/components/molecules/page';
import { AccountDetail } from 'src/components/organisms/account/detail';

export const AccountDetailPage: FC = () => {
  /* 定数宣言 */
  const title = 'アカウント詳細';

  return (
    <Page title={title}>
      <AccountDetail />
    </Page>
  );
};
