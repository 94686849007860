import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { apiCall, createApiUrl } from 'src/functions/api/call';
import useStaffInformation from 'src/hooks/use-staff-information';
import { ApiEndpoints } from 'src/utils/api';

export interface UseFetchProps {
  iniState?: any;
  observable?: any[];
  disable?: boolean;
  reqProp: {
    method?: 'get' | 'post' | 'put' | 'delete';
    pathKey: keyof ApiEndpoints;
    pathParam?: string;
    queryParams?: { [key: string]: any };
    data?: any;
  };
  aftProp?: {
    errorFunc?: (err: any) => void;
    resolve?: (res: any) => void;
    nextPath?: string;
    showMessage?: boolean;
    nextPathUseResponseData?: boolean;
  };
  setStateFunc?: (_: any) => void;
  organize?: any;
  organizeOption?: any;
}

export default function useFetch<T>({
  iniState = [],
  observable = [],
  disable = false,
  reqProp: { method = 'get', pathKey, pathParam, queryParams, data },
  aftProp: { errorFunc, resolve, nextPath, showMessage, nextPathUseResponseData } = {},
  setStateFunc,
  organize,
  organizeOption,
}: UseFetchProps) {
  const [loading, setLoading] = useState(false);
  const [fetchedData, setFetchedData] = useState<T>(iniState);
  const navigate = useNavigate();
  const { pathname, search } = useLocation();
  const staff = useStaffInformation();
  useEffect(() => {
    if (disable) return;

    const url = createApiUrl({
      staff,
      pathKey,
      pathParam,
      queryParams,
    });

    (async () => {
      setLoading(true);
      const res = await apiCall<T>({
        reqProp: { method, pathKey, url, data },
        curtProp: { staff, pathname, search },
        aftProp: {
          errorFunc: () => {
            setLoading(false);
            errorFunc;
          },
          resolve,
          navigate,
          nextPath,
          showMessage,
          nextPathUseResponseData,
        },
      });
      const resData = organize ? organize(res, organizeOption) : res;
      setFetchedData(resData);
      setStateFunc && setStateFunc(resData);
      setLoading(false);
    })();
  }, observable);
  return { fetchedData, setFetchedData, loading };
}
