import { Box, Card, CardHeader, Container, Stack } from '@mui/material';
import { PhotoPlan } from 'codegen/axios/photo/photo_studio';
import { FC } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import EditButton from 'src/components/atoms/edit-button';
import HeadLineText from 'src/components/atoms/head-line-text';
import DeleteModal from 'src/components/molecules/delete-modal';
import { planDetailFetchOrganize } from 'src/functions/fetch-value-organize';
import useFetch from 'src/hooks/use-fetch';
import usePush from 'src/hooks/use-push';
import useStaffInformation from 'src/hooks/use-staff-information';
import DetailCard from 'src/components/molecules/detail-card';
import { AvailableOptionCard } from 'src/components/organisms/plan/detail/available-options';
import { defaultValues } from 'src/components/organisms/plan/form';
import { PhotoPlanImageField } from './plan-image';

export const PlanDetail: FC = () => {
  /* 定数宣言 */
  const title = 'プラン詳細';
  const staff = useStaffInformation();
  const { id: photoStudioPlanId } = useParams();
  const navigate = useNavigate();
  const push = usePush();

  /* データ取得 */
  const { fetchedData: responseData } = useFetch<PhotoPlan>({
    iniState: defaultValues,
    reqProp: {
      pathKey: 'plan',
      pathParam: photoStudioPlanId,
    },
  });

  const handleEditButtonOnClick = () => {
    navigate(`/${staff.userType}/setting/plan/${photoStudioPlanId}/edit`);
  };

  const onDelete = () => {
    new Promise((resolve) =>
      push(resolve, {
        reqProp: {
          pathKey: 'plan',
          method: 'delete',
          pathParam: photoStudioPlanId,
        },
        aftProp: {
          resolve,
          nextPath: `/setting/plan`,
          showMessage: {
            state: { snackBar: { open: true, viewType: 'info', message: '削除しました' } },
          },
        },
      })
    );
  };

  return (
    <Container>
      <Stack direction="row" alignItems="center" justifyContent="start" mb={2} ml={1} mt={'3%'} mr={0}>
        <HeadLineText text={title} />

        <Box sx={{ ml: 'auto' }}>
          <DeleteModal
            sx={{
              bgcolor: 'background.paper',
              minWidth: 50,
              minHeight: 47,
              boxShadow: 1,
              borderRadius: 2,
              ml: 2,
            }}
            OnDelete={onDelete}
          />

          <EditButton handleEditOnClick={handleEditButtonOnClick} />
        </Box>
      </Stack>

      <DetailCard
        defaultValues={planDetailFetchOrganize(responseData)}
        labelProperty={{
          する: 'success',
          しない: 'cancel',
        }}
      />

      <AvailableOptionCard options={responseData.availableOptions ?? []} />

      <Card sx={{ mt: 5, whiteSpace: 'pre-line' }}>
        <CardHeader title="プラン画像" sx={{ mt: 1 }} />

        <PhotoPlanImageField label="photoStudioPlanTop" width={480} height={270} displayNumber={1} />
      </Card>
    </Container>
  );
};
