import React, { useEffect } from 'react';
import { Container, Link, Typography } from '@mui/material';
import Page from 'src/components/molecules/page';

export const externalSiteUrl = 'https://www.studioselfit.com/';
const ExternalSitePage: React.FC = () => {
  useEffect(() => {
    const openExternalSite = () => {
      window.open(externalSiteUrl, '_blank');
    };

    openExternalSite();
  }, []);

  return (
    <Page title={'トップページ'}>
      <Container>
        <Typography color="text.secondary">リダイレクトしています...</Typography>
        <Link href={externalSiteUrl} target="_blank">
          リダイレクトしない場合はこちら
        </Link>
      </Container>
    </Page>
  );
};

export default ExternalSitePage;
