import { LoadingButton } from '@mui/lab';
import { Card, Checkbox, Container, FormControlLabel, Stack, Table, TableContainer } from '@mui/material';
import { AvailableOption } from 'codegen/axios/photo/photo_studio';
import { FC, useEffect } from 'react';
import { useForm, SubmitHandler, useFieldArray } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { FormProvider } from 'src/components/atoms/hook-form';
import NotFoundTableBody from 'src/components/molecules/not-found-table-body';
import { availableOptionFetchOrganize } from 'src/functions/fetch-value-organize';
import { availableOptionValueOrganize } from 'src/functions/push-value-organize';
import useFetch from 'src/hooks/use-fetch';
import usePush from 'src/hooks/use-push';

export type AvailableOptionFormType = {
  options: Array<AvailableOption>;
};

export const AvailableOptionForm: FC = () => {
  /* hook宣言 */
  const { id: photoStudioPlanId } = useParams();
  const push = usePush();

  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
    reset,
  } = useForm<AvailableOptionFormType>({
    defaultValues: {
      options: [],
    },
  });

  const { fields, append } = useFieldArray({
    control,
    name: 'options',
  });

  // /* データ取得 */
  const { fetchedData: responseData } = useFetch<Array<AvailableOption>>({
    iniState: [],
    reqProp: {
      pathKey: 'plan',
      pathParam: `${photoStudioPlanId}/options`,
    },
    organize: availableOptionFetchOrganize,
  });

  useEffect(() => {
    reset({ options: responseData });
  }, [responseData]);

  const handleCheckboxChange = (index: number, checked: boolean) => {
    const newFields = [...fields];
    newFields[index].availableFlg = checked;
    reset({ options: [] });
    append(newFields);
  };

  const onSubmit: SubmitHandler<AvailableOptionFormType> = (data: AvailableOptionFormType) => {
    new Promise((resolve) => {
      push(resolve, {
        reqProp: {
          pathKey: 'plan',
          pathParam: `${photoStudioPlanId}/options`,
          method: 'put',
          data: availableOptionValueOrganize(data.options),
        },
        aftProp: {
          resolve,
          nextPath: `/setting/plan/${photoStudioPlanId}`,
          showMessage: {
            state: {
              snackBar: { open: true, viewType: 'success', message: '保存しました' },
            },
          },
        },
      });
    });
  };

  const isOptionNotFound = responseData.length !== 0;

  return (
    <FormProvider onSubmit={handleSubmit(onSubmit)}>
      {isOptionNotFound && (
        <Container>
          <Card sx={{ mt: 5, p: 5 }}>
            <Stack>
              {fields.map((field, index) => (
                <FormControlLabel
                  key={`${field.id}-${index}`}
                  label={field.name}
                  control={
                    <Checkbox
                      checked={field.availableFlg}
                      onChange={(e) => handleCheckboxChange(index, e.target.checked)}
                    />
                  }
                />
              ))}
            </Stack>
          </Card>

          <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }} />

          <Stack direction="row" alignItems="center" justifyContent="flex-end" sx={{ m: 1 }} spacing={8}>
            <LoadingButton type="submit" sx={{ width: 200 }} size="large" variant="contained" loading={isSubmitting}>
              保存
            </LoadingButton>
          </Stack>
        </Container>
      )}
      {!isOptionNotFound && (
        <Card>
          <TableContainer sx={{ minWidth: 800 }}>
            <Table>
              <NotFoundTableBody overrideMsg="オプションが見つかりませんでした。「設定 > オプション」からオプションを登録してください。" />
            </Table>
          </TableContainer>
        </Card>
      )}
    </FormProvider>
  );
};
