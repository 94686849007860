// components
import Page from 'src/components/molecules/page';
import usePush from 'src/hooks/use-push';
import CompanyCreateTemplate from 'src/components/templates/company/company-create-template';
import { companyValueOrganize as valueOrganize } from 'src/functions/push-value-organize';
import { CompanyForm } from 'src/types/company-form';

export default function Create() {
  /* hook宣言 */
  const push = usePush();

  /* 定数宣言 */
  const title = '会社登録';
  const defaultValues = {
    companyId: '',
    companyName: '',
    companyNameKana: '',
    zip: '',
    prefecture: null,
    city: '',
    street: '',
    email: '',
    telno: '',
  };

  /* handle */
  const OnSubmit = (formValue: CompanyForm) =>
    new Promise((resolve) => {
      push(resolve, {
        reqProp: { pathKey: 'company', method: 'post', data: valueOrganize(formValue) },
        aftProp: {
          resolve,
          nextPath: '/companies',
          nextPathUseResponseData: true,
          showMessage: {
            state: { snackBar: { open: true, viewType: 'success', message: '登録しました。' } },
          },
        },
      });
    });

  return (
    <Page title={title}>
      <CompanyCreateTemplate
        {...(() => ({
          OnSubmit,
          defaultValues,
          title,
        }))()}
      />
    </Page>
  );
}
